import React, { useState, useEffect, useRef } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./support.css";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function Support() {
  const formRef = useRef();
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const initialValues = { response: "" };
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedUserData, setSelectedUserData] = useState({});

  // add message modal
  const [openView, setOpenView] = useState(false);
  const handleOpenView = (data) => {
    setSelectedUserData(data);
    setOpenView(true);
  };
  const handleCloseView = () => setOpenView(false);
  const [openMessage, setOpenMessage] = useState(false);
  const handleOpenMessage = (data) => {
    setSelectedUserData(data);
    setOpenMessage(true);
  };
  const handleCloseMessage = () => setOpenMessage(false);

  const handleGetUserQuery = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_USER_QUERY).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setData(res.data);
        setFilteredData(res.data);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    handleGetUserQuery();
  }, []);

  const requestSearch = (e) => {
    let result = data.filter((data) => {
      let fullName = (data?.firstName + " " + data?.lastName)
        ?.toLowerCase()
        .replace(/\s/g, "");
      let searchQuery = e?.target?.value
        ?.trim()
        ?.toLowerCase()
        .replace(/\s/g, "");

      let email = data?.email?.toLowerCase().includes(searchQuery);
      let number = data?.phoneNumber?.toString().includes(searchQuery);
      let subject =
        data?.subject &&
        data?.subject
          .toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase());

      const date = new Date(data?.createdAt);
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      let filterDate = formattedDate.includes(e?.target?.value);

      return (
        fullName.includes(searchQuery) ||
        email ||
        number ||
        subject ||
        filterDate
      );
    });
    setCurrentPage(1);
    setFilteredData(result);
  };

  const renderTableRows = () => {
    return filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data, index) => {
        const startIndex = (currentPage - 1) * 10;
        const adjustedIndex = startIndex + index + 1;
        return (
          <Index.TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            key={data?._id}
          >
            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {adjustedIndex}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Tooltip
                title={data?.firstName}
                arrow
                placement="top"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {" "}
                  {data?.firstName ? data?.firstName : "-"}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.email ? data?.email : "-"}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={data?.subject}
                arrow
                placement="top"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {" "}
                  {data?.subject}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {PagesIndex.moment(data?.createdAt)?.format(
                  "DD/MM/YYYY hh:mm A"
                )}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
              align="right"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.revertedBy?.name || "-"}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Box className="admin-table-data-btn-flex">
                {adminDetails?.roleId?.permissions?.includes("support_log") && (
                  <Index.Tooltip
                    title={data?.response ? "Reverted" : "Revert"}
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                  >
                    {data?.response ? (
                      <Index.Button className="admin-table-data-btn">
                        <img
                          src={PagesIndex.Svg.checkIcon}
                          className="admin-icon"
                          alt="Revert"
                        />
                      </Index.Button>
                    ) : (
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => handleOpenMessage(data)}
                      >
                        <img
                          src={PagesIndex.Svg.reverticon}
                          className="admin-icon"
                          alt="Revert"
                        />
                      </Index.Button>
                    )}
                  </Index.Tooltip>
                )}

                <Index.Tooltip
                  title="View"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-table-data-btn"
                    onClick={() => handleOpenView(data)}
                  >
                    <img
                      src={PagesIndex.Svg.yelloweye}
                      className="admin-icon"
                      alt="Revert"
                    />
                  </Index.Button>
                </Index.Tooltip>
              </Index.Box>
            </Index.TableCell>
          </Index.TableRow>
        );
      });
  };

  const renderContent = () => {
    if (filteredData?.length) {
      return renderTableRows();
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };

  const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const generateCSV = async () => {
    const headers = [
      "Sr. No.",
      "User Name",
      "Email Id",
      "Subject",
      "Description",
      "Date",
    ];
    let modifiedData = filteredData.map((data, index) => {
      let objData = [
        index + 1,
        data?.firstName ? data?.firstName.replaceAll(",", "") : "-",
        data?.email ? data?.email : "-",
        data?.subject ? data?.subject.replaceAll(",", "") : "-",
        data?.description ? data?.description.replaceAll(",", "") : "-",
        PagesIndex.moment(data?.createdAt)?.utc().format("DD/MM/YYYY hh:mm A"),
      ];
      return objData.join(",");
    });

    exportData(
      [[headers, ...modifiedData].join("\n")],
      `${PagesIndex.moment().format("DD-MM-YYYY hh:mm:ss A")}_Support_list.csv`,
      "text/csv"
    );
  };
  const handleSubmitResponse = (values) => {
    setButtonSpinner(true)
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", selectedUserData?._id);
    urlencoded.append("response", values?.response);
    PagesIndex.apiPostHandler(
      PagesIndex.Api.RESPONSE_USER_QUERY,
      urlencoded
    ).then((res) => {
      setButtonSpinner(false)
      if (res.status === 200) {
        handleGetUserQuery();
        handleCloseMessage();
        PagesIndex.toasterSuccess(res?.message);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-page-title admin-user-list-page-title"
          component="h2"
          variant="h2"
        >
          Support List
        </Index.Typography>
        <Index.Box className="admin-userlist-btn-flex">
          <Index.Box className="admin-search-main">
            <Index.Box className="admin-search-box">
              <Index.Box className="admin-form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="admin-form-control"
                  placeholder="Search"
                  onChange={requestSearch}
                />
                <img
                  src={PagesIndex.Svg.search}
                  className="admin-search-grey-img admin-icon"
                  alt="search"
                ></img>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {filteredData?.length > 0 && <Index.Box className="admin-userlist-inner-btn-flex">
            <Index.Box className="admin-adduser-btn-main btn-main-secondary">
              <Index.Button
                className="admin-adduser-btn btn-secondary"
                onClick={generateCSV}
              >
                <img
                  src={PagesIndex.Svg.exporticon}
                  className="admin-plus-icon"
                  alt="plus"
                />
                <span>Export CSV</span>
              </Index.Button>
            </Index.Box>
          </Index.Box>}
        </Index.Box>
      </Index.Box>

      <Index.Box className="common-card">
        <Index.Box
          className={`admin-zone-table-main ${loading ? "" : "page-table-main"
            }`}
        >
          <Index.TableContainer
            component={Index.Paper}
            className="table-container"
          >
            {loading ? (
              <PagesIndex.Loader />
            ) : (
              <Index.Table aria-label="simple table" className="table">
                <Index.TableHead className="table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="7%"
                    >
                      Sr. No.
                    </Index.TableCell>

                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="12%"
                    >
                      Name
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="12%"
                    >
                      Email Id
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="20%"
                    >
                      Subject
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="15%"
                    >
                      Date & Time
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="15%"
                      align="right"
                    >
                      Reverted By
                    </Index.TableCell>

                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="10%"
                      align="right"
                    >
                      Action
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  {renderContent()}
                </Index.TableBody>
              </Index.Table>
            )}
          </Index.TableContainer>
        </Index.Box>
        <Index.Box className="admin-pagination-main">
          {filteredData?.length ? (
            <Index.Pagination
              page={currentPage}
              count={Math.ceil(filteredData?.length / 10)}
              onChange={(event, newPage) => setCurrentPage(newPage)}
              variant="outlined"
              shape="rounded"
              className="admin-pagination"
            />
          ) : (
            ""
          )}
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={openMessage}
        onClose={handleCloseMessage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-add-user-modal-inner-main admin-modal-inner"
        >
          <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleSubmitResponse}
            initialValues={initialValues}
            validationSchema={PagesIndex.responseSchema}
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} noValidate="novalidate">
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {" "}
                    Response
                  </Index.Typography>
                  <Index.Button
                    className="modal-close-btn"
                    onClick={handleCloseMessage}
                  >
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Button>
                </Index.Box>
                <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                  <Index.Box className="support-details-main">
                    <Index.Box className="support-flex">
                      <Index.Typography className="support-lable">
                        Subject:
                      </Index.Typography>
                      <Index.Typography className="support-data">
                        {selectedUserData?.subject}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="support-flex">
                      <Index.Typography className="support-lable">
                        Description:
                      </Index.Typography>
                      <Index.Typography className="support-data">
                        {selectedUserData?.description}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.Box className="admin-form-group">
                      <Index.FormHelperText className="admin-form-lable">
                        Response<span className="astrick-sing">*</span>{" "}
                        <span className="option-condition-text">
                          (Max character length: 250)
                        </span>
                      </Index.FormHelperText>
                      <Index.TextareaAutosize
                        name="response"
                        aria-label="minimum height"
                        style={{ resize: "vertical", overflow: "auto" }}
                        value={values?.response}
                        onChange={handleChange}
                        placeholder="Enter Response"
                        className="admin-form-control-textarea"
                        maxLength={250}
                      />
                      {touched.response && errors.response && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.response}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="admin-discard-btn-main border-btn-main">
                      <Index.Button
                        className="admin-discard-user-btn border-btn"
                        onClick={handleCloseMessage}
                        disabled={buttonSpinner}
                      >
                        Discard
                      </Index.Button>
                    </Index.Box>
                    <Index.Box className="admin-save-btn-main btn-main-primary">
                      <Index.Button
                        className="admin-save-user-btn btn-primary"
                        type="submit"
                        disabled={buttonSpinner}
                      >
                        {buttonSpinner ? <PagesIndex.Spinner /> :
                          (<>
                            <img
                              src={PagesIndex.Svg.save}
                              className="admin-user-save-icon"
                              alt="Save"
                            />
                            <span>Save</span>
                          </>)}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Modal>

      <Index.Modal
        open={openView}
        onClose={handleCloseView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-add-user-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="admin-modal-title"
              variant="h6"
              component="h2"
            >
              {" "}
              View Details
            </Index.Typography>
            <Index.Button className="modal-close-btn" onClick={handleCloseView}>
              <img
                src={PagesIndex.Svg.closeblack}
                className="admin-modal-close-icon"
                alt="Close"
              />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="support-details-main">
              <Index.Box className="support-flex">
                <Index.Typography className="support-lable">
                  Name:
                </Index.Typography>
                <Index.Typography className="support-data">
                  {selectedUserData?.firstName}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="support-flex">
                <Index.Typography className="support-lable">
                  Subject:
                </Index.Typography>
                <Index.Typography className="support-data">
                  {selectedUserData?.subject}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="support-flex">
                <Index.Typography className="support-lable">
                  Description:
                </Index.Typography>
                <Index.Typography className="support-data">
                  {selectedUserData?.description}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="support-flex">
                <Index.Typography className="support-lable">
                  Response:
                </Index.Typography>
                <Index.Typography className="support-data">
                  {selectedUserData?.response || "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="modal-footer">
            <Index.Box className="admin-modal-user-btn-flex">
              <Index.Box className="admin-save-btn-main btn-main-primary">
                <Index.Button
                  className="admin-save-user-btn btn-primary"
                  onClick={handleCloseView}
                >
                  <span>Close</span>
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </Index.Box>
  );
}
