import React, { useCallback, useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./manageUser.responsive.css";
import { useNavigate, useParams } from "react-router-dom";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function ViewUser() {
  const colorTheme = [
    "#111827",
    "#6b7280",
    "#c85803",
    "#000",
    "#b90005",
    "#3c0000",
    "#717171",
    "#dbd5ce",
    "#5cb37d",
    "#adb5bd",
    "#ffa447",
    "#59B4C3",
    "#D04848",
    "#173ab8",
  ];
  const navigate = useNavigate();
  const { id } = useParams();

  const [openMessage, setOpenMessage] = useState(false);
  const handleOpenMessage = () => setOpenMessage(true);
  const handleCloseMessage = () => setOpenMessage(false);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);

  const [seriesPie, setSeriesPie] = useState([]);
  const [dataLength, setDataLength] = useState(20)

  const [openContactModal, setOpenContactModal] = useState(false);
  const handleCloseContactModal = () => setOpenContactModal(false);
  const [contactList, setContactList] = useState([]);
  const [contactListLoading, setContactListLoading] = useState(true);
  const [buttonSpinner, setButtonSpinner] = useState(false);

  // add message modal
  const [currentPage, setCurrentPage] = useState(1);
  const [userDetails, setUserDetails] = useState({});
  const [userCount, setUserCount] = useState({});
  const [openUnblockUser, setOpenUnblockUser] = useState(false);
  const [userNewsRating, setUserNewsRating] = useState([]);
  const [voterList, setVoterList] = useState([]);
  const [ratingLoader, setRatingLoader] = useState(false);
  const [voteloading, setVoteloading] = useState(false);

  const initialValues = {
    reason: userDetails?.reason ? userDetails?.reason : "",
  };

  const [optionsPie, setOptionsPie] = useState({
    chart: {
      width: "100%",
      // height: 400,
      type: "pie",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
        },
        export: {
          csv: {
            filename: "Content Duration Distribution",
            columnDelimiter: ',',
            // headerCategory: 'Times Of (the) Day',
            // headerValue: 'View News',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            }
          },
          svg: {
            filename: "Content Duration Distribution",
          },
          png: {
            filename: "Content Duration Distribution",
          }
        },
        autoSelected: 'zoom'
      },
    },
    stroke: {
      width: 0,
    },
    dataLabels: {
      style: {
        fontSize: "10px",
        fontWeight: 100,
      },
      formatter: function (val) {
        return val == 100 ? val : val.toFixed(2) + "%";
      },
    },
    labels: [],
    noData: {
      text: "No data available",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  const [seriesBar, setSeriesBar] = useState([
    {
      name: "Views",
      data: [],
    },
  ]);
  const [optionsBar, setOptionsBar] = useState({
    chart: {
      height: 280,
      type: "bar",
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    noData: {
      text: "No data available",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    xaxis: {
      categories: [],
      position: "bottom",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val;
        },
      },
    },
  });


  const generateRandomColors = (numColors) => {
    const colors = [];
    const usedIndexes = new Set();

    while (colors.length < numColors) {
      const randomIndex = Math.floor(Math.random() * colorTheme.length);
      if (!usedIndexes.has(randomIndex)) {
        colors.push(colorTheme[randomIndex]);
        usedIndexes.add(randomIndex);
      }
    }

    return colors;
  };

  const handleGetUsercontactList = () => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("pageNumber", 1);
    urlEncoded.append("pageSize", dataLength);

    PagesIndex.apiPostHandler(
      PagesIndex.Api.GET_USER_CONTECT_LIST + "/" + id, urlEncoded
    ).then((res) => {
      setContactListLoading(false);
      if (res.status === 200) {
        setContactList(res.data?.response);
        setDataLength(res?.data?.lenth)
      }
    });
  }

  const handleOpenContactModal = () => {
    setOpenContactModal(true);
    setContactListLoading(true);
    handleGetUsercontactList();
  };

  const handleOpenUnblockUser = () => setOpenUnblockUser(true);
  const handleCloseUnblockUser = () => setOpenUnblockUser(false);

  const handleGetUserDetails = useCallback(() => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_USER_DETAILS + "/" + id).then(
      (res) => {
        if (res.status === 200) {
          setUserDetails(res.data);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  }, []);
  const handleGetVoteList = useCallback(() => {
    setVoteloading(true);
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_USER_VOTES_LIST + "/" + id
    ).then((res) => {
      setVoteloading(false);
      if (res.status === 200) {
        setVoterList(res?.data);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  }, [id]);

  const handleGetUserCount = useCallback(() => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_USER_COUNT + "/" + id).then(
      (res) => {
        if (res.status === 200) {
          setUserCount(res.data);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  }, [id]);

  const handleGetUserGraphDetails = useCallback(() => {
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_USER_DETAILS_GRAPH + "/" + id
    ).then((res) => {
      if (res.status === 200) {
        const colors = generateRandomColors(res?.data?.graphData?.length);
        setSeriesPie(
          res?.data?.graphData?.map(
            (data) => +parseFloat(data?.percentage)?.toFixed(1)
          )
        );
        setOptionsPie((prev) => ({
          ...prev,
          labels: res?.data?.graphData?.map((data) => data?.categoryName),
          colors: colors,
        }));
        setOptionsBar((prev) => ({
          ...prev,
          xaxis: {
            ...prev?.xaxis,
            categories: res?.data?.graphData?.map((data) => data?.categoryName),
          },
        }));
        setSeriesBar((prev) => [
          {
            ...prev[0],
            data: res?.data?.graphData?.map((data) => data?.views),
          },
        ]);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  }, [id]);

  const handleGetUserNewsRating = useCallback(() => {
    setRatingLoader(true);
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_USER_NEWS_RATING + "/" + id
    ).then((res) => {
      setRatingLoader(false);
      if (res.status === 200) {
        setUserNewsRating(res?.data);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  }, [id]);

  useEffect(() => {
    handleGetUserNewsRating();
    handleGetUserDetails();
    handleGetUserCount();
    handleGetUserGraphDetails();
    handleGetVoteList();
  }, [
    handleGetUserGraphDetails,
    handleGetUserCount,
    handleGetUserDetails,
    handleGetUserNewsRating,
    handleGetVoteList,
  ]);

  useEffect(() => {
    handleGetUsercontactList()
  }, [])

  const handleAddEditBlockResone = (values) => {
    setButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", userDetails?._id);
    urlEncoded.append("reason", values?.reason);

    PagesIndex.apiPostHandler(
      PagesIndex.Api.ADD_EDIT_USER_BLOCK_REASON,
      urlEncoded
    )
      .then((res) => {
        setButtonSpinner(false)
        if (res.status === 201 || res.status === 200) {
          handleCloseMessage();
          handleCloseUnblockUser();
          handleGetUserDetails();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };

  const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const generateNewsRatingCSV = async () => {
    const headers = [
      "News ID",
      "Category",
      "Date & Time",
    ];
    let modifiedData = userNewsRating?.map((data, index) => {
      let objData = [
        data?.newsId?.newsNo ? data?.newsId?.newsNo?.replaceAll(",", "") : "-",
        data?.newsId?.category[0]?.categoryName
          ? data?.newsId?.category[0]?.categoryName?.replaceAll(",", "|")
          : "-",
        data?.createdAt
          ? PagesIndex.moment(data?.createdAt).format("DD/MM/YYYY")
          : "-",
      ];
      return objData.join(",");
    });

    exportData(
      [[headers, ...modifiedData].join("\n")],
      `${PagesIndex.moment().format(
        "DD-MM-YYYY hh:mm:ss A"
      )}_News_Rating_Distribution_list.csv`,
      "text/csv"
    );
  };
  const generateVoteCSV = async () => {
    const headers = [
      "Sr. No.",
      "News",
      "Date & Time",
    ];
    let modifiedData = voterList?.map((data, index) => {
      let objData = [
        index + 1,
        data?.newsId?.newsTitle ? data?.newsId?.newsTitle?.replaceAll(",", "") : "-",
        data?.newsId?.category[0]?.categoryName
          ? data?.newsId?.category[0]?.categoryName?.replaceAll(",", "|")
          : "-",
        data?.updatedAt
          ? PagesIndex.moment(data?.updatedAt).format("DD/MM/YYYY")
          : "-",
      ];
      return objData.join(",");
    });

    exportData(
      [[headers, ...modifiedData].join("\n")],
      `${PagesIndex.moment().format(
        "DD-MM-YYYY hh:mm:ss A"
      )}_Vote_list.csv`,
      "text/csv"
    );
  };

  // for open handleChangedropdown

  const renderUserNews = () => {
    return userNewsRating?.map((data) => {
      let reactionIcon;

      if (data?.rating != null) {
        if (data.rating > 0 && data.rating < 1) {
          reactionIcon = (
            <img
              src={PagesIndex.Svg.reaction1}
              className="reaction-icon"
              alt="reaction"
            />
          );
        } else if (data.rating >= 1 && data.rating < 2) {
          reactionIcon = (
            <img
              src={PagesIndex.Svg.reaction2}
              className="reaction-icon"
              alt="reaction"
            />
          );
        } else if (data.rating >= 2 && data.rating < 3) {
          reactionIcon = (
            <img
              src={PagesIndex.Svg.reaction3}
              className="reaction-icon"
              alt="reaction"
            />
          );
        } else if (data.rating >= 3 && data.rating <= 4) {
          reactionIcon = (
            <img
              src={PagesIndex.Svg.reaction4}
              className="reaction-icon"
              alt="reaction"
            />
          );
        } else if (data.rating >= 4 && data.rating <= 5) {
          reactionIcon = (
            <img
              src={PagesIndex.Svg.reaction5}
              className="reaction-icon"
              alt="reaction"
            />
          );
        } else {
          reactionIcon = "-";
        }
      } else {
        reactionIcon = "-";
      }
      return (
        <Index.TableRow key={data?._id}>
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {" "}
              <Index.Tooltip
                title={data?.newsId?.newsNo}
                arrow
                placement="top"
                className="admin-tooltip"
              >
                <span className="news-id-text">
                  {data?.newsId?.newsNo ? data?.newsId?.newsNo : "-"}
                </span>
              </Index.Tooltip>
            </Index.Typography>
          </Index.TableCell>
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {" "}
              {data?.newsId?.category[0]?.categoryName
                ? data?.newsId?.category[0]?.categoryName
                : "-"}
            </Index.Typography>
          </Index.TableCell>
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {data?.createdAt
                ? PagesIndex.moment(data?.createdAt).format(
                  "DD/MM/YYYY HH:mm:ss"
                )
                : "-"}
            </Index.Typography>
          </Index.TableCell>
          <Index.TableCell
            component="td"
            variant="td"
            className="table-td"
            align="center"
          >
            {reactionIcon}
          </Index.TableCell>
        </Index.TableRow>
      );
    });
  };

  const renderUserNewsContent = () => {
    return (
      <>
        {!ratingLoader && userNewsRating?.length > 0 && renderUserNews()}
        {!ratingLoader && (!userNewsRating || userNewsRating?.length === 0) && (
          <PagesIndex.DataNotFound />
        )}
      </>
    );
  };
  const renderVotersContent = () => {
    if (voteloading) {
      return <PagesIndex.Loader />;
    } else if (voterList?.length) {
      return renderVotersTableRows();
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };
  const renderVotersTableRows = () => {
    return voterList?.map((data, index) => {
      return (
        <Index.TableRow
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          key={data?._id}
        >
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {index + 1}
            </Index.Typography>
          </Index.TableCell>
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Tooltip
              title={data?.newsId?.newsTitle}
              arrow
              placement="bottom"
              className="admin-tooltip"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                <span className="news-id-text">{data?.newsId?.newsNo}</span>
                {data?.newsId?.newsTitle}
              </Index.Typography>
            </Index.Tooltip>
          </Index.TableCell>
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {data?.updatedAt
                ? PagesIndex.moment(new Date(data?.updatedAt)).format(
                  "YYYY-MM-DD hh:mm:ss A"
                )
                : "-"}
            </Index.Typography>
          </Index.TableCell>
        </Index.TableRow>
      );
    });
  };
  const renderContactableRows = () => {
    return contactList?.map((data, index) => {
      const startIndex = (currentPage - 1) * 10;
      const adjustedIndex = startIndex + index + 1;
      return (
        <Index.TableRow
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          key={data?._id}
        >
          <Index.TableCell component="td" variant="td" className="table-td">
            {adjustedIndex}
          </Index.TableCell>

          <Index.TableCell component="td" variant="td" className="table-td">
            {data?.name ? data?.name : "-"}
          </Index.TableCell>

          <Index.TableCell component="td" variant="td" className="table-td">
            {data?.phoneNo ? data?.phoneNo : "-"}
          </Index.TableCell>
        </Index.TableRow>
      );
    });
  };

  const renderContactContent = () => {
    return (
      <>
        {!contactListLoading &&
          contactList?.length > 0 &&
          renderContactableRows()}
        {!contactListLoading && (!contactList || contactList.length === 0) && (
          <PagesIndex.DataNotFound />
        )}
      </>
    );
  };

  return (
    <PagesIndex.Formik
      enableReinitialize
      onSubmit={handleAddEditBlockResone}
      initialValues={initialValues}
      validationSchema={PagesIndex.userBlockedSchema}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <Index.Box>
          <Index.Box className="admin-dashboard-content admin-user-management-content">
            <Index.Box className="admin-user-list-flex admin-page-title-main">
              <Index.Box className="admin-title-left">
                <Index.Button
                  className="admin-adduser-btn btn-primary back-btn"
                  onClick={() => {
                    navigate(`/admin/manage-user`);
                  }}
                >
                  <img
                    src={PagesIndex.Svg.backarrow}
                    className="admin-icon edit-icons"
                    alt="plus"
                  />
                </Index.Button>
                <Index.Typography
                  className="admin-view-page-title"
                  component="h2"
                  variant="h2"
                >
                  User Details
                </Index.Typography>
              </Index.Box>
              <Index.Box className="dash-dropdown-box">
                <Index.Box className="admin-form-group">
                  <Index.Box className="admin-dropdown-box">
                    {/* <Index.FormControl className="admin-form-control">
                      <Index.Select
                        className="admin-dropdown-select "
                        value={age}
                        onChange={handleChangedropdown}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <Index.MenuItem value="" className="admin-menuitem">
                          7 Days
                        </Index.MenuItem>
                        <Index.MenuItem value={10} className="admin-menuitem">
                          7 Days
                        </Index.MenuItem>
                        <Index.MenuItem value={20} className="admin-menuitem">
                          7 Days
                        </Index.MenuItem>
                        <Index.MenuItem value={30} className="admin-menuitem">
                          7 Days
                        </Index.MenuItem>
                      </Index.Select>
                    </Index.FormControl> */}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="user-details-card common-card">
              <Index.Box className="view-details-content-flex">
                <Index.Box className="view-details-img-flex user-view-details-img-flex ">
                  <Index.Box className="admin-file-upload-view-btn-main">
                    <img
                      src={
                        userDetails?.image
                          ? process.env.REACT_APP_IMAGE_ENDPOINT +
                          userDetails?.image
                          : PagesIndex.Png.usericon
                      }
                      className="admin-upload-view-img"
                      alt="upload img"
                    ></img>
                  </Index.Box>
                  <Index.Box className="user-view-content-main">
                    <Index.Box className="view-content-main">
                      <Index.Box className="view-content-box">
                        <Index.Box className="view-content-flex">
                          <Index.Typography className="view-content-lable">
                            Name:
                          </Index.Typography>
                          <Index.Typography className="view-content-data">
                            {userDetails?.firstName || userDetails?.lastName
                              ? `${userDetails?.firstName} ${userDetails?.lastName}`
                              : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="view-content-box">
                        <Index.Box className="view-content-flex">
                          <Index.Typography className="view-content-lable">
                            Email Id:
                          </Index.Typography>
                          <Index.Typography className="view-content-data">
                            {userDetails?.email ? userDetails?.email : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="view-content-box">
                        <Index.Box className="view-content-flex">
                          <Index.Typography className="view-content-lable">
                            Mobile No:
                          </Index.Typography>
                          <Index.Typography className="view-content-data">
                            {userDetails?.mobile ? userDetails?.mobile : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="view-content-box">
                        <Index.Box className="view-content-flex">
                          <Index.Typography className="view-content-lable">
                            Gender:
                          </Index.Typography>
                          <Index.Typography className="view-content-data">
                            {userDetails?.gender ? userDetails?.gender : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="view-content-box">
                        <Index.Box className="view-content-flex">
                          <Index.Typography className="view-content-lable">
                            Date Of Birth:
                          </Index.Typography>
                          <Index.Typography className="view-content-data">
                            {userDetails?.birthDate
                              ? PagesIndex.moment(
                                userDetails?.birthDate
                              ).format("DD/MM/YYYY")
                              : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="view-content-box">
                        <Index.Box className="view-content-flex">
                          <Index.Typography className="view-content-lable">
                            Location:
                          </Index.Typography>
                          <Index.Typography className="view-content-data">
                            {userDetails?.location
                              ? userDetails?.location
                              : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="view-category-content-main">
                      <Index.Box className="view-content-flex">
                        <Index.Typography className="view-content-lable">
                          Genres/Categories:
                        </Index.Typography>
                        <Index.Box className="view-category-flex-box">
                          {userDetails?.interests?.length
                            ? userDetails?.interests?.map((data) => {
                              return (
                                <Index.Box
                                  className="view-category-box"
                                  key={data?._id}
                                >
                                  <Index.Typography className="view-content-data">
                                    {data?.categoryName}
                                  </Index.Typography>
                                </Index.Box>
                              );
                            })
                            : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="admin-userlist-inner-btn-flex">
                  <Index.Box className="admin-icons-btn-main btn-main-primary">
                    {!userDetails?.isBlocked ? (
                      <Index.Tooltip
                        title="Block"
                        arrow
                        placement="bottom"
                        className="admin-tooltip"
                      >
                        <Index.Button
                          className="admin-icons-btn btn-primary"
                          type="submit"
                          onClick={handleOpenMessage}
                        >
                          <img
                            src={PagesIndex.Svg.blockicon}
                            className="admin-icons"
                            alt="Block"
                          />
                        </Index.Button>
                      </Index.Tooltip>
                    ) : (
                      <Index.Tooltip
                        title="Unblock"
                        arrow
                        placement="bottom"
                        className="admin-tooltip"
                      >
                        <Index.Button
                          className="admin-icons-btn btn-primary"
                          type="submit"
                          onClick={handleOpenUnblockUser}
                        >
                          <img
                            src={PagesIndex.Svg.unlockicon}
                            className="admin-icons"
                            alt="Unblock"
                          />
                        </Index.Button>
                      </Index.Tooltip>
                    )}
                  </Index.Box>

                  <Index.Box className="admin-icons-btn-main btn-main-primary">
                    <Index.Tooltip
                      title="Phone Directory"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-icons-btn btn-primary"
                        type="submit"
                        onClick={() => handleOpenContactModal()}
                      >
                        <img
                          src={PagesIndex.Svg.phonebookIcon}
                          className="admin-icons"
                          alt="Phone Directory"
                        />
                      </Index.Button>
                    </Index.Tooltip>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {userDetails?.isBlocked ? (
              <Index.Box className="user-block-bg">
                <Index.Typography className="user-block-text">
                  {userDetails?.reason}
                </Index.Typography>
              </Index.Box>
            ) : null}

            <Index.Box className="grid-row chart-row-main">
              <Index.Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  className="grid-column"
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 4",
                    md: "span 4",
                    lg: "span 4",
                  }}
                >
                  <Index.Box className="admin-dashboard-box common-card">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total Views
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {userCount?.totalViews ? userCount?.totalViews : 0}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <Index.Box className="admin-dash-icon-box">
                          <img
                            src={PagesIndex.Svg.totalViews}
                            className="admin-dash-icons"
                            alt="dashboard icon"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  className="grid-column"
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 4",
                    md: "span 4",
                    lg: "span 4",
                  }}
                >
                  <Index.Box className="admin-dashboard-box common-card">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Live Content Views
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {userCount?.liveContentViews
                            ? userCount?.liveContentViews
                            : 0}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <Index.Box className="admin-dash-icon-box">
                          <img
                            src={PagesIndex.Svg.liveVideos}
                            className="admin-dash-icons"
                            alt="dashboard icon"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  className="grid-column"
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 4",
                    md: "span 4",
                    lg: "span 4",
                  }}
                >
                  <Index.Box className="admin-dashboard-box common-card">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total Rating
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {userCount?.totalRating ? userCount?.totalRating : 0}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <Index.Box className="admin-dash-icon-box">
                          <img
                            src={PagesIndex.Svg.totalRating}
                            className="admin-dash-icons"
                            alt="dashboard icon"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="grid-row chart-row-main">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="dashboard-chart-main">
                      <Index.Box className="chart-col common-card">
                        <Index.Box className="chart-title-main">
                          <Index.Typography
                            className="chart-title"
                            component="h2"
                            variant="h2"
                          >
                            Content Duration Distribution
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="chart-main-box">
                          <Index.Box className="chart-box">
                            <PagesIndex.ReactApexChart
                              options={optionsPie}
                              series={seriesPie}
                              type="pie"
                              height={238}
                              width={optionsPie.chart.width}
                            // height={optionsPie.chart.height}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="common-card">
                      <Index.Box className="chart-title-main">
                        <Index.Typography
                          className="chart-title"
                          component="h2"
                          variant="h2"
                        >
                          News Rating Distribution
                        </Index.Typography>
                      </Index.Box>
                      {userNewsRating?.length > 0 && <Index.Box className="admin-adduser-btn-main btn-main-secondary">
                        <Index.Button
                          className="admin-adduser-btn btn-secondary"
                          onClick={generateNewsRatingCSV}
                        >
                          <img
                            src={PagesIndex.Svg.exporticon}
                            className="admin-plus-icon"
                            alt="plus"
                          />
                          <span>Export CSV</span>
                        </Index.Button>
                      </Index.Box>}
                      <Index.Box className="page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                          sx={{ maxHeight: 245, minHeight: 245 }}
                        >
                          {ratingLoader ? <PagesIndex.Loader /> :
                            (<Index.Table stickyHeader aria-label="sticky table">
                              <Index.TableHead className="table-head">
                                <Index.TableRow className="table-row">
                                  <Index.TableCell
                                    className="table-th"
                                    width="30%"
                                  >
                                    News ID
                                  </Index.TableCell>
                                  <Index.TableCell
                                    className="table-th"
                                    width="20%"
                                  >
                                    Category
                                  </Index.TableCell>
                                  <Index.TableCell
                                    className="table-th"
                                    width="30%"
                                  >
                                    Date & Time
                                  </Index.TableCell>
                                  <Index.TableCell
                                    className="table-th"
                                    width="5%"
                                    align="center"
                                  >
                                    Reaction
                                  </Index.TableCell>
                                </Index.TableRow>
                              </Index.TableHead>
                              <Index.TableBody className="table-body">
                                {renderUserNewsContent()}
                              </Index.TableBody>
                            </Index.Table>
                            )}
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="common-card">
                      <Index.Box className="chart-title-main">
                        <Index.Typography
                          className="chart-title"
                          component="h2"
                          variant="h2"
                        >
                          Vote List
                        </Index.Typography>
                      </Index.Box>
                      {voterList?.length > 0 && <Index.Box className="admin-adduser-btn-main btn-main-secondary">
                        <Index.Button
                          className="admin-adduser-btn btn-secondary"
                          onClick={generateVoteCSV}
                        >
                          <img
                            src={PagesIndex.Svg.exporticon}
                            className="admin-plus-icon"
                            alt="plus"
                          />
                          <span>Export CSV</span>
                        </Index.Button>
                      </Index.Box>}
                      <Index.Box className="page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                          sx={{ maxHeight: 245, minHeight: 245 }}
                        >
                          <Index.Table stickyHeader aria-label="sticky table">
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  className="table-th"
                                  width="10%"
                                >
                                  Sr. No.
                                </Index.TableCell>
                                <Index.TableCell
                                  className="table-th"
                                  width="40%"
                                >
                                  News
                                </Index.TableCell>
                                <Index.TableCell
                                  className="table-th"
                                  width="25%"
                                >
                                  Date & Time
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {renderVotersContent()}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="admin-sub-title-main inner-title-main">
              <Index.Typography
                className="admin-sub-title"
                component="h2"
                variant="h2"
              >
                Activity Log
              </Index.Typography>
            </Index.Box>
            <Index.Box className="common-card">
              <Index.Box className="admin-view-web-series-table-main page-table-main">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  <Index.Table aria-label="simple table" className="table">
                    <Index.TableHead className="table-head">
                      <Index.TableRow className="table-row">
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="5%"
                        >
                          Sr. No.
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="7%"
                        >
                          Type
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="20%"
                        >
                          Category
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="15%"
                        >
                          Zone
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="15%"
                        >
                          Activity Date & time
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="15%"
                        >
                          Video Id
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="table-body">
                      {userDetails?.log?.slice(
                        (currentPage - 1) * 10,
                        (currentPage - 1) * 10 + 10
                      )?.length ? (
                        userDetails?.log
                          ?.slice(
                            (currentPage - 1) * 10,
                            (currentPage - 1) * 10 + 10
                          )
                          ?.map((data, index) => {
                            return (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                key={data?._id}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  <Index.Typography className="admin-table-data-text">
                                    {" "}
                                    {(currentPage - 1) * 10 + (index + 1)}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  <Index.Typography className="admin-table-data-text">
                                    {" "}
                                    {data?.type}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  <Index.Tooltip
                                    title={
                                      data?.newsId?.category?.length
                                        ? data?.newsId?.category
                                          ?.map((data) => data?.categoryName)
                                          ?.toString()
                                          ?.replaceAll(",", ", ")
                                        : data?.movieWebseriesId?.category
                                          ?.length
                                          ? data?.movieWebseriesId?.category
                                            ?.map((data) => data?.categoryName)
                                            ?.toString()
                                            ?.replaceAll(",", ", ")
                                          : "-"
                                    }
                                    arrow
                                    placement="top"
                                    className="admin-tooltip"
                                  >
                                    <Index.Typography className="admin-table-data-text">
                                      {" "}
                                      {data?.newsId?.category?.length
                                        ? data?.newsId?.category
                                          ?.map((data) => data?.categoryName)
                                          ?.toString()
                                          ?.replaceAll(",", ", ")
                                        : data?.movieWebseriesId?.category
                                          ?.length
                                          ? data?.movieWebseriesId?.category
                                            ?.map((data) => data?.categoryName)
                                            ?.toString()
                                            ?.replaceAll(",", ", ")
                                          : "-"}
                                    </Index.Typography>
                                  </Index.Tooltip>
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {" "}
                                  {data?.newsId?.zone?.name || "-"}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {" "}
                                  {PagesIndex.moment(data?.createdAt).format(
                                    "DD MMMM YYYY hh:mm A"
                                  )}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td border-btn-main"
                                >
                                  {data?.type === "news" ? (
                                    <Index.Link
                                      to={`/admin/view-video/${data?.newsId?._id}`}
                                      className="border-btn border-link"
                                      target="_blank"
                                    >
                                      {data?.newsId?.newsNo}
                                    </Index.Link>
                                  ) : data?.type === "webseries" ? (
                                    <Index.Link
                                      to={`/admin/view-web-series/${data?.movieWebseriesId?._id}`}
                                      className="border-btn border-link"
                                      target="_blank"
                                    >
                                      {data?.movieWebseriesId?.title}
                                    </Index.Link>
                                  ) : (
                                    <Index.Link
                                      to={`/admin/view-movie/${data?.movieWebseriesId?._id}`}
                                      className="border-btn border-link"
                                      target="_blank"
                                    >
                                      {data?.movieWebseriesId?.title}
                                    </Index.Link>
                                  )}
                                </Index.TableCell>
                              </Index.TableRow>
                            );
                          })
                      ) : (
                        <PagesIndex.DataNotFound />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="admin-pagination-main">
                {userDetails?.log?.length ? (
                  <Index.Pagination
                    page={currentPage}
                    count={Math.ceil(userDetails?.log?.length / 10)}
                    onChange={(event, newPage) => setCurrentPage(newPage)}
                    variant="outlined"
                    shape="rounded"
                    className="admin-pagination"
                  />
                ) : (
                  ""
                )}
              </Index.Box>
            </Index.Box>

            <Index.Modal
              open={openMessage}
              onClose={handleCloseMessage}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="admin-modal"
            >
              <form onSubmit={handleSubmit} noValidate="novalidate">
                <Index.Box
                  sx={style}
                  className="admin-add-user-modal-inner-main admin-modal-inner"
                >
                  <Index.Box className="admin-modal-header">
                    <Index.Typography
                      id="modal-modal-title"
                      className="admin-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {" "}
                      Reason<span className="astrick-sing">*</span>{" "}
                      <span className="option-condition-text">
                        (Max character length: 250)
                      </span>
                    </Index.Typography>
                    <Index.Button
                      className="modal-close-btn"
                      onClick={handleCloseMessage}
                    >
                      <img
                        src={PagesIndex.Svg.closeblack}
                        className="admin-modal-close-icon"
                        alt="Close"
                      />
                    </Index.Button>
                  </Index.Box>
                  <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                    <Index.Box className="admin-input-box admin-modal-input-box">
                      <Index.Box className="admin-form-group">
                        <Index.TextareaAutosize
                          aria-label="minimum height"
                          // minRows={3}
                          style={{ resize: "vertical", overflow: "auto" }}
                          placeholder="Example test"
                          className="admin-form-control-textarea"
                          name="reason"
                          onChange={handleChange}
                          value={values?.reason}
                          error={errors.reason && touched.reason}
                          maxLength={250}
                        />
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.reason && touched.reason
                            ? errors.reason
                            : null}
                        </PagesIndex.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="modal-footer">
                    <Index.Box className="admin-modal-user-btn-flex">
                      <Index.Box className="admin-save-btn-main btn-main-primary">
                        <Index.Button
                          className="admin-icons-btn btn-primary update-btn"
                          type="Submit"
                          disabled={buttonSpinner}
                        >
                          {buttonSpinner ? <PagesIndex.Spinner /> :
                            <>
                              <img
                                src={PagesIndex.Svg.blockicon}
                                className="admin-icons block-icon"
                                alt="Block"
                              ></img>
                              <span>Block</span>
                            </>}
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            </Index.Modal>

            <Index.Modal
              open={openUnblockUser}
              onClose={handleCloseUnblockUser}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="admin-modal"
            >
              <Index.Box
                sx={style}
                className="admin-delete-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                  <Index.Box className="admin-modal-circle-main">
                    <img
                      src={PagesIndex.Svg.unlockroundicon}
                      className="admin-user-circle-img"
                      alt="Close"
                    />
                  </Index.Box>
                  <Index.Typography
                    className="admin-delete-modal-title"
                    component="h2"
                    variant="h2"
                  >
                    Are you sure?
                  </Index.Typography>
                  <Index.Typography
                    className="admin-delete-modal-para admin-common-para"
                    component="p"
                    variant="p"
                  >
                    Do you really want to unblock this user?
                  </Index.Typography>
                  <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
                    <Index.Button
                      className="admin-modal-cancel-btn border-btn"
                      onClick={handleCloseUnblockUser}
                      disabled={buttonSpinner}
                    >
                      Cancel
                    </Index.Button>
                    <Index.Button
                      className="unblock-user admin-modal-delete-btn btn"
                      onClick={handleAddEditBlockResone}
                      disabled={buttonSpinner}
                    >
                      {buttonSpinner ? <PagesIndex.Spinner /> :
                        (<>
                          <img
                            src={PagesIndex.Svg.save}
                            className="admin-user-save-icon"
                            alt="Save"
                          />
                          <span>Unblock User</span>
                        </>)
                      }
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Modal>

            <Index.Modal
              open={openContactModal}
              onClose={handleCloseContactModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="admin-modal"
            >
              <Index.Box
                sx={style}
                className="admin-log-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {" "}
                    Phone Directory
                  </Index.Typography>
                  <Index.Button
                    className="modal-close-btn"
                    onClick={handleCloseContactModal}
                  >
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Button>
                </Index.Box>
                <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                  <Index.Box className="common-card">
                    <Index.Box className="page-table-main state-activity-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        {contactListLoading ? <PagesIndex.Loader /> :
                          (
                            <Index.Table
                              aria-label="simple table"
                              className="table"
                            >
                              <Index.TableHead className="table-head">
                                <Index.TableRow className="table-row">
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    width="10%"
                                  >
                                    Sr. No.
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    width="60%"
                                  >
                                    Contact Name
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    width="30%"
                                  >
                                    Mobile Number
                                  </Index.TableCell>
                                </Index.TableRow>
                              </Index.TableHead>
                              <Index.TableBody className="table-body">
                                {renderContactContent()}
                              </Index.TableBody>
                            </Index.Table>
                          )}
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Modal>
          </Index.Box>
        </Index.Box>
      )}
    </PagesIndex.Formik>
  );
}
