import React, { useCallback, useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./webSeries.css";
import "./webSeries.responsive.css";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

// for custom switch design

export default function ViewWebSeries() {
  const params = PagesIndex.useParams();
  const navigate = PagesIndex.useNavigate();
  const { id } = params;
  // delete modal
  const [webSeriesData, setWebSeriesData] = useState([]);
  const [videoLink, setVideoLink] = useState("");
  const [openIframeModal, setOpenIframeModal] = useState(false);
  const [message, setMessage] = useState("Copy");
  const handleCloseifameModal = () => {
    setOpenIframeModal(false);
  };
  const handleGetWebSeriesData = useCallback(() => {
    PagesIndex.apiGetHandler(
      `${PagesIndex.Api.VIEW_WEB_SERIES_DATA}/${id}`
    ).then((res) => {
      if (res?.status === 200) {
        setWebSeriesData(res?.data);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  }, [id]);
  useEffect(() => {
    handleGetWebSeriesData();
  }, [handleGetWebSeriesData]);

  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Box className="admin-title-left">
          <Index.Button
            className="admin-adduser-btn btn-primary back-btn"
            onClick={() => {
              navigate(`/admin/web-series`);
            }}
          >
            <img
              src={PagesIndex.Svg.backarrow}
              className="admin-icon edit-icons"
              alt="plus"
            />
          </Index.Button>
          <Index.Typography
            className="admin-view-page-title"
            component="h2"
            variant="h2"
          >
            Web Series Details
          </Index.Typography>
        </Index.Box>
      </Index.Box>
      <Index.Box className="view-details-card">
        <Index.Box className="admin-file-view-card common-card">
          <Index.Box className="admin-file-view-main">
            <Index.Box className="highlight-box">
              <Index.Box className="highlight-flex">
                <img
                  src={PagesIndex.Svg.calendaricon}
                  className="calendar-icon"
                  alt=""
                />
                <Index.Typography className="highlight-content-lable">
                  Release Date:
                </Index.Typography>
                <Index.Typography className="highlight-content-data">
                  {PagesIndex.moment(
                    webSeriesData?.webSeries?.releaseDate
                  ).format("DD-MM-YYYY")}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <img
              src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${webSeriesData?.webSeries?.image}`}
              className="admin-file-view-img"
              alt="upload img"
            />
          </Index.Box>
          <Index.Box className="admin-view-btn-flex">
            <Index.Box className="btn-main-primary">
              <Index.Button
                className="btn-primary"
                onClick={() => {
                  setOpenIframeModal(true);
                  setVideoLink(webSeriesData?.webSeries?.trailerLink);
                }}
              >
                <img
                  src={PagesIndex.Svg.playicon}
                  className="admin-icon play-icon"
                  alt="View"
                />
                <span>Trailer</span>
              </Index.Button>
            </Index.Box>
          </Index.Box>
          <Index.Box className="review-flex">
            <Index.Box className="review-box">
              <Index.Typography className="review-title">
                Total Viewers
              </Index.Typography>
              <Index.Typography className="review-data">
                {webSeriesData?.views}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="review-box">
              <Index.Typography className="review-title">
                Rating
              </Index.Typography>
              <Index.Typography className="review-data">
                {webSeriesData?.rating?.toFixed(1) || "0"}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="common-card brif-content-card">
          <Index.Box className="view-movie-content-main brif-content-main">
            <Index.Box className="news-content-main">
              <Index.Typography className="news-name-text">
                {webSeriesData?.webSeries?.title}
              </Index.Typography>
              <Index.Typography className="news-discription-text">
                {webSeriesData?.webSeries?.description}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Trailer Link:
                </Index.Typography>
                <Index.Box className="view-content-link-flex">
                  <Index.Typography className="view-content-data">
                    {webSeriesData?.webSeries?.trailerLink}
                  </Index.Typography>
                  <Index.Tooltip
                    title={message}
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                    onMouseLeave={() => {
                      setTimeout(() => {
                        setMessage("Copy");
                      }, 1000);
                    }}
                  >
                    <Index.Button
                      className="copy-btn-main"
                      onClick={() => {
                        setMessage("Copied ✓");
                        navigator.clipboard.writeText(
                          webSeriesData?.webSeries?.trailerLink
                        );
                      }}
                    >
                      <img
                        src={PagesIndex.Svg.copyicon}
                        className="copy-icon"
                        alt="copy"
                      />
                    </Index.Button>
                  </Index.Tooltip>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-category-content-main">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Categories:
              </Index.Typography>
              <Index.Box className="view-category-flex-box">
                {webSeriesData?.webSeries?.categoryName
                  ?.split(",")
                  ?.map((data) => {
                    return (
                      <Index.Box className="view-category-box" key={data}>
                        <Index.Typography className="view-content-data">
                          {data}
                        </Index.Typography>
                      </Index.Box>
                    );
                  })}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <PagesIndex.WebSeriesDetailsTabs webSeriesData={webSeriesData} />

      <Index.Modal
        open={openIframeModal}
        onClose={handleCloseifameModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-ifram-modal-inner-main admin-modal-inner"
        >
          <Index.Button
            className="iframe-modal-close-btn"
            onClick={handleCloseifameModal}
          >
            <img
              src={PagesIndex.Svg.closeroundicon}
              className="admin-modal-close-icon"
              alt="Close"
            />
          </Index.Button>
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="iframe-main">
              <iframe
                className="iframe-main-tag"
                src={`https://www.youtube.com/embed/${
                  videoLink?.includes("watch?")
                    ? videoLink?.split("=")[1]
                    : videoLink?.split("/")[3]
                }?autoplay=1`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </Index.Box>
  );
}
