import React, { useCallback, useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import PropTypes from "prop-types";
import "./advertiser.Responsive.css";

// for tabs design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ViewAdvertiser() {
  const params = PagesIndex.useParams();
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  let page = location?.state?.page;
  const { id } = params;
  // for tabs design

  const [value, setValue] = useState(page ? page : 0);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [documentTypesData, setDocumentTypesData] = useState([]);

  const [open, setOpen] = useState(false);
  const [creativeListData, setCreativeListData] = useState([]);
  const [creativeCount, setCreativeCount] = useState(0);

  const formRef = useRef();
  const initialValues = {
    companyName: clientData?.billingCompName || "",
    address: clientData?.billingAddress || "",
    number: clientData?.billingContact || "",
    email: clientData?.billingEmail || "",
    GSTNo: clientData?.gstNo || "",
    PANNo: clientData?.panNo || "",
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    formRef.current.resetForm();
    setOpen(false);
  };

  const handleChangeValue = (event, newValue) => {
    setValue(newValue);
  };

  const handleGetAdData = useCallback(() => {
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_CLIENTS_DETAILS + "/" + id
    ).then((res) => {
      if (res?.status === 200) {
        setClientData(res?.data);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  }, [id]);
  const handleGetDocumentTypes = useCallback(() => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_DOCUMENT_TYPES + "/" + id).then(
      (res) => {
        if (res?.status === 200) {
          setDocumentTypesData(res?.data);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  }, []);

  const handleGetCreatives = useCallback(() => {
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_CREATIVES_BY_CLIENT + "/" + id
    ).then((res) => {
      if (res.status === 200) {
        setCreativeListData(res?.data?.getCreative);
        setCreativeCount(res?.data?.countOfCreative);
      }
    });
  }, [id]);
  useEffect(() => {
    handleGetAdData();
    handleGetCreatives();
    handleGetDocumentTypes();
  }, [handleGetAdData, handleGetDocumentTypes, handleGetCreatives]);
  const ScrollIntoView = () => {
    // Scroll to the first error when errors change
    const { errors, isSubmitting } = PagesIndex.useFormikContext(); // Extracting errors from the Formik context
    useEffect(() => {
      if (errors && Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        let scrollDiv = document.getElementById("scrollDiv");
        const firstErrorElement = document.querySelector(
          `[id="${firstErrorField}"]`
        );
        if (firstErrorElement) {
          scrollDiv.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          firstErrorElement.focus();
        }
      }
    }, [isSubmitting]);
  };

  const handleAddEditBilling = (values) => {
    setButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    urlEncoded.append("billingCompName", values?.companyName);
    urlEncoded.append("billingAddress", values?.address);
    urlEncoded.append("billingContact", values?.number);
    urlEncoded.append("billingEmail", values?.email);
    urlEncoded.append("gstNo", values?.GSTNo);
    urlEncoded.append("panNo", values?.PANNo);
    PagesIndex.apiPostHandler(
      PagesIndex.Api.ADD_BILLING_INFORMATION,
      urlEncoded
    )
      .then((res) => {
        setButtonSpinner(false)
        if (res.status === 201 || res.status === 200) {
          handleGetAdData();
          handleClose();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };

  return (
    <>
      <Index.Box className="admin-dashboard-content admin-user-management-content">
        <Index.Box className="admin-user-list-flex admin-page-title-main">
          <Index.Box className="admin-title-left">
            <Index.Button
              className="admin-adduser-btn btn-primary back-btn"
              onClick={() => {
                navigate(`/admin/clients`);
              }}
            >
              <img
                src={PagesIndex.Svg.backarrow}
                className="admin-icon edit-icons"
                alt="plus"
              />
            </Index.Button>
            <Index.Typography
              className="admin-view-page-title"
              component="h2"
              variant="h2"
            >
              Client Details
            </Index.Typography>
          </Index.Box>
        </Index.Box>

        <Index.Box className="admin-tabs-main-box">
          <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Index.Tabs
              value={value}
              onChange={handleChangeValue}
              aria-label="basic tabs example"
              className="admin-tabs-main"
            >
              <Index.Tab
                label="Client information"
                {...a11yProps(0)}
                className="admin-tab"
              />
              <Index.Tab
                label="Billing information"
                {...a11yProps(1)}
                className="admin-tab"
              />
              <Index.Tab
                label="Advertisements"
                {...a11yProps(2)}
                className="admin-tab"
              />
              <Index.Tab
                label="Uploaded Documents"
                {...a11yProps(3)}
                className="admin-tab"
              />
              <Index.Tab
                label="Creative"
                {...a11yProps(4)}
                className="admin-tab"
              />
            </Index.Tabs>
          </Index.Box>
          <TabPanel value={value} index={0} className="admin-tabpanel">
            <Index.Box className="admin-tabpanel-main">
              <PagesIndex.AdvertiserInformation data={clientData} />
            </Index.Box>
          </TabPanel>
          <TabPanel value={value} index={1} className="admin-tabpanel">
            <Index.Box className="admin-tabpanel-main">
              <PagesIndex.BillingInformation
                data={clientData}
                handleOpen={handleOpen}
              />
            </Index.Box>
          </TabPanel>
          <TabPanel value={value} index={2} className="admin-tabpanel">
            <Index.Box className="admin-tabpanel-main">
              <PagesIndex.Advertisments id={id} />
            </Index.Box>
          </TabPanel>
          <TabPanel value={value} index={3} className="admin-tabpanel">
            <Index.Box className="admin-tabpanel-main">
              <PagesIndex.UploadDocuments
                data={documentTypesData}
                handleGetDocumentTypes={handleGetDocumentTypes}
              />
            </Index.Box>
          </TabPanel>
          <TabPanel value={value} index={4} className="admin-tabpanel">
            <Index.Box className="admin-tabpanel-main">
              <PagesIndex.Creative
                creativeListData={creativeListData}
                handleGetCreatives={handleGetCreatives}
                creativeCount={creativeCount}
              />
            </Index.Box>
          </TabPanel>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <PagesIndex.Formik
          enableReinitialize
          onSubmit={handleAddEditBilling}
          initialValues={initialValues}
          validationSchema={PagesIndex.billingInformation}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} noValidate="novalidate">
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {clientData?.billingCompName ? "Edit" : "Add"} Billing
                    Information
                  </Index.Typography>
                </Index.Box>
                <Index.Box
                  className="admin-modal-hgt-scroll cus-scrollbar"
                  id="scrollDiv"
                >
                  {/* <ScrollIntoView /> */}
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Company name
                      <span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        name="companyName"
                        fullWidth
                        id="companyName"
                        className="admin-form-control"
                        placeholder="Enter company Name"
                        onChange={handleChange}
                        value={values?.companyName}
                        error={errors.companyName && touched.companyName}
                        inputProps={{ maxLength: 40 }}
                      />
                      <PagesIndex.FormHelperText className="admin-error-text">
                        {errors.companyName && touched.companyName
                          ? errors.companyName
                          : null}
                      </PagesIndex.FormHelperText>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Address
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        name="address"
                        fullWidth
                        id="stateName"
                        className="admin-form-control"
                        placeholder="Enter State Name"
                        onChange={handleChange}
                        value={values?.address}
                        error={errors.address && touched.address}
                      // inputProps={{ maxLength: 40 }}
                      />
                      <PagesIndex.FormHelperText className="admin-error-text">
                        {errors.address && touched.address
                          ? errors.address
                          : null}
                      </PagesIndex.FormHelperText>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Mobile Number
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        name="number"
                        fullWidth
                        id="number"
                        className="admin-form-control"
                        placeholder="Enter Mobile Number"
                        onChange={(e) => {
                          const regex = /^[0-9]*$/;
                          if (regex.test(e.target.value)) {
                            handleChange(e);
                          }
                        }}
                        value={values?.number}
                        inputProps={{ maxLength: 10 }}
                        error={errors.number && touched.number}
                      />
                      {touched.number && errors.number && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.number}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Email Id
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        name="email"
                        fullWidth
                        id="email"
                        className="admin-form-control"
                        placeholder="Enter Email Id"
                        onChange={handleChange}
                        value={values?.email}
                        error={errors.email && touched.email}
                        inputProps={{ maxLength: 50 }}
                      />
                      <PagesIndex.FormHelperText className="admin-error-text">
                        {errors.email && touched.email ? errors.email : null}
                      </PagesIndex.FormHelperText>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      GST No.
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        name="GSTNo"
                        fullWidth
                        id="GSTNo"
                        className="admin-form-control"
                        placeholder="Enter GST No."
                        onChange={(e) =>
                          setFieldValue("GSTNo", e.target.value.toUpperCase())
                        }
                        value={values?.GSTNo}
                        error={errors.GSTNo && touched.GSTNo}
                        inputProps={{ maxLength: 15 }}
                      />

                      <PagesIndex.FormHelperText className="admin-error-text">
                        {errors.GSTNo && touched.GSTNo ? errors.GSTNo : null}
                      </PagesIndex.FormHelperText>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      PAN No.
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        name="PANNo"
                        fullWidth
                        id="PANNo"
                        className="admin-form-control"
                        placeholder="Enter PAN No."
                        onChange={(e) =>
                          setFieldValue("PANNo", e.target.value.toUpperCase())
                        }
                        value={values?.PANNo}
                        error={errors.PANNo && touched.PANNo}
                        inputProps={{ maxLength: 10 }}
                      />
                      <PagesIndex.FormHelperText className="admin-error-text">
                        {errors.PANNo && touched.PANNo ? errors.PANNo : null}
                      </PagesIndex.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="admin-discard-btn-main border-btn-main">
                      <Index.Button
                        className="admin-discard-user-btn border-btn"
                        onClick={handleClose}
                        disabled={buttonSpinner}
                      >
                        Discard
                      </Index.Button>
                    </Index.Box>
                    <Index.Box className="admin-save-btn-main btn-main-primary">
                      <Index.Button
                        className={`${clientData?.billingCompName ? "update-btn" : "save-btn"} admin-save-user-btn btn-primary`}
                        type="submit"
                        disabled={buttonSpinner}
                      >
                        {buttonSpinner ? <PagesIndex.Spinner /> :
                          (<>
                            <img
                              src={PagesIndex.Svg.save}
                              className="admin-user-save-icon"
                              alt="Save"
                            />
                            <span>{clientData?.billingCompName ? "Update" : "Add"}</span>
                          </>)
                        }
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>
    </>
  );
}
