import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./faqManagement.css";
import "./faqManagement.responsive.css";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function FaqManagement() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const formRef = useRef();
  const initialValues = {
    question: "",
    answer: "",
  };
  const [id, setId] = useState("");
  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  // add user modal
  const [open, setOpen] = useState(false);
  // delete modal
  const [openDelete, setOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
  const [activityLoading, setActivityLoading] = useState(true);

  const [activityLog, setActivityLog] = useState([]);
  const [openLogModal, setOpenLogModal] = useState(false);
  const handleCloseLogModal = () => setOpenLogModal(false);
  const [searchValue, setSearchValue] = useState("");

  const handleOpenLogModal = (id) => {
    setOpenLogModal(true);
    setActivityLoading(true);
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_FAQ_ACTIVITY_LOG + "/" + id
    ).then((res) => {
      setActivityLoading(false);
      if (res.status === 200) {
        setActivityLog(res.data?.activityLog);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    handleGetFaqs();
  }, []);
  const handleOpen = (op) => {
    setOpen(true);
    setAddOrEdit(op);
  };
  const handleClose = () => {
    formRef.current.resetForm();
    setOpen(false);
  };
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setId(id);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };
  const handleGetFaqs = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_FAQ).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setData(res.data);
        setFilteredData(res.data);
      }
    });
  };
  const handleAddEditFaqs = (values) => {
    setButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    if (id) {
      urlEncoded.append("id", id);
    }
    urlEncoded.append("question", values?.question);
    urlEncoded.append("answer", values?.answer);
    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_EDIT_FAQ, urlEncoded)
      .then((res) => {
        setButtonSpinner(false)
        if (res.status === 201 || res.status === 200) {
          handleClose();
          handleGetFaqs();
          setSearchValue("");
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };
  const handleDeleteFaqs = () => {
    setDeleteButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(PagesIndex.Api.DELETE_FAQ, urlEncoded).then(
      (res) => {
        setDeleteButtonSpinner(false)
        if (res.status === 200) {
          handleCloseDelete();
          handleGetFaqs();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const requestSearch = (e) => {
    setSearchValue(e.target.value);
    let filteredData = data.filter(
      (data) =>
        data?.question
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.answer
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase())
    );
    setCurrentPage(1);
    setFilteredData(filteredData);
  };

  const renderTableRows = (setFieldValue) => {
    return filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data) => {
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={data?.question}
                arrow
                placement="top"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {" "}
                  {data?.question}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={data?.answer}
                arrow
                placement="top"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {" "}
                  {data?.answer}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              align="right"
              className="table-td"
            >
              <Index.Box className="admin-table-data-btn-flex ">
                {adminDetails?.roleId?.permissions?.includes("faq_edit") && (
                  <Index.Tooltip
                    title="Edit"
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                  >
                    <Index.Button
                      className="admin-table-data-btn"
                      onClick={() => {
                        setId(data?._id);
                        setFieldValue("question", data?.question);
                        setFieldValue("answer", data?.answer);
                        handleOpen("Edit");
                      }}
                    >
                      <img
                        src={PagesIndex.Svg.blueedit}
                        className="admin-icon"
                        alt="Edit"
                      ></img>
                    </Index.Button>
                  </Index.Tooltip>
                )}
                {adminDetails?.roleId?.permissions?.includes("faq_log") && (
                  <Index.Tooltip
                    title="Log"
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                  >
                    <Index.Button
                      className="admin-table-data-btn"
                      onClick={() => handleOpenLogModal(data?._id)}
                    >
                      <img
                        src={PagesIndex.Svg.logicon}
                        className="admin-icon"
                        alt="Log"
                      ></img>
                    </Index.Button>
                  </Index.Tooltip>
                )}
                {adminDetails?.roleId?.permissions?.includes("faq_delete") && (
                  <Index.Tooltip
                    title="Delete"
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                  >
                    <Index.Button
                      className="admin-table-data-btn"
                      onClick={() => handleOpenDelete(data?._id)}
                    >
                      <img
                        src={PagesIndex.Svg.trash}
                        className="admin-icon"
                        alt="Delete"
                      ></img>
                    </Index.Button>
                  </Index.Tooltip>
                )}
              </Index.Box>
            </Index.TableCell>
          </Index.TableRow>
        );
      });
  };

  const renderActivityTableRows = () => {
    return activityLog
      .slice()
      .reverse()
      .map((data) => {
        let updatedInfo = "";

        if (
          data?.previousAnswer !== data?.currentAnswer ||
          data?.previousQuestion !== data?.currentQuestion
        ) {
          if (data?.updatedBy?.name) {
            updatedInfo = `${data?.updatedBy?.name}-${data?.dateTime
              ? PagesIndex.moment(data?.dateTime).format(
                "DD/MM/YYYY HH:mm:ss"
              )
              : "-"
              }`;
          } else {
            updatedInfo = "-";
          }
        }
        return (
          <Index.TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            key={data?._id}
          >
            {updatedInfo !== "" && (
              <>
                <Index.TableCell
                  component="td"
                  variant="td"
                  scope="row"
                  className="table-td"
                >
                  <Index.Typography className="admin-table-data-text">
                    <Index.Tooltip
                      title={`${data?.previousQuestion !== data?.currentQuestion
                        ? `${data?.updatedBy?.name} has updated Question from ${data?.previousQuestion
                        } to ${data?.currentQuestion + " "}`
                        : ""
                        }`}
                      arrow
                      placement="top"
                      className="admin-tooltip"
                    >
                      {data?.previousQuestion !== data?.currentQuestion && (
                        <Index.Box>
                          <span className="span-bold">
                            {data?.updatedBy?.name}
                          </span>{" "} has updated Question from{" "}
                          <span className="span-bold">
                            {data?.previousQuestion}
                          </span>{" "}
                          to{" "}
                          <span className="span-bold">
                            {data?.currentQuestion + " "}
                          </span>
                        </Index.Box>
                      )}
                    </Index.Tooltip>
                  </Index.Typography>

                  <Index.Typography className="admin-table-data-text">
                    <Index.Tooltip
                      title={`${data?.previousAnswer !== data?.currentAnswer
                        ? `${data?.updatedBy?.name} has updated Answer from ${data?.previousAnswer
                        } to ${data?.currentAnswer + " "}`
                        : ""
                        }`}
                      arrow
                      placement="top"
                      className="admin-tooltip"
                    >
                      {data?.previousAnswer !== data?.currentAnswer && (
                        <Index.Box>
                          <span className="span-bold">
                            {data?.updatedBy?.name}
                          </span>{" "} has updated Answer from{" "}
                          <span className="span-bold">
                            {data?.previousAnswer}
                          </span>{" "}
                          to{" "}
                          <span className="span-bold">
                            {data?.currentAnswer + " "}
                          </span>
                        </Index.Box>
                      )}
                    </Index.Tooltip>
                  </Index.Typography>
                </Index.TableCell>

                <Index.TableCell
                  component="td"
                  variant="td"
                  className="table-td"
                >
                  {updatedInfo}
                </Index.TableCell>
              </>
            )}
          </Index.TableRow>
        );
      });
  };

  const renderActivityLogContent = () => {
    return (
      <>
        {!activityLoading &&
          activityLog?.length > 0 &&
          renderActivityTableRows()}
        {!activityLoading && (!activityLog || activityLog.length === 0) && (
          <PagesIndex.DataNotFound />
        )}
      </>
    );
  };

  const activityLogModal = () => {
    return (
      <Index.Modal
        open={openLogModal}
        onClose={handleCloseLogModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-log-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="admin-modal-title"
              variant="h6"
              component="h2"
            >
              {" "}
              Activity Log
            </Index.Typography>
            <Index.Button
              className="modal-close-btn"
              onClick={handleCloseLogModal}
            >
              <img
                src={PagesIndex.Svg.closeblack}
                className="admin-modal-close-icon"
                alt="Close"
              />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="common-card">
              <Index.Box className="page-table-main faq-activity-log-table-main">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  {activityLoading ? (
                    <PagesIndex.Loader />
                  ) : (
                    <Index.Table aria-label="simple table" className="table">
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="70%"
                          >
                            Activity Log
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="30%"
                          >
                            Updated Date & Time
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {renderActivityLogContent()}
                      </Index.TableBody>
                    </Index.Table>
                  )}
                </Index.TableContainer>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    );
  };

  const deleteModal = () => {
    return (
      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box>
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              Do you really want to delete this FAQ? This process cannot be
              undone.
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseDelete}
                disabled={deleteButtonSpinner}
              >
                Cancel
              </Index.Button>
              <Index.Button
                className="delete-user admin-modal-delete-btn btn"
                onClick={handleDeleteFaqs}
                disabled={deleteButtonSpinner}
              >
                {deleteButtonSpinner ? <PagesIndex.Spinner /> : <span>Delete</span>
                }
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    );
  };

  const addEditModal = (
    values,
    errors,
    touched,
    handleChange,
    handleSubmit
  ) => {
    return (
      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <form onSubmit={handleSubmit} noValidate="novalidate">
          <Index.Box
            sx={style}
            className="admin-add-user-modal-inner-main admin-modal-inner"
          >
            <Index.Box className="admin-modal-header">
              <Index.Typography
                id="modal-modal-title"
                className="admin-modal-title"
                variant="h6"
                component="h2"
              >
                {addOrEdit} FAQ
              </Index.Typography>
              {/* <Index.Box className="modal-close-btn" onClick={handleClose}>
                <img
                  src={PagesIndex.Svg.closeblack}
                  className="admin-modal-close-icon"
                  alt="Close"
                />
              </Index.Box> */}
            </Index.Box>
            <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
              <Index.Box className="admin-input-box admin-modal-input-box">
                <Index.FormHelperText className="admin-form-lable">
                  Question<span className="astrick-sing">*</span>
                  <span className="option-condition-text">
                    (Max character length: 100)
                  </span>
                </Index.FormHelperText>
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    name="question"
                    fullWidth
                    id="fullWidth"
                    className="admin-form-control"
                    placeholder="Enter Question"
                    onChange={handleChange}
                    value={values?.question}
                    error={errors.question && touched.question}
                    inputProps={{ maxLength: 100 }}
                  />
                  <PagesIndex.FormHelperText className="admin-error-text">
                    {errors.question && touched.question
                      ? errors.question
                      : null}
                  </PagesIndex.FormHelperText>
                </Index.Box>
              </Index.Box>
              <Index.Box className="admin-input-box admin-modal-input-box">
                <Index.FormHelperText className="admin-form-lable">
                  Answer<span className="astrick-sing">*</span>
                  <span className="option-condition-text">
                    (Max character length: 500)
                  </span>
                </Index.FormHelperText>
                <Index.Box className="admin-form-group">
                  <Index.TextareaAutosize
                    name="answer"
                    aria-label="minimum height"
                    style={{ resize: "vertical", overflow: "auto" }}
                    // minRows={3}
                    className="admin-form-control-textarea"
                    onChange={handleChange}
                    placeholder="Enter Answer"
                    value={values.answer}
                    error={errors.answer && touched.answer}
                    maxLength={500}
                  />
                  <PagesIndex.FormHelperText className="admin-error-text">
                    {errors.answer && touched.answer ? errors.answer : null}
                  </PagesIndex.FormHelperText>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="modal-footer">
              <Index.Box className="admin-modal-user-btn-flex">
                <Index.Box className="admin-discard-btn-main border-btn-main">
                  <Index.Button
                    className="admin-discard-user-btn border-btn"
                    onClick={handleClose}
                    disabled={buttonSpinner}
                  >
                    Discard
                  </Index.Button>
                </Index.Box>
                <Index.Box className="admin-save-btn-main btn-main-primary">
                  <Index.Button
                    className={`${id ? "update-btn" : "save-btn"} admin-save-user-btn btn-primary`}
                    type="submit"
                    disabled={buttonSpinner}
                  >
                    {buttonSpinner ? <PagesIndex.Spinner /> :
                      (<>
                        <img
                          src={PagesIndex.Svg.save}
                          className="admin-user-save-icon"
                          alt="Save"
                        />
                        <span>{id ? "Update" : "Add"}</span>
                      </>)
                    }
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </form>
      </Index.Modal>
    );
  };

  return (
    <PagesIndex.Formik
      enableReinitialize
      onSubmit={handleAddEditFaqs}
      initialValues={initialValues}
      validationSchema={PagesIndex.FAQSchema}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <>
          <Index.Box className="admin-dashboard-content admin-user-list-content">
            <Index.Box className="admin-user-list-flex admin-page-title-main">
              <Index.Typography
                className="admin-page-title admin-user-list-page-title"
                component="h2"
                variant="h2"
              >
                FAQ List
              </Index.Typography>
              <Index.Box className="admin-userlist-btn-flex">
                <Index.Box className="admin-search-main">
                  <Index.Box className="admin-search-box">
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="admin-form-control"
                        placeholder="Search"
                        value={searchValue}
                        onChange={requestSearch}
                      />
                      <img
                        src={PagesIndex.Svg.search}
                        className="admin-search-grey-img admin-icon"
                        alt="search"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                {adminDetails?.roleId?.permissions?.includes("faq_add") && (
                  <Index.Box className="admin-userlist-inner-btn-flex">
                    <Index.Box className="admin-adduser-btn-main btn-main-primary">
                      <Index.Button
                        className="admin-adduser-btn btn-primary"
                        onClick={() => {
                          setId("");
                          handleOpen("Add");
                        }}
                      >
                        <img
                          src={PagesIndex.Svg.plus}
                          className="admin-plus-icon"
                          alt="plus"
                        />
                        <span>Add FAQ</span>
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                )}
              </Index.Box>
            </Index.Box>
            <Index.Box className="faq-accordian-main">
              <Index.Box className="accordian-main common-card">
                {/* {loading && <PagesIndex.Loader />} */}
                {!loading &&
                  filteredData?.length > 0 &&
                  filteredData
                    ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
                    ?.map((data) => (
                      <Index.Accordion className="accordian" key={data?._id}>
                        <Index.AccordionSummary
                          className="accordian-summary"
                          expandIcon={
                            <Index.ExpandMoreIcon className="accordian-expan-icon" />
                          }
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Index.Box className="accordian-title-flex">
                            <Index.Box className="accordian-title-left-main">
                              {data?.question}
                            </Index.Box>
                            <Index.Box className="accordian-title-right-main">
                              <Index.Box className="admin-table-data-btn-flex">
                                {adminDetails?.roleId?.permissions?.includes(
                                  "faq_edit"
                                ) && (
                                    <Index.Tooltip
                                      title="Edit"
                                      arrow
                                      placement="bottom"
                                      className="admin-tooltip"
                                    >
                                      <Index.Button
                                        className="admin-table-data-btn"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setId(data?._id);
                                          setFieldValue(
                                            "question",
                                            data.question
                                          );
                                          setFieldValue("answer", data.answer);
                                          handleOpen("Edit");
                                        }}
                                      >
                                        <img
                                          src={PagesIndex.Svg.blueedit}
                                          className="admin-icon"
                                          alt="Edit"
                                        ></img>
                                      </Index.Button>
                                    </Index.Tooltip>
                                  )}

                                {adminDetails?.roleId?.permissions?.includes(
                                  "faq_log"
                                ) && (
                                    <Index.Tooltip
                                      title="Log"
                                      arrow
                                      placement="bottom"
                                      className="admin-tooltip"
                                    >
                                      <Index.Button
                                        className="admin-table-data-btn"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleOpenLogModal(data?._id);
                                        }}
                                      >
                                        <img
                                          src={PagesIndex.Svg.logicon}
                                          className="admin-icon"
                                          alt="Log"
                                        ></img>
                                      </Index.Button>
                                    </Index.Tooltip>
                                  )}
                                {adminDetails?.roleId?.permissions?.includes(
                                  "faq_delete"
                                ) && (
                                    <Index.Tooltip
                                      title="Delete"
                                      arrow
                                      placement="bottom"
                                      className="admin-tooltip"
                                    >
                                      <Index.Button
                                        className="admin-table-data-btn"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleOpenDelete(data?._id);
                                        }}
                                      >
                                        <img
                                          src={PagesIndex.Svg.trash}
                                          className="admin-icon"
                                          alt="Delete"
                                        ></img>
                                      </Index.Button>
                                    </Index.Tooltip>
                                  )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.AccordionSummary>
                        <Index.AccordionDetails className="accordian-details">
                          {data?.answer}
                        </Index.AccordionDetails>
                      </Index.Accordion>
                    ))}
                {!loading && (!filteredData || filteredData?.length === 0) && (
                  <PagesIndex.DataNotFound />
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {addEditModal(values, errors, touched, handleChange, handleSubmit)}
          {deleteModal()}
          {activityLogModal()}
        </>
      )}
    </PagesIndex.Formik>
  );
}
