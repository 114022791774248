import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "../auth.css";
import "../auth.responsive.css";

export default function ResetPassword() {
  const initialValues = {
    email: "",
  };
  const [buttonSpinner, setButtonSpinner] = useState(false);
  // for page redirect
  const navigate = PagesIndex.useNavigate();

  // for password eye hide and show
  const validateEmail = (values) => {
    setButtonSpinner(true)
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", values?.email);
    PagesIndex.apiPostHandler(PagesIndex.Api.VALIDATE_EMAIL, urlencoded).then(
      (res) => {
        setButtonSpinner(false)
        if (res.status === 200) {
          navigate("/otp-verify", {
            replace: true,
            state: { email: values?.email },
          });
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  return (
    <div>
      <Index.Box className="admin-auth-main-flex">
        <Index.Box className="admin-auth-left-main">
          <PagesIndex.AuthBackground />
        </Index.Box>
        <Index.Box className="admin-auth-right-main">
          <Index.Box className="admin-auth-box">
            <Index.Box className="admin-auth-main">
              <PagesIndex.Formik
                enableReinitialize
                onSubmit={validateEmail}
                initialValues={initialValues}
                validationSchema={PagesIndex.resetPasswordValidationSchema}
              >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                  <Index.Box
                    component={"form"}
                    noValidate="novalidate"
                    onSubmit={handleSubmit}
                    className="admin-auth-inner-main admin-forgot-pass-inner-main"
                  >
                    <Index.Typography
                      component="h2"
                      variant="h2"
                      className="admin-wel-text"
                    >
                      Forgot Password
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="admin-sign-para admin-common-para"
                    >
                      Please enter your email.
                    </Index.Typography>
                    <Index.Box className="admin-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Email Id
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          name="email"
                          fullWidth
                          id="fullWidth"
                          className="admin-form-control"
                          placeholder="Enter Email Id"
                          autoComplete="off"
                          onChange={handleChange}
                          value={values.email}
                          error={errors?.email && touched?.email}
                          inputProps={{ maxLength: 50 }}
                        />
                        <Index.FormHelperText className="admin-error-text">
                          {errors?.email && touched?.email
                            ? errors?.email
                            : null}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="admin-flex-all admin-forgot-row">
                      <Index.Link
                        className="admin-forgot-para admin-common-para"
                        to="/"
                      >
                        Back to Login?
                      </Index.Link>
                    </Index.Box>
                    <Index.Box className="btn-main-primary admin-login-btn-main">
                      <Index.Button
                        className="btn-primary admin-login-btn"
                        type="submit"
                        disabled={buttonSpinner}
                      >
                        {buttonSpinner ? <PagesIndex.Spinner /> : "Submit"}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                )}
              </PagesIndex.Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
