import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./manageUser.css";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Spinner from "../../../../component/common/spinner/Spinner";

// for modal design
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

// for modal design
const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function ManagementUser() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const formikRef = useRef();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [categoryData, setCategoryData] = useState([]);
  const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openCalender, setOpenCalender] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [id, setId] = useState("");
  const [editUserData, setEditUserData] = useState({});
  const [openStatusModal, setOpenStatusModal] = useState(false);
  // add user modal
  const [openAddUser, setOpenAddUser] = useState(false);
  const handleOpenAddUser = (op, data) => {
    setOpenAddUser(true);
    setAddOrEdit(op);
    if (data) {
      setEditUserData(data);
      setId(data?._id);
    }
  };
  const handleCloseAddUser = () => {
    formikRef.current.resetForm();
    setEditUserData({});
    setId("");
    setOpenAddUser(false);
    setOpenCalender(false);
  };

  const handleGetUsers = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.USER_LIST).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setData(res.data);
        setFilteredData(res.data);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  const handleGetCategory = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_CATEGORY).then((res) => {
      if (res.status === 200) {
        const activeCategory = res?.data?.filter((data) => data?.isActive);
        setCategoryData(activeCategory);
      }
    });
  };

  useEffect(() => {
    handleGetCategory();
  }, []);

  const requestSearch = (e) => {
    setSearchValue(e.target.value);
    let result = data.filter((data) => {
      let fullName = (data?.firstName + " " + data?.lastName)
        ?.toLowerCase()
        .replace(/\s/g, "");
      let searchQuery = e?.target?.value
        ?.trim()
        ?.toLowerCase()
        .replace(/\s/g, "");

      let email = data?.email?.toLowerCase().includes(searchQuery);
      let number = data?.mobile?.toString().includes(searchQuery);
      let gender = data?.gender?.toLowerCase() === e.target.value.toLowerCase();

      const date = new Date(data?.createdAt);
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      let filterDate = formattedDate.includes(e?.target?.value);

      return (
        fullName.includes(searchQuery) ||
        email ||
        number ||
        filterDate ||
        gender
      );
    });
    setCurrentPage(1);
    setFilteredData(result);
  };

  const initialValues = {
    firstName: id ? editUserData?.firstName : "",
    lastName: id ? editUserData?.lastName : "",
    email: id ? editUserData?.email : "",
    number: id ? editUserData?.mobile : "",
    dateOfBirth: (id && editUserData?.birthDate) ? dayjs(editUserData?.birthDate) : null,
    gender: id ? editUserData?.gender : "",
    category: id ? editUserData?.interests : "",
    location: id ? (editUserData?.location ? editUserData?.location : "") : "",
  };


  useEffect(() => {
    setLoading(true);
    handleGetUsers();
  }, []);
  const handleOpenStatusModal = (id) => {
    setId(id);
    setOpenStatusModal(true);
  };
  const handleCloseStatusModal = () => {
    setId("");
    setOpenStatusModal(false);
  };
  const handleStatus = () => {
    setStatusButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(PagesIndex.Api.ISACTIVE_USER, urlEncoded).then(
      (res) => {
        setStatusButtonSpinner(false)
        if (res.status === 200) {
          handleGetUsers();
          handleCloseStatusModal();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };

  const handleSubmitUser = (values, { resetForm }) => {
    setButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    let date = PagesIndex.moment(new Date(values?.dateOfBirth)).format(
      "YYYY-MM-DD"
    );
    urlEncoded.append("firstName", values?.firstName);
    urlEncoded.append("lastName", values?.lastName);
    urlEncoded.append("email", values?.email);
    urlEncoded.append("mobile", values?.number);
    urlEncoded.append("birthDate", date);
    urlEncoded.append("gender", values?.gender);
    urlEncoded.append("location", values?.location);
    urlEncoded.append("addedBy", "admin");
    if (id) {
      urlEncoded.append("id", id);
    }
    if (values?.category?.length === 0) {
      urlEncoded.append("interests", JSON.stringify(values?.category));
    }
    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_USER, urlEncoded)
      .then((res) => {
        setButtonSpinner(false)
        if (res.status === 201 || res.status === 200) {
          handleGetUsers();
          handleCloseAddUser();
          resetForm();
          setSearchValue("");
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };

  const renderTableRows = () => {
    return filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data, index) => {
        const startIndex = (currentPage - 1) * 10;
        const adjustedIndex = startIndex + index + 1;
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {adjustedIndex}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Box className="admin-main-data-flex">
                <Index.Box className="admin-table-avatar-icon-box">
                  <img
                    src={
                      data?.image
                        ? process.env.REACT_APP_IMAGE_ENDPOINT + data?.image
                        : PagesIndex.Svg.manIcon
                    }
                    className="avtar-icons"
                    alt="user"
                  />
                </Index.Box>
                <Index.Box className="admin-inner-data-flex">
                  <Index.Typography className="admin-table-data-text">
                    {" "}
                    {data?.email ? data?.email : ""}
                  </Index.Typography>
                  <Index.Typography className="admin-table-data-text">
                    {" "}
                    {data?.mobile ? data?.mobile : ""}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.firstName || data?.lastName
                  ? data?.firstName + " " + data?.lastName
                  : "-"}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.gender ? data?.gender : "-"}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {PagesIndex.moment(data?.createdAt)?.format(
                  "DD/MM/YYYY hh:mm A"
                )}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.createdBy?.name ? data?.createdBy?.name : "-"}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.updatedBy?.name ? data?.updatedBy?.name : "-"}
              </Index.Typography>
            </Index.TableCell>

            {
              (adminDetails?.roleId?.permissions?.includes("user_edit") ||
                adminDetails?.roleId?.permissions?.includes("user_delete")) &&
              (<Index.TableCell
                component="td"
                variant="td"
                className="table-td"
                align="right"
              >
                <Index.Tooltip
                  title={data?.isActive ? "Active" : "Deactive"}
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Box className="admin-switch-main">
                    <Index.FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      className="admin-switch-lable"
                      checked={data?.isActive}
                      onChange={() => {
                        handleOpenStatusModal(data?._id);
                      }}
                    />
                  </Index.Box>
                </Index.Tooltip>
              </Index.TableCell>)}

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Box className="admin-table-data-btn-flex">
                <Index.Tooltip
                  title="View"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-table-data-btn"
                    onClick={() => navigate(`/admin/view-user/${data?._id}`)}
                  >
                    <img
                      src={PagesIndex.Svg.yelloweye}
                      className="admin-icon"
                      alt="View"
                    ></img>
                  </Index.Button>
                </Index.Tooltip>
                {adminDetails?.roleId?.permissions?.includes("user_edit") && (
                  <Index.Tooltip
                    title="Edit"
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                  >
                    <Index.Button
                      className="admin-table-data-btn"
                      onClick={() => {
                        handleOpenAddUser("Edit", data);
                      }}
                    >
                      <img
                        src={PagesIndex.Svg.blueedit}
                        className="admin-icon"
                        alt="Edit"
                      ></img>
                    </Index.Button>
                  </Index.Tooltip>
                )}
              </Index.Box>
            </Index.TableCell>
          </Index.TableRow>
        );
      });
  };

  const renderContent = () => {
    if (filteredData?.length) {
      return renderTableRows();
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };

  const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
  const generateCSV = async () => {
    const headers = [
      "Sr. No.",
      "User Name",
      "Email Id",
      "Mobile",
      "Location",
      "Gender",
      "Created By",
      "Updated By",
      "Date",
    ];

    let modifiedData = [];
    filteredData.forEach((data, index) => {
      let objData = [
        index + 1,
        data?.firstName || data?.lastName
          ? data?.firstName?.replaceAll(",", "") +
          " " +
          data?.lastName?.replaceAll(",", "")
          : "-",
        data?.email ? data?.email?.replaceAll(",", "") : "-",
        data?.mobile ? data?.mobile?.replaceAll(",", "") : "-",
        data?.location ? data?.location?.replaceAll(",", "") : "-",
        data?.gender ? data?.gender?.replaceAll(",", "") : "-",
        data?.createdBy?.name
          ? data?.createdBy?.name?.replaceAll(",", "")
          : "-",
        data?.updatedBy?.name
          ? data?.updatedBy?.name?.replaceAll(",", "")
          : "-",
        PagesIndex.moment(data?.createdAt).format("DD/MM/YYYY"),
      ];
      modifiedData.push(objData.join(","));
    });

    exportData(
      [[headers, ...modifiedData].join("\n")],
      `${PagesIndex.moment().format("DD-MM-YYYY hh:mm:ss A")}_User_list.csv`,
      "text/csv"
    );
  };
  return (
    <PagesIndex.Formik
      enableReinitialize
      onSubmit={handleSubmitUser}
      initialValues={initialValues}
      validationSchema={PagesIndex.addUserValidationSchema}
      innerRef={formikRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Index.Box className="admin-dashboard-content admin-user-list-content">
          <Index.Box className="admin-user-list-flex admin-page-title-main">
            <Index.Typography
              className="admin-page-title admin-user-list-page-title"
              component="h2"
              variant="h2"
            >
              App User List
            </Index.Typography>
            <Index.Box className="admin-userlist-btn-flex">
              <Index.Box className="admin-search-main">
                <Index.Box className="admin-search-box">
                  <Index.Box className="admin-form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="admin-form-control"
                      placeholder="Search"
                      value={searchValue}
                      onChange={requestSearch}
                    />
                    <img
                      src={PagesIndex.Svg.search}
                      className="admin-search-grey-img admin-icon"
                      alt="search"
                    ></img>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="admin-userlist-inner-btn-flex">
                {filteredData?.length > 0 && <Index.Box className="admin-adduser-btn-main btn-main-secondary">
                  <Index.Button
                    className="admin-adduser-btn btn-secondary"
                    onClick={generateCSV}
                  >
                    <img
                      src={PagesIndex.Svg.exporticon}
                      className="admin-plus-icon"
                      alt="plus"
                    />
                    <span>Export CSV</span>
                  </Index.Button>
                </Index.Box>}
                {
                  adminDetails?.roleId?.permissions?.includes("user_add") &&
                  (<Index.Box className="admin-adduser-btn-main btn-main-primary">
                    <Index.Button
                      className="admin-adduser-btn btn-primary"
                      onClick={() => {
                        setId("");
                        handleOpenAddUser("Add");
                      }}
                    >
                      {" "}
                      <img
                        src={PagesIndex.Svg.plus}
                        className="admin-plus-icon"
                        alt="plus"
                      />
                      <span>Add User</span>
                    </Index.Button>
                  </Index.Box>)}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="common-card">
            <Index.Box
              className={`admin-manage-user-table-main page-table-main`}
            >
              <Index.TableContainer
                component={Index.Paper}
                className="table-container"
              >
                {loading ? (
                  <PagesIndex.Loader />
                ) : (
                  <Index.Table aria-label="simple table" className="table">
                    <Index.TableHead className="table-head">
                      <Index.TableRow className="table-row">
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="3%"
                        >
                          Sr. No.
                        </Index.TableCell>

                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="6%"
                        >
                          App User Details
                        </Index.TableCell>

                        {/* <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="15%"
                        >
                          Email Id
                        </Index.TableCell> */}

                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="7%"
                        >
                          Name
                        </Index.TableCell>

                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="3%"
                        >
                          Gender
                        </Index.TableCell>

                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="8%"
                        >
                          Created Date & Time
                        </Index.TableCell>

                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="7%"
                        >
                          Created By
                        </Index.TableCell>

                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="7%"
                        >
                          Updated By
                        </Index.TableCell>

                        {
                          (adminDetails?.roleId?.permissions?.includes("user_edit") ||
                            adminDetails?.roleId?.permissions?.includes("user_delete")) &&
                          (
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="2%"
                              align="right"
                            >
                              Status
                            </Index.TableCell>)
                        }
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="5%"
                          align="right"
                        >
                          Action
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="table-body">
                      {renderContent()}
                    </Index.TableBody>
                  </Index.Table>
                )}
              </Index.TableContainer>
            </Index.Box>
            <Index.Box className="admin-pagination-main">
              {filteredData?.length ? (
                <Index.Pagination
                  page={currentPage}
                  count={Math.ceil(filteredData?.length / 10)}
                  onChange={(event, newPage) => setCurrentPage(newPage)}
                  variant="outlined"
                  shape="rounded"
                  className="admin-pagination"
                />
              ) : (
                ""
              )}
            </Index.Box>
          </Index.Box>

          <Index.Modal
            open={openAddUser}
            onClose={handleCloseAddUser}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <form onSubmit={handleSubmit} noValidate="novalidate">
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {" "}
                    {addOrEdit} User
                  </Index.Typography>
                  {/* <Index.Button
                    className="modal-close-btn"
                    onClick={handleCloseAddUser}
                  >
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Button> */}
                </Index.Box>
                <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                  <Index.Box className="grid-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box add-admin-user-input">
                            <Index.FormHelperText className="admin-form-lable">
                              First Name<span className="astrick-sing">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="admin-form-control"
                                placeholder="Enter First Name"
                                name="firstName"
                                onChange={handleChange}
                                value={values?.firstName}
                                error={errors.firstName && touched.firstName}
                                inputProps={{ maxLength: 30 }}
                              />
                              {touched.firstName && errors.firstName && (
                                <PagesIndex.FormHelperText className="admin-error-text">
                                  {errors.firstName}
                                </PagesIndex.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 8",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box add-admin-user-input">
                            <Index.FormHelperText className="admin-form-lable">
                              Last Name<span className="astrick-sing">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="admin-form-control"
                                placeholder="Enter Last Name"
                                name="lastName"
                                onChange={handleChange}
                                value={values?.lastName}
                                error={errors.lastName && touched.lastName}
                                inputProps={{ maxLength: 30 }}
                              />
                              {touched.lastName && errors.lastName && (
                                <PagesIndex.FormHelperText className="admin-error-text">
                                  {errors.lastName}
                                </PagesIndex.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 8",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box add-admin-user-input">
                            <Index.FormHelperText className="admin-form-lable">
                              Email Id <span className="astrick-sing">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="admin-form-control"
                                placeholder="Enter Email Id"
                                name="email"
                                onChange={handleChange}
                                value={values?.email}
                                error={errors.email && touched.email}
                                disabled={!!id}
                                inputProps={{ maxLength: 50 }}
                              />
                              {touched.email && errors.email && (
                                <PagesIndex.FormHelperText className="admin-error-text">
                                  {errors.email}
                                </PagesIndex.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 8",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box add-admin-user-input">
                            <Index.FormHelperText className="admin-form-lable">
                              Mobile Number
                              <span className="astrick-sing">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="admin-form-control"
                                placeholder="Enter Mobile Number"
                                name="number"
                                onChange={(e) => {
                                  const regex = /^[0-9]*$/;
                                  if (regex.test(e.target.value)) {
                                    handleChange(e);
                                  }
                                }}
                                value={values?.number}
                                inputProps={{ maxLength: 10 }}
                                error={errors.number && touched.number}
                                disabled={!!id}
                              />
                              {touched.number && errors.number && (
                                <PagesIndex.FormHelperText className="admin-error-text">
                                  {errors.number}
                                </PagesIndex.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 8",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box add-admin-user-input admin-date-input-main">
                            <Index.FormHelperText className="admin-form-lable">
                              Date Of Birth
                              <span className="astrick-sing">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <PagesIndex.LocalizationProvider
                                dateAdapter={Index.AdapterDayjs}
                              >
                                <PagesIndex.DatePicker
                                  open={openCalender}
                                  onClose={() => setOpenCalender(false)}
                                  className="admin-form-control admin-date-picker-control"
                                  value={values.dateOfBirth}
                                  maxDate={dayjs().subtract(16, "years")}
                                  onChange={(date) => {
                                    setFieldValue("dateOfBirth", date);
                                  }}
                                  slotProps={{
                                    popper: {
                                      className: "date-picker-body",
                                    },
                                    textField: {
                                      readOnly: true,
                                      onClick: () => setOpenCalender(true),
                                    },
                                  }}
                                  format="DD/MM/YYYY"
                                />
                              </PagesIndex.LocalizationProvider>
                              <Index.FormHelperText className="admin-error-text">
                                {errors.dateOfBirth &&
                                  touched.dateOfBirth &&
                                  errors.dateOfBirth}
                              </Index.FormHelperText>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 8",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box add-admin-user-input">
                            <Index.FormHelperText className="admin-form-lable">
                              Gender<span className="astrick-sing">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.Box className="admin-dropdown-box">
                                <Index.FormControl className="admin-form-control">
                                  <Index.Select
                                    className="admin-dropdown-select"
                                    value={values?.gender}
                                    name="gender"
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    renderValue={
                                      values?.gender
                                        ? undefined
                                        : () => "Select Gender"
                                    }
                                  >
                                    <Index.MenuItem
                                      value="Male"
                                      className="admin-drop-menuitem"
                                    >
                                      Male
                                    </Index.MenuItem>
                                    <Index.MenuItem
                                      value="Female"
                                      className="admin-drop-menuitem"
                                    >
                                      Female
                                    </Index.MenuItem>
                                    <Index.MenuItem
                                      value="Other"
                                      className="admin-drop-menuitem"
                                    >
                                      Other
                                    </Index.MenuItem>
                                  </Index.Select>
                                </Index.FormControl>
                                <PagesIndex.FormHelperText className="admin-error-text">
                                  {errors.gender && touched.gender
                                    ? errors.gender
                                    : null}
                                </PagesIndex.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box add-admin-user-input">
                            <Index.FormHelperText className="admin-form-lable">
                              Location<span className="astrick-sing">*</span>
                            </Index.FormHelperText>
                            <PagesIndex.AutoCompaleteLocation
                              initialValues={values}
                              setFieldValue={setFieldValue}
                              errors={errors}
                              touched={touched}
                              values={values}
                            />
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 8",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box add-admin-user-input input-complete-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Genres/Categories
                              <span className="option-condition-text">
                                (Optional)
                              </span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.Box className="admin-dropdown-box">
                                <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                  <Index.Autocomplete
                                    className="admin-auto-complete-filed"
                                    options={categoryData || []}
                                    name="category"
                                    multiple
                                    closeText={""}
                                    openText={""}
                                    clearText={""}
                                    disableCloseOnSelect
                                    value={
                                      values?.category
                                        ? categoryData.filter((data) =>
                                          values?.category?.some(
                                            (cat) => cat._id === data._id
                                          )
                                        )
                                        : []
                                    }
                                    onChange={(e, selectedOptions) => {
                                      setFieldValue(
                                        "category",
                                        selectedOptions.map((option) => ({
                                          _id: option?._id,
                                          categoryName: option?.categoryName,
                                        }))
                                      );
                                    }}
                                    getOptionLabel={(option) =>
                                      option?.categoryName
                                    }
                                    renderOption={(props, item) => (
                                      <li {...props} key={item?._id}>
                                        {item?.categoryName}
                                      </li>
                                    )}
                                    renderInput={(params) => (
                                      <Index.TextField
                                        fullWidth
                                        className="admin-form-control"
                                        placeholder={
                                          values?.category?.length
                                            ? ""
                                            : "Select Genre/Category"
                                        }
                                        {...params}
                                        size="small"
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </Index.FormControl>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="admin-discard-btn-main border-btn-main">
                      <Index.Button
                        className="admin-discard-user-btn border-btn"
                        onClick={handleCloseAddUser}
                        disabled={buttonSpinner}
                      >
                        Discard
                      </Index.Button>
                    </Index.Box>
                    <Index.Box className="admin-save-btn-main btn-main-primary">
                      <Index.Button
                        className={`${id ? "update-btn" : "save-btn"} admin-save-user-btn btn-primary`}
                        type="submit"
                        disabled={buttonSpinner}
                      >
                        {buttonSpinner ? <Spinner /> :
                          (<>
                            <img
                              src={PagesIndex.Svg.save}
                              className="admin-user-save-icon"
                              alt="Save"
                            />
                            <span>{id ? "Update" : "Add"}</span>
                          </>)
                        }
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          </Index.Modal>
          <PagesIndex.ChangeStatusModal
            data={"user"}
            statusButtonSpinner={statusButtonSpinner}
            openStatusModal={openStatusModal}
            handleCloseStatusModal={handleCloseStatusModal}
            handleChangeStatus={handleStatus}
          />
        </Index.Box>
      )}
    </PagesIndex.Formik>
  );
}
