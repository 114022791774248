import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { useNavigate } from "react-router-dom";
// import "./programType.Reponsive.css";

// for modal design

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function SalesOrderList() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );

  const formRef = useRef();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState({});
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [blockButtonSpinner, setBlockButtonSpinner] = useState(false);

  const [filteredData, setFilteredData] = useState([]);
  // add user modal
  const [openDelete, setOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [prevBookingsData, setPrevBookingsData] = useState([]);
  const [openMessage, setOpenMessage] = useState(false);
  const handleOpenMessage = () => setOpenMessage(true);
  const handleCloseMessage = () => {
    setSelectedValue("");
    setOpenMessage(false);
    formRef.current.resetForm();
  };

  const open = Boolean(anchorEl);
  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setId(data?._id);
    setModalData(data);
  };
  const handleMenuItemClick = (value) => {
    setSelectedValue(value);
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
    formRef.current.resetForm();
  };

  // add message modal
  const [openView, setOpenView] = useState(false);
  const handleOpenView = (data, id) => {
    setOpenView(true);
    setModalData(data);
    setId(id);
  };
  const handleCloseView = () => {
    setOpenView(false);
    formRef.current.resetForm();
  };

  const initialValues = {
    reason: id && modalData?.status == "Reject" ? modalData?.reason : "",
  };

  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setId(id);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };
  const handleGetSalesOrder = () => {
    setLoading(true)
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_SALES_ORDERS).then(
      (res) => {
        if (res.status === 200) {
          setLoading(false)
          // let filter = res.data?.filter(
          //   (ele) => adminDetails?._id == ele?.approvalId?._id
          // );
          // if (filter?.length !== 0) {
          //   setData(filter);
          //   setFilteredData(filter);
          // } else {
          setData(res?.data);
          setFilteredData(res?.data);
          // }
        }
      }
    );
  };
  const handleGetPreviousBookings = () => {
    setLoading(true);
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_BOOKING + "/" + id).then(
      (res) => {
        setLoading(false);
        if (res.status === 200) {
          setPrevBookingsData(res.data);
        }
      }
    );
  };
  useEffect(() => {
    handleGetSalesOrder();
  }, []);

  useEffect(() => {
    id && handleGetPreviousBookings();
  }, [id]);

  const handleDeleteSalesOrder = () => {
    setDeleteButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(
      PagesIndex.Api.DELETE_SALES_ORDER,
      urlEncoded
    ).then((res) => {
      setDeleteButtonSpinner(false)
      if (res.status === 200) {
        handleCloseDelete();
        handleGetSalesOrder();
        PagesIndex.toasterSuccess(res?.message);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  const requestSearch = (e) => {
    setSearchValue(e.target.value);
    let filteredData = data.filter(
      (data) =>
        data?.client?.firstName
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        PagesIndex.moment(new Date(data?.updatedAt))
          .format("YYYY-MM-DD")
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.orderNo
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.createdBy?.name
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.totalAmount
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase())
    );
    setCurrentPage(1);
    setFilteredData(filteredData);
  };

  const renderTableRows = () => {
    return filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data) => {
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                <span className="news-id-text">{data?.orderNo}</span>
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {data?.client?.firstName}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {data?.campaignName}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {parseFloat(data?.totalAmount)?.toLocaleString("en-Us") || "-"}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {data?.updatedAt || data?.createdBy?.name
                  ? data?.createdBy?.name +
                  "-" +
                  PagesIndex.moment(new Date(data?.updatedAt)).format(
                    "YYYY-MM-DD hh:mm:ss A"
                  )
                  : "-"}
              </Index.Typography>
            </Index.TableCell>

            {/* <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Box
                className={
                  data?.status == "Pending"
                    ? "status-lable-orang"
                    : data?.status == "Reject"
                    ? "status-lable-red"
                    : "status-lable"
                }
              >
                {data?.status ? data?.status : "-"}
              </Index.Box>
            </Index.TableCell> */}

            <Index.TableCell
              component="td"
              variant="td"
              align="right"
              className="table-td"
            >
              <Index.Box className="admin-table-data-btn-flex ">
                {adminDetails?.roleId?.permissions?.includes(
                  "sales_order_edit"
                ) &&
                  adminDetails?._id !== data?.approvalId?._id && (
                    <Index.Tooltip
                      title="Edit"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => {
                          navigate(`/admin/add-sales-order/${data?._id}`, {
                            state: {
                              orderList: data,
                            },
                          });
                        }}
                      >
                        <img
                          src={PagesIndex.Svg.blueedit}
                          className="admin-icon"
                          alt="Edit"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}

                <Index.Tooltip
                  title="View"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-table-data-btn"
                    onClick={() => handleOpenView(data, data?._id)}
                  >
                    <img
                      src={PagesIndex.Svg.yelloweye}
                      className="admin-icon"
                      alt="View"
                    />
                  </Index.Button>
                </Index.Tooltip>

                <Index.Tooltip
                  title="View Bookings"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-table-data-btn"
                    onClick={() => {
                      navigate(`/admin/sales-sub-order-list/${data?._id}`);
                    }}
                  >
                    <img
                      src={PagesIndex.Svg.viewbookingIcon}
                      className="admin-icon"
                      alt="View Booking"
                    />
                  </Index.Button>
                </Index.Tooltip>
                
                {adminDetails?.roleId?.permissions?.includes(
                  "sales_order_add"
                ) && (
                    <Index.Tooltip
                      title="Add Booking"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => {
                          navigate(`/admin/add-sales-sub-order/${data?._id}`, {
                            state: {
                              orderList: data,
                            },
                          });
                        }}
                      >
                        <img
                          src={PagesIndex.Svg.darkplus}
                          className="admin-icon"
                          alt="Plus"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>)}

                {adminDetails?.roleId?.permissions?.includes(
                  "sales_order_delete"
                ) && (
                    <Index.Tooltip
                      title="Delete"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => handleOpenDelete(data?._id)}
                      // onClick={() => handleOpenMessage(data?._id)}
                      >
                        <img
                          src={PagesIndex.Svg.trash}
                          className="admin-icon"
                          alt="Delete"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}
                {adminDetails?._id == data?.approvalId?._id &&
                  data?.status == "Pending" && (
                    <Index.Tooltip
                      title={anchorEl == null ? "Menu" : ""}
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Box className="action-menu-main">
                        <Index.Button
                          id="basic-button"
                          className="admin-table-data-btn"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={(e) => handleClick(e, data)}
                        >
                          <img
                            src={PagesIndex.Svg.DotsIcon}
                            className="admin-icon"
                            alt="Delete"
                          ></img>
                        </Index.Button>
                        <Index.Menu
                          className="action-menu"
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <Index.MenuItem
                            className="admin-drop-header-menuitem"
                            onClick={() => handleMenuItemClick("Approve")}
                          >
                            Approve
                          </Index.MenuItem>
                          {/* <Index.MenuItem className="admin-drop-header-menuitem" onClick={() => handleMenuItemClick('Pending')}>Pending</Index.MenuItem> */}
                          <Index.MenuItem
                            className="admin-drop-header-menuitem"
                            onClick={() => handleMenuItemClick("Reject")}
                          >
                            Reject
                          </Index.MenuItem>
                        </Index.Menu>
                      </Index.Box>
                    </Index.Tooltip>
                  )}
              </Index.Box>
            </Index.TableCell>
          </Index.TableRow>
        );
      });
  };

  const renderContent = () => {
    if (filteredData?.length) {
      return renderTableRows();
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };
  const handleDownloadVideo = async (data) => {
    const a = document.createElement("a");

    a.setAttribute("download", data?.documentsName); // Replace with the desired filename for the downloaded file
    a.href = process.env.REACT_APP_IMAGE_ENDPOINT + data?.file;
    a.click();
    window.URL.revokeObjectURL(
      process.env.REACT_APP_IMAGE_ENDPOINT + data?.file
    );
  };
  const handleAddEditBlockResone = (values) => {
    setBlockButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    if (values?.reason) {
      urlEncoded.append("reason", values?.reason);
      urlEncoded.append("status", "Reject");
    } else {
      urlEncoded.append("status", selectedValue);
    }
    if (values?.reason) {
    }
    PagesIndex.apiPostHandler(PagesIndex.Api.GET_SALES_STATUS, urlEncoded)
      .then((res) => {
        setBlockButtonSpinner(false)
        if (res.status === 201 || res.status === 200) {
          handleCloseMessage();
          handleGetSalesOrder();
          handleCloseView();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
    setSelectedValue("");
  };

  useEffect(() => {
    if (selectedValue) {
      if (selectedValue == "Reject") {
        handleOpenMessage();
      } else {
        handleAddEditBlockResone();
      }
    }
  }, [selectedValue]);

  return (
    <PagesIndex.Formik
      enableReinitialize
      onSubmit={handleAddEditBlockResone}
      initialValues={initialValues}
      validationSchema={PagesIndex.userBlockedSchema}
      innerRef={formRef}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <>
          <Index.Box className="admin-dashboard-content admin-user-list-content">
            <Index.Box className="admin-user-list-flex admin-page-title-main">
              <Index.Typography
                className="admin-page-title admin-user-list-page-title"
                component="h2"
                variant="h2"
              >
                Sales Deal List
              </Index.Typography>
              <Index.Box className="admin-userlist-btn-flex">
                <Index.Box className="admin-search-main">
                  <Index.Box className="admin-search-box">
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="admin-form-control"
                        placeholder="Search"
                        value={searchValue}
                        onChange={requestSearch}
                      />
                      <img
                        src={PagesIndex.Svg.search}
                        className="admin-search-grey-img admin-icon"
                        alt="search"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                {adminDetails?.roleId?.permissions?.includes(
                  "sales_order_add"
                ) && (
                    <Index.Box className="admin-userlist-inner-btn-flex">
                      <Index.Box className="admin-adduser-btn-main btn-main-primary">
                        <Index.Button
                          className="admin-adduser-btn btn-primary"
                          onClick={() => {
                            navigate(`/admin/add-sales-order`);
                          }}
                        >
                          <img
                            src={PagesIndex.Svg.plus}
                            className="admin-plus-icon"
                            alt="plus"
                          />
                          <span>Add Deal</span>
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  )}
              </Index.Box>
            </Index.Box>

            <Index.Box className="common-card">
              <Index.Box
                className={`admin-distric-table-main ${loading ? "" : "page-table-main"
                  }`}
              >
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  {loading ? (
                    <PagesIndex.Loader />
                  ) : (
                    <Index.Table aria-label="simple table" className="table">
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="10%"
                          >
                            Order Id
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="15%"
                          >
                            Client Name
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="15%"
                          >
                            Deal Name
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="12%"
                          >
                            Total Amount
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="22%"
                          >
                            Created Date & Time
                          </Index.TableCell>

                          {/* <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="12%"
                          >
                            Status
                          </Index.TableCell> */}

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="5%"
                            align="right"
                          >
                            Action
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {renderContent()}
                      </Index.TableBody>
                    </Index.Table>
                  )}
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="admin-pagination-main">
                {filteredData?.length ? (
                  <Index.Pagination
                    page={currentPage}
                    count={Math.ceil(filteredData?.length / 10)}
                    onChange={(event, newPage) => setCurrentPage(newPage)}
                    variant="outlined"
                    shape="rounded"
                    className="admin-pagination"
                  />
                ) : (
                  ""
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Modal
            open={openMessage}
            onClose={handleCloseMessage}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <form onSubmit={handleSubmit} noValidate="novalidate">
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {" "}
                    Reason<span className="astrick-sing">*</span>{" "}
                    <span className="option-condition-text">
                      (Max character length: 500)
                    </span>
                  </Index.Typography>
                  <Index.Button
                    className="modal-close-btn"
                    onClick={handleCloseMessage}
                  >
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Button>
                </Index.Box>
                <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.Box className="admin-form-group">
                      <Index.TextareaAutosize
                        aria-label="minimum height"
                        // minRows={3}
                        style={{ resize: "vertical", overflow: "auto" }}
                        placeholder="Example test"
                        className="admin-form-control-textarea"
                        name="reason"
                        onChange={handleChange}
                        value={values?.reason}
                        error={errors.reason && touched.reason}
                        maxLength={500}
                      />
                      <PagesIndex.FormHelperText className="admin-error-text">
                        {errors.reason && touched.reason ? errors.reason : null}
                      </PagesIndex.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="admin-save-btn-main btn-main-primary">
                      <Index.Button
                        className="admin-icons-btn btn-primary update-btn"
                        type="Submit"
                        disabled={blockButtonSpinner}
                      >
                        {blockButtonSpinner ? <PagesIndex.Spinner /> :
                          <>
                            <img
                              src={PagesIndex.Svg.blockicon}
                              className="admin-icons block-icon"
                              alt="Block"
                            ></img>
                            <span>Submit</span>
                          </>}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          </Index.Modal>

          <Index.Modal
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <Index.Box
              sx={style}
              className="admin-delete-modal-inner-main admin-modal-inner"
            >
              <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                <Index.Box className="admin-modal-circle-main">
                  <img
                    src={PagesIndex.Svg.closecircle}
                    className="admin-user-circle-img"
                    alt="Close"
                  />
                </Index.Box>
                <Index.Typography
                  className="admin-delete-modal-title"
                  component="h2"
                  variant="h2"
                >
                  Are you sure?
                </Index.Typography>
                <Index.Typography
                  className="admin-delete-modal-para admin-common-para"
                  component="p"
                  variant="p"
                >
                  Do you really want to delete this sales deal? This process
                  cannot be undone.
                </Index.Typography>
                <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
                  <Index.Button
                    className="admin-modal-cancel-btn border-btn"
                    onClick={handleCloseDelete}
                    disabled={deleteButtonSpinner}
                  >
                    Cancel
                  </Index.Button>
                  <Index.Button
                    className="delete-user admin-modal-delete-btn btn"
                    onClick={handleDeleteSalesOrder}
                    disabled={deleteButtonSpinner}
                  >
                    {deleteButtonSpinner ? <PagesIndex.Spinner /> : <span>Delete</span>
                    }
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>

          <Index.Modal
            open={openView}
            onClose={handleCloseView}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <form onSubmit={handleSubmit} noValidate="novalidate">
              <Index.Box
                sx={style}
                className="admin-view-sales-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {" "}
                    Order Details{" "}
                    <span className="sales-order-id-text">
                      ({modalData?.orderNo})
                    </span>
                  </Index.Typography>
                  <Index.Button
                    className="modal-close-btn"
                    onClick={handleCloseView}
                  >
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Button>
                </Index.Box>
                <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                  <Index.Box className="sales-order-view-main">
                    <Index.Box className="sales-order-view-flex">
                      <Index.Box className="sales-order-view-left">
                        <Index.Box className="sales-order-view-content">
                          <Index.Typography className="sales-order-name-lable">
                            Client Name:
                          </Index.Typography>
                          <Index.Typography className="sales-order-name-text">
                            {modalData?.client?.firstName}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="sales-order-view-right">
                        <Index.Box className="sales-order-view-content">
                          <Index.Typography className="sales-order-name-lable">
                            Start Date:
                          </Index.Typography>
                          <Index.Typography className="sales-order-name-text">
                            {PagesIndex.moment(modalData?.startDate).format(
                              "DD/MM/YYYY"
                            )}
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="sales-order-view-content">
                          <Index.Typography className="sales-order-name-lable">
                            End Date:
                          </Index.Typography>
                          <Index.Typography className="sales-order-name-text">
                            {PagesIndex.moment(modalData?.endDate).format(
                              "DD/MM/YYYY"
                            )}
                          </Index.Typography>
                        </Index.Box>

                        <Index.Box className="sales-order-view-content">
                          <Index.Typography className="sales-order-name-lable">
                            Status:
                          </Index.Typography>
                          <Index.Typography className="sales-order-name-text">
                            {modalData?.status}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="common-card">
                      <Index.Box
                        className={`admin-order-details-table-main page-table-main`}
                      >
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          {loading ? (
                            <PagesIndex.Loader />
                          ) : (
                            <Index.Table
                              aria-label="simple table"
                              className="table"
                            >
                              <Index.TableHead className="table-head">
                                <Index.TableRow className="table-row">
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    width="2%"
                                  >
                                    Sr. No.
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    width="5%"
                                  >
                                    Platform
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    width="5%"
                                  >
                                    Element
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    width="5%"
                                  >
                                    Total Units
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    width="5%"
                                  >
                                    Remaining Units
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    width="5%"
                                  >
                                    Rate
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    width="5%"
                                  >
                                    Selling Rate
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    width="5%"
                                  >
                                    Amount
                                  </Index.TableCell>
                                </Index.TableRow>
                              </Index.TableHead>
                              <Index.TableBody className="table-body">
                                {modalData?.orderDetails?.map((ele, index) => {
                                  return (
                                    <Index.TableRow
                                      className="table-row"
                                      key={ele?._id}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {index + 1}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {ele?.platform}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {ele?.elements}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {ele?.totalSeconds}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        <Index.Typography className="admin-table-data-text">
                                          {ele?.remainingUnits || "-"}
                                        </Index.Typography>
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {parseFloat(ele?.rate)?.toLocaleString(
                                          "en-Us"
                                        )}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {ele?.discountrate}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {parseFloat(
                                          ele?.amount
                                        )?.toLocaleString("en-Us")}
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  );
                                })}

                                <Index.TableRow>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  ></Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  ></Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  ></Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  ></Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  ></Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  ></Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="right"
                                  >
                                    <Index.Box className="total-text">
                                      Total Amount:
                                    </Index.Box>
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {parseFloat(
                                      modalData?.totalAmount
                                    )?.toLocaleString("en-Us")}
                                  </Index.TableCell>
                                </Index.TableRow>
                              </Index.TableBody>
                            </Index.Table>
                          )}
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                    {modalData?.document?.length !== 0 && (
                      <>
                        <Index.Box className="view-sales-approve-box">
                          <Index.Box className="view-sales-approve-flex">
                            <Index.Box className="admin-sub-title-main">
                              <Index.Typography
                                className="admin-sub-title"
                                component="h2"
                                variant="h2"
                              >
                                List of Attachment
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="common-card attechment-card">
                          {modalData?.document?.map((url) => {
                            return (
                              // <Index.Box
                              //   className="sales-attechment-flex-box"
                              //   key={ele?._id}
                              // >
                              //   <Index.Box className="sales-attechment-box">
                              //     <Index.Typography className="sales-approve-attechment-name">
                              //       {ele?.file}
                              //     </Index.Typography>
                              //   </Index.Box>
                              // </Index.Box>

                              <Index.Box
                                className="document-data-flex"
                                key={url?.file}
                              >
                                <Index.Box className="document-lable-flex">
                                  <Index.Typography className="document-id-text">
                                    {url?.name?.toUpperCase()}
                                  </Index.Typography>
                                  <Index.Typography className="document-lable-text">
                                    {url?.file}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="document-content-main">
                                  <Index.Box className="document-delete-main">
                                    <Index.Button
                                      className="admin-table-data-btn"
                                      onClick={() => handleDownloadVideo(url)}
                                    >
                                      <img
                                        src={PagesIndex.Svg.download}
                                        className="admin-icon"
                                        alt="Download"
                                      ></img>
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            );
                          })}
                        </Index.Box>
                      </>
                    )}
                    {prevBookingsData?.length ? (
                      <>
                        <Index.Box className="other-flex">
                          <Index.Box className="admin-sub-title-main">
                            <Index.Typography
                              className="admin-sub-title"
                              component="h2"
                              variant="h2"
                            >
                              Previous Order Details
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="common-card">
                          <Index.Box
                            className={`admin-previous-order-details-table-main page-table-main`}
                          >
                            <Index.TableContainer
                              component={Index.Paper}
                              className="table-container"
                            >
                              {loading ? (
                                <PagesIndex.Loader />
                              ) : (
                                prevBookingsData?.map((dataOuter, index) => {
                                  return (
                                    <Index.Table
                                      aria-label="simple table"
                                      className="table"
                                      key={data?._id}
                                    >
                                      <Index.TableHead className="table-head">
                                        <Index.TableRow className="table-row">
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="7%"
                                          >
                                            <span className="news-id-text">
                                              {dataOuter?.subOrderNo}
                                            </span>
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th-trasparent"
                                            width="5%"
                                          ></Index.TableCell>
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th-trasparent"
                                            width="5%"
                                          ></Index.TableCell>
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th-trasparent"
                                            width="5%"
                                          ></Index.TableCell>
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th-trasparent"
                                            width="5%"
                                          ></Index.TableCell>
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th-trasparent"
                                            width="5%"
                                          ></Index.TableCell>
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th-trasparent"
                                            colSpan={2}
                                          >
                                            <Index.Box className="date-box-flex">
                                              <Index.Typography className="date-text">
                                                Start Date:
                                                <span className="date-text-span">
                                                  {PagesIndex.moment(
                                                    dataOuter?.startDate
                                                  ).format("DD/MM/YYYY")}
                                                </span>
                                              </Index.Typography>
                                              <Index.Typography className="date-text">
                                                End Date:
                                                <span className="date-text-span">
                                                  {PagesIndex.moment(
                                                    dataOuter?.endDate
                                                  ).format("DD/MM/YYYY")}
                                                </span>
                                              </Index.Typography>
                                            </Index.Box>
                                          </Index.TableCell>
                                        </Index.TableRow>
                                        <Index.TableRow className="table-row">
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="2%"
                                          >
                                            Sr. No.
                                          </Index.TableCell>

                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="5%"
                                          >
                                            Platform
                                          </Index.TableCell>

                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="5%"
                                          >
                                            Element
                                          </Index.TableCell>

                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="5%"
                                          >
                                            Total
                                          </Index.TableCell>

                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="5%"
                                          >
                                            Selling Rate
                                          </Index.TableCell>

                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="5%"
                                          >
                                            Remaining Units
                                          </Index.TableCell>

                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="5%"
                                          >
                                            Used Units
                                          </Index.TableCell>

                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="5%"
                                          >
                                            Amount
                                          </Index.TableCell>
                                        </Index.TableRow>
                                      </Index.TableHead>
                                      <Index.TableBody className="table-body">
                                        {dataOuter?.orderDetails?.map(
                                          (data, index) => {
                                            return (
                                              <Index.TableRow
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                  {
                                                    border: 0,
                                                  },
                                                }}
                                                key={data?._id}
                                              >
                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  <Index.Typography className="admin-table-data-text">
                                                    {index + 1}
                                                  </Index.Typography>
                                                </Index.TableCell>

                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  <Index.Typography className="admin-table-data-text">
                                                    {data?.platform
                                                      ? data?.platform
                                                      : "-"}
                                                  </Index.Typography>
                                                </Index.TableCell>

                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  <Index.Typography className="admin-table-data-text">
                                                    {data?.elements
                                                      ? data?.elements
                                                      : "-"}
                                                  </Index.Typography>
                                                </Index.TableCell>

                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  <Index.Typography className="admin-table-data-text">
                                                    {data?.totalSeconds
                                                      ? data?.totalSeconds
                                                      : 0}{" "}
                                                    ({data?.unit})
                                                  </Index.Typography>
                                                </Index.TableCell>

                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  <Index.Typography className="admin-table-data-text">
                                                    {" "}
                                                    {data?.discountrate
                                                      ? data?.discountrate
                                                      : 0}
                                                  </Index.Typography>
                                                </Index.TableCell>

                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  <Index.Typography className="admin-table-data-text">
                                                    {" "}
                                                    {parseFloat(
                                                      data?.remainingUnits || 0
                                                    )}
                                                  </Index.Typography>
                                                </Index.TableCell>
                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  <Index.Typography className="admin-table-data-text">
                                                    {" "}
                                                    {parseFloat(
                                                      data?.units || 0
                                                    )}
                                                  </Index.Typography>
                                                </Index.TableCell>

                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  <Index.Typography className="admin-table-data-text">
                                                    {" "}
                                                    {data?.unit == "seconds"
                                                      ? (data?.units *
                                                        data?.rate) /
                                                      10 || 0
                                                      : data?.units *
                                                      data?.rate || 0}
                                                  </Index.Typography>
                                                </Index.TableCell>
                                              </Index.TableRow>
                                            );
                                          }
                                        )}
                                        <Index.TableRow>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                          ></Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                          ></Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                          ></Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                          ></Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                          ></Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                          ></Index.TableCell>

                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                            align="right"
                                          >
                                            <Index.Box className="total-text">
                                              Total Amount:
                                            </Index.Box>
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                          >
                                            <Index.Box className="total-text">
                                              {" "}
                                              {parseFloat(
                                                dataOuter?.totalAmount || 0
                                              )?.toLocaleString("en-US")}
                                            </Index.Box>
                                          </Index.TableCell>
                                        </Index.TableRow>
                                      </Index.TableBody>
                                    </Index.Table>
                                  );
                                })
                              )}
                            </Index.TableContainer>
                          </Index.Box>
                        </Index.Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </Index.Box>

                {/* <Index.Box className="sales-order-view-flex">
                  <Index.Box className="sales-order-view-content">
                    <Index.Typography className="sales-order-name-lable">
                      Request For Approvel:
                    </Index.Typography>
                    <Index.Typography className="sales-order-name-text">
                      {modalData?.approvalId?.name}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box> */}

                {adminDetails?._id == modalData?.approvalId?._id &&
                  modalData?.status !== "Reject" ? (
                  <>
                    <Index.Box className="admin-input-box add-user-input view-sales-approve-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Reasons
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextareaAutosize
                          aria-label="minimum height"
                          // minRows={3}
                          placeholder=""
                          className="admin-form-control-textarea"
                          name="reason"
                          onChange={handleChange}
                          value={values?.reason}
                          error={errors.reason && touched.reason}
                          maxLength={500}
                        />
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.reason && touched.reason
                            ? errors.reason
                            : null}
                        </PagesIndex.FormHelperText>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main sales-view-btn-flex">
                      <Index.Button
                        className="admin-modal-delete-btn btn"
                        onClick={() => setSelectedValue("Approve")}
                      >
                        Approve
                      </Index.Button>
                      <Index.Button
                        className="admin-modal-cancel-btn border-btn"
                        type="submit"
                      >
                        Reject
                      </Index.Button>
                    </Index.Box>
                  </>
                ) : (
                  modalData?.reason && (
                    <Index.Box className="sales-order-view-flex">
                      <Index.Box className="sales-order-view-content">
                        <Index.Typography className="sales-order-name-lable">
                          Reason:
                        </Index.Typography>
                        <Index.Typography className="sales-order-name-text">
                          {modalData?.reason}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  )
                )}
              </Index.Box>
            </form>
          </Index.Modal>
        </>
      )}
    </PagesIndex.Formik>
  );
}
