import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./reportsAndAnalytics.css";
import "./reportsAndAnalyticsResponsive.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
export default function ReportsAndAnalytics() {
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [dataHeaders, setDataHeaders] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [type, setType] = useState("today");
  const [open, setOpen] = useState(false);
  const [previousType, setPreviousType] = useState("");
  const [openCalenderFrom, setOpenCalenderFrom] = useState(false);
  const [openCalenderTo, setOpenCalenderTo] = useState(false);

  const handleGetReportsCustom = () => {
    setLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append(
      "from",
      PagesIndex.moment(new Date(formRef?.current?.values?.startDate)).format(
        "YYYY-MM-DD"
      )
    );
    urlEncoded.append(
      "to",
      PagesIndex.moment(new Date(formRef?.current?.values?.endDate)).format(
        "YYYY-MM-DD"
      )
    );
    urlEncoded.append("type", type);
    PagesIndex.apiPostHandler(PagesIndex.Api.AD_REPORT, urlEncoded).then(
      (res) => {
        setLoading(false);
        if (res.status === 200) {
          if (formRef?.current?.isSubmitting) {
            handleClose();
            setType(
              `${PagesIndex.moment(
                new Date(formRef?.current?.values?.startDate)
              ).format("DD/MM/YYYY")} to ${PagesIndex.moment(
                new Date(formRef?.current?.values?.endDate)
              ).format("DD/MM/YYYY")}`
            );
          }
          setDataHeaders(res.data?.headers);
          setData(res.data?.response);
          setFilteredData(res.data?.response);
        } else {
          setData([]);
          setFilteredData([]);
        }
      }
    );
  };
  const handleGetReports = () => {
    setLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("from", "");
    urlEncoded.append("to", "");
    urlEncoded.append("type", type);
    PagesIndex.apiPostHandler(PagesIndex.Api.AD_REPORT, urlEncoded).then(
      (res) => {
        setLoading(false);
        if (res.status === 200) {
          setDataHeaders(res.data?.headers);
          setData(res.data?.response);
          setFilteredData(res.data?.response);
        } else {
          setData([]);
          setFilteredData([]);
        }
      }
    );
  };
  const requestSearch = (e) => {
    setSearchValue(e.target.value);
    let filteredData = data.filter((data) =>
      data?.["Advertisement No."]
        ?.toLowerCase()
        .includes(e?.target?.value?.trim()?.toLowerCase())
    );
    setCurrentPage(1);
    setFilteredData(filteredData);
  };
  const handleChangedropdown = (event) => {
    setType(event.target.value);
    setPreviousType(type);
  };
  const timeConverter = (seconds) => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let remainingSeconds = Math.floor(seconds % 60);

    let result = "";
    if (hours > 0) {
      result += hours + " h" + " ";
    }
    if (minutes > 0 || (hours === 0 && minutes === 0)) {
      result += minutes + " m" + " ";
    }
    if ((hours === 0 && minutes === 0) || remainingSeconds >= 0) {
      result += remainingSeconds + " s" + " ";
    }

    return result;
  };
  const renderTableRows = () => {
    return filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data) => {
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            {dataHeaders?.map((headerData) => {
              return (
                <Index.TableCell
                  component="td"
                  variant="td"
                  className="table-td"
                  align={
                    headerData === "Advertisement No." ? "left" : "center"
                  }
                  key={headerData}
                >
                  <Index.Typography className="admin-table-data-text">
                    {headerData === "Avg. Min. Time"
                      ? timeConverter(data?.[headerData])
                      : data?.[headerData] || 0}
                  </Index.Typography>
                </Index.TableCell>
              );
            })}
          </Index.TableRow>
        );
      });
  };

  const dataNotFound = () => {
    return <PagesIndex.DataNotFound />;
  };
  const renderContent = () => {
    if (filteredData?.length) {
      return renderTableRows();
    } else {
      return dataNotFound();
    }
  };
  const formRef = useRef();
  const initialValues = {
    startDate: null,
    endDate: null,
  };
  useEffect(() => {
    if (type === "custom") {
      handleOpen();
    } else if (
      type !==
      `${PagesIndex.moment(
        new Date(formRef?.current?.values?.startDate)
      ).format("DD/MM/YYYY")} to ${PagesIndex.moment(
        new Date(formRef?.current?.values?.endDate)
      ).format("DD/MM/YYYY")}` &&
      type !== "custom"
    ) {
      formRef.current.resetForm();
      handleGetReports();
    }
  }, [type]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (value) => {
    if (!formRef?.current?.isSubmitting) {
      setType(previousType);
    }
    setOpen(false);
  };
  const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const generateCSV = async () => {
    const headers1 = [
      "",
      "",
      "",
      "Devices",
      "",
      "Age Group",
      "",
      "",
      "",
      "",
      "Location",
    ];
    let modifiedData = filteredData.map((data, index) => {
      let objData = [];
      dataHeaders?.map((headerData) => {
        objData.push([
          headerData === "Avg. Min. Time"
            ? timeConverter(data[headerData])
            : data[headerData] || 0,
        ]);
      });
      return objData.join(",");
    });
    exportData(
      [[headers1, dataHeaders, ...modifiedData].join("\n")],
      `${PagesIndex.moment().format(
        "DD-MM-YYYY hh:mm:ss A"
      )}_Advertisement_Report.csv`,
      "text/csv"
    );
  };

  return (
    <PagesIndex.Formik
      enableReinitialize
      onSubmit={handleGetReportsCustom}
      initialValues={initialValues}
      validationSchema={PagesIndex.dashbordFilterDate}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Index.Box className="admin-dashboard-content admin-user-list-content">
          <Index.Box className="admin-user-list-flex admin-page-title-main">
            <Index.Typography
              className="admin-page-title admin-user-list-page-title"
              component="h2"
              variant="h2"
            >
              Advertisement Reports & Analytics List
            </Index.Typography>
            <Index.Box className="admin-userlist-btn-flex">
              <Index.Box className="admin-search-main">
                <Index.Box className="admin-search-box">
                  <Index.Box className="admin-form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="admin-form-control"
                      placeholder="Search"
                      value={searchValue}
                      onChange={requestSearch}
                    />
                    <img
                      src={PagesIndex.Svg.search}
                      className="admin-search-grey-img admin-icon"
                      alt="search"
                    ></img>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="admin-form-group">
                <Index.Box className="admin-dropdown-box">
                  <Index.FormControl className="admin-form-control">
                    <Index.Select
                      className="admin-dropdown-select "
                      value={type}
                      onChange={handleChangedropdown}
                      displayEmpty
                      defaultValue="today"
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <Index.MenuItem value="today" className="admin-menuitem">
                        Today
                      </Index.MenuItem>
                      <Index.MenuItem
                        value="sevenday"
                        className="admin-menuitem"
                      >
                        Last 7 Days
                      </Index.MenuItem>
                      <Index.MenuItem value="month" className="admin-menuitem">
                        Last Month
                      </Index.MenuItem>
                      <Index.MenuItem value="custom" className="admin-menuitem">
                        Custom
                      </Index.MenuItem>
                      {values?.startDate && values?.endDate && (
                        <Index.MenuItem
                          value={`${PagesIndex.moment(
                            new Date(values?.startDate)
                          ).format("DD/MM/YYYY")} to ${PagesIndex.moment(
                            new Date(values?.endDate)
                          ).format("DD/MM/YYYY")}`}
                          className="admin-menuitem"
                          disabled
                        >
                          {PagesIndex.moment(
                            new Date(values?.startDate)
                          ).format("DD/MM/YYYY")}{" "}
                          to{" "}
                          {PagesIndex.moment(new Date(values?.endDate)).format(
                            "DD/MM/YYYY"
                          )}
                        </Index.MenuItem>
                      )}
                    </Index.Select>
                  </Index.FormControl>
                </Index.Box>
              </Index.Box>
              {filteredData?.length > 0 && <Index.Box className="admin-adduser-btn-main btn-main-secondary">
                <Index.Button
                  className="admin-adduser-btn btn-secondary"
                  onClick={generateCSV}
                >
                  <img
                    src={PagesIndex.Svg.exporticon}
                    className="admin-plus-icon"
                    alt="plus"
                  />
                  <span>Export CSV</span>
                </Index.Button>
              </Index.Box>}
            </Index.Box>
          </Index.Box>

          <Index.Box className="common-card">
            <Index.Box
              className={`admin-report-analtics-table-main page-table-main`}
            >
              <Index.TableContainer
                component={Index.Paper}
                className="table-container"
              >
                {loading ? (
                  <PagesIndex.Loader />
                ) : (
                  <Index.Table aria-label="simple table" className="table">
                    <Index.TableHead className="table-head">
                      <Index.TableRow className="table-row">
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                          colSpan={1}
                        ></Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                          colSpan={1}
                        ></Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                          colSpan={1}
                        ></Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                          colSpan={2}
                        >
                          Device
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                          colSpan={4}
                        >
                          Age Group
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                          colSpan={1}
                        ></Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                          colSpan={12}
                        >
                          Location
                        </Index.TableCell>
                      </Index.TableRow>
                      <Index.TableRow className="table-row">
                        {dataHeaders?.map((data) => {
                          return (
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="fit-content"
                              align={
                                data === "Advertisement No."
                                  ? "left"
                                  : "center"
                              }
                              key={data}
                            >
                              {data}
                            </Index.TableCell>
                          );
                        })}
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="table-body">
                      {renderContent()}
                    </Index.TableBody>
                  </Index.Table>
                )}
              </Index.TableContainer>
            </Index.Box>
            <Index.Box className="admin-pagination-main">
              {filteredData?.length ? (
                <Index.Pagination
                  page={currentPage}
                  count={Math.ceil(filteredData?.length / 10)}
                  onChange={(event, newPage) => setCurrentPage(newPage)}
                  variant="outlined"
                  shape="rounded"
                  className="admin-pagination"
                />
              ) : (
                ""
              )}
            </Index.Box>
          </Index.Box>
          <Index.Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <form onSubmit={handleSubmit} noValidate="novalidate">
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Select Date
                  </Index.Typography>
                  <Index.Button
                    className="modal-close-btn"
                    onClick={handleClose}
                  >
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Button>
                </Index.Box>
                <Index.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  className={"admin-modal-hgt-scroll cus-scrollbar"}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-input-box admin-add-movie-input-box admin-date-input-main ">
                      <Index.FormHelperText className="admin-form-lable">
                        <span>From Date</span>
                        <span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <PagesIndex.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <PagesIndex.DatePicker
                            open={openCalenderFrom}
                            onClose={() => setOpenCalenderFrom(false)}
                            className="admin-form-control admin-date-picker-control"
                            value={values.startDate} // Use '01/01/2000' as the default value
                            disableFuture
                            onChange={(date) => {
                              setFieldValue("startDate", date);
                              setFieldValue("endDate", null);
                            }}
                            slotProps={{
                              popper: {
                                className: "date-picker-body",
                              },
                              textField: {
                                readOnly: true,
                                onClick: () => setOpenCalenderFrom(true),
                              },
                            }}
                            format="DD/MM/YYYY"
                          />
                        </PagesIndex.LocalizationProvider>
                        <Index.FormHelperText className="admin-error-text">
                          {errors.startDate &&
                            touched.startDate &&
                            errors.startDate}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-input-box admin-add-movie-input-box admin-date-input-main ">
                      <Index.FormHelperText className="admin-form-lable">
                        <span>To Date</span>
                        <span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <PagesIndex.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <PagesIndex.DatePicker
                            open={openCalenderTo}
                            onClose={() => setOpenCalenderTo(false)}
                            className="admin-form-control admin-date-picker-control"
                            value={values.endDate} // Use '01/01/2000' as the default value
                            disableFuture
                            minDate={
                              values.startDate
                                ? PagesIndex.dayjs(values.startDate).add(
                                  0,
                                  "day"
                                )
                                : PagesIndex.dayjs().add(0, "day")
                            }
                            onChange={(date) => {
                              setFieldValue("endDate", date);
                            }}
                            slotProps={{
                              popper: {
                                className: "date-picker-body",
                              },
                              textField: {
                                readOnly: true,
                                onClick: () => setOpenCalenderTo(true),
                              },
                            }}
                            format="DD/MM/YYYY"
                          />
                        </PagesIndex.LocalizationProvider>
                        <Index.FormHelperText className="admin-error-text">
                          {errors.endDate && touched.endDate && errors.endDate}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="admin-save-btn-main btn-main-primary">
                      <Index.Button
                        className="admin-save-user-btn btn-primary"
                        type="submit"
                      >
                        <img
                          src={PagesIndex.Svg.save}
                          className="admin-user-save-icon"
                          alt="Save"
                        />
                        <span>Submit</span>
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          </Index.Modal>
        </Index.Box>
      )}
    </PagesIndex.Formik>
  );
}
