import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./tagMasterResponsive.css";

// for modal design

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function TagManagement() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const formRef = useRef();
  const initialValues = {
    tag: "",
  };
  const [id, setId] = useState("");
  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
  // add user modal
  const [open, setOpen] = useState(false);
  const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);
  // delete modal
  const [openDelete, setOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [activityLog, setActivityLog] = useState([]);
  const [activityLoading, setActivityLoading] = useState(true);
  const [filterLog, setFilterLog] = useState([]);

  const [openLogModal, setOpenLogModal] = useState(false);
  const handleCloseLogModal = () => setOpenLogModal(false);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [openStatusModal, setOpenStatusModal] = useState(false);

  const handleOpenLogModal = (id) => {
    setOpenLogModal(true);
    setActivityLoading(true);
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_TAG_ACTIVITY_LOG + "/" + id
    ).then((res) => {
      setActivityLoading(false);
      if (res.status === 200) {
        let data = res?.data?.activityLog?.filter(
          (data) =>
            data?.previousTagName !== data?.updatedTagName ||
            data?.previousStatus !== data?.currentStatus
        );
        setFilterLog(data);
        setActivityLog(res.data?.activityLog);
      }
    });
  };

  const handleOpen = (op) => {
    setOpen(true);
    setAddOrEdit(op);
  };
  const handleClose = () => {
    formRef.current.resetForm();
    setOpen(false);
  };
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setId(id);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };

  const handleGetTag = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_TAG).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        let data = res?.data?.sort((a, b) =>
          a?.tag?.localeCompare(b?.tag));
        setData(data);
        setFilteredData(data);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    handleGetTag();
  }, []);

  const handleAddEditTag = (values) => {
    setButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    if (id) {
      urlEncoded.append("id", id);
    }
    urlEncoded.append("tag", values?.tag);
    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_EDIT_TAG, urlEncoded)
      .then((res) => {
        setButtonSpinner(false)
        if (res.status === 201 || res.status === 200) {
          handleClose();
          handleGetTag();
          setSearchValue("");
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };
  const handleDeleteTag = () => {
    setDeleteButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(PagesIndex.Api.DELETE_TAG, urlEncoded).then(
      (res) => {
        setDeleteButtonSpinner(false)
        if (res.status === 200) {
          handleCloseDelete();
          handleGetTag();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const requestSearch = (e) => {
    setSearchValue(e.target.value);
    let filteredData = data.filter((data) =>
      data?.tag?.toLowerCase().includes(e?.target?.value?.trim()?.toLowerCase())
    );
    setCurrentPage(1);
    setFilteredData(filteredData);
  };
  const handleOpenStatusModal = (id) => {
    setId(id);
    setOpenStatusModal(true);
  };
  const handleCloseStatusModal = () => {
    setId("");
    setOpenStatusModal(false);
  };
  const handleStatus = () => {
    setStatusButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(PagesIndex.Api.ISACTIVE_TAG, urlEncoded).then(
      (res) => {
        setStatusButtonSpinner(false)
        if (res.status === 200) {
          handleCloseStatusModal();
          PagesIndex.toasterSuccess(res?.message);
          handleGetTag();
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };

  const renderTableRows = (setFieldValue) => {
    return filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data) => {
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.tag}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              className="table-td"
              align="right"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.createdBy?.name || "-"}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              className="table-td"
              align="right"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.updatedBy?.name || "-"}
              </Index.Typography>
            </Index.TableCell>

            {(adminDetails?.roleId?.permissions?.includes("tag_edit") ||
              adminDetails?.roleId?.permissions?.includes("tag_delete")) && (
                <Index.TableCell
                  component="td"
                  variant="td"
                  className="table-td"
                  align="right"
                >
                  <Index.Tooltip
                    title={data?.isActive ? "Active" : "Deactive"}
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                  >
                    <Index.Box className="admin-switch-main">
                      <Index.FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} />}
                        className="admin-switch-lable"
                        checked={data?.isActive}
                        onChange={() => {
                          handleOpenStatusModal(data?._id);
                        }}
                      />
                    </Index.Box>
                  </Index.Tooltip>
                </Index.TableCell>
              )}
            {(adminDetails?.roleId?.permissions?.includes("tag_log") ||
              adminDetails?.roleId?.permissions?.includes("tag_edit") ||
              adminDetails?.roleId?.permissions?.includes("tag_delete")) && (
                <Index.TableCell
                  component="td"
                  variant="td"
                  align="right"
                  className="table-td"
                >
                  <Index.Box className="admin-table-data-btn-flex ">
                    {adminDetails?.roleId?.permissions?.includes("tag_edit") && (
                      <Index.Tooltip
                        title="Edit"
                        arrow
                        placement="bottom"
                        className="admin-tooltip"
                      >
                        <Index.Button
                          className="admin-table-data-btn"
                          onClick={() => {
                            setId(data?._id);
                            setFieldValue("tag", data.tag);
                            handleOpen("Edit");
                          }}
                        >
                          <img
                            src={PagesIndex.Svg.blueedit}
                            className="admin-icon"
                            alt="Edit"
                          ></img>
                        </Index.Button>
                      </Index.Tooltip>
                    )}
                    {adminDetails?.roleId?.permissions?.includes("tag_log") && (
                      <Index.Tooltip
                        title="Log"
                        arrow
                        placement="bottom"
                        className="admin-tooltip"
                      >
                        <Index.Button
                          className="admin-table-data-btn"
                          onClick={() => handleOpenLogModal(data?._id)}
                        >
                          <img
                            src={PagesIndex.Svg.logicon}
                            className="admin-icon"
                            alt="Log"
                          ></img>
                        </Index.Button>
                      </Index.Tooltip>
                    )}

                    {adminDetails?.roleId?.permissions?.includes(
                      "tag_delete"
                    ) && (
                        <Index.Tooltip
                          title="Delete"
                          arrow
                          placement="bottom"
                          className="admin-tooltip"
                        >
                          <Index.Button
                            className="admin-table-data-btn"
                            onClick={() => handleOpenDelete(data?._id)}
                          >
                            <img
                              src={PagesIndex.Svg.trash}
                              className="admin-icon"
                              alt="Delete"
                            ></img>
                          </Index.Button>
                        </Index.Tooltip>
                      )}
                  </Index.Box>
                </Index.TableCell>
              )}
          </Index.TableRow>
        );
      });
  };

  const renderContent = (setFieldValue) => {
    if (filteredData?.length) {
      return renderTableRows(setFieldValue);
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };

  const renderActivityTableRows = () => {
    return activityLog
      .slice()
      .reverse()
      .map((data) => {
        let updatedInfo = "";

        if (
          data?.previousTagName !== data?.updatedTagName ||
          data?.previousStatus !== data?.currentStatus
        ) {
          if (data?.updatedBy?.name) {
            updatedInfo = `${data?.updatedBy?.name}-${data?.dateTime
              ? PagesIndex.moment(data?.dateTime).format(
                "DD/MM/YYYY HH:mm:ss"
              )
              : "-"
              }`;
          } else {
            updatedInfo = "-";
          }
        }
        return (
          <Index.TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            key={data?._id}
          >
            {updatedInfo !== "" && (
              <>
                <Index.TableCell
                  component="td"
                  variant="td"
                  scope="row"
                  className="table-td"
                >
                  <Index.Tooltip
                    title={`${data?.previousTagName !== data?.updatedTagName
                      ? `${data?.updatedBy?.name} has updated tag name from ${data?.previousTagName
                      } to ${data?.updatedTagName + " "}`
                      : ""
                      }
                    ${data?.previousStatus !== data?.currentStatus
                        ? `Tag Status has been updated from ${!data?.currentStatus ? "Active" : "Deactive"
                        } to ${data?.currentStatus ? "Active" : "Deactive"} `
                        : ""
                      }
                    `}
                    arrow
                    placement="top"
                    className="admin-tooltip"
                  >
                    <Index.Typography className="admin-table-data-text">
                      {data?.previousTagName !== data?.updatedTagName && (
                        <span>
                          <span className="span-bold">
                            {data?.updatedBy?.name}
                          </span>{" "} has updated tag name from{" "}
                          <span className="span-bold">
                            {data?.previousTagName}
                          </span>{" "}
                          to{" "}
                          <span className="span-bold">
                            {data?.updatedTagName + " "}
                          </span>
                        </span>
                      )}

                      {data?.previousStatus !== data?.currentStatus && (
                        <span>
                          Tag status has been updated{" "}
                          <span className="span-bold">
                            {!data?.currentStatus ? "Active" : "Deactive"}
                          </span>{" "}
                          to&nbsp;
                          <span className="span-bold">
                            {data?.currentStatus ? "Active" : "Deactive"}
                          </span>
                        </span>
                      )}
                    </Index.Typography>
                  </Index.Tooltip>
                </Index.TableCell>

                <Index.TableCell
                  component="td"
                  variant="td"
                  className="table-td"
                >
                  {updatedInfo}
                </Index.TableCell>
              </>
            )}
          </Index.TableRow>
        );
      });
  };

  const renderActivityLogContent = () => {
    return (
      <>
        {!activityLoading &&
          activityLog?.length > 0 &&
          renderActivityTableRows()}
        {!activityLoading &&
          (!activityLog ||
            activityLog.length === 0 ||
            filterLog.length === 0) && <PagesIndex.DataNotFound />}
      </>
    );
  };
  const ScrollIntoView = () => {
    // Scroll to the first error when errors change
    const { errors, isSubmitting } = PagesIndex.useFormikContext(); // Extracting errors from the Formik context
    useEffect(() => {
      if (errors && Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        let scrollDiv = document.getElementById("scrollDiv");
        const firstErrorElement = document.querySelector(
          `[id="${firstErrorField}"]`
        );
        if (firstErrorElement) {
          scrollDiv.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          firstErrorElement.focus();
        }
      }
    }, [isSubmitting]);
  };
  return (
    <PagesIndex.Formik
      enableReinitialize
      onSubmit={handleAddEditTag}
      initialValues={initialValues}
      validationSchema={PagesIndex.tagSchema}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <>
          <Index.Box className="admin-dashboard-content admin-user-list-content">
            <Index.Box className="admin-user-list-flex admin-page-title-main">
              <Index.Typography
                className="admin-page-title admin-user-list-page-title"
                component="h2"
                variant="h2"
              >
                Tag List
              </Index.Typography>
              <Index.Box className="admin-userlist-btn-flex">
                <Index.Box className="admin-search-main">
                  <Index.Box className="admin-search-box">
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="admin-form-control"
                        placeholder="Search"
                        value={searchValue}
                        onChange={requestSearch}
                      />
                      <img
                        src={PagesIndex.Svg.search}
                        className="admin-search-grey-img admin-icon"
                        alt="search"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                {adminDetails?.roleId?.permissions?.includes("tag_add") && (
                  <Index.Box className="admin-userlist-inner-btn-flex">
                    <Index.Box className="admin-adduser-btn-main btn-main-primary">
                      <Index.Button
                        className="admin-adduser-btn btn-primary"
                        onClick={() => {
                          setId("");
                          handleOpen("Add");
                        }}
                      >
                        <img
                          src={PagesIndex.Svg.plus}
                          className="admin-plus-icon"
                          alt="plus"
                        />
                        <span>Add Tag</span>
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                )}
              </Index.Box>
            </Index.Box>

            <Index.Box className="common-card">
              <Index.Box
                className={`admin-tag-master-table-main  ${loading ? "" : "page-table-main"
                  }`}
              >
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  {loading ? (
                    <PagesIndex.Loader />
                  ) : (
                    <Index.Table aria-label="simple table" className="table">
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="20%"
                          >
                            Tag Name
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="12%"
                            align="right"
                          >
                            Created By
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="12%"
                            align="right"
                          >
                            Updated By
                          </Index.TableCell>

                          {(adminDetails?.roleId?.permissions?.includes(
                            "tag_edit"
                          ) ||
                            adminDetails?.roleId?.permissions?.includes(
                              "tag_delete"
                            )) && (
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="6%"
                                align="right"
                              >
                                Status
                              </Index.TableCell>
                            )}

                          {(adminDetails?.roleId?.permissions?.includes(
                            "tag_log"
                          ) ||
                            adminDetails?.roleId?.permissions?.includes(
                              "tag_edit"
                            ) ||
                            adminDetails?.roleId?.permissions?.includes(
                              "tag_delete"
                            )) && (
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="5%"
                                align="right"
                              >
                                Action
                              </Index.TableCell>
                            )}
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {renderContent(setFieldValue)}
                      </Index.TableBody>
                    </Index.Table>
                  )}
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="admin-pagination-main">
                {filteredData?.length ? (
                  <Index.Pagination
                    page={currentPage}
                    count={Math.ceil(filteredData?.length / 10)}
                    onChange={(event, newPage) => setCurrentPage(newPage)}
                    variant="outlined"
                    shape="rounded"
                    className="admin-pagination"
                  />
                ) : (
                  ""
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <Index.Box
              sx={style}
              className="admin-add-user-modal-inner-main admin-modal-inner"
            >
              <Index.Box className="admin-modal-header">
                <Index.Typography
                  id="modal-modal-title"
                  className="admin-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {addOrEdit} Tag
                </Index.Typography>
                {/* <Index.Box className="modal-btn-main" onClick={handleClose}>
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="admin-modal-close-icon"
                    alt="Close"
                  />
                </Index.Box> */}
              </Index.Box>
              <Index.Box
                component={"form"}
                noValidate="novalidate"
                onSubmit={handleSubmit}
                className="admin-modal-hgt-scroll cus-scrollbar"
                id="scrollDiv"
              >
                {/* <ScrollIntoView /> */}
                <Index.Box className="admin-input-box admin-modal-input-box">
                  <Index.FormHelperText className="admin-form-lable">
                    Tag Name<span className="astrick-sing">*</span>
                  </Index.FormHelperText>
                  <Index.Box className="admin-form-group">
                    <Index.TextField
                      name="tag"
                      fullWidth
                      id="tag"
                      className="admin-form-control"
                      placeholder="Enter Tag Name"
                      onChange={handleChange}
                      value={values?.tag}
                      error={errors.tag && touched.tag}
                      inputProps={{ maxLength: 40 }}
                    />
                    <PagesIndex.FormHelperText className="admin-error-text">
                      {errors.tag && touched.tag ? errors.tag : null}
                    </PagesIndex.FormHelperText>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="admin-discard-btn-main border-btn-main">
                      <Index.Button
                        className="admin-discard-user-btn border-btn"
                        onClick={handleClose}
                        disabled={buttonSpinner}
                      >
                        Discard
                      </Index.Button>
                    </Index.Box>
                    <Index.Box className="admin-save-btn-main btn-main-primary">
                      <Index.Button
                        className={`${id ? "update-btn" : "save-btn"} admin-save-user-btn btn-primary`}
                        type="submit"
                        disabled={buttonSpinner}
                      >
                        {buttonSpinner ? <PagesIndex.Spinner /> :
                          (<>
                            <img
                              src={PagesIndex.Svg.save}
                              className="admin-user-save-icon"
                              alt="Save"
                            />
                            <span>{id ? "Update" : "Add"}</span>
                          </>)
                        }
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>

          <Index.Modal
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <Index.Box
              sx={style}
              className="admin-delete-modal-inner-main admin-modal-inner"
            >
              <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                <Index.Box className="admin-modal-circle-main">
                  <img
                    src={PagesIndex.Svg.closecircle}
                    className="admin-user-circle-img"
                    alt="Close"
                  />
                </Index.Box>
                <Index.Typography
                  className="admin-delete-modal-title"
                  component="h2"
                  variant="h2"
                >
                  Are you sure?
                </Index.Typography>
                <Index.Typography
                  className="admin-delete-modal-para admin-common-para"
                  component="p"
                  variant="p"
                >
                  Do you really want to delete this tag? This process cannot be
                  undone.
                </Index.Typography>
                <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
                  <Index.Button
                    className="admin-modal-cancel-btn border-btn"
                    onClick={handleCloseDelete}
                    disabled={deleteButtonSpinner}
                  >
                    Cancel
                  </Index.Button>
                  <Index.Button
                    className="delete-user admin-modal-delete-btn btn"
                    onClick={handleDeleteTag}
                    disabled={deleteButtonSpinner}
                  >
                    {deleteButtonSpinner ? <PagesIndex.Spinner /> : <span>Delete</span>
                    }
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>

          <Index.Modal
            open={openLogModal}
            onClose={handleCloseLogModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <Index.Box
              sx={style}
              className="admin-log-modal-inner-main admin-modal-inner"
            >
              <Index.Box className="admin-modal-header">
                <Index.Typography
                  id="modal-modal-title"
                  className="admin-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {" "}
                  Activity Log
                </Index.Typography>
                <Index.Button
                  className="modal-close-btn"
                  onClick={handleCloseLogModal}
                >
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="admin-modal-close-icon"
                    alt="Close"
                  />
                </Index.Button>
              </Index.Box>
              <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                <Index.Box className="common-card">
                  <Index.Box className="page-table-main tag-activity-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      {activityLoading ? (
                        <PagesIndex.Loader />
                      ) : (
                        <Index.Table aria-label="simple table" className="table">
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="70%"
                              >
                                Activity Log
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="30%"
                              >
                                Updated Date
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {renderActivityLogContent()}
                          </Index.TableBody>
                        </Index.Table>
                      )}
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>
          <PagesIndex.ChangeStatusModal
            data={"tag"}
            statusButtonSpinner={statusButtonSpinner}
            openStatusModal={openStatusModal}
            handleCloseStatusModal={handleCloseStatusModal}
            handleChangeStatus={handleStatus}
          />
        </>
      )}
    </PagesIndex.Formik>
  );
}
