import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./advertising.css";

// for modal design
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}
export default function Advertising() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const navigate = PagesIndex.useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [advertiserData, setAdvertiserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [celebrityList, setCelebrityList] = useState([]);
  const [passionList, setPassionList] = useState([]);
  const [socialCauseList, setSocialCauseList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [id, setId] = useState("");
  const [valueTabs, setValueTabs] = useState(0);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);

  const handleChangeTabs = (event, newValue) => {
    setCurrentPage(1);
    setValueTabs(newValue);
  };
  const handleGetAdvertising = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ADVERTISING).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setData(res.data);
        setFilteredData(res.data);
      }
    });
  };
  const handleGetArchived = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.ARCHIVED_ADVERTISING_LIST).then(
      (res) => {
        setLoading(false);
        if (res.status === 200) {
          setData(res.data);
          setFilteredData(res.data);
        }
      }
    );
  };

  useEffect(() => {
    setLoading(true);
    handleGetAd();
  }, []);
  useEffect(() => {
    if (valueTabs === 0) {
      handleGetAdvertising();
    }
    if (valueTabs === 1) {
      handleGetArchived();
    }
  }, [valueTabs]);
  const handleGetAd = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_ADVERTISER).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        const celebrityList = [];
        const passionList = [];
        const socialCauseList = [];
        setAdvertiserData(
          res?.data?.filter((item) => item?.isActive && !item?.isDraft)
        );
        res.data?.map((data) =>
          data?.celebrities?.map((data) => celebrityList.push(data))
        );
        res.data?.map((data) =>
          data?.passionate?.map((data) => passionList.push(data))
        );
        res.data?.map((data) =>
          data?.socialCause?.map((data) => socialCauseList.push(data))
        );
        setCelebrityList([...new Set([...celebrityList])]);
        setPassionList([...new Set([...passionList])]);
        setSocialCauseList([...new Set([...socialCauseList])]);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const requestSearch = (e) => {
    let result = data.filter((data) => {
      let fullName = `${data?.userId?.firstName} ${data?.userId?.lastName}`
        ?.toLowerCase()
        ?.includes(e?.target?.value?.trim()?.toLowerCase());
      let preferredBudget = (data?.preferrrdBudget)
        .toLowerCase()
        .includes(e?.target?.value?.trim()?.toLowerCase());
      let email = data?.email
        ?.toLowerCase()
        .includes(e.target.value?.trim()?.toLowerCase());
      let number = data?.phoneNumber
        ?.toString()
        .includes(e.target.value?.trim()?.toLowerCase());
      let business = data?.businessInformation
        ?.toLowerCase()
        ?.includes(e?.target?.value?.trim()?.toLowerCase());
      let productInformation = data?.productInformation
        ?.toLowerCase()
        ?.includes(e?.target?.value?.trim()?.toLowerCase());

      const date = new Date(data?.createdAt);
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      let filterDate = formattedDate.includes(e?.target?.value);

      return (
        fullName ||
        email ||
        number ||
        filterDate ||
        business ||
        preferredBudget ||
        productInformation
      );
    });
    setCurrentPage(1);
    setFilteredData(result);
  };
  const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
  const generateCSV = async () => {
    const headers = [
      "Sr. No.",
      "User Name",
      "Business Information",
      "Product Information",
      "Preferred Budget",
      "Date",
    ];
    let modifiedData = [];
    filteredData.forEach((data, index) => {
      let objData = [
        index + 1,
        data?.userId?.firstName || data?.userId?.lastName
          ? data?.userId?.firstName + " " + data?.userId?.lastName
          : "-",
        data?.businessInformation
          ? data?.businessInformation.replaceAll(",", "")
          : "-",
        data?.productInformation
          ? data?.productInformation.replaceAll(",", "")
          : "-",
        data?.preferrrdBudget ? data?.preferrrdBudget.replaceAll(",", "") : "-",
        PagesIndex.moment(data?.createdAt).format("DD/MM/YYYY"),
      ];
      modifiedData.push(objData.join(","));
    });

    exportData(
      [[headers, ...modifiedData].join("\n")],
      `${PagesIndex.moment().format(
        "DD-MM-YYYY hh:mm:ss A"
      )}_Advertising_list.csv`,
      "text/csv"
    );
  };
  const handleAdvertiserLeadDelete = () => {
    setDeleteButtonSpinner(true)
    PagesIndex.apiGetHandler(
      PagesIndex.Api.DELETE_ADVERTISER_LEAD + "/" + id
    ).then((res) => {
      setDeleteButtonSpinner(false)
      if (res.status === 200) {
        handleCloseDelete();
        handleGetAdvertising();
        PagesIndex.toasterSuccess(res?.message);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  // delete modal
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setId(id);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };
  const renderTableRows = () => {
    return filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data, index) => {
        const startIndex = (currentPage - 1) * 10;
        const adjustedIndex = startIndex + index + 1;
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
            key={data?._id}
          >
            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {adjustedIndex}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Tooltip
                title={data?.userId?.firstName || data?.userId?.lastName
                  ? data?.userId?.firstName + " " + data?.userId?.lastName
                  : "-"}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {" "}
                  {data?.userId?.firstName || data?.userId?.lastName
                    ? data?.userId?.firstName + " " + data?.userId?.lastName
                    : "-"}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Tooltip
                title={
                  data?.businessInformation ? data?.businessInformation : "-"
                }
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {" "}
                  {data?.businessInformation ? data?.businessInformation : "-"}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Tooltip
                title={
                  data?.productInformation ? data?.productInformation : "-"
                }
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {" "}
                  {data?.productInformation ? data?.productInformation : "-"}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.preferrrdBudget ? data?.preferrrdBudget : "-"}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.convertedBy?.name || "-"}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {PagesIndex.moment(data?.createdAt).format("DD/MM/YYYY")}
              </Index.Typography>
            </Index.TableCell>

            {valueTabs === 0 ? (
              <Index.TableCell
                component="td"
                variant="td"
                align="right"
                className="table-td"
              >
                <Index.Box className="admin-table-data-btn-flex ">
                  <Index.Tooltip
                    title={data?.convert ? "Converted" : "Convert"}
                    arrow
                    placement="top"
                    className="admin-tooltip"
                  >
                    <Index.Button
                      className="admin-table-data-btn"
                      onClick={() => {
                        !data?.convert &&
                          navigate(`/admin/add-client`, {
                            state: {
                              advertiserLeadData: data,
                            },
                          });
                      }}
                    >
                      {data?.convert ? (
                        <img
                          src={PagesIndex.Svg.checkIcon}
                          className="admin-icon"
                          alt="Converted"
                        ></img>
                      ) : (
                        <img
                          src={PagesIndex.Svg.changeIcon}
                          className="admin-icon"
                          alt="Convert"
                        ></img>
                      )}
                    </Index.Button>
                  </Index.Tooltip>
                  {adminDetails?.roleId?.permissions?.includes(
                    "advertising_delete"
                  ) && (
                      <Index.Tooltip
                        title="Delete"
                        arrow
                        placement="bottom"
                        className="admin-tooltip"
                      >
                        <Index.Button
                          className="admin-table-data-btn"
                          onClick={() => handleOpenDelete(data?._id)}
                        >
                          <img
                            src={PagesIndex.Svg.trash}
                            className="admin-icon"
                            alt="Delete"
                          ></img>
                        </Index.Button>
                      </Index.Tooltip>
                    )}
                </Index.Box>
              </Index.TableCell>
            ) : (
              ""
            )}
          </Index.TableRow>
        );
      });
  };

  const renderContent = () => {
    if (filteredData?.length) {
      return renderTableRows();
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };

  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-page-title admin-user-list-page-title"
          component="h2"
          variant="h2"
        >
          Advertising List
        </Index.Typography>
        <Index.Box className="admin-userlist-btn-flex">
          <Index.Box className="admin-search-main">
            <Index.Box className="admin-search-box">
              <Index.Box className="admin-form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="admin-form-control"
                  placeholder="Search"
                  onChange={requestSearch}
                />
                <img
                  src={PagesIndex.Svg.search}
                  className="admin-search-grey-img admin-icon"
                  alt="search"
                ></img>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {filteredData?.length > 0 && (
            <Index.Box className="admin-adduser-btn-main btn-main-secondary">
              <Index.Button
                className="admin-adduser-btn btn-secondary"
                onClick={generateCSV}
              >
                <img
                  src={PagesIndex.Svg.exporticon}
                  className="admin-plus-icon"
                  alt="plus"
                />
                <span>Export CSV</span>
              </Index.Button>
            </Index.Box>
          )}
        </Index.Box>
      </Index.Box>

      <Index.Box className="news-tabs-main">
        <Index.Box className="admin-tabs-main-box">
          <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Index.Tabs
              value={valueTabs}
              onChange={handleChangeTabs}
              aria-label="basic tabs example"
              className="admin-tabs-main"
            >
              <Index.Tab label="Advertising List" className="admin-tab" />
              <Index.Tab label="Archived List" className="admin-tab" />
            </Index.Tabs>
          </Index.Box>
          <TabPanel value={valueTabs} index={0} className="admin-tabpanel">
            <Index.Box className="admin-tabpanel-main">
              <Index.Box className="common-card">
                <Index.Box
                  className={`admin-advertising-table-main ${loading ? "" : "page-table-main"
                    }`}
                >
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container"
                  >
                    {loading ? (
                      <PagesIndex.Loader />
                    ) : (
                      <Index.Table aria-label="simple table" className="table">
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="5%"
                            >
                              Sr. No.
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="10%"
                            >
                              User Name
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="15%"
                            >
                              Business Information
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="15%"
                            >
                              Product Information
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="10%"
                            >
                              Preferred Budget
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="10%"
                            >
                              Converted By
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="10%"
                            >
                              Date
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="3%"
                              align="center"
                            >
                              Action
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {renderContent()}
                        </Index.TableBody>
                      </Index.Table>
                    )}
                  </Index.TableContainer>
                </Index.Box>
                <Index.Box className="admin-pagination-main">
                  {filteredData?.length ? (
                    <Index.Pagination
                      page={currentPage}
                      count={Math.ceil(filteredData?.length / 10)}
                      onChange={(event, newPage) => setCurrentPage(newPage)}
                      variant="outlined"
                      shape="rounded"
                      className="admin-pagination"
                    />
                  ) : (
                    ""
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </TabPanel>
          <TabPanel value={valueTabs} index={1} className="admin-tabpanel">
            <Index.Box className="admin-tabpanel-main">
              <Index.Box className="common-card">
                <Index.Box className="admin-advertising-table-main page-table-main">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container"
                  >
                    <Index.Table aria-label="simple table" className="table">
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="5%"
                          >
                            Sr. No.
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="10%"
                          >
                            User Name
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="15%"
                          >
                            Business Information
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="10%"
                          >
                            Product Information
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="10%"
                          >
                            Preferred Budget
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="10%"
                          >
                            Converted By
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="10%"
                          >
                            Date
                          </Index.TableCell>
                          {valueTabs === 0 ? (
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="3%"
                              align="center"
                            >
                              Action
                            </Index.TableCell>
                          ) : (
                            ""
                          )}
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {renderContent()}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
                <Index.Box className="admin-pagination-main">
                  {filteredData?.length ? (
                    <Index.Pagination
                      page={currentPage}
                      count={Math.ceil(filteredData?.length / 10)}
                      onChange={(event, newPage) => setCurrentPage(newPage)}
                      variant="outlined"
                      shape="rounded"
                      className="admin-pagination"
                    />
                  ) : (
                    ""
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </TabPanel>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box>
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              Do you really want to delete this record? This process cannot be
              undone.
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseDelete}
                disabled={deleteButtonSpinner}
              >
                Cancel
              </Index.Button>

              <Index.Button
                className="delete-user admin-modal-delete-btn btn"
                onClick={handleAdvertiserLeadDelete}
                disabled={deleteButtonSpinner}
              >
                {deleteButtonSpinner ? <PagesIndex.Spinner /> : <span>Delete</span>
                }
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </Index.Box>
  );
}
