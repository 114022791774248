import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { debounce } from "@mui/material/utils";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PropTypes from "prop-types";

const autocompleteService = { current: null };
const placeService = { current: null };

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const SelectMultipleLocation = ({
  initialValues,
  setFieldValue,
  errors,
  touched,
  values,
}) => {
  const [placeInputValue, setPlaceInputValue] = useState(""); // Initialize with an empty string
  const [placeOptions, setPlaceOptions] = useState([]);

  const loaded = React.useRef(false);

  // Google place functionality start
  //   if (typeof window !== "undefined" && !loaded.current) {
  //     if (!document.querySelector("#google-maps")) {
  //       loadScript(
  //         `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
  //         document.querySelector("head"),
  //         "google-maps"
  //       );
  //     }

  //     loaded.current = true;
  //   }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
      // Place detail instance to get lat/lng from place id, becuase Autocomplete does not provide place lat/lng
      placeService.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (placeInputValue === "") {
      setPlaceOptions(values.location ? [values.location] : []);
      return undefined;
    }

    fetch({ input: placeInputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (values.location) {
          newOptions = [values.location];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setPlaceOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [placeInputValue, fetch]);

  return (
    <Index.Box className="admin-form-group">
      <Index.Autocomplete
        fullWidth
        size="small"
        id="google-map-demo"
        className="admin-form-control admin-auto-complete-control"
        getOptionLabel={(option) =>
          option && typeof option === "object" && option?.description
            ? option?.description
            : option
        }
        filterOptions={(options, { inputValue }) => {
          const defaultLocations = new Set(
            (values?.coordinates || []).flatMap((item) => item?.location || [])
          );
          const filteredOptions = options.filter((option) => {
            if (inputValue === "") return true;
            return (
              option &&
              typeof option === "object" &&
              !defaultLocations.has(option?.description)
            );
          });
          return filteredOptions;
        }}
        options={placeOptions}
        autoComplete
        multiple
        closeText={""}
        openText={""}
        clearText={""}
        includeInputInList
        filterSelectedOptions
        defaultValue={values?.coordinates}
        noOptionsText="No locations"
        onChange={(event, newValue) => {
          const selectedCoordinates = [];
          // Check if newValue is iterable
          newValue?.length
            ? newValue.forEach((selectedOption) => {
              const request = {
                placeId: selectedOption.place_id,
                fields: ["geometry", "address_components"],
              };
              placeService.current.getDetails(request, (place, status) => {
                if (
                  status === window.google.maps.places.PlacesServiceStatus.OK
                ) {
                  const latitude = place.geometry.location.lat();
                  const longitude = place.geometry.location.lng();
                  const data = {
                    ...selectedOption,
                    location: selectedOption.description,
                    latitude: latitude.toString(),
                    longitude: longitude.toString(),
                  };
                  selectedCoordinates.push(data);
                  setFieldValue("coordinates", selectedCoordinates);
                }
              });
            })
            : setFieldValue("coordinates", selectedCoordinates);
        }}
        onInputChange={(event, newInputValue) => {
          setPlaceInputValue(newInputValue); // Set the new input value correctly
        }}
        renderInput={(params) => {
          return (
            <Index.TextField
              size="small"
              {...params}
              id="location"
              name="location"
              fullWidth
              placeholder={values?.coordinates?.length ? "" : "Enter User Location"}
              className="admin-form-control"
            />
          );
        }}
        renderOption={(props, option) => {
          if (!option || Object.keys(option).length === 0) {
            return null;
          }
          const matches =
            option.structured_formatting?.main_text_matched_substrings || [];

          const mainText = option.structured_formatting?.main_text || "";
          const parts = parse(
            mainText,
            matches.map((match) => [match.offset, match.offset + match.length])
          );
          return (
            <li {...props}>
              <Index.Grid container alignItems="center">
                <Index.Grid item sx={{ display: "flex", width: 44 }}>
                  <LocationOnIcon sx={{ color: "text.secondary" }} />
                </Index.Grid>
                <Index.Grid
                  item
                  sx={{
                    width: "calc(100% - 44px)",
                    wordWrap: "break-word",
                  }}
                >
                  {parts.map((part, index) => (
                    <Index.Box
                      key={Index.uuidv4()}
                      component="span"
                      sx={{
                        fontWeight: part.highlight ? "bold" : "regular",
                      }}
                    >
                      {part.text}
                    </Index.Box>
                  ))}

                  <Index.Typography variant="body2" color="text.secondary">
                    {option.structured_formatting?.secondary_text}
                  </Index.Typography>
                </Index.Grid>
              </Index.Grid>
            </li>
          );
        }}
      />

      {touched.location && errors.location && (
        <PagesIndex.FormHelperText className="admin-error-text">
          {errors.location}
        </PagesIndex.FormHelperText>
      )}
    </Index.Box>
  );
};

export default SelectMultipleLocation;

SelectMultipleLocation.propTypes = {
  initialValues: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};
