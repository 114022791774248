import React, { useState, useEffect, useRef } from "react";
import Index from "../../../Index";
import dayjs from "dayjs";
import PagesIndex from "../../../PagesIndex";
import { useNavigate } from "react-router-dom";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function PhoneDrectory() {
  // const { adminDetails } = PagesIndex.useSelector(
  //     (state) => state.AdminReducer
  // );
  const [filteredData, setFilteredData] = useState([]);
  const [toalData, setToalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [loading, setLoading] = useState(true);

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); // Reset to the first page whenever items per page change
  };

  const handleGetUserQuery = () => {
    // itemsPerPage, currentPage
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("pageNumber", currentPage);
    urlEncoded.append("pageSize", itemsPerPage);

    PagesIndex.apiPostHandler(PagesIndex.Api.GET_USER_CONTECT_LIST, urlEncoded).then(
      (res) => {
        setLoading(false);
        if (res.status === 200) {
          setFilteredData(res?.data?.response);
          setToalData(res?.data?.length)
        }
      }
    );
  };

  useEffect(() => {
    setLoading(true);
    handleGetUserQuery();
  }, [itemsPerPage, currentPage]);

  const renderTableRows = () => {
    return filteredData
      ?.map((data, index) => {
        const startIndex = (currentPage - 1) * 10;
        const adjustedIndex = startIndex + index + 1;
        return (
          <Index.TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            key={data?._id}
          >
            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {adjustedIndex}
              </Index.Typography>
            </Index.TableCell>

            {/* <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >

              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.createdAt ?
                  PagesIndex.moment(new Date(data?.createdAt)).format(
                    "DD/MM/YYYY") : "-"}
              </Index.Typography>
            </Index.TableCell> */}

            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Tooltip
                title={data?.userId?.email}
                arrow
                placement="top"
                className="admin-tooltip"
              >
                <Index.Box className="admin-main-data-flex">
                  <Index.Box className="admin-table-avatar-icon-box">
                    <img
                      src={data?.userId?.image ? process.env.REACT_APP_IMAGE_ENDPOINT + data?.userId?.image : PagesIndex.Svg.manIcon}
                      className="avtar-icons"
                      alt="user"
                    />
                  </Index.Box>
                  <Index.Box className="admin-inner-data-flex">

                    <Index.Typography className="admin-table-data-text">
                      {" "}
                      {data?.userId?.email}
                    </Index.Typography>
                    <Index.Typography className="admin-table-data-text">
                      {" "}
                      {data?.userId?.mobile}
                    </Index.Typography>
                   
                  </Index.Box>
                </Index.Box>
              </Index.Tooltip>
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.contact?.name ? data?.contact?.name : "-"}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.contact?.phoneNo ? data?.contact?.phoneNo : "-"}
              </Index.Typography>
            </Index.TableCell>
          </Index.TableRow>
        );
      });
  };

  const renderContent = () => {
    if (filteredData?.length) {
      return renderTableRows();
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };

  const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const generateCSV = async (values) => {

    const headers = [
      "Sr. No.",
      // "date",
      "Email Id",
      "User Name",
      "Mobile Number",
    ];
    let modifiedData = filteredData?.map((data, index) => {
      const startIndex = (currentPage - 1) * 10;
      const adjustedIndex = startIndex + index + 1;
      let objData = [
        adjustedIndex,
        // data?.createdAt
        //   ? PagesIndex.moment(new Date(data?.createdAt)).format("YYYY-MM-DD")
        //   : "-",
        data?.userId?.email ? data?.userId?.email : "-",
        data?.contact?.name ? data?.contact?.name : "-",
        data?.contact?.phoneNo ? data?.contact?.phoneNo : "-",
      ];
      return objData.join(",");
    });

    exportData(
      [[headers, ...modifiedData].join("\n")],
      `${PagesIndex.moment().format("DD-MM-YYYY hh:mm:ss A")}_Support_list.csv`,
      "text/csv"
    );
  };

  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-page-title admin-user-list-page-title"
          component="h2"
          variant="h2"
        >
          Phone Directory
        </Index.Typography>
        <Index.Box className="admin-userlist-btn-flex">
          <Index.Box className="admin-search-main">
            {/* <Index.Box className="admin-search-box">
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="admin-form-control"
                        placeholder="Search"
                        onChange={requestSearch}
                      />
                      <img
                        src={PagesIndex.Svg.search}
                        className="admin-search-grey-img admin-icon"
                        alt="search"
                      ></img>
                    </Index.Box>
                  </Index.Box> */}
          </Index.Box>
          {filteredData?.length ? (
            <Index.Box className="admin-userlist-btn-flex">
              <Index.Tooltip
                title="Export Report"
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Box className="admin-adduser-btn-main btn-main-primary">
                  <Index.Button
                    className="admin-adduser-btn btn-primary"
                    onClick={() => generateCSV()}
                  >
                    <img
                      src={PagesIndex.Svg.report}
                      className="admin-icon edit-icons"
                      alt="plus"
                    />
                    <span>Export Report</span>
                  </Index.Button>
                </Index.Box>
              </Index.Tooltip>
            </Index.Box>
          ) : (
            ""
          )}
        </Index.Box>
      </Index.Box>

      <Index.Box className="common-card">
        <Index.Box
          className={`admin-zone-table-main ${loading ? "" : "page-table-main"
            }`}
        >
          <Index.TableContainer
            component={Index.Paper}
            className="table-container"
          >
            {loading ? (
              <PagesIndex.Loader />
            ) : (
              <Index.Table aria-label="simple table" className="table">
                <Index.TableHead className="table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="5%"
                    >
                      Sr. No.
                    </Index.TableCell>

                    {/* <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="10%"
                          >
                            Date
                          </Index.TableCell> */}

                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="20%"
                    >
                      App User Details
                    </Index.TableCell>

                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="20%"
                    >
                      Contact Name
                    </Index.TableCell>

                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="10%"
                    >
                      Contact Number
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  {renderContent()}
                </Index.TableBody>
              </Index.Table>
            )}
          </Index.TableContainer>
        </Index.Box>
        <Index.Box className="admin-pagination-main">
          {filteredData?.length ? (
            <>
              <Index.Select
                className="admin-dropdown-select"
                labelId="items-per-page-label"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                label="Items per page"
                inputProps={{ "aria-label": "Without label" }}
              >
                <Index.MenuItem className="admin-drop-menuitem" value={5}>5</Index.MenuItem>
                <Index.MenuItem className="admin-drop-menuitem" value={10}>10</Index.MenuItem>
                <Index.MenuItem className="admin-drop-menuitem" value={15}>15</Index.MenuItem>
                <Index.MenuItem className="admin-drop-menuitem" value={20}>20</Index.MenuItem>
              </Index.Select>

              <Index.Pagination
                page={currentPage}
                count={Math.ceil(toalData / itemsPerPage)}
                onChange={(event, newPage) => setCurrentPage(newPage)}
                variant="outlined"
                shape="rounded"
                className="admin-pagination"
              />
            </>
          ) : (
            ""
          )}
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
