import React, { useCallback, useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { useLocation } from "react-router-dom";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function ViewNews() {
  const videoRef = useRef();
  const location = useLocation();
  const navigate = PagesIndex.useNavigate();
  const params = PagesIndex.useParams();
  const [loading, setLoading] = useState(false);
  const [loadingTable2, setLoadingTable2] = useState(false);
  const [newsData, setNewsData] = useState([]);
  const [type, setType] = useState("today");
  const { id } = params;
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  // ifame Modal
  const [openifameModal, setOpenifameModal] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [data, setData] = useState({});
  const [newsList, setNewsList] = useState([]);
  const [voterList, setVoterList] = useState([]);
  const [voterListLength, setVoterListLength] = useState(0);
  const [videoLink, setVideoLink] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);

  const [message, setMessage] = useState("Copy");

  const handleGetNewsData = useCallback(() => {
    setLoading(true);
    PagesIndex.apiGetHandler(PagesIndex.Api.VIEW_NEWS + "/" + id).then(
      (res) => {
        setLoading(false);
        if (res.status === 200) {
          setData(res.data);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  }, [id]);

  const handleGetNewsListData = useCallback(() => {
    setLoadingTable2(true);
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_NEWS_LIST_GRAPH_DATA + "/" + id
    ).then((res) => {
      setLoadingTable2(false);
      if (res.status === 200) {
        setNewsList(res?.data?.adsPlayUserNews);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  }, [id]);
  const handleGetVoterListData = useCallback(() => {
    setLoadingTable2(true);
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_USER_VOTE_RATING + "/" + id
    ).then((res) => {
      setLoadingTable2(false);
      if (res.status === 200) {
        setVoterList(res?.data);
        setVoterListLength(res?.data?.length);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  }, [id]);

  useEffect(() => {
    handleGetNewsData();
    handleGetNewsListData();
    handleGetVoterListData();
  }, [handleGetNewsData, handleGetNewsListData, handleGetVoterListData]);

  const handleOpenifameModal = () => {
    setVideoLink(data?.youtube_link);
    setOpenifameModal(true);
  };
  const handleCloseifameModal = () => setOpenifameModal(false);
  const handleOpenVideoModal = (link) => {
    videoRef?.current?.load();
    setVideoLink(link);
    setOpenVideoModal(true);
  };
  const handleCloseVideoModal = () => setOpenVideoModal(false);

  const renderViewersTableRows = () => {
    return data?.newsViewsList?.map((data, index) => {
      return (
        <Index.TableRow
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          key={data?._id}
        >
          <Index.TableCell scope="row" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {" "}
              {(currentPage - 1) * 10 + (index + 1)}
            </Index.Typography>
          </Index.TableCell>

          <Index.TableCell scope="row" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {" "}
              {data?.createdAt
                ? PagesIndex.moment(data?.createdAt).format("DD/MM/YYYY")
                : "-"}
            </Index.Typography>
          </Index.TableCell>

          <Index.TableCell className="table-td">
            <Index.Typography className="admin-table-data-text">
              {" "}
              {data?.source ? data?.source : "-"}
            </Index.Typography>
          </Index.TableCell>

          <Index.TableCell className="table-td">
            <Index.Typography className="admin-table-data-text">
              {" "}
              {data?.userId?.firstName && data?.userId?.lastName
                ? `${data?.userId?.firstName} ${data?.userId?.lastName}`
                : data?.userId?.mobile ?? data?.userId?.email ?? "-"}
            </Index.Typography>
          </Index.TableCell>
        </Index.TableRow>
      );
    });
  };

  const renderViewersContent = () => {
    if (data?.newsViewsList?.length) {
      return renderViewersTableRows();
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };

  const renderVotersContent = () => {
    if (voterList?.length) {
      return renderVotersTableRows();
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };
  const renderVotersTableRows = () => {
    return voterList?.map((data, index) => {
      return (
        <Index.TableRow
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          key={data?._id}
        >
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {index + 1}
            </Index.Typography>
          </Index.TableCell>
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {data?.userId?.firstName && data?.userId?.lastName
                ? `${data?.userId?.firstName} ${data?.userId?.lastName}`
                : data?.userId?.mobile ?? data?.userId?.email ?? "-"}
            </Index.Typography>
          </Index.TableCell>
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {data?.updatedAt
                ? PagesIndex.moment(new Date(data?.updatedAt)).format(
                    "YYYY-MM-DD hh:mm:ss A"
                  )
                : "-"}
            </Index.Typography>
          </Index.TableCell>
        </Index.TableRow>
      );
    });
  };
  const renderAdsPlayTableRows = () => {
    return newsList?.map((data, index) => {
      return (
        <Index.TableRow key={data?._id}>
          <Index.TableCell
            component="td"
            variant="td"
            scope="row"
            className="table-td"
          >
            <Index.Typography className="admin-table-data-text">
              {" "}
              {(currentPage2 - 1) * 10 + (index + 1)}
            </Index.Typography>
          </Index.TableCell>
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {" "}
              {data?.adsId?.adsNo ? data?.adsId?.adsNo : "-"}
            </Index.Typography>
          </Index.TableCell>
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {" "}
              {data?.type ? data?.type : "-"}
            </Index.Typography>
          </Index.TableCell>

          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {" "}
              {data?.userId?.firstName} {data?.userId?.lastName}
            </Index.Typography>
          </Index.TableCell>
        </Index.TableRow>
      );
    });
  };

  const renderAdsPlayContent = () => {
    if (newsList?.length) {
      return renderAdsPlayTableRows();
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };

  let reactionIcon;

  if (data?.newsRating != null) {
    if (data.newsRating > 0 && data.newsRating < 1) {
      reactionIcon = (
        <img
          src={PagesIndex.Svg.reaction1}
          className="reaction-icon"
          alt="reaction"
        />
      );
    } else if (data.newsRating >= 1 && data.newsRating < 2) {
      reactionIcon = (
        <img
          src={PagesIndex.Svg.reaction2}
          className="reaction-icon"
          alt="reaction"
        />
      );
    } else if (data.newsRating >= 2 && data.newsRating < 3) {
      reactionIcon = (
        <img
          src={PagesIndex.Svg.reaction3}
          className="reaction-icon"
          alt="reaction"
        />
      );
    } else if (data.newsRating >= 3 && data.newsRating <= 4) {
      reactionIcon = (
        <img
          src={PagesIndex.Svg.reaction4}
          className="reaction-icon"
          alt="reaction"
        />
      );
    } else if (data.newsRating >= 4 && data.newsRating <= 5) {
      reactionIcon = (
        <img
          src={PagesIndex.Svg.reaction5}
          className="reaction-icon"
          alt="reaction"
        />
      );
    } else {
      reactionIcon = "-";
    }
  } else {
    reactionIcon = "-";
  }

  const handleChangedropdown = (event) => {
    setType(event.target.value);
  };

  const handleGetHomeSectionGraph = () => {
    PagesIndex.apiPostHandler(
      PagesIndex.Api.GET_NEWS_GRAPH_DATA + "/" + id + "/" + type
    ).then((res) => {
      if (res.status === 200) {
        const chartData = Object.entries(res.data).map(([time, count]) => ({
          x: time,
          y: count,
        }));

        // Set the manipulated data to state
        setNewsData(chartData);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  useEffect(() => {
    handleGetHomeSectionGraph();
  }, [type]);

  const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const generateViewersCSV = async () => {
    const headers = ["Sr. No.", "Date", "Source", "User Name"];
    let modifiedData = data?.newsViewsList?.map((data, index) => {
      let objData = [
        index + 1,
        data?.createdAt
          ? PagesIndex.moment(data?.createdAt).format("DD/MM/YYYY")
          : "-",
        data?.source ? data?.source?.toString()?.replaceAll(",", "|") : "-",
        data?.userId?.firstName && data?.userId?.lastName
          ? `${data?.userId?.firstName} ${data?.userId?.lastName}`
          : data?.userId?.mobile ?? data?.userId?.email ?? "-",
      ];
      return objData.join(",");
    });

    exportData(
      [[headers, ...modifiedData].join("\n")],
      `${PagesIndex.moment().format(
        "DD-MM-YYYY hh:mm:ss A"
      )}_List_Of_Viewers.csv`,
      "text/csv"
    );
  };
  const generatePlayCSV = async () => {
    const headers = ["Sr. No.", "Ad. No.", "Type", "User Name"];
    let modifiedData = newsList?.map((data, index) => {
      let objData = [
        index + 1,
        data?.adsId?.adsNo
          ? data?.adsId?.adsNo?.toString()?.replaceAll(",", "|")
          : "-",
        data?.type ? data?.type?.toString()?.replaceAll(",", "|") : "-",
        data?.userId?.firstName
          ? data?.userId?.firstName?.toString()?.replaceAll(",", "|")
          : "-",
      ];
      return objData.join(",");
    });

    exportData(
      [[headers, ...modifiedData].join("\n")],
      `${PagesIndex.moment().format(
        "DD-MM-YYYY hh:mm:ss A"
      )}_List_Of_Ads_Play.csv`,
      "text/csv"
    );
  };

  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main view-page-title-main">
        <Index.Box className="admin-title-left">
          <Index.Button
            className="admin-adduser-btn btn-primary back-btn"
            onClick={() => {
              navigate(`/admin/videos`);
            }}
          >
            <img
              src={PagesIndex.Svg.backarrow}
              className="admin-icon edit-icons"
              alt="plus"
            />
          </Index.Button>
          <Index.Typography
            className="admin-view-page-title"
            component="h2"
            variant="h2"
          >
            <span className="title-text">{data?.newsNo}</span>
          </Index.Typography>
        </Index.Box>
        <Index.Box className="admin-userlist-btn-flex">
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                {data?.createdBy?.name ? `${data?.createdBy?.name}:` : ""}
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <p className="created-text-lable">Last Updated At:</p>
                <p className="created-text-value">
                  {PagesIndex.moment(data?.updatedAt).format(
                    "DD/MM/YYYY hh:mm A"
                  )}
                </p>
                {adminDetails?.roleId?.permissions?.includes("videos_edit") && (
                  <Index.Tooltip
                    title="Edit Video"
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                  >
                    <Index.Box className="admin-adduser-btn-main btn-main-primary">
                      <Index.Button
                        className="admin-adduser-btn btn-primary"
                        onClick={() => {
                          navigate(`/admin/add-video/${data?._id}`, {
                            state: {
                              data: {
                                ...data,
                                tags: data?.tags?.map((data) => data?._id),
                              },
                              page: location?.state?.page,
                            },
                          });
                        }}
                      >
                        <img
                          src={PagesIndex.Svg.editwhite}
                          className="admin-icon edit-icons"
                          alt="plus"
                        />
                        <span>Edit Video</span>
                      </Index.Button>
                    </Index.Box>
                  </Index.Tooltip>
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="view-details-card">
        <Index.Box className="admin-file-view-card common-card">
          <Index.Box className="admin-file-view-main">
            <Index.Box className="highlight-box">
              <Index.Box className="highlight-flex">
                <img
                  src={PagesIndex.Svg.calendaricon}
                  className="calendar-icon"
                  alt=""
                />
                <Index.Typography className="highlight-content-lable">
                  Publish Date:
                </Index.Typography>
                <Index.Typography className="highlight-content-data">
                  {data?.scheduledDate
                    ? PagesIndex.moment(data?.scheduledDate).format(
                        "DD-MM-YYYY"
                      )
                    : "N/A"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <img
              src={
                data?.image
                  ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${data?.image}`
                  : PagesIndex.Svg.noimgfound
              }
              className="admin-file-view-img"
              alt="upload img"
            />
          </Index.Box>
          <Index.Box className="admin-view-btn-flex">
            {data?.youtube_link && (
              <Index.Box className="btn-main-primary">
                <Index.Button
                  className="btn-primary"
                  onClick={handleOpenifameModal}
                >
                  <img
                    src={PagesIndex.Svg.playicon}
                    className="admin-icon play-icon"
                    alt="View"
                  />
                  <span>Youtube</span>
                </Index.Button>
              </Index.Box>
            )}

            {data?.s3_link && (
              <Index.Box className="btn-main orange-btn-main">
                <Index.Button
                  className="orange-btn"
                  onClick={() =>
                    handleOpenVideoModal(
                      `${process.env.REACT_APP_IMAGE_ENDPOINT}${data?.s3_link}`
                    )
                  }
                >
                  <img
                    src={PagesIndex.Svg.playicon}
                    className="admin-icon play-icon"
                    alt="View"
                  />
                  <span>AWS(S3)</span>
                </Index.Button>
              </Index.Box>
            )}
            {data?.internal_link && (
              <Index.Box className="btn-main btn-main-secondary">
                <Index.Button
                  className="btn-secondary"
                  onClick={() => handleOpenVideoModal(data?.internal_link)}
                >
                  <img
                    src={PagesIndex.Svg.playicon}
                    className="admin-icon play-icon"
                    alt="View"
                  />
                  <span>Internal Server</span>
                </Index.Button>
              </Index.Box>
            )}
          </Index.Box>
          <Index.Box className="reaction-icon-flex">
            <Index.Box className="review-box">
              <Index.Typography className="review-title">
                Views
              </Index.Typography>
              <Index.Typography className="review-data">
                {data?.newsViews || "0"}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="review-box">
              <Index.Typography className="review-title">
                Rating
              </Index.Typography>
              <Index.Typography className="review-data">
                {data?.newsRating?.toFixed(1) || "0"}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="review-box">
              <Index.Typography className="review-title">
                Votes
              </Index.Typography>
              <Index.Typography className="review-data">
                {voterListLength}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="news-view-content-main common-card">
          <Index.Box className="news-content-main">
            <Index.Typography className="news-name-text">
              {data?.newsTitle}
            </Index.Typography>
            <Index.Typography className="news-discription-text">
              {data?.description}
            </Index.Typography>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                State:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {data?.state?.stateName}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Zone Name:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {data?.zone?.name}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                District Name:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {data?.district?.name}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                City:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {data?.city}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Tags:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                {data?.tags?.length
                  ? data?.tags?.map((data) => {
                      return (
                        <Index.Typography
                          className="view-content-data"
                          key={data?._id}
                        >
                          #{data?.tag}
                        </Index.Typography>
                      );
                    })
                  : "-"}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {data?.youtube_link && (
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Youtube Link:
                </Index.Typography>
                <Index.Box className="view-content-link-flex">
                  <Index.Typography className="view-content-data">
                    {data?.youtube_link}
                  </Index.Typography>
                  <Index.Tooltip
                    title={message}
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                    onMouseLeave={() => {
                      setTimeout(() => {
                        setMessage("Copy");
                      }, 1000);
                    }}
                  >
                    <Index.Button
                      className="copy-btn-main"
                      onClick={() => {
                        setMessage("Copied ✓");
                        navigator.clipboard.writeText(data?.youtube_link);
                      }}
                    >
                      <img
                        src={PagesIndex.Svg.copyicon}
                        className="copy-icon"
                        alt="copy"
                      />
                    </Index.Button>
                  </Index.Tooltip>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          )}

          {data?.wowza_link && (
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  AWS Link:
                </Index.Typography>
                <Index.Box className="view-content-link-flex">
                  <Index.Typography className="view-content-data">
                    {data?.wowza_link}
                  </Index.Typography>
                  <Index.Tooltip
                    title={message}
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                    onMouseLeave={() => {
                      setTimeout(() => {
                        setMessage("Copy");
                      }, 1000);
                    }}
                  >
                    <Index.Button
                      className="copy-btn-main"
                      onClick={() => {
                        setMessage("Copied ✓");
                        navigator.clipboard.writeText(data?.wowza_link);
                      }}
                    >
                      <img
                        src={PagesIndex.Svg.copyicon}
                        className="copy-icon"
                        alt="copy"
                      />
                    </Index.Button>
                  </Index.Tooltip>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          )}
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Updated User Name:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {data?.updatedBy?.name}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Section To Show:
              </Index.Typography>
              <Index.Box className="view-category-flex-box">
                {data?.sectionToShow?.length
                  ? data?.sectionToShow?.map((data) => {
                      return (
                        <Index.Box
                          className="view-category-box"
                          key={data?._id}
                        >
                          <Index.Typography className="view-content-data">
                            {data?.name}
                          </Index.Typography>
                        </Index.Box>
                      );
                    })
                  : "-"}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Categories:
              </Index.Typography>
              <Index.Box className="view-category-flex-box">
                {data?.category?.length
                  ? data?.category?.map((data) => {
                      return (
                        <Index.Box
                          className="view-category-box"
                          key={data?._id}
                        >
                          <Index.Typography className="view-content-data">
                            {data?.categoryName}
                          </Index.Typography>
                        </Index.Box>
                      );
                    })
                  : "-"}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Ad Position:
              </Index.Typography>
              <Index.Box className="view-content-link-flex">
                <Index.Typography className="view-content-data">
                  {data?.adsPart?.toString()?.replaceAll(",", ", ")}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box> */}
        </Index.Box>
      </Index.Box>
      <Index.Box className="analytic-title-flex view-news-analytic-row">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            className="admin-sub-title"
            component="h2"
            variant="h2"
          >
            Analytics
          </Index.Typography>
        </Index.Box>
        <Index.Box className="dash-dropdown-box">
          <Index.Box className="admin-form-group">
            <Index.Box className="admin-dropdown-box">
              <Index.FormControl className="admin-form-control">
                <Index.Select
                  className="admin-dropdown-select "
                  value={type}
                  onChange={handleChangedropdown}
                  displayEmpty
                  defaultValue="today"
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <Index.MenuItem value="today" className="admin-menuitem">
                    Today
                  </Index.MenuItem>
                  <Index.MenuItem value="sevenday" className="admin-menuitem">
                    Last 7 Days
                  </Index.MenuItem>
                  <Index.MenuItem value="month" className="admin-menuitem">
                    Last Month
                  </Index.MenuItem>
                </Index.Select>
              </Index.FormControl>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="grid-row chart-row-main">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="dashboard-chart-main">
                <Index.Box className="chart-col common-card">
                  <Index.Box className="chart-title-main">
                    <Index.Typography
                      className="chart-title"
                      component="h2"
                      variant="h2"
                    >
                      Content News Distribution
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="chart-main-box">
                    <Index.Box className="chart-box">
                      <PagesIndex.ReactApexChart
                        series={[
                          { name: "Times Of (the) Day", data: newsData },
                        ]}
                        type="area"
                        width="100%"
                        height={250}
                        options={{
                          chart: {
                            stacked: false,
                            height: 350,
                            zoom: {
                              type: "x",
                              enabled: true,
                              autoScaleYaxis: true,
                            },
                            toolbar: {
                              show: true,
                              offsetX: 0,
                              offsetY: 0,
                              tools: {
                                download: true,
                                selection: false,
                                zoom: false,
                                zoomin: false,
                                zoomout: false,
                                pan: false,
                                reset:
                                  false |
                                  '<img src="/static/icons/reset.png" width="20">',
                                customIcons: [],
                              },
                              export: {
                                csv: {
                                  filename: "Content News Distribution",
                                  columnDelimiter: ",",
                                  headerCategory: "Times Of (the) Day",
                                  headerValue: "View News",
                                  dateFormatter(timestamp) {
                                    return new Date(timestamp).toDateString();
                                  },
                                },
                                svg: {
                                  filename: "Content News Distribution",
                                },
                                png: {
                                  filename: "Content News Distribution",
                                },
                              },
                              autoSelected: "zoom",
                            },
                            xaxis: {
                              categories: newsData.map((data) => data.x),
                            },
                            markers: {
                              size: 0,
                              strokeWidth: 0,
                              fillOpacity: 1,
                              colors: ["#008FFB"],
                            },
                            stroke: {
                              width: 2,
                              curve: "straight",
                            },
                            fill: {
                              type: "gradient",
                              gradient: {
                                shadeIntensity: 1,
                                opacityFrom: 0.7,
                                opacityTo: 0.9,
                                stops: [0, 100],
                              },
                            },
                            discrete: [
                              {
                                seriesIndex: 0,
                                dataPointIndex: newsData.reduce(
                                  (acc, curr, index, array) => {
                                    if (
                                      index > 0 &&
                                      array[index - 1].y !== curr.y
                                    ) {
                                      acc.push(index);
                                    }
                                    return acc;
                                  },
                                  []
                                ),
                              },
                            ],
                          },
                          noData: {
                            text: "No data available",
                            align: "center",
                            verticalAlign: "middle",
                            offsetX: 0,
                            offsetY: 0,
                            style: {
                              color: undefined,
                              fontSize: "14px",
                              fontFamily: undefined,
                            },
                          },
                        }}
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="grid-row">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="adverstiser-col">
                <Index.Box className="admin-title-flex-main">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h2"
                      variant="h2"
                    >
                      List Of Viewers
                    </Index.Typography>
                  </Index.Box>
                  {data?.newsViewsList?.length > 0 && (
                    <Index.Box className="admin-adduser-btn-main btn-main-secondary">
                      <Index.Button
                        className="admin-adduser-btn btn-secondary"
                        onClick={generateViewersCSV}
                      >
                        <img
                          src={PagesIndex.Svg.exporticon}
                          className="admin-plus-icon"
                          alt="plus"
                        />
                        <span>Export CSV</span>
                      </Index.Button>
                    </Index.Box>
                  )}
                </Index.Box>

                <Index.Box className="common-card">
                  <Index.Box className="admin-view-web-series-table-main page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                      sx={{ maxHeight: 273, minHeight: 273 }}
                    >
                      {loading ? (
                        <PagesIndex.Loader />
                      ) : (
                        <Index.Table stickyHeader aria-label="sticky table">
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell className="table-th" width="4%">
                                Sr. No.
                              </Index.TableCell>

                              <Index.TableCell className="table-th" width="4%">
                                Date
                              </Index.TableCell>

                              <Index.TableCell className="table-th" width="4%">
                                Source
                              </Index.TableCell>

                              <Index.TableCell className="table-th" width="7%">
                                User Name
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {renderViewersContent()}
                          </Index.TableBody>
                        </Index.Table>
                      )}
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="adverstiser-col">
                <Index.Box className="admin-title-flex-main">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h2"
                      variant="h2"
                    >
                      List Of Ads Play
                    </Index.Typography>
                  </Index.Box>
                  {newsList?.length > 0 && (
                    <Index.Box className="admin-adduser-btn-main btn-main-secondary">
                      <Index.Button
                        className="admin-adduser-btn btn-secondary"
                        onClick={generatePlayCSV}
                      >
                        <img
                          src={PagesIndex.Svg.exporticon}
                          className="admin-plus-icon"
                          alt="plus"
                        />
                        <span>Export CSV</span>
                      </Index.Button>
                    </Index.Box>
                  )}
                </Index.Box>

                <Index.Box className="common-card">
                  <Index.Box className="admin-view-web-series-table-main page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                      sx={{ maxHeight: 273, minHeight: 273 }}
                    >
                      {loading ? (
                        <PagesIndex.Loader />
                      ) : (
                        <Index.Table stickyHeader aria-label="sticky table">
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell className="table-th" width="4%">
                                Sr. No.
                              </Index.TableCell>
                              <Index.TableCell className="table-th" width="4%">
                                Ad. No.
                              </Index.TableCell>

                              <Index.TableCell className="table-th" width="7%">
                                Type
                              </Index.TableCell>

                              <Index.TableCell className="table-th" width="7%">
                                User Name
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {renderAdsPlayContent()}
                          </Index.TableBody>
                        </Index.Table>
                      )}
                    </Index.TableContainer>
                  </Index.Box>
                  {/* <Index.Box className="admin-pagination-main">
                    {newsList?.length ? (
                      <Index.Pagination
                        page={currentPage2}
                        count={Math.ceil(newsList?.length / 10)}
                        onChange={(event, newPage) => setCurrentPage2(newPage)}
                        variant="outlined"
                        shape="rounded"
                        className="admin-pagination"
                      />
                    ) : (
                      ""
                    )}
                  </Index.Box> */}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="grid-row">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="adverstiser-col">
                <Index.Box className="admin-sub-title-main inner-title-main">
                  <Index.Typography
                    className="admin-sub-title"
                    component="h2"
                    variant="h2"
                  >
                    List Of Votes
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="common-card">
                  <Index.Box className="admin-view-web-series-table-main page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                      sx={{ maxHeight: 273, minHeight: 273 }}
                    >
                      {loading ? (
                        <PagesIndex.Loader />
                      ) : (
                        <Index.Table stickyHeader aria-label="sticky table">
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="2%"
                              >
                                Sr. No.
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="6%"
                              >
                                App User Detail
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="4%"
                              >
                                Date & Time
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {renderVotersContent()}
                          </Index.TableBody>
                        </Index.Table>
                      )}
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={openifameModal}
        onClose={handleCloseifameModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-ifram-modal-inner-main admin-modal-inner"
        >
          <Index.Button
            className="iframe-modal-close-btn"
            onClick={handleCloseifameModal}
          >
            <img
              src={PagesIndex.Svg.closeroundicon}
              className="admin-modal-close-icon"
              alt="Close"
            />
          </Index.Button>
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="iframe-main">
              <iframe
                className="iframe-main-tag"
                src={`https://www.youtube.com/embed/${
                  videoLink?.includes("shorts")
                    ? videoLink?.split("/")[4]
                    : videoLink?.includes("watch?")
                    ? videoLink?.split("=")[1]
                    : videoLink?.split("/")[3]
                }?autoplay=1`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      <Index.Modal
        open={openVideoModal}
        onClose={handleCloseVideoModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-ifram-modal-inner-main admin-modal-inner"
        >
          <Index.Button
            className="iframe-modal-close-btn"
            onClick={handleCloseVideoModal}
          >
            <img
              src={PagesIndex.Svg.closeroundicon}
              className="admin-modal-close-icon"
              alt="Close"
            />
          </Index.Button>
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="iframe-main">
              <video
                ref={videoRef}
                className="iframe-main-tag"
                controls
                autoPlay="autoplay"
                muted
              >
                <source src={videoLink} type="video/mp4" />
              </video>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </Index.Box>
  );
}
