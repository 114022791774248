import loginbg from './images/jpg/login.jpg';
import newsimg from './images/jpg/news.jpg';








const Jpg = {
    loginbg,
    newsimg,
}

export default Jpg;