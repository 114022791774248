import logoGif from './images/gif/logo.gif';








const Gif = {
      logoGif
}

export default Gif;