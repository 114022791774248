import React from 'react'
import Index from '../../Index'
import PropTypes from 'prop-types';


export default function BorderButton({ className, onClick, btnLabel }) {
  return (
    <>
      <Index.Box className='border-btn-main'>
        <Index.Button className={className} onClick={onClick}>{btnLabel}</Index.Button>
      </Index.Box>


      {/* use this button like below demo */}
      {/* <Index.BorderButton btnLabel="View Button" className='border-btn'/> */}
    </>
  )
}
BorderButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  btnLabel: PropTypes.string.isRequired
};