import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { useNavigate } from "react-router-dom";

// for modal design
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function VotersList() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [type, setType] = useState("userWise");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [openUserVoterModal, setOpenUserVoterModal] = useState(false);
  const [userVoter, setUserVoter] = useState([]);
  const [id, setNewsId] = useState("");

  const handleCloseUserVoterModal = () => setOpenUserVoterModal(false);
  const handleOpenUserVoterModal = (id) => {
    setNewsId(id);
    setOpenUserVoterModal(true);
  };

  const handleChange = (e) => {
    setType(e.target.value);
    setCurrentPage(1);
  };

  const handleGetVotersList = () => {
    setLoading(true);
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_VOTERS_LIST).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        const newData = res?.data?.voteList?.map((ele) => {
          const findVote = res?.data?.voteCounts?.find(
            (count) => count?.newsId == ele?.newsId?._id
          );
          let voteCount = findVote ? findVote?.voteCount : 0;
          return { ...ele, voteCount };
        });
        setData(newData);
        setFilteredData(newData);
        setUserVoter(res?.data?.voterUserList);
      }
    });
  };
  useEffect(() => {
    handleGetVotersList();
  }, []);

  const requestSearch = (e) => {
    setSearchValue(e.target.value);
    let filteredData = data.filter(
      (data) =>
        data?.newsId?.newsNo
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        PagesIndex.moment(new Date(data?.updatedAt))
          .format("YYYY-MM-DD hh:mm:ss A")
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.newsId?.newsTitle
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.userId?.mobile
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.userId?.email
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        `${data?.userId?.firstName} ${data?.userId?.lastName}`
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase())
    );
    setCurrentPage(1);
    setFilteredData(filteredData);
  };

  const renderTableRows = () => {
    return filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data) => {
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                <span
                  className="news-id-text"
                  onClick={() =>
                    navigate(`/admin/view-video/${data?.newsId?._id}`)
                  }
                >
                  {data?.newsId?.newsNo}
                </span>
                <Index.Tooltip
                  title={data?.newsId?.newsTitle}
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  {data?.newsId?.newsTitle}
                </Index.Tooltip>
              </Index.Typography>
            </Index.TableCell>

            {type === "userWise" ? (
              <Index.TableCell
                component="td"
                variant="td"
                scope="row"
                className="table-td"
              >
                <Index.Tooltip
                  title={data?.userId?.email}
                  arrow
                  placement="top"
                  className="admin-tooltip"
                >
                  <Index.Box className="admin-main-data-flex">
                    <Index.Box className="admin-table-avatar-icon-box">
                      <img
                        src={
                          data?.userId?.image
                            ? process.env.REACT_APP_IMAGE_ENDPOINT +
                              data?.userId?.image
                            : PagesIndex.Svg.manIcon
                        }
                        className="avtar-icons"
                        alt="user"
                      />
                    </Index.Box>
                    <Index.Box className="admin-inner-data-flex">
                      <Index.Typography className="admin-table-data-text">
                        {" "}
                        {data?.userId?.email}
                      </Index.Typography>
                      <Index.Typography className="admin-table-data-text">
                        {" "}
                        {data?.userId?.mobile}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Tooltip>
              </Index.TableCell>
            ) : (
              <Index.TableCell
                component="td"
                variant="td"
                className="table-td table-cursor"
                onClick={() => handleOpenUserVoterModal(data?.newsId?._id)}
              >
                <Index.Typography className="admin-table-data-text">
                  {data?.voteCount ? data?.voteCount : 0}
                </Index.Typography>
              </Index.TableCell>
            )}

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.updatedAt
                  ? PagesIndex.moment(new Date(data?.updatedAt)).format(
                      "YYYY-MM-DD hh:mm:ss A"
                    )
                  : "-"}
              </Index.Typography>
            </Index.TableCell>
          </Index.TableRow>
        );
      });
  };

  const renderContent = () => {
    if (filteredData?.length) {
      return renderTableRows();
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };
  const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
  const generateCSV = async () => {
    const headers = [
      "News",
      type == "userWise" ? "App User Detail" : "Voters",
      "Date & Time",
    ];
    let modifiedData = [];
    filteredData.forEach((data, index) => {
      let objData = [
        data?.newsId?.newsNo
          ? `${data?.newsId?.newsNo} ${data?.newsId?.newsTitle}`
              .replaceAll(",", "")
              .replaceAll(".", "")
          : "-",
        type == "userWise"
          ? data?.userId?.firstName && data?.userId?.lastName
            ? `${data?.userId?.firstName} ${data?.userId?.lastName}`
            : data?.userId?.mobile ?? data?.userId?.email ?? "-"
          : data?.voteCount
          ? data?.voteCount
          : 0,
        PagesIndex.moment(data?.createdAt).format("DD/MM/YYYY hh:mm:ss A"),
      ];
      modifiedData.push(objData.join(","));
    });

    exportData(
      [[headers, ...modifiedData].join("\n")],
      `${PagesIndex.moment().format("DD-MM-YYYY hh:mm:ss A")}_Votes_list.csv`,
      "text/csv"
    );
  };

  const renderVoterTableRows = () => {
    return userVoter
      ?.filter((ele) => ele?.newsId?._id == id)
      ?.map((data) => {
        return (
          <Index.TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            key={data?._id}
          >
            <>
              <Index.TableCell component="td" variant="td" className="table-td">
                <Index.Typography className="admin-table-data-text">
                  {" "}
                  <span className="news-id-text">{data?.newsId?.newsNo}</span>
                  {data?.newsId?.newsTitle}
                </Index.Typography>
              </Index.TableCell>

              <Index.TableCell
                component="td"
                variant="td"
                scope="row"
                className="table-td"
              >
                <Index.Tooltip
                  title={data?.userId?.email}
                  arrow
                  placement="top"
                  className="admin-tooltip"
                >
                  <Index.Box className="admin-main-data-flex">
                    <Index.Box className="admin-inner-data-flex">
                      <Index.Typography className="admin-table-data-text">
                        {" "}
                        {data?.userId?.email}
                      </Index.Typography>
                      <Index.Typography className="admin-table-data-text">
                        {" "}
                        {data?.userId?.mobile}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Tooltip>
              </Index.TableCell>

              <Index.TableCell component="td" variant="td" className="table-td">
                <Index.Typography className="admin-table-data-text">
                  {data?.updatedAt
                    ? PagesIndex.moment(new Date(data?.updatedAt)).format(
                        "YYYY-MM-DD hh:mm:ss A"
                      )
                    : "-"}
                </Index.Typography>
              </Index.TableCell>
            </>
          </Index.TableRow>
        );
      });
  };

  const renderVoterLogContent = () => {
    return (
      <>
        {renderVoterTableRows()}
        {userVoter?.length === 0 && <PagesIndex.DataNotFound />}
      </>
    );
  };

  return (
    <Index.Box className="admin-dashboard-content admin-user-list-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-page-title admin-user-list-page-title"
          component="h2"
          variant="h2"
        >
          Voters List
        </Index.Typography>
        <Index.Box className="admin-userlist-btn-flex">
          <Index.Box className="admin-search-main">
            <Index.Box className="admin-search-box">
              <Index.Box className="admin-form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="admin-form-control"
                  placeholder="Search"
                  value={searchValue}
                  onChange={requestSearch}
                />
                <img
                  src={PagesIndex.Svg.search}
                  className="admin-search-grey-img admin-icon"
                  alt="search"
                ></img>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dropdown-box">
            <Index.FormControl className="admin-form-control">
              <Index.Select
                className="admin-dropdown-select "
                name={type}
                id={type}
                value={type}
                onChange={handleChange}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                renderValue={type ? undefined : () => "Select Type"}
              >
                <Index.MenuItem
                  value={"userWise"}
                  className="admin-drop-menuitem"
                >
                  User Wise
                </Index.MenuItem>

                <Index.MenuItem
                  value={"newsWise"}
                  className="admin-drop-menuitem"
                >
                  News Wise
                </Index.MenuItem>
              </Index.Select>
            </Index.FormControl>
          </Index.Box>
          {filteredData?.length > 0 && (
            <Index.Box className="admin-adduser-btn-main btn-main-secondary">
              <Index.Button
                className="admin-adduser-btn btn-secondary"
                onClick={generateCSV}
              >
                <img
                  src={PagesIndex.Svg.exporticon}
                  className="admin-plus-icon"
                  alt="plus"
                />
                <span>Export CSV</span>
              </Index.Button>
            </Index.Box>
          )}
        </Index.Box>
      </Index.Box>

      <Index.Box className="common-card">
        <Index.Box
          className={`admin-distric-table-main ${
            loading ? "" : "page-table-main"
          }`}
        >
          <Index.TableContainer
            component={Index.Paper}
            className="table-container"
          >
            {loading ? (
              <PagesIndex.Loader />
            ) : (
              <Index.Table aria-label="simple table" className="table">
                <Index.TableHead className="table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="40%"
                    >
                      News
                    </Index.TableCell>

                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="10%"
                    >
                      Voters
                    </Index.TableCell>

                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="12%"
                    >
                      Date & Time
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  {renderContent()}
                </Index.TableBody>
              </Index.Table>
            )}
          </Index.TableContainer>
        </Index.Box>
        <Index.Box className="admin-pagination-main">
          {filteredData?.length ? (
            <Index.Pagination
              page={currentPage}
              count={Math.ceil(filteredData?.length / 10)}
              onChange={(event, newPage) => setCurrentPage(newPage)}
              variant="outlined"
              shape="rounded"
              className="admin-pagination"
            />
          ) : (
            ""
          )}
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={openUserVoterModal}
        onClose={handleCloseUserVoterModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-log-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="admin-modal-title"
              variant="h6"
              component="h2"
            >
              {" "}
              User List
            </Index.Typography>
            <Index.Button
              className="modal-close-btn"
              onClick={handleCloseUserVoterModal}
            >
              <img
                src={PagesIndex.Svg.closeblack}
                className="admin-modal-close-icon"
                alt="Close"
              />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="common-card">
              <Index.Box className="page-table-main distric-activity-table-main">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  <Index.Table aria-label="simple table" className="table">
                    <Index.TableHead className="table-head">
                      <Index.TableRow className="table-row">
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="30%"
                        >
                          News No.
                        </Index.TableCell>

                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="40%"
                        >
                          User Name
                        </Index.TableCell>

                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="40%"
                        >
                          Date & Time
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="table-body">
                      {renderVoterLogContent()}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </Index.Box>
  );
}
