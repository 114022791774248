import React, { useState, useEffect } from "react";
import Index from "../Index";
import PagesIndex from "../../container/PagesIndex";
import "./webView.css";

const WebView = () => {
  const params = PagesIndex.useParams();
  const { path } = params;
  const [cmsText, setCmsText] = useState("");

  const getData = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_CMS_HELP_SUPPORT).then(
      (res) => {
        if (res.status === 200) {
          if (path === "privacy-And-policy") {
            setCmsText(res?.data?.privacyPolicy || "<p></p>");
          }
          if (path === "term-and-condition") {
            setCmsText(res?.data?.termsCondition || "<p></p>");
          }
          if (path === "help-and-support") {
            setCmsText(res?.data?.helpAndSupport || "<p></p>");
          }
        }
      }
    );
  };

  useEffect(() => {
    getData();
  }, [path]);

  return (
    <Index.Box className="admin-dashboard-content cms-page">
      <Index.Box
        className="cms-page-content-web-main privacy-policy-page"
        dangerouslySetInnerHTML={{ __html: cmsText }}
      />
    </Index.Box>
  );
};

export default WebView;
