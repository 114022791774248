import type from "./type";

const AdminLogInAction = (payload) => ({
  type: type.adminLogInSuccess,
  payload,
});

const GetAdminTokenAction = (payload) => {
  return {
    type: type.getAdminToken,
    payload,
  };
};

const AdminLogOutAction = () => ({
  type: type.adminLogOutSuccess,
});
export { AdminLogInAction, AdminLogOutAction, GetAdminTokenAction };
