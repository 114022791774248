import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import PropTypes from "prop-types";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

// for custom switch design

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const TableData = ({
  filteredData,
  currentPage,
  handleOpenAddEpisode,
  handleOpenDelete,
  handleChangeStatusEpisode,
}) => {
  const location = PagesIndex.useLocation();
  const { state } = location;
  const { seasonTitle, seriesTitle } = state;
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const navigate = PagesIndex.useNavigate();
  return filteredData?.length ? (
    filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data, index) => {
        return (
          <Index.TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            key={data?._id}
          >
            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {(currentPage - 1) * 10 + (index + 1)}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                <Index.Box className="admin-table-data-flex">
                  <img
                    src={
                      data?.image
                        ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${data?.image}`
                        : PagesIndex.Svg.noimgfound
                    }
                    className="admin-table-data-img "
                    alt="Thumbnail"
                  ></img>
                </Index.Box>
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.name}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {seasonTitle}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {seriesTitle}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={data?.isActive ? "Active" : "Deactive"}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Box className="admin-switch-main">
                  <Index.FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    className="admin-switch-lable"
                    checked={data?.isActive}
                    onChange={() => handleChangeStatusEpisode(data?._id)}
                  />
                </Index.Box>
              </Index.Tooltip>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Box className="admin-table-data-btn-flex">
                {adminDetails?.roleId?.permissions?.includes(
                  "episode_edit"
                ) && (
                  <Index.Tooltip
                    title="Edit"
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                  >
                    <Index.Button
                      className="admin-table-data-btn"
                      onClick={() => handleOpenAddEpisode("Edit", data)}
                    >
                      <img
                        src={PagesIndex.Svg.blueedit}
                        className="admin-icon"
                        alt="Edit"
                      ></img>
                    </Index.Button>
                  </Index.Tooltip>
                )}
                <Index.Tooltip
                  title="View"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-table-data-btn"
                    onClick={() => {
                      navigate(`/admin/view-episode/${data?._id}`, {
                        state: { data: data },
                      });
                    }}
                  >
                    <img
                      src={PagesIndex.Svg.yelloweye}
                      className="admin-icon"
                      alt="View"
                    ></img>
                  </Index.Button>
                </Index.Tooltip>
                {adminDetails?.roleId?.permissions?.includes(
                  "episode_delete"
                ) && (
                  <Index.Tooltip
                    title="Delete"
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                  >
                    <Index.Button
                      className="admin-table-data-btn"
                      onClick={() => handleOpenDelete(data?._id)}
                    >
                      <img
                        src={PagesIndex.Svg.trash}
                        className="admin-icon"
                        alt="Delete"
                      ></img>
                    </Index.Button>
                  </Index.Tooltip>
                )}
              </Index.Box>
            </Index.TableCell>
          </Index.TableRow>
        );
      })
  ) : (
    <PagesIndex.DataNotFound />
  );
};
export default function Episode() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const formRef = useRef();
  const location = PagesIndex.useLocation();
  const { state } = location;
  const { seasonId, seriesTitle, seasonTitle } = state;
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
  const [id, setId] = useState("");
  const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectData, setSelectData] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [searchValue, setSearchValue] = useState("");
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const initialValues = {
    name: id ? selectData?.name : "",
    link: id ? selectData?.link : "",
    description: id ? selectData?.description : "",
    image: "",
    imageUrl: id ? selectData?.image : "",
  };
  useEffect(() => {
    handleGetEpisodes();
  }, []);
  useEffect(() => {
    let result = data.filter((data) => {
      let season = data?.name
        ?.toLowerCase()
        .includes(searchValue?.trim()?.toLowerCase());

      return season;
    });

    setCurrentPage(1);
    setFilteredData(result);
  }, [searchValue]);
  // add Episode modal
  const [openAddEpisode, setOpenAddEpisode] = useState(false);
  const handleOpenAddEpisode = (op, data) => {
    setAddOrEdit(op);
    if (data) {
      setId(data?._id);
      setSelectData(data);
    }
    setOpenAddEpisode(true);
  };
  const handleCloseAddEpisode = () => {
    setId("");
    setOpenAddEpisode(false);
  };

  const handleOpenDelete = (id) => {
    setId(id);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };

  // page navigate

  const handleGetEpisodes = () => {
    PagesIndex.apiGetHandler(`${PagesIndex.Api.GET_EPISODE}/${seasonId}`).then(
      (res) => {
        setLoading(false);
        if (res?.status === 200) {
          setData(res?.data);
          setFilteredData(res?.data);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const handleAddEditEpisode = (values) => {
    setButtonSpinner(true);
    const formData = new FormData();
    if (id) {
      formData.append("episodeId", id);
    }
    formData.append("seasonId", seasonId);
    formData.append("name", values?.name);
    formData.append("link", values?.link);
    formData.append("description", values?.description);
    if (values?.image) {
      formData.append("profile", values?.image);
    }
    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_EDIT_EPISODE, formData)
      .then((res) => {
        setButtonSpinner(false);
        if (res.status === 201 || res.status === 200) {
          handleCloseAddEpisode();
          handleGetEpisodes();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };
  const handleOpenStatusModal = (id) => {
    setId(id);
    setOpenStatusModal(true);
  };
  const handleCloseStatusModal = () => {
    setId("");
    setOpenStatusModal(false);
  };
  const handleStatus = () => {
    setStatusButtonSpinner(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("seasonId", seasonId);
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(PagesIndex.Api.EPISODE_STATUS, urlEncoded).then(
      (res) => {
        setStatusButtonSpinner(false);
        if (res.status === 200) {
          handleGetEpisodes();
          handleCloseStatusModal();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const handleDeleteEpisode = () => {
    setDeleteButtonSpinner(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("seasonId", seasonId);
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(PagesIndex.Api.DELETE_EPISODE, urlEncoded).then(
      (res) => {
        setDeleteButtonSpinner(false);
        if (res.status === 200) {
          handleCloseDelete();
          handleGetEpisodes();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };

  // for img
  const renderImage = (values) => {
    const isUploadImg = values?.imageUrl || values.image;
    const isImageBlob = values.image instanceof Blob;
    const imageUrl = values?.imageUrl
      ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${values?.imageUrl}`
      : null;
    let src;

    if (isImageBlob) {
      src = URL.createObjectURL(values.image);
    } else if (isUploadImg) {
      src = imageUrl;
    } else {
      src = PagesIndex.Svg.fileupload;
    }

    return (
      <img
        className={isUploadImg ? "upload-img" : "upload-profile-img"}
        src={src}
        alt="category "
      />
    );
  };
  const ScrollIntoView = () => {
    // Scroll to the first error when errors change
    const { errors, isSubmitting } = PagesIndex.useFormikContext(); // Extracting errors from the Formik context
    useEffect(() => {
      if (errors && Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        let scrollDiv = document.getElementById("scrollDiv");
        const firstErrorElement = document.querySelector(
          `[id="${firstErrorField}"]`
        );
        if (firstErrorElement) {
          scrollDiv.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          firstErrorElement.focus();
        }
      }
    }, [isSubmitting]);
  };
  return (
    <>
      <Index.Box className="admin-dashboard-content admin-user-management-content">
        <Index.Box className="admin-user-list-flex admin-page-title-main">
          <Index.Typography
            className="admin-page-title admin-user-list-page-title"
            component="h2"
            variant="h2"
          >
            Episode List
          </Index.Typography>
          <Index.Box className="admin-userlist-btn-flex">
            <Index.Box className="admin-search-main">
              <Index.Box className="admin-search-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="admin-form-control"
                    placeholder="Search"
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <img
                    src={PagesIndex.Svg.search}
                    className="admin-search-grey-img admin-icon"
                    alt="search"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {adminDetails?.roleId?.permissions?.includes("episode_add") && (
              <Index.Box className="admin-userlist-inner-btn-flex">
                <Index.Box className="admin-adduser-btn-main btn-main-primary">
                  <Index.Button
                    className="admin-adduser-btn btn-primary"
                    onClick={() => handleOpenAddEpisode("Add")}
                  >
                    <img
                      src={PagesIndex.Svg.plus}
                      className="admin-plus-icon"
                      alt="plus"
                    />
                    <span>Add Episode</span>
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>

        <Index.Box className="common-card">
          <Index.Box className="admin-episode-table-main page-table-main">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table aria-label="simple table" className="table">
                <Index.TableHead className="table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="4%"
                    >
                      Sr. No.
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="3%"
                    >
                      Thumbnail
                    </Index.TableCell>

                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="20%"
                    >
                      Episode Name
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="7%"
                    >
                      Season Name
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="8%"
                    >
                      Series Name
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="3%"
                    >
                      Status
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="right"
                      width="5%"
                    >
                      Action
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  {loading ? (
                    <PagesIndex.Loader />
                  ) : (
                    <TableData
                      filteredData={filteredData}
                      currentPage={currentPage}
                      handleOpenAddEpisode={handleOpenAddEpisode}
                      handleOpenDelete={handleOpenDelete}
                      handleChangeStatusEpisode={handleOpenStatusModal}
                    />
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="admin-pagination-main">
            {filteredData?.length ? (
              <Index.Pagination
                page={currentPage}
                count={Math.ceil(filteredData?.length / 10)}
                onChange={(event, newPage) => setCurrentPage(newPage)}
                variant="outlined"
                shape="rounded"
                className="admin-pagination"
              />
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={openAddEpisode}
        onClose={handleCloseAddEpisode}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-add-user-modal-inner-main admin-modal-inner"
        >
          <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleAddEditEpisode}
            initialValues={initialValues}
            validationSchema={
              id ? PagesIndex.episodeEditSchema : PagesIndex.episodeSchema
            }
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} noValidate="novalidate">
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {addOrEdit} Episode
                  </Index.Typography>
                  {/* <Index.Button
                    className="modal-close-btn"
                    onClick={handleCloseAddEpisode}
                  >
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Button> */}
                </Index.Box>
                <Index.Box
                  className="admin-modal-hgt-scroll cus-scrollbar"
                  id="scrollDiv"
                >
                  {/* <ScrollIntoView /> */}
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Series Name
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="admin-form-control"
                        placeholder="Enter Series Name"
                        value={seriesTitle}
                        disabled
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Season Name
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="admin-form-control"
                        placeholder="Enter Season Name"
                        value={seasonTitle}
                        disabled
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Episode Name<span className="astrick-sing">*</span> (Max
                      character length: 50)
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        fullWidth
                        id="name"
                        className="admin-form-control"
                        placeholder="Enter Episode Name"
                        onChange={handleChange}
                        name="name"
                        value={values?.name}
                        error={errors.name && touched.name}
                        inputProps={{ maxLength: 50 }}
                      />
                      {touched.name && errors.name && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.name}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box add-user-input">
                    <Index.FormHelperText className="admin-form-lable">
                      Description<span className="astrick-sing">*</span> (Max
                      character length: 250)
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextareaAutosize
                        aria-label="minimum height"
                        style={{ resize: "vertical", overflow: "auto" }}
                        // minRows={3}
                        placeholder="Enter Description"
                        className="admin-form-control-textarea"
                        onChange={handleChange}
                        id="description"
                        name="description"
                        value={values?.description}
                        error={errors.description && touched.description}
                        maxLength={250}
                      />
                      {touched.description && errors.description && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.description}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Video Link<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        fullWidth
                        id="link"
                        className="admin-form-control"
                        placeholder="Enter Video Link"
                        onChange={handleChange}
                        name="link"
                        value={values?.link}
                        error={errors.link && touched.link}
                      />
                      {touched.link && errors.link && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.link}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Thumbnail<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="file-upload-main thumbnail-box">
                      <Index.Button
                        variant="contained"
                        component="label"
                        className="file-upload-button"
                      >
                        {renderImage(values)}
                        <input
                          hidden
                          accept="image/*"
                          multiple
                          type="file"
                          id="image"
                          name="image"
                          onChange={(e) => {
                            if (e.target.files?.length !== 0) {
                              setFieldValue("image", e.target.files[0]);
                            }
                          }}
                        />
                      </Index.Button>
                      {touched.image && errors.image && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.image}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="admin-save-btn-main btn-main-primary">
                      <Index.Button
                        className={`${
                          id ? "update-btn" : "save-btn"
                        } admin-save-user-btn btn-primary`}
                        type="submit"
                        disabled={buttonSpinner}
                      >
                        {buttonSpinner ? (
                          <PagesIndex.Spinner />
                        ) : (
                          <>
                            <img
                              src={PagesIndex.Svg.save}
                              className="admin-user-save-icon"
                              alt="Save"
                            />
                            <span>{id ? "Update" : "Add"}</span>
                          </>
                        )}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Modal>

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box>
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              Do you really want to delete this episode? This process cannot be
              undone.
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseDelete}
                disabled={deleteButtonSpinner}
              >
                Cancel
              </Index.Button>
              <Index.Button
                className="delete-user admin-modal-delete-btn btn"
                onClick={handleDeleteEpisode}
                disabled={deleteButtonSpinner}
              >
                {deleteButtonSpinner ? (
                  <PagesIndex.Spinner />
                ) : (
                  <span>Delete</span>
                )}
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      <PagesIndex.ChangeStatusModal
        data={"episode"}
        statusButtonSpinner={statusButtonSpinner}
        openStatusModal={openStatusModal}
        handleCloseStatusModal={handleCloseStatusModal}
        handleChangeStatus={handleStatus}
      />
    </>
  );
}
TableData.propTypes = {
  filteredData: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  handleOpenAddEpisode: PropTypes.func.isRequired,
  handleOpenDelete: PropTypes.func.isRequired,
  handleChangeStatusEpisode: PropTypes.func.isRequired,
};
