import type from "./type";

const initialState = {
  isAdminLogin: false,
  adminDetails: {},
  token: "",
};
const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.adminLogInSuccess:
      return {
        ...state,
        adminDetails: action.payload,
        isAdminLogin: true,
      };
    case type.getAdminToken:
      return {
        ...state,
        token: action.payload,
      };
    case type.adminLogOutSuccess:
      return initialState;
    default:
      return state;
  }
};

export default AdminReducer;
