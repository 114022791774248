import React, { useState, useEffect, useRef } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { useLocation, useParams } from "react-router-dom";

export default function AdminActivityLog() {
    const { adminDetails } = PagesIndex.useSelector(
        (state) => state.AdminReducer
    );
    const { id } = useParams();
    const location = useLocation();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);

    const handleGetUserQuery = () => {
        setLoading(true);
        PagesIndex.apiGetHandler(
            PagesIndex.Api.GET_ADMIN_ACTIVITY + "/" + adminDetails?._id
        ).then((res) => {
            setLoading(false);
            if (res.status === 200) {
                setData(res?.data);
                setFilteredData(res?.data)
            }
        });
    };
    useEffect(() => {
        handleGetUserQuery();
    }, []);

    const requestSearch = (e) => {
        let filteredData = data.filter((data) =>
            data?.actionType
                ?.toLowerCase()
                .includes(e?.target?.value?.trim()?.toLowerCase()) ||
            data?.actionBy?.name
                ?.toLowerCase()
                .includes(e?.target?.value?.trim()?.toLowerCase()) ||

            PagesIndex.moment(data?.createdAt)
                .format("DD/MM/YYYY hh:mm A")
                ?.toLowerCase()
                .includes(e?.target?.value?.trim()?.toLowerCase())
        );
        setCurrentPage(1);
        setFilteredData(filteredData);
    };

    const renderTableRows = () => {
        return filteredData
            ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
            ?.map((data, index) => {
                const startIndex = (currentPage - 1) * 10;
                const adjustedIndex = startIndex + index + 1;
                return (
                    <Index.TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        key={data?._id}
                    >
                        <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                        >
                            <Index.Typography className="admin-table-data-text">
                                {" "}
                                {adjustedIndex}
                            </Index.Typography>
                        </Index.TableCell>

                        <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                        >
                            <Index.Tooltip
                                title={data?.actionType}
                                arrow
                                placement="top"
                                className="admin-tooltip"
                            >
                                <Index.Typography className="admin-table-data-text">
                                    {" "}
                                    {data?.actionType ? data?.actionType : "-"}
                                </Index.Typography>
                            </Index.Tooltip>
                        </Index.TableCell>

                        <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                        >
                            <Index.Typography className="admin-table-data-text">
                                {" "}
                                {data?.actionBy?.name ? data?.actionBy?.name : "-"}
                            </Index.Typography>
                        </Index.TableCell>

                        <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                        >
                            <Index.Typography className="admin-table-data-text">
                                {" "}
                                {PagesIndex.moment(data?.updatedAt)?.format(
                                    "DD/MM/YYYY hh:mm A"
                                )}
                            </Index.Typography>
                        </Index.TableCell>
                    </Index.TableRow>
                );
            });
    };

    const renderContent = () => {
        if (filteredData?.length) {
            return renderTableRows();
        } else {
            return <PagesIndex.DataNotFound />;
        }
    };

    return (
        <Index.Box className="admin-dashboard-content admin-user-management-content">
            <Index.Box className="admin-user-list-flex admin-page-title-main">
                <Index.Typography
                    className="admin-page-title admin-user-list-page-title"
                    component="h2"
                    variant="h2"
                >
                    Activity Log Of <span className="title-text">{location?.state?.data}</span>
                </Index.Typography>
                <Index.Box className="admin-userlist-btn-flex">
                    <Index.Box className="admin-search-main">
                        <Index.Box className="admin-search-box">
                            <Index.Box className="admin-form-group">
                                <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="admin-form-control"
                                    placeholder="Search"
                                    onChange={requestSearch}
                                />
                                <img
                                    src={PagesIndex.Svg.search}
                                    className="admin-search-grey-img admin-icon"
                                    alt="search"
                                ></img>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>

            <Index.Box className="common-card">
                <Index.Box
                    className={`admin-zone-table-main ${loading ? "" : "page-table-main"
                        }`}
                >
                    <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                    >
                        {loading ? (
                            <PagesIndex.Loader />
                        ) : (
                            <Index.Table aria-label="simple table" className="table">
                                <Index.TableHead className="table-head">
                                    <Index.TableRow className="table-row">
                                        <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="7%"
                                        >
                                            Sr. No.
                                        </Index.TableCell>

                                        <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="12%"
                                        >
                                            Admin Activity
                                        </Index.TableCell>
                                        <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="12%"
                                        >
                                            Action By
                                        </Index.TableCell>
                                        <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="15%"
                                        >
                                            Date & Time
                                        </Index.TableCell>
                                    </Index.TableRow>
                                </Index.TableHead>
                                <Index.TableBody className="table-body">
                                    {renderContent()}
                                </Index.TableBody>
                            </Index.Table>
                        )}
                    </Index.TableContainer>
                </Index.Box>
                <Index.Box className="admin-pagination-main">
                    {filteredData?.length ? (
                        <Index.Pagination
                            page={currentPage}
                            count={Math.ceil(filteredData?.length / 10)}
                            onChange={(event, newPage) => setCurrentPage(newPage)}
                            variant="outlined"
                            shape="rounded"
                            className="admin-pagination"
                        />
                    ) : (
                        ""
                    )}
                </Index.Box>
            </Index.Box>

        </Index.Box>
    );
}
