import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { FieldArray, getIn } from "formik";
import dayjs from "dayjs";

export default function AddSlot() {
  const { id } = PagesIndex.useParams();
  const formRef = useRef();
  const params = PagesIndex.useParams();
  const { page } = params;
  const { state } = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();
  const [fromDateID, setFromDateId] = useState("");
  const [toDateID, setToDateId] = useState("");
  const [buttonSpinner, setButtonSpinner] = useState(false);

  // generate time
  function generateTimeIntervals() {
    const intervals = [];
    const startOfDay = new Date("2024-06-13T00:08:00.000+05:30"); // Start 8 minutes past midnight in IST
    const intervalDuration = 2 * 60 * 1000; // 2 minutes in milliseconds
    const gapDuration = 8 * 60 * 1000; // 8 minutes in milliseconds

    for (let i = 0; i < (24 * 60) / 10; i++) {
      // 24 hours, every 10 minutes
      const fromTime = new Date(
        startOfDay.getTime() + i * (intervalDuration + gapDuration)
      );
      const toTime = new Date(fromTime.getTime() + intervalDuration);

      intervals.push({
        fromTime: fromTime.toISOString(),
        toTime: toTime.toISOString(),
        duration: intervalDuration / 1000, // duration in seconds
      });
    }
    return intervals;
  }

  const initialValues = {
    slotType: id ? state?.data?.type : page,
    slotName: id ? state?.data?.slotName : "",
    timeDetails:
      id && state?.data?.slot?.length
        ? state?.data?.slot
        : [
            {
              fromTime: null,
              toTime: null,
              duration: 0,
            },
            {
              fromTime: null,
              toTime: null,
              duration: 0,
            },
            {
              fromTime: null,
              toTime: null,
              duration: 0,
            },
            {
              fromTime: null,
              toTime: null,
              duration: 0,
            },
            {
              fromTime: null,
              toTime: null,
              duration: 0,
            },
            {
              fromTime: null,
              toTime: null,
              duration: 0,
            },
            {
              fromTime: null,
              toTime: null,
              duration: 0,
            },
            {
              fromTime: null,
              toTime: null,
              duration: 0,
            },
            {
              fromTime: null,
              toTime: null,
              duration: 0,
            },
          ],
  };
  const handleAddEditSlot = (values) => {
    setButtonSpinner(true);
    const urlEncoded = new URLSearchParams();
    if (id) {
      urlEncoded.append("id", id);
    }
    urlEncoded.append("type", values?.slotType);
    urlEncoded.append("slotName", values?.slotName);
    // urlEncoded.append("slot", JSON.stringify(generateTimeIntervals()));
    urlEncoded.append("slot", JSON.stringify(values?.timeDetails));
    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_SLOT, urlEncoded)
      .then((res) => {
        setButtonSpinner(false);
        if (res.status === 201 || res.status === 200) {
          navigate(`/admin/slot-management/${page}`);
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };
  const ScrollIntoView = () => {
    // Scroll to the first error when errors change
    const { errors, isSubmitting } = PagesIndex.useFormikContext(); // Extracting errors from the Formik context
    useEffect(() => {
      if (errors && Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        const firstErrorElement = document.querySelector(
          `[id="${firstErrorField}"]`
        );
        if (firstErrorElement) {
          firstErrorElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          firstErrorElement.focus();
        }
      }
    }, [isSubmitting]);
  };
  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-page-title admin-user-list-page-title"
          component="h2"
          variant="h2"
        >
          {id ? "Edit" : "Add"} Template
        </Index.Typography>
      </Index.Box>
      <PagesIndex.Formik
        enableReinitialize
        onSubmit={handleAddEditSlot}
        initialValues={initialValues}
        validationSchema={PagesIndex.slotAddEditSchema}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} noValidate="novalidate">
            {/* <ScrollIntoView /> */}
            <Index.Box className="common-card advertisement-card">
              <Index.Box className="grid-row">
                <Index.Box className="grid-main">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h2"
                      variant="h2"
                    >
                      Template Details
                    </Index.Typography>
                  </Index.Box>

                  <Index.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 4",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Template Name<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.TextField
                            name="slotName"
                            fullWidth
                            id="slotName"
                            className="admin-form-control"
                            placeholder="Enter Template Name"
                            onChange={handleChange}
                            value={values?.slotName}
                            inputProps={{ maxLength: 80 }}
                            // error={errors.slotName && touched.slotName}
                          />
                          {touched.slotName && errors.slotName && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.slotName}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="advertisement-card">
                <Index.Box className="grid-row">
                  <FieldArray name="timeDetails">
                    {({ push, remove }) => (
                      <Index.Box className="">
                        <Index.Box className="admin-sub-title-main">
                          <Index.Typography
                            className="admin-sub-title"
                            component="h2"
                            variant="h2"
                          >
                            Slot Timings
                          </Index.Typography>
                        </Index.Box>

                        <Index.Box className="grid-main">
                          <Index.Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                          >
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 12",
                              }}
                              className="grid-column"
                            >
                              <Index.Box
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                columnGap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                              >
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 2",
                                    md: "span 2",
                                    lg: "span 2",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="admin-input-box admin-add-event-input-box">
                                    <Index.FormHelperText className="admin-form-lable">
                                      From
                                      <span className="astrick-sing">*</span>
                                    </Index.FormHelperText>
                                  </Index.Box>
                                </Index.Box>

                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 2",
                                    md: "span 2",
                                    lg: "span 2",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="admin-input-box admin-add-event-input-box">
                                    <Index.FormHelperText className="admin-form-lable">
                                      To
                                      <span className="astrick-sing">*</span>
                                    </Index.FormHelperText>
                                  </Index.Box>
                                </Index.Box>

                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 8",
                                    md: "span 8",
                                    lg: "span 8",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="admin-input-box admin-add-event-input-box">
                                    <Index.FormHelperText className="admin-form-lable">
                                      Duration
                                      <span className="option-condition-text">
                                        (in seconds)
                                      </span>
                                    </Index.FormHelperText>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                              >
                                {values?.timeDetails?.map((data, index) => {
                                  const FromTime = `timeDetails[${index}].fromTime`;
                                  const touchedFromTime = getIn(
                                    touched,
                                    FromTime
                                  );
                                  const errorFromTime = getIn(errors, FromTime);
                                  const fromTimeValue = data?.fromTime
                                    ? dayjs(data.fromTime)
                                    : null;
                                  //  dayjs(PagesIndex.moment(data.fromTime).utcOffset("-05:30"))

                                  const Totime = `timeDetails[${index}].toTime`;
                                  const touchedToTime = getIn(touched, Totime);
                                  const errorToTime = getIn(errors, Totime);
                                  const toTimeValue = data?.toTime
                                    ? dayjs(data.toTime)
                                    : null;
                                  const Duration = `timeDetails[${index}].duration`;
                                  const toDurationValue = data?.duration
                                    ? data?.duration
                                    : null;
                                  return (
                                    <React.Fragment key={data?._id}>
                                      <Index.Box
                                        gridColumn={{
                                          xs: "span 12",
                                          sm: "span 2",
                                          md: "span 2",
                                          lg: "span 2",
                                        }}
                                        className="grid-column"
                                      >
                                        <Index.Box className="admin-input-box admin-add-event-input-box admin-date-input-main">
                                          <Index.FormControl
                                            className="admin-form-control admin-drop-form-control"
                                            onClick={() => setFromDateId(index)}
                                          >
                                            <Index.Box className="admin-form-group">
                                              <PagesIndex.LocalizationProvider
                                                dateAdapter={Index.AdapterDayjs}
                                              >
                                                {" "}
                                                <PagesIndex.TimePicker
                                                  open={index === fromDateID}
                                                  onClose={() =>
                                                    setFromDateId("")
                                                  }
                                                  views={[
                                                    "hours",
                                                    "minutes",
                                                    "seconds",
                                                  ]}
                                                  timeSteps={{
                                                    minutes: 1,
                                                    seconds: 1,
                                                  }}
                                                  className="admin-form-control admin-date-picker-control"
                                                  value={fromTimeValue}
                                                  onChange={(date) => {
                                                    setFieldValue(
                                                      FromTime,
                                                      date
                                                    );
                                                    setFieldValue(Totime, null);
                                                    setFromDateId(null);
                                                  }}
                                                  ampm={false}
                                                  slotProps={{
                                                    popper: {
                                                      className:
                                                        "date-picker-body",
                                                    },
                                                    textField: {
                                                      // Remove readOnly attribute to allow typing
                                                      onClick: () =>
                                                        setFromDateId(""),
                                                    },
                                                    actionBar: {
                                                      actions: [], // Remove the default actions including the "OK" button
                                                    },
                                                  }}
                                                />
                                              </PagesIndex.LocalizationProvider>
                                              <Index.FormHelperText className="admin-error-text">
                                                {errorFromTime &&
                                                  touchedFromTime &&
                                                  errorFromTime}
                                              </Index.FormHelperText>
                                            </Index.Box>
                                          </Index.FormControl>
                                        </Index.Box>
                                      </Index.Box>

                                      <Index.Box
                                        gridColumn={{
                                          xs: "span 12",
                                          sm: "span 2",
                                          md: "span 2",
                                          lg: "span 2",
                                        }}
                                        className="grid-column"
                                      >
                                        <Index.Box className="admin-input-box admin-add-event-input-box admin-date-input-main">
                                          <Index.FormControl
                                            className="admin-form-control admin-drop-form-control"
                                            onClick={() => setToDateId(index)}
                                          >
                                            <Index.Box className="admin-form-group">
                                              <PagesIndex.LocalizationProvider
                                                dateAdapter={Index.AdapterDayjs}
                                              >
                                                {/* <PagesIndex.TimePicker
                                                  open={index === toDateID}
                                                  onClose={() => setToDateId("")}
                                                  views={[
                                                    "hours",
                                                    "minutes",
                                                    "seconds",
                                                  ]}
                                                  timeSteps={{
                                                    minutes: 1,
                                                    seconds: 1,
                                                  }}
                                                  className="admin-form-control admin-date-picker-control"
                                                  value={toTimeValue}
                                                  disabled={!fromTimeValue}
                                                  minTime={PagesIndex.dayjs(
                                                    fromTimeValue
                                                  ).add(10, "seconds")}
                                                  componentsProps={{
                                                    actionBar: {
                                                      actions: [], // Remove the default actions including the "OK" button
                                                    },
                                                  }}
                                                  onChange={(date) => {
                                                    setFieldValue(Totime, date);
                                                    setFieldValue(
                                                      Duration,
                                                      date.diff(
                                                        fromTimeValue,
                                                        "seconds"
                                                      )
                                                    );
                                                    setToDateId(null);
                                                  }}
                                                  ampm={false}
                                                  slotProps={{
                                                    popper: {
                                                      className:
                                                        "date-picker-body",
                                                    },
                                                    textField: {
                                                      readOnly: true,
                                                      onClick: () =>
                                                        setToDateId(""),
                                                    },
                                                    actionBar: {
                                                      actions: [],
                                                    },
                                                  }}
                                                /> */}

                                                <PagesIndex.TimePicker
                                                  open={index === toDateID}
                                                  onClose={() =>
                                                    setToDateId("")
                                                  }
                                                  views={[
                                                    "hours",
                                                    "minutes",
                                                    "seconds",
                                                  ]}
                                                  timeSteps={{
                                                    minutes: 1,
                                                    seconds: 1,
                                                  }}
                                                  className="admin-form-control admin-date-picker-control"
                                                  value={toTimeValue}
                                                  disabled={!fromTimeValue}
                                                  minTime={PagesIndex.dayjs(
                                                    fromTimeValue
                                                  ).add(10, "seconds")}
                                                  onChange={(date) => {
                                                    setFieldValue(Totime, date);
                                                    setFieldValue(
                                                      Duration,
                                                      date.diff(
                                                        fromTimeValue,
                                                        "seconds"
                                                      )
                                                    );
                                                    setToDateId(null);
                                                  }}
                                                  ampm={false}
                                                  slotProps={{
                                                    popper: {
                                                      className:
                                                        "date-picker-body",
                                                    },
                                                    textField: {
                                                      // Remove readOnly attribute to allow typing
                                                      onClick: () =>
                                                        setToDateId(""),
                                                    },
                                                    actionBar: {
                                                      actions: [], // Remove the default actions including the "OK" button
                                                    },
                                                  }}
                                                />
                                              </PagesIndex.LocalizationProvider>
                                              <Index.FormHelperText className="admin-error-text">
                                                {errorToTime &&
                                                  touchedToTime &&
                                                  errorToTime}
                                              </Index.FormHelperText>
                                            </Index.Box>
                                          </Index.FormControl>
                                        </Index.Box>
                                      </Index.Box>

                                      <Index.Box
                                        gridColumn={{
                                          xs: "span 12",
                                          sm: "span 1",
                                          md: "span 1",
                                          lg: "span 1",
                                        }}
                                        className="grid-column"
                                      >
                                        <Index.Box className="admin-input-box admin-add-event-input-box">
                                          <Index.FormControl className="admin-form-control admin-drop-form-control">
                                            <Index.Box className="admin-form-group">
                                              <Index.Box className="add-slot-box">
                                                {fromTimeValue &&
                                                toTimeValue &&
                                                toDurationValue
                                                  ? toDurationValue
                                                  : 0}
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.FormControl>
                                        </Index.Box>
                                      </Index.Box>

                                      <Index.Box
                                        gridColumn={{
                                          xs: "span 12",
                                          sm: "span 1",
                                          md: "span 1",
                                          lg: "span 1",
                                        }}
                                        className="grid-column"
                                      >
                                        <Index.Box className="admin-input-box admin-add-event-input-box add-row-input-box">
                                          <Index.Box className="btn-main-primary orange-btn-main add-row-flex">
                                            {values?.timeDetails?.length > 1 ? (
                                              <Index.Button
                                                className="btn-primary add-row-btn "
                                                onClick={() => remove(index)}
                                              >
                                                <img
                                                  src={
                                                    PagesIndex.Svg.deleteicon
                                                  }
                                                  className="admin-incress-dicress-icon"
                                                  alt="Save"
                                                />
                                              </Index.Button>
                                            ) : (
                                              ""
                                            )}
                                            {index ===
                                            values?.timeDetails?.length - 1 ? (
                                              <Index.Button
                                                className="orange-btn add-row-btn"
                                                onClick={() => {
                                                  push({
                                                    fromTime: "",
                                                    toTime: "",
                                                    duration: "",
                                                  });
                                                }}
                                              >
                                                <img
                                                  src={PagesIndex.Svg.plus}
                                                  className="admin-incress-dicress-icon"
                                                  alt="Save"
                                                />
                                              </Index.Button>
                                            ) : (
                                              ""
                                            )}
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                      <Index.Box
                                        gridColumn={{
                                          xs: "span 12",
                                          sm: "span 6",
                                          md: "span 6",
                                          lg: "span 6",
                                        }}
                                        className="grid-column"
                                      ></Index.Box>
                                    </React.Fragment>
                                  );
                                })}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    )}
                  </FieldArray>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-user-btn-flex">
              <Index.Box className="border-btn-main">
                <Index.Button
                  className="border-btn"
                  onClick={() => navigate(`/admin/slot-management/${page}`)}
                  disabled={buttonSpinner}
                >
                  Discard
                </Index.Button>
              </Index.Box>
              <Index.Box className="btn-main-primary">
                <Index.Button
                  className={`${
                    id ? "update-btn" : "save-btn"
                  } admin-save-user-btn btn-primary`}
                  type="submit"
                  disabled={buttonSpinner}
                >
                  {buttonSpinner ? (
                    <PagesIndex.Spinner />
                  ) : (
                    <>
                      <img
                        src={PagesIndex.Svg.save}
                        className="admin-user-save-icon"
                        alt="Save"
                      />
                      <span>{id ? "Update" : "Add"}</span>
                    </>
                  )}
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </form>
        )}
      </PagesIndex.Formik>
    </Index.Box>
  );
}
