import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { styled } from "@mui/material/styles";

// for modal design
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  border: "1px solid #b2b3b3",
  width: 12,
  height: 12,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "transparent",
}));

function BpCheckbox(props) {
  return (
    <Index.Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  "&:before": {
    display: "block",
    width: 12,
    height: 12,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
});

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function MediaPlatform() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [selectuserData, setSelectuserData] = useState();
  const [loading, setLoading] = useState(true);
  const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const [activityLog, setActivityLog] = useState([]);
  const [activityLoading, setActivityLoading] = useState(true);
  const [filterLog, setFilterLog] = useState([]);

  const [openLogModal, setOpenLogModal] = useState(false);
  const handleCloseLogModal = () => setOpenLogModal(false);
  const [searchValue, setSearchValue] = useState("");
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);

  const handleOpenLogModal = (id) => {
    setOpenLogModal(true);
    setActivityLoading(true);
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_MEDIATYPE_ACTIVITY_LOG + "/" + id
    ).then((res) => {
      setActivityLoading(false);
      if (res.status === 200) {
        let data = res?.data?.activityLog?.filter(
          (data) =>
            data?.previousName !== data?.name ||
            data?.previousStatus !== data?.currentStatus
        );
        setFilterLog(data);
        setActivityLog(res.data?.activityLog);
      }
    });
  };

  const formRef = useRef();
  const initialValues = {
    mediaPlatform: id ? selectuserData?.name : "",
  };

  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [filteredData, setFilteredData] = useState([]);
  // add user modal
  const [open, setOpen] = useState(false);
  // delete modal
  const [openDelete, setOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const handleOpen = (op, id, data) => {
    setOpen(true);
    setAddOrEdit(op);
    setId(id);
    setSelectuserData(data);
  };
  const handleClose = () => {
    formRef.current.resetForm();
    setOpen(false);
  };
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setId(id);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };

  const handleGetCategory = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_MEDIATYPE).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setData(res.data);
        setFilteredData(res.data);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    handleGetCategory();
  }, []);

  const handleAddEditCategory = (values) => {
    setButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    if (id) {
      urlEncoded.append("id", id);
    }
    urlEncoded.append("name", values?.mediaPlatform);

    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_EDIT_MEDIATYPE, urlEncoded)
      .then((res) => {
        setButtonSpinner(false)
        if (res.status === 201 || res.status === 200) {
          handleClose();
          handleGetCategory();
          setSearchValue("");
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };

  const handleDeleteCategory = () => {
    setDeleteButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(PagesIndex.Api.DELETE_MEDIATYPE, urlEncoded).then(
      (res) => {
        setDeleteButtonSpinner(false)
        if (res.status === 200) {
          handleCloseDelete();
          handleGetCategory();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const requestSearch = (e) => {
    setSearchValue(e.target.value);
    let filteredData = data.filter((data) =>
      data?.name
        ?.toLowerCase()
        .includes(e?.target?.value?.trim()?.toLowerCase())
    );
    setCurrentPage(1);
    setFilteredData(filteredData);
  };
  const handleOpenStatusModal = (id) => {
    setId(id);
    setOpenStatusModal(true);
  };
  const handleCloseStatusModal = () => {
    setId("");
    setOpenStatusModal(false);
  };

  const handleStatus = () => {
    setStatusButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(
      PagesIndex.Api.ISACTIVE_MEDIATYPE,
      urlEncoded
    ).then((res) => {
      setStatusButtonSpinner(false)
      if (res.status === 200) {
        handleCloseStatusModal();
        PagesIndex.toasterSuccess(res?.message);
        handleGetCategory();
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  const renderTableRows = (setFieldValue) => {
    return filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data) => {
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.name}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              className="table-td"
              align="right"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.createdBy?.name || "-"}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              className="table-td"
              align="right"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.updatedBy?.name || "-"}
              </Index.Typography>
            </Index.TableCell>

            {(adminDetails?.roleId?.permissions?.includes(
              "media_platform_edit"
            ) ||
              adminDetails?.roleId?.permissions?.includes(
                "media_platform_delete"
              )) && (
                <Index.TableCell
                  component="td"
                  variant="td"
                  align="right"
                  className="table-td"
                >
                  <Index.Box className="admin-switch-main">
                    <Index.FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      className="admin-switch-lable"
                      checked={data?.isActive}
                      onChange={() => {
                        handleOpenStatusModal(data?._id);
                      }}
                    />
                  </Index.Box>
                </Index.TableCell>
              )}

            {(adminDetails?.roleId?.permissions?.includes(
              "media_platform_edit"
            ) ||
              adminDetails?.roleId?.permissions?.includes("media_platform_log") ||
              adminDetails?.roleId?.permissions?.includes(
                "media_platform_delete"
              )) && (
                <Index.TableCell
                  component="td"
                  variant="td"
                  align="right"
                  className="table-td"
                >
                  <Index.Box className="admin-table-data-btn-flex ">
                    {adminDetails?.roleId?.permissions?.includes(
                      "media_platform_edit"
                    ) && (
                        <Index.Tooltip
                          title="Edit"
                          arrow
                          placement="bottom"
                          className="admin-tooltip"
                        >
                          <Index.Button
                            className="admin-table-data-btn"
                            onClick={() => {
                              setId(data?._id);
                              setFieldValue("mediaPlatform", data.name);
                              handleOpen("Edit", data?._id, data);
                            }}
                          >
                            <img
                              src={PagesIndex.Svg.blueedit}
                              className="admin-icon"
                              alt="Edit"
                            ></img>
                          </Index.Button>
                        </Index.Tooltip>
                      )}

                    {adminDetails?.roleId?.permissions?.includes(
                      "media_platform_log"
                    ) && (
                        <Index.Tooltip
                          title="Log"
                          arrow
                          placement="bottom"
                          className="admin-tooltip"
                        >
                          <Index.Button
                            className="admin-table-data-btn"
                            onClick={() => handleOpenLogModal(data?._id)}
                          >
                            <img
                              src={PagesIndex.Svg.logicon}
                              className="admin-icon"
                              alt="Log"
                            ></img>
                          </Index.Button>
                        </Index.Tooltip>
                      )}

                    {adminDetails?.roleId?.permissions?.includes(
                      "media_platform_delete"
                    ) && (
                        <Index.Tooltip
                          title="Delete"
                          arrow
                          placement="bottom"
                          className="admin-tooltip"
                        >
                          <Index.Button
                            className="admin-table-data-btn"
                            onClick={() => handleOpenDelete(data?._id)}
                          >
                            <img
                              src={PagesIndex.Svg.trash}
                              className="admin-icon"
                              alt="Delete"
                            ></img>
                          </Index.Button>
                        </Index.Tooltip>
                      )}
                  </Index.Box>
                </Index.TableCell>
              )}
          </Index.TableRow>
        );
      });
  };

  const renderContent = (setFieldValue) => {
    if (filteredData?.length) {
      return renderTableRows(setFieldValue);
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };

  const renderActivityTableRows = () => {
    return activityLog
      .slice()
      .reverse()
      .map((data) => {
        let updatedInfo = "";

        if (
          data?.previousName !== data?.name ||
          data?.previousStatus !== data?.currentStatus
        ) {
          if (data?.updatedBy?.name) {
            updatedInfo = `${data?.updatedBy?.name}-${data?.dateTime
              ? PagesIndex.moment(data?.dateTime).format(
                "DD/MM/YYYY HH:mm:ss"
              )
              : "-"
              }`;
          } else {
            updatedInfo = "-";
          }
        }
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            {updatedInfo !== "" && (
              <>
                <Index.TableCell
                  component="td"
                  variant="td"
                  scope="row"
                  className="table-td"
                >
                  <Index.Typography className="admin-table-data-text">
                    <Index.Tooltip
                      title={`${data?.previousName !== data?.name
                        ? `${data?.updatedBy?.name
                        } has updated Media Platform from ${data?.previousName
                        } to ${data?.name + " "}`
                        : ""
                        }`}
                      arrow
                      placement="top"
                      className="admin-tooltip"
                    >
                      {data?.previousName !== data?.name && (
                        <span>
                          <span className="span-bold">
                            {data?.updatedBy?.name}
                          </span>{" "}
                          has updated Media Platform from{" "}
                          <span className="span-bold">
                            {data?.previousName}
                          </span>{" "}
                          to{" "}
                          <span className="span-bold">{data?.name + " "}</span>
                        </span>
                      )}
                    </Index.Tooltip>
                  </Index.Typography>

                  <Index.Typography className="admin-table-data-text">
                    <Index.Tooltip
                      title={`${data?.previousStatus !== data?.currentStatus
                        ? `${data?.updatedBy?.name
                        } Status has been updated from ${!data?.currentStatus ? "Active" : "Deactive"
                        } to ${data?.currentStatus ? "Active" : "Deactive"
                        } `
                        : ""
                        }`}
                      arrow
                      placement="top"
                      className="admin-tooltip"
                    >
                      {data?.previousStatus !== data?.currentStatus && (
                        <span>
                          <span className="span-bold">
                            {data?.updatedBy?.name}
                          </span>{" "}
                          Status has been updated from{" "}
                          <span className="span-bold">
                            {!data?.currentStatus ? "Active" : "Deactive"}
                          </span>{" "}
                          to&nbsp;
                          <span className="span-bold">
                            {data?.currentStatus ? "Active" : "Deactive"}
                          </span>
                        </span>
                      )}
                    </Index.Tooltip>
                  </Index.Typography>
                </Index.TableCell>

                <Index.TableCell
                  component="td"
                  variant="td"
                  className="table-td"
                >
                  {updatedInfo}
                </Index.TableCell>
              </>
            )}
          </Index.TableRow>
        );
      });
  };

  const renderActivityLogContent = () => {
    return (
      <>
        {!activityLoading &&
          activityLog?.length > 0 &&
          renderActivityTableRows()}
        {!activityLoading &&
          (!activityLog ||
            activityLog.length === 0 ||
            filterLog.length === 0) && <PagesIndex.DataNotFound />}
      </>
    );
  };

  return (
    <PagesIndex.Formik
      enableReinitialize
      onSubmit={handleAddEditCategory}
      initialValues={initialValues}
      validationSchema={PagesIndex.clientMediaPatformSchema}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <>
          <Index.Box className="admin-dashboard-content admin-user-list-content">
            <Index.Box className="admin-user-list-flex admin-page-title-main">
              <Index.Typography
                className="admin-page-title admin-user-list-page-title"
                component="h2"
                variant="h2"
              >
                Media Platform List
              </Index.Typography>
              <Index.Box className="admin-userlist-btn-flex">
                <Index.Box className="admin-search-main">
                  <Index.Box className="admin-search-box">
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="admin-form-control"
                        placeholder="Search"
                        value={searchValue}
                        onChange={requestSearch}
                      />
                      <img
                        src={PagesIndex.Svg.search}
                        className="admin-search-grey-img admin-icon"
                        alt="search"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                {adminDetails?.roleId?.permissions?.includes(
                  "media_platform_add"
                ) && (
                    <Index.Box className="admin-userlist-inner-btn-flex">
                      <Index.Box className="admin-adduser-btn-main btn-main-primary">
                        <Index.Button
                          className="admin-adduser-btn btn-primary"
                          onClick={() => {
                            setId("");
                            handleOpen("Add");
                          }}
                        >
                          <img
                            src={PagesIndex.Svg.plus}
                            className="admin-plus-icon"
                            alt="plus"
                          />
                          <span>Add Media Platform</span>
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  )}
              </Index.Box>
            </Index.Box>

            <Index.Box className="common-card">
              <Index.Box
                className={`admin-category-table-main ${loading ? "" : "page-table-main"
                  }`}
              >
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  {loading ? (
                    <PagesIndex.Loader />
                  ) : (
                    <Index.Table aria-label="simple table" className="table">
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="30%"
                          >
                            Media Platform Name
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="12%"
                            align="right"
                          >
                            Created By
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="12%"
                            align="right"
                          >
                            Updated By
                          </Index.TableCell>

                          {(adminDetails?.roleId?.permissions?.includes(
                            "media_platform_edit"
                          ) ||
                            adminDetails?.roleId?.permissions?.includes(
                              "media_platform_delete"
                            )) && (
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="6%"
                                align="right"
                              >
                                Status
                              </Index.TableCell>
                            )}

                          {(adminDetails?.roleId?.permissions?.includes(
                            "media_platform_edit"
                          ) ||
                            adminDetails?.roleId?.permissions?.includes(
                              "media_platform_log"
                            ) ||
                            adminDetails?.roleId?.permissions?.includes(
                              "media_platform_delete"
                            )) && (
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="4%"
                                align="right"
                              >
                                Action
                              </Index.TableCell>
                            )}
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {renderContent(setFieldValue)}
                      </Index.TableBody>
                    </Index.Table>
                  )}
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="admin-pagination-main">
                {filteredData?.length ? (
                  <Index.Pagination
                    page={currentPage}
                    count={Math.ceil(filteredData?.length / 10)}
                    onChange={(event, newPage) => setCurrentPage(newPage)}
                    variant="outlined"
                    shape="rounded"
                    className="admin-pagination"
                  />
                ) : (
                  ""
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <form onSubmit={handleSubmit} noValidate="novalidate">
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {addOrEdit} Media Platform
                  </Index.Typography>
                  {/* <Index.Box className="modal-close-btn" onClick={handleClose}>
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Box> */}
                </Index.Box>
                <Index.Box
                  className="admin-modal-hgt-scroll cus-scrollbar"
                  id="scrollDiv"
                >
                  {/* <ScrollIntoView /> */}
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Media Platform Name<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        name="mediaPlatform"
                        fullWidth
                        id="mediaPlatform"
                        className="admin-form-control"
                        placeholder="Enter Media Platform Name"
                        onChange={handleChange}
                        value={values?.mediaPlatform}
                        error={errors.mediaPlatform && touched.mediaPlatform}
                        inputProps={{ maxLength: 40 }}
                      />
                      {touched.mediaPlatform && errors.mediaPlatform && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.mediaPlatform}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="admin-discard-btn-main border-btn-main">
                      <Index.Button
                        className="admin-discard-user-btn border-btn"
                        onClick={handleClose}
                        disabled={buttonSpinner}
                      >
                        Discard
                      </Index.Button>
                    </Index.Box>
                    <Index.Box className="admin-save-btn-main btn-main-primary">
                      <Index.Button
                        className={`${id ? "update-btn" : "save-btn"} admin-save-user-btn btn-primary`}
                        type="submit"
                        disabled={buttonSpinner}
                      >
                        {buttonSpinner ? <PagesIndex.Spinner /> :
                          (<>
                            <img
                              src={PagesIndex.Svg.save}
                              className="admin-user-save-icon"
                              alt="Save"
                            />
                            <span>{id ? "Update" : "Add"}</span>
                          </>)
                        }
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          </Index.Modal>

          <Index.Modal
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <Index.Box
              sx={style}
              className="admin-delete-modal-inner-main admin-modal-inner"
            >
              <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                <Index.Box className="admin-modal-circle-main">
                  <img
                    src={PagesIndex.Svg.closecircle}
                    className="admin-user-circle-img"
                    alt="Close"
                  />
                </Index.Box>
                <Index.Typography
                  className="admin-delete-modal-title"
                  component="h2"
                  variant="h2"
                >
                  Are you sure?
                </Index.Typography>
                <Index.Typography
                  className="admin-delete-modal-para admin-common-para"
                  component="p"
                  variant="p"
                >
                  Do you really want to delete this media platform? This process
                  cannot be undone.
                </Index.Typography>
                <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
                  <Index.Button
                    className="admin-modal-cancel-btn border-btn"
                    onClick={handleCloseDelete}
                    disabled={deleteButtonSpinner}
                  >
                    Cancel
                  </Index.Button>

                  <Index.Button
                    className="delete-user admin-modal-delete-btn btn"
                    onClick={handleDeleteCategory}
                    disabled={deleteButtonSpinner}
                  >
                    {deleteButtonSpinner ? <PagesIndex.Spinner /> : <span>Delete</span>
                    }
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>

          <Index.Modal
            open={openLogModal}
            onClose={handleCloseLogModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <Index.Box
              sx={style}
              className="admin-log-modal-inner-main admin-modal-inner"
            >
              <Index.Box className="admin-modal-header">
                <Index.Typography
                  id="modal-modal-title"
                  className="admin-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {" "}
                  Activity Log
                </Index.Typography>
                <Index.Button
                  className="modal-close-btn"
                  onClick={handleCloseLogModal}
                >
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="admin-modal-close-icon"
                    alt="Close"
                  />
                </Index.Button>
              </Index.Box>
              <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                <Index.Box className="common-card">
                  <Index.Box className="page-table-main category-activity-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      {activityLoading ? (
                        <PagesIndex.Loader />
                      ) : (
                        <Index.Table
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="70%"
                              >
                                Activity Log
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="30%"
                              >
                                Updated Date & Time
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {renderActivityLogContent()}
                          </Index.TableBody>
                        </Index.Table>
                      )}
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>
          <PagesIndex.ChangeStatusModal
            data={"media platform"}
            statusButtonSpinner={statusButtonSpinner}
            openStatusModal={openStatusModal}
            handleCloseStatusModal={handleCloseStatusModal}
            handleChangeStatus={handleStatus}
          />
        </>
      )}
    </PagesIndex.Formik>
  );
}
