import { persistStore, persistReducer } from "redux-persist";
import localStorage from "redux-persist/es/storage";
import {thunk} from "redux-thunk";
import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";

const persistConfig = {
  key: "root",
  storage: localStorage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer); //persist reducer combine both local and root reducer
const Store = () => {
  let store = configureStore({
    reducer: persistedReducer,
    middleware: () => [thunk],
  }); //apply middleware to run rootreducer
  let persistor = persistStore(store);
  return { store, persistor };
};

export default Store;
