import axios from "axios";

// const REACT_APP_API_URL = "https://api.newscapital.com/api/admin/";
// const REACT_APP_API_URL = "http://192.168.1.19:3047/api/admin/";
const REACT_APP_API_URL = "http://192.168.1.102:3047/api/admin/";
// const REACT_APP_API_URL = "https://bf8d-106-213-40-206.ngrok-free.app/api/admin/";

const DataService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: REACT_APP_API_URL,
});

export default DataService;
