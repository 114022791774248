import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import dayjs from "dayjs";
import './adsManagement.css'
import './adsManagementResponsive.css'


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
export default function AdsManagement() {
  const formRef = useRef();
  // page navigate
  const navigate = PagesIndex.useNavigate();
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [id, setId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openCalenderStart, setOpenCalenderStart] = useState(false);
  const [openCalenderEnd, setOpenCalenderEnd] = useState(false);
  const [agesList, setAgesList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
  const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);
  const initialValues = {
    startDate: null,
    endDate: null,
    status: "",
    gender: [],
    category: [],
    ageGroups: [],
    type: "",
    isSubmit: "",
  };
  // useEffect(() => {
  //   let result = data.filter((data) => {
  //     let compName = data?.advId?.compName
  //       ?.toLowerCase()
  //       .includes(searchValue?.toLowerCase());
  //     let type = data?.type?.toLowerCase().includes(searchValue?.toLowerCase());
  //     let mediaType = data?.mediaType
  //       ?.toLowerCase()
  //       .includes(searchValue?.toLowerCase());
  //     let startDate = PagesIndex.moment(new Date(data?.startDate))
  //       .utc()
  //       .format("DD/MM/YYYY")
  //       .includes(searchValue?.toLowerCase());
  //     let endDate = PagesIndex.moment(new Date(data?.endDate))
  //       .utc()
  //       .format("DD/MM/YYYY")
  //       .includes(searchValue?.toLowerCase());
  //     return compName || type || mediaType || startDate || endDate;
  //   });

  //   setCurrentPage(1);
  //   setFilteredData(result);
  // }, [searchValue]);
  useEffect(() => {
    handleGetCategory();
    handleGetAges();
  }, []);
  useEffect(() => {
    handleGetAdvertisements();
  }, [searchValue, currentPage]);

  useEffect(() => {
    if (
      formRef?.current?.values?.isSubmit == true
    ) {
      setCurrentPage(1)
    }
  }, [formRef?.current?.values])

  const handleOpenDelete = (id) => {
    setId(id);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };
  const handleGetAdvertisements = () => {
    setLoading(true);
    const urlEncoded = new URLSearchParams();
    if (formRef?.current?.values?.isSubmit == true) {
      if (formRef?.current?.values?.startDate) {
        urlEncoded.append(
          "from",
          PagesIndex.moment(new Date(formRef?.current?.values?.startDate)).format(
            "YYYY-MM-DD"
          )
        );
      }
      if (formRef?.current?.values?.endDate) {
        urlEncoded.append(
          "to",
          PagesIndex.moment(new Date(formRef?.current?.values?.endDate)).format(
            "YYYY-MM-DD"
          )
        );
      }
      if (formRef?.current?.values?.status) {
        urlEncoded.append("status", formRef?.current?.values?.status);
      }
      if (formRef?.current?.values?.gender?.length) {
        urlEncoded.append(
          "gender",
          JSON.stringify(formRef?.current?.values?.gender)
        );
      }
      if (formRef?.current?.values?.ageGroups?.length) {
        urlEncoded.append(
          "age",
          JSON.stringify(formRef?.current?.values?.ageGroups)
        );
      }
      if (formRef?.current?.values?.category?.length) {
        urlEncoded.append(
          "category",
          JSON.stringify(
            formRef?.current?.values?.category?.map((data) => data?._id)
          )
        );
      }
    }
    urlEncoded.append("pageNumber", currentPage);
    urlEncoded.append("pageSize", 10);
    urlEncoded.append("search", searchValue);
    PagesIndex.apiPostHandler(
      PagesIndex.Api.GET_ADVERTISEMENTS,
      urlEncoded
    ).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setData(res?.data);
        setFilteredData(res?.data?.paginatedResults);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const handleOpenStatusModal = (id) => {
    setId(id);
    setOpenStatusModal(true);
  };
  const handleCloseStatusModal = () => {
    setId("");
    setOpenStatusModal(false);
  };
  const handleStatus = () => {
    setStatusButtonSpinner(true)
    PagesIndex.apiGetHandler(
      PagesIndex.Api.ISACTIVE_ADVERTISEMENT + "/" + id
    ).then((res) => {
      setStatusButtonSpinner(false)
      if (res.status === 200) {
        handleGetAdvertisements();
        handleCloseStatusModal();
        PagesIndex.toasterSuccess(res?.message);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const handleDeleteAd = () => {
    setDeleteButtonSpinner(true)
    PagesIndex.apiGetHandler(
      PagesIndex.Api.DELETE_ADVERTISEMENT + "/" + id
    ).then((res) => {
      setDeleteButtonSpinner(false)
      if (res.status === 200) {
        handleCloseDelete();
        handleGetAdvertisements();
        PagesIndex.toasterSuccess(res?.message);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const generateCSV = async () => {
    const headers = [
      "Sr. No.",
      "Advertisement No.",
      "Client",
      "Type",
      "Budget",
      "Start Date",
      "End Date",
      "Weekdays",
      "Time Bands",
      "Per Day Ad",
      "Total Ads To Play",
      "Media Type",
      "Ad Mob Id",
      "Genders",
      "Age Groups",
      "State",
      "Location",
      "Genres/Categories",
      "Videos",
    ];
    let modifiedData = filteredData.map((data, index) => {
      let objData = [
        index + 1,
        data?.adsNo ? data?.adsNo?.replaceAll(",", "") : "-",
        data?.advId?.compName
          ? data?.advId?.compName?.replaceAll(",", "|")
          : "-",
        data?.type ? data?.type?.replaceAll(",", "|") : "-",
        data?.budget ? data?.budget?.replaceAll(",", "|") : "-",
        data?.startDate
          ? PagesIndex.moment(data?.startDate).format("DD/MM/YYYY")
          : "-",
        data?.endDate
          ? PagesIndex.moment(data?.endDate).format("DD/MM/YYYY")
          : "-",
        data?.weekDay?.length
          ? data?.weekDay?.toString()?.replaceAll(",", "|")
          : "-",
        data?.slot?.length ? data?.slot?.toString()?.replaceAll(",", "|") : "-",
        data?.perDayAd ? data?.perDayAd?.replaceAll(",", "|") : "-",
        data?.totalAdPlay ? data?.totalAdPlay?.replaceAll(",", "|") : "-",
        data?.mediaType ? data?.mediaType?.replaceAll(",", "|") : "-",
        data?.adMobId ? data?.adMobId?.replaceAll(",", "|") : "-",
        data?.gender?.length
          ? data?.gender?.toString()?.replaceAll(",", "|")
          : "-",
        data?.age?.length ? data?.age?.toString()?.replaceAll(",", "|") : "-",
        data?.state?.stateName
          ? data?.state?.stateName?.replaceAll(",", "|")
          : "-",
        data?.location ? data?.location?.replaceAll(",", "|") : "-",
        data?.category?.length
          ? data?.category
            ?.map((data) => data?.categoryName)
            ?.toString()
            ?.replaceAll(",", "|")
          : "-",
        data?.newsId?.length
          ? data?.newsId
            ?.map((data) => data?.newsNo)
            ?.toString()
            ?.replaceAll(",", "|")
          : "-",
      ];
      return objData.join(",");
    });

    exportData(
      [[headers, ...modifiedData].join("\n")],
      `${PagesIndex.moment().format(
        "DD-MM-YYYY hh:mm:ss A"
      )}_Advertisement_list.csv`,
      "text/csv"
    );
  };
  const handleGetCategory = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_CATEGORY).then((res) => {
      if (res.status === 200) {
        const category = res?.data?.filter((data) => data?.isActive);
        setCategoryList(category);
      }
    });
  };
  const handleGetAges = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.AGE_LIST).then((res) => {
      if (res?.status === 200) {
        setAgesList(
          res?.data?.filter((data) => data?.isActive)?.map((data) => data?.age)
        );
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-page-title admin-user-list-page-title"
          component="h2"
          variant="h2"
        >
          Advertisements List
        </Index.Typography>
        <Index.Box className="admin-userlist-btn-flex">
          <Index.Box className="admin-search-main">
            <Index.Box className="admin-search-box">
              <Index.Box className="admin-form-group">
                <Index.TextField
                  value={searchValue}
                  fullWidth
                  id="fullWidth"
                  className="admin-form-control"
                  placeholder="Search"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <img
                  src={PagesIndex.Svg.search}
                  className="admin-search-grey-img admin-icon"
                  alt="search"
                ></img>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="admin-userlist-inner-btn-flex">
            {adminDetails?.roleId?.permissions?.includes("ads_add") && (
              <Index.Box className="admin-adduser-btn-main btn-main-primary">
                <Index.Button
                  className="admin-adduser-btn btn-primary"
                  onClick={() => navigate("/admin/add-ads-management")}
                >
                  <img
                    src={PagesIndex.Svg.plus}
                    className="admin-plus-icon"
                    alt="plus"
                  />
                  <span>Add Advertisement</span>
                </Index.Button>
              </Index.Box>
            )}
            {filteredData?.length > 0 && <Index.Box className="admin-adduser-btn-main btn-main-secondary">
              <Index.Button
                className="admin-adduser-btn btn-secondary"
                onClick={generateCSV}
              >
                <img
                  src={PagesIndex.Svg.exporticon}
                  className="admin-plus-icon"
                  alt="plus"
                />
                <span>Export CSV</span>
              </Index.Button>
            </Index.Box>}
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="filter-card common-card">
        <Index.Box className="filter-flex">
          <Index.Box className="grid-row">
            <PagesIndex.Formik
              enableReinitialize
              onSubmit={handleGetAdvertisements}
              initialValues={initialValues}
              validationSchema={PagesIndex.adFilterSchema}
              innerRef={formRef}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit} noValidate="novalidate">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      className="filter-row"
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 11",
                          md: "span 11",
                          lg: "span 11",
                        }}
                        className="grid-column"
                      >
                        <Index.Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 3",
                              md: "span 4",
                              lg: "span 4",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="admin-input-box advertiser-filter-input admin-date-input-main">
                              <Index.FormHelperText className="admin-form-lable">
                                <span>From Date</span>
                                <span className="astrick-sing">*</span>
                              </Index.FormHelperText>
                              <Index.Box className="admin-form-group">
                                <PagesIndex.LocalizationProvider
                                  dateAdapter={PagesIndex.AdapterDayjs}
                                  className="date-localization-main"
                                >
                                  <PagesIndex.DatePicker
                                    open={openCalenderStart}
                                    onClose={() => setOpenCalenderStart(false)}
                                    className="admin-form-control admin-date-picker-control"
                                    value={values?.startDate}
                                    onChange={(date) => {
                                      setFieldValue("startDate", date);
                                      setFieldValue("endDate", null);
                                    }}
                                    slotProps={{
                                      popper: {
                                        className: "date-picker-body",
                                      },
                                      textField: {
                                        readOnly: true,
                                        onClick: () =>
                                          setOpenCalenderStart(true),
                                      },
                                    }}
                                    format="DD/MM/YYYY"
                                  />
                                </PagesIndex.LocalizationProvider>
                              </Index.Box>
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.startDate && touched.startDate
                                  ? errors.startDate
                                  : null}
                              </PagesIndex.FormHelperText>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 3",
                              md: "span 4",
                              lg: "span 4",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="admin-input-box advertiser-filter-input admin-date-input-main">
                              <Index.FormHelperText className="admin-form-lable">
                                <span>To Date</span>
                                <span className="astrick-sing">*</span>
                              </Index.FormHelperText>
                              <Index.Box className="admin-form-group">
                                <PagesIndex.LocalizationProvider
                                  dateAdapter={PagesIndex.AdapterDayjs}
                                  className="date-localization-main"
                                >
                                  <PagesIndex.DatePicker
                                    open={openCalenderEnd}
                                    onClose={() => setOpenCalenderEnd(false)}
                                    className="admin-form-control admin-date-picker-control"
                                    value={values?.endDate}
                                    minDate={dayjs(values?.startDate).add(
                                      0,
                                      "day"
                                    )}
                                    onChange={(date) => {
                                      setFieldValue("endDate", date);
                                    }}
                                    slotProps={{
                                      popper: {
                                        className: "date-picker-body",
                                      },
                                      textField: {
                                        readOnly: true,
                                        onClick: () => setOpenCalenderEnd(true),
                                      },
                                    }}
                                    format="DD/MM/YYYY"
                                  />
                                </PagesIndex.LocalizationProvider>
                              </Index.Box>
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.endDate && touched.endDate
                                  ? errors.endDate
                                  : null}
                              </PagesIndex.FormHelperText>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 3",
                              md: "span 4",
                              lg: "span 4",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="admin-input-box advertiser-filter-input ">
                              <Index.FormHelperText className="admin-form-lable">
                                <span>Status</span>
                                <span className="astrick-sing">*</span>
                              </Index.FormHelperText>
                              <Index.Box className="admin-form-group">
                                <Index.Box className="admin-dropdown-box">
                                  <Index.FormControl className="admin-form-control">
                                    <Index.Select
                                      name="status"
                                      className="admin-dropdown-select "
                                      value={values?.status}
                                      onChange={handleChange}
                                      displayEmpty
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                      renderValue={
                                        values?.status
                                          ? undefined
                                          : () => "Select Status"
                                      }
                                    >
                                      <Index.MenuItem
                                        value={"active"}
                                        className="admin-menuitem"
                                      >
                                        Active
                                      </Index.MenuItem>
                                      <Index.MenuItem
                                        value={"deactive"}
                                        className="admin-menuitem"
                                      >
                                        Deactive
                                      </Index.MenuItem>
                                    </Index.Select>
                                  </Index.FormControl>
                                </Index.Box>
                              </Index.Box>
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.status && touched.status
                                  ? errors.status
                                  : null}
                              </PagesIndex.FormHelperText>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 3",
                              md: "span 4",
                              lg: "span 4",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="admin-input-box advertiser-filter-input input-complete-box">
                              <Index.FormHelperText className="admin-form-lable">
                                Genders<span className="astrick-sing">*</span>
                              </Index.FormHelperText>
                              <Index.Box className="admin-form-group">
                                <Index.Box className="admin-dropdown-box">
                                  <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                    <Index.Autocomplete
                                      className="admin-auto-complete-filed"
                                      options={[
                                        "All",
                                        "Male",
                                        "Female",
                                        "Other",
                                      ]}
                                      name="gender"
                                      multiple
                                      disableCloseOnSelect
                                      closeText={""}
                                      openText={""}
                                      clearText={""}
                                      value={values?.gender}
                                      onChange={(e, selectedOptions) => {
                                        if (selectedOptions.includes("All")) {
                                          setFieldValue("gender", [
                                            "Male",
                                            "Female",
                                            "Other",
                                          ]);
                                        } else {
                                          setFieldValue(
                                            "gender",
                                            selectedOptions.map(
                                              (option) => option
                                            )
                                          );
                                        }
                                      }}
                                      getOptionLabel={(option) => option}
                                      renderOption={(props, item) => (
                                        <li {...props} key={item}>
                                          {item}
                                        </li>
                                      )}
                                      renderInput={(params) => (
                                        <Index.TextField
                                          fullWidth
                                          className="admin-form-control"
                                          placeholder={
                                            values?.gender?.length
                                              ? ""
                                              : "Select Gender"
                                          }
                                          {...params}
                                          size="small"
                                          variant="outlined"
                                        />
                                      )}
                                    />
                                  </Index.FormControl>
                                </Index.Box>
                              </Index.Box>
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.gender && touched.gender
                                  ? errors.gender
                                  : null}
                              </PagesIndex.FormHelperText>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 3",
                              md: "span 4",
                              lg: "span 4",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="admin-input-box advertiser-filter-input input-complete-box">
                              <Index.FormHelperText className="admin-form-lable">
                                <span>Category</span>
                                <span className="astrick-sing">*</span>
                              </Index.FormHelperText>
                              <Index.Box className="admin-form-group">
                                <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                  <Index.Autocomplete
                                    className="admin-auto-complete-filed admin-dropdown-select admin-check-dropdown-select"
                                    options={categoryList}
                                    name="category"
                                    multiple
                                    disableCloseOnSelect
                                    closeText={""}
                                    openText={""}
                                    clearText={""}
                                    disableClearable
                                    value={values?.category}
                                    onChange={(e, selectedOptions) => {
                                      setFieldValue(
                                        "category",
                                        selectedOptions.map((option) => option)
                                      );
                                    }}
                                    getOptionLabel={(option) =>
                                      option?.categoryName
                                    }
                                    renderOption={(
                                      props,
                                      option,
                                      { selected }
                                    ) => {
                                      return (
                                        <Index.MenuItem
                                          {...props}
                                          className="select-check-itme"
                                        >
                                          <Index.Checkbox
                                            checked={selected}
                                            className="select-check-box"
                                          />
                                          <Index.ListItemText
                                            primary={option?.categoryName}
                                            className="select-check-text"
                                          />
                                        </Index.MenuItem>
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <Index.TextField
                                        fullWidth
                                        className="admin-form-control"
                                        placeholder={
                                          values?.category?.length
                                            ? ""
                                            : "Select Category"
                                        }
                                        {...params}
                                        size="small"
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </Index.FormControl>
                              </Index.Box>
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.category && touched.category
                                  ? errors.category
                                  : null}
                              </PagesIndex.FormHelperText>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 3",
                              md: "span 4",
                              lg: "span 4",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="admin-input-box advertiser-filter-input input-complete-box">
                              <Index.FormHelperText className="admin-form-lable">
                                <span>Age Groups</span>
                                <span className="astrick-sing">*</span>
                              </Index.FormHelperText>
                              <Index.Box className="admin-form-group">
                                <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                  <Index.Autocomplete
                                    className="admin-auto-complete-filed admin-dropdown-select admin-check-dropdown-select"
                                    options={["All", ...agesList]}
                                    name="ageGroups"
                                    multiple
                                    disableCloseOnSelect
                                    closeText={""}
                                    openText={""}
                                    clearText={""}
                                    disableClearable
                                    value={values?.ageGroups}
                                    onChange={(e, selectedOptions) => {
                                      if (selectedOptions.includes("All")) {
                                        setFieldValue("ageGroups", [
                                          ...agesList,
                                        ]);
                                      } else {
                                        setFieldValue(
                                          "ageGroups",
                                          selectedOptions.map(
                                            (option) => option
                                          )
                                        );
                                      }
                                    }}
                                    getOptionLabel={(option) => option}
                                    renderOption={(
                                      props,
                                      option,
                                      { selected }
                                    ) => {
                                      return (
                                        <Index.MenuItem
                                          {...props}
                                          className="select-check-itme"
                                        >
                                          <Index.Checkbox
                                            checked={selected}
                                            className="select-check-box"
                                          />
                                          <Index.ListItemText
                                            primary={option}
                                            className="select-check-text"
                                          />
                                        </Index.MenuItem>
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <Index.TextField
                                        fullWidth
                                        className="admin-form-control"
                                        placeholder={
                                          values?.ageGroups?.length
                                            ? ""
                                            : "Select Age Group"
                                        }
                                        {...params}
                                        size="small"
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </Index.FormControl>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 1",
                          md: "span 1",
                          lg: "span 1",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box advertiser-filter-input input-complete-box">
                          <Index.FormHelperText className="admin-form-lable">
                            {""}
                          </Index.FormHelperText>
                          <Index.Box className="filter-btns">
                            <Index.Tooltip
                              title="Search"
                              arrow
                              placement="top"
                              className="admin-tooltip"
                            >
                              <Index.Box className="btn-main-primary admin-icons-btn-main">
                                <Index.Button
                                  className="btn-primary admin-icons-btn"
                                  type="submit"
                                  disabled={(formRef?.current?.values?.endDate ||
                                    formRef?.current?.values?.startDate ||
                                    formRef?.current?.values?.status ||
                                    formRef?.current?.values?.category?.length ||
                                    formRef?.current?.values?.ageGroups?.length ||
                                    formRef?.current?.values?.gender?.length) == 0 ? true : false}
                                    onClick={()=> setFieldValue("isSubmit", true)}
                                >
                                  <img
                                    src={PagesIndex.Svg.searchwhite}
                                    className="admin-icons"
                                    alt="Save"
                                  />
                                </Index.Button>
                              </Index.Box>
                            </Index.Tooltip>
                            <Index.Tooltip
                              title="Reset"
                              arrow
                              placement="top"
                              className="admin-tooltip"
                            >
                              <Index.Box className="btn-main-primary admin-icons-btn-main">
                                <Index.Button
                                  className="btn-primary admin-icons-btn"
                                  onClick={() => {
                                    formRef?.current?.resetForm();
                                    setCurrentPage(1);
                                    setTimeout(() => {
                                      handleGetAdvertisements();
                                    }, 1000);
                                  }}
                                >
                                  <img
                                    src={PagesIndex.Svg.reset}
                                    className="admin-icons"
                                    alt="Reset"
                                  />
                                </Index.Button>
                              </Index.Box>
                            </Index.Tooltip>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="common-card">
        <Index.Box className={`ads-managment-table page-table-main`}>
          <Index.TableContainer
            component={Index.Paper}
            className="table-container"
          >
            {loading ? <PagesIndex.Loader /> :
              <Index.Table aria-label="simple table" className="table">
                <Index.TableHead className="table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="12%"
                    >
                      Client
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="5%"
                    >
                      Type
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="6%"
                    >
                      Start Date
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="6%"
                    >
                      End Date
                    </Index.TableCell>
                    {/* <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="15%"
                  >
                    Week Day
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="15%"
                  >
                    Slot
                  </Index.TableCell> */}
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="5%"
                    >
                      Ad Type
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="7%"
                    >
                      Per day Ad
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="8%"
                    >
                      Total Ads to Play
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="3%"
                      align="right"
                    >
                      Status
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="right"
                      width="4%"
                    >
                      Action
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  {loading ? (
                    <PagesIndex.Loader />
                  ) : filteredData?.length ? (
                    filteredData
                      ?.map((data, index) => {
                        return (
                          <Index.TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            key={data?._id}
                          >
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="table-td"
                            >
                              <Index.Typography className="admin-table-data-text"
                                onClick={() =>
                                  navigate(
                                    `/admin/view-ads-management/${data?._id}`
                                  )
                                }>
                                {" "}
                                <span className="news-id-text">
                                  {data?.adsNo}
                                </span>{" "}
                                <Index.Tooltip
                                  title={data?.advId?.compName}
                                  arrow
                                  placement="bottom"
                                  className="admin-tooltip"
                                >
                                  {data?.advId?.compName}
                                </Index.Tooltip>
                              </Index.Typography>
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              <Index.Box className="color-box">
                                <Index.Box
                                  key={index}
                                  className={
                                    data.type === "On Click, On View"
                                      ? "yellow-long lable-btn"
                                      : data.type === "Fixed cost"
                                        ? "green lable-btn"
                                        : "gray lable-btn"
                                  }
                                >
                                  {data.type}
                                </Index.Box>
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {" "}
                              {PagesIndex.moment(data?.startDate)
                                .utc()
                                .format("DD/MM/YYYY")}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {" "}
                              {PagesIndex.moment(data?.endDate)
                                .utc()
                                .format("DD/MM/YYYY")}
                            </Index.TableCell>
                            {/* <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                          >
                            {" "}
                            {data?.weekDay?.toString()?.replaceAll(",", ", ")}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                          >
                            {" "}
                            {data?.slot?.toString()?.replaceAll(",", ", ")}
                          </Index.TableCell> */}
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {" "}
                              {data?.mediaType || "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {" "}
                              {data?.perDayAd}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {" "}
                              {data?.totalAdPlay || "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                              align="right"
                            >
                              <Index.Tooltip
                                title={data?.isActive ? "Active" : "Deactive"}
                                arrow
                                placement="bottom"
                                className="admin-tooltip"
                              >
                                <Index.Box className="admin-switch-main">
                                  <Index.FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                    className="admin-switch-lable"
                                    checked={data?.isActive}
                                    onChange={() =>
                                      handleOpenStatusModal(data?._id)
                                    }
                                  />
                                </Index.Box>
                              </Index.Tooltip>
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                              align="right"
                            >
                              <Index.Box className="admin-table-data-btn-flex">
                                {adminDetails?.roleId?.permissions?.includes(
                                  "ads_edit"
                                ) && (
                                    <Index.Tooltip
                                      title="Edit"
                                      arrow
                                      placement="bottom"
                                      className="admin-tooltip"
                                    >
                                      <Index.Button
                                        className="admin-table-data-btn"
                                        onClick={() =>
                                          navigate(
                                            `/admin/edit-ads-management/${data?._id}`,
                                            {
                                              state: { data: data },
                                            }
                                          )
                                        }
                                      >
                                        <img
                                          src={PagesIndex.Svg.blueedit}
                                          className="admin-icon"
                                          alt="Edit"
                                        ></img>
                                      </Index.Button>
                                    </Index.Tooltip>
                                  )}
                                <Index.Tooltip
                                  title="View"
                                  arrow
                                  placement="bottom"
                                  className="admin-tooltip"
                                >
                                  <Index.Button
                                    className="admin-table-data-btn"
                                    onClick={() =>
                                      navigate(
                                        `/admin/view-ads-management/${data?._id}`
                                      )
                                    }
                                  >
                                    <img
                                      src={PagesIndex.Svg.yelloweye}
                                      className="admin-icon"
                                      alt="View"
                                    ></img>
                                  </Index.Button>
                                </Index.Tooltip>
                                {adminDetails?.roleId?.permissions?.includes(
                                  "ads_delete"
                                ) && (
                                    <Index.Tooltip
                                      title="Delete"
                                      arrow
                                      placement="bottom"
                                      className="admin-tooltip"
                                    >
                                      <Index.Button
                                        className="admin-table-data-btn"
                                        onClick={() => handleOpenDelete(data?._id)}
                                      >
                                        <img
                                          src={PagesIndex.Svg.trash}
                                          className="admin-icon"
                                          alt="Delete"
                                        ></img>
                                      </Index.Button>
                                    </Index.Tooltip>
                                  )}
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        );
                      })
                  ) : (
                    <PagesIndex.DataNotFound />
                  )}
                </Index.TableBody>
              </Index.Table>
            }
          </Index.TableContainer>
        </Index.Box>
        <Index.Box className="admin-pagination-main">
          {data?.totalCount?.count ? (
            <Index.Pagination
              page={currentPage}
              count={Math.ceil(data?.totalCount?.count / 10)}
              onChange={(event, newPage) => setCurrentPage(newPage)}
              variant="outlined"
              shape="rounded"
              className="admin-pagination"
            />
          ) : (
            ""
          )}
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box>
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              Do you really want to delete this advertisement? This process
              cannot be undone.
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseDelete}
                disabled={deleteButtonSpinner}
              >
                Cancel
              </Index.Button>
              <Index.Button
                className="delete-user admin-modal-delete-btn btn"
                onClick={handleDeleteAd}
                disabled={deleteButtonSpinner}
              >
                {deleteButtonSpinner ? <PagesIndex.Spinner /> : <span>Delete</span>
                }
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      <PagesIndex.ChangeStatusModal
        data={"advertisement"}
        statusButtonSpinner={statusButtonSpinner}
        openStatusModal={openStatusModal}
        handleCloseStatusModal={handleCloseStatusModal}
        handleChangeStatus={handleStatus}
      />
    </Index.Box>
  );
}
