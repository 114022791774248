import React, { useCallback, useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function ViewSponser() {
  const navigate = PagesIndex.useNavigate();
  const params = PagesIndex.useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const { id } = params;

  const handleGetSponser = () => {
    setLoading(true);
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_VIEW_DETAILS + "/" + id).then(
      (res) => {
        setLoading(false);
        if (res.status === 200) {
          setData(res.data);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  useEffect(() => {
    handleGetSponser();
  }, []);

  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main view-page-title-main">
        <Index.Box className="admin-title-left">
          <Index.Button
            className="admin-adduser-btn btn-primary back-btn"
            onClick={() => {
              navigate(`/admin/sponsor-management`);
            }}
          >
            <img
              src={PagesIndex.Svg.backarrow}
              className="admin-icon edit-icons"
              alt="plus"
            />
          </Index.Button>
          <Index.Typography
            className="admin-view-page-title"
            component="h2"
            variant="h2"
          >
            Sponsor Details
          </Index.Typography>
        </Index.Box>
      </Index.Box>
      <Index.Box className="view-details-card">
        <Index.Box className="admin-file-view-card common-card">
          <Index.Box className="admin-file-view-main">
            <img
              src={
                data?.logo
                  ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${data?.logo}`
                  : PagesIndex.Svg.noimgfound
              }
              className="admin-file-view-img"
              alt="upload img"
            />
          </Index.Box>
        </Index.Box>
        <Index.Box className="news-view-content-main common-card">
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Sponsor Name:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.name?.compName}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Email Id:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.email}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Categories:
              </Index.Typography>
              <Index.Box className="view-category-flex-box">
                {data?.category?.length
                  ? data?.category?.map((data) => {
                      return (
                        <Index.Box
                          className="view-category-box"
                          key={data?._id}
                        >
                          <Index.Typography className="view-content-data">
                            {data?.categoryName}
                          </Index.Typography>
                        </Index.Box>
                      );
                    })
                  : "-"}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Mobile No.:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {data?.mobile}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Districts:
              </Index.Typography>
              <Index.Box className="view-category-flex-box">
                {data?.district?.length
                  ? data?.district?.map((data) => {
                      return (
                        <Index.Box
                          className="view-category-box"
                          key={data?._id}
                        >
                          <Index.Typography className="view-content-data">
                            {data?.name}
                          </Index.Typography>
                        </Index.Box>
                      );
                    })
                  : "-"}
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                News:
              </Index.Typography>
              <Index.Box className="view-category-flex-box">
                {data?.news?.length
                  ? data?.news?.map((data) => {
                      return (
                        <Index.Box
                          className="view-category-box"
                          key={data?._id}
                        >
                          <Index.Typography className="view-content-data">
                            {data?.newsNo}
                          </Index.Typography>
                        </Index.Box>
                      );
                    })
                  : "-"}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Start Date:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {PagesIndex.moment(data?.startDate).format(
                    "DD/MM/YYYY"
                  )}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                End Date:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {PagesIndex.moment(data?.endDate).format(
                    "DD/MM/YYYY"
                  )}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
