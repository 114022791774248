import React from "react";
import Index from "../../../../Index";
import PagesIndex from "../../../../PagesIndex";

export default function WebSeriesListOfSeason(props) {
  const { data } = props;
  return (
    <Index.Box className="accordian-main common-card">
      <Index.Box className="admin-view-web-series-table-main page-table-main">
        <Index.TableContainer
          component={Index.Paper}
          className="table-container"
        >
          <Index.Table aria-label="simple table" className="table">
            <Index.TableHead className="table-head">
              <Index.TableRow className="table-row">
                <Index.TableCell
                  component="th"
                  variant="th"
                  className="table-th"
                  width="4%"
                >
                  Sr. No.
                </Index.TableCell>
                <Index.TableCell
                  component="th"
                  variant="th"
                  className="table-th"
                  width="15%"
                >
                  Seasons Name
                </Index.TableCell>
                <Index.TableCell
                  component="th"
                  variant="th"
                  className="table-th"
                  width="10%"
                  align="center"
                >
                  Episodes
                </Index.TableCell>
                <Index.TableCell
                  component="th"
                  variant="th"
                  className="table-th"
                  width="15%"
                >
                  Cast
                </Index.TableCell>
                <Index.TableCell
                  component="th"
                  variant="th"
                  className="table-th"
                  align="right"
                  width="10%"
                >
                  Action
                </Index.TableCell>
              </Index.TableRow>
            </Index.TableHead>
            <Index.TableBody className="table-body">
              {data?.length ? (
                data?.map((data, index) => {
                  return (
                    <Index.TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      key={data?._id}
                    >
                      <Index.TableCell
                        colSpan={12}
                        className="table-td accordian-inner-td"
                      >
                        <Index.Accordion className="accordian">
                          <Index.AccordionSummary
                            className="accordian-series-summary"
                            // expandIcon={<Index.ExpandMoreIcon className="accordian-expan-icon" />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Index.Table>
                              <Index.TableBody>
                                <Index.TableRow>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td table-td-inner"
                                    width="3%"
                                  >
                                    <Index.Typography className="admin-table-data-text">
                                      {" "}
                                      {index + 1}
                                    </Index.Typography>
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td table-td-inner"
                                    width="15%"
                                  >
                                    <Index.Typography className="admin-table-data-text">
                                      {" "}
                                      {data?.season}
                                    </Index.Typography>
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    align="center"
                                    className="table-td table-td-inner"
                                    width="10%"
                                  >
                                    {" "}
                                    {data?.episode?.length}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td table-td-inner"
                                    width="15%"
                                  >
                                    {" "}
                                    {data?.cast
                                      ?.map((data) => data?.castId?.name)
                                      .toString()
                                      .replaceAll(",", ", ")}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td table-td-inner"
                                    align="center"
                                    width="10%"
                                  >
                                    <Index.Tooltip
                                      title="View"
                                      arrow
                                      placement="bottom"
                                      className="admin-tooltip"
                                    >
                                      <Index.Box className="admin-table-data-btn-flex">
                                        <Index.Button className="admin-table-data-btn">
                                          <img
                                            src={PagesIndex.Svg.yelloweye}
                                            className="admin-icon"
                                            alt="View"
                                          ></img>
                                        </Index.Button>
                                      </Index.Box>
                                    </Index.Tooltip>
                                  </Index.TableCell>
                                </Index.TableRow>
                              </Index.TableBody>
                            </Index.Table>
                          </Index.AccordionSummary>
                          <Index.AccordionDetails className="accordian-series-details">
                            <Index.Box className="common-card">
                              <Index.Box className="inner-table-title-main">
                                <Index.Typography
                                  className="inner-table-title"
                                  component="h2"
                                  variant="h2"
                                >
                                  List Of Episode
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="admin-view-acc-summary-main">
                                <Index.Box className="admin-view-web-series-table-main page-table-main">
                                  <Index.TableContainer
                                    component={Index.Paper}
                                    className="table-container"
                                  >
                                    <Index.Table
                                      aria-label="simple table"
                                      className="table"
                                    >
                                      <Index.TableHead className="table-head">
                                        <Index.TableRow className="table-row">
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="2%"
                                          >
                                            Sr. No.
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="3%"
                                          >
                                            Thumbnail
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="7%"
                                          >
                                            Episode Name
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="15%"
                                          >
                                            Description
                                          </Index.TableCell>
                                        </Index.TableRow>
                                      </Index.TableHead>
                                      <Index.TableBody className="table-body">
                                        {data?.episode?.length ? (
                                          data?.episode?.map((item, index) => {
                                            return (
                                              <Index.TableRow
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                    {
                                                      border: 0,
                                                    },
                                                }}
                                                key={item?._id}
                                              >
                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  scope="row"
                                                  className="table-td"
                                                >
                                                  <Index.Typography className="admin-table-data-text">
                                                    {" "}
                                                    {index + 1}
                                                  </Index.Typography>
                                                </Index.TableCell>
                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  <Index.Typography className="admin-table-data-text">
                                                    <Index.Box className="admin-table-data-flex">
                                                      <img
                                                        src={
                                                          item?.image
                                                            ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${item?.image}`
                                                            : PagesIndex.Svg
                                                                .noimgfound
                                                        }
                                                        className="admin-table-data-img "
                                                        alt="User"
                                                      ></img>
                                                    </Index.Box>
                                                  </Index.Typography>
                                                </Index.TableCell>
                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  <Index.Typography className="admin-table-data-text">
                                                    {" "}
                                                    {item?.name}
                                                  </Index.Typography>
                                                </Index.TableCell>
                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  {" "}
                                                  {item?.description}
                                                </Index.TableCell>
                                              </Index.TableRow>
                                            );
                                          })
                                        ) : (
                                          <PagesIndex.DataNotFound />
                                        )}
                                      </Index.TableBody>
                                    </Index.Table>
                                  </Index.TableContainer>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.AccordionDetails>
                        </Index.Accordion>
                      </Index.TableCell>
                    </Index.TableRow>
                  );
                })
              ) : (
                <PagesIndex.DataNotFound />
              )}
            </Index.TableBody>
          </Index.Table>
        </Index.TableContainer>
      </Index.Box>
    </Index.Box>
  );
}
