import Routes from "./routes/Routes";
import "./assets/style/global.css";
import "./assets/style/global.responsive.css";
import "./assets/style/select2.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Poppins, sans-serif",
        fontWeight: "400",
        lineHeight: "normal",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Toaster
        reverseOrder={false}
        toastOptions={{
          duration: 3000,
          style: {
            maxWidth: "unset",
          },
        }}
      />
      <div className="App">
        <Routes />
      </div>
    </ThemeProvider>
  );
}

export default App;
