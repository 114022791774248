import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./roleManagement.css";
import "./roleManagement.responsive.css";

// for modal design
const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

// for custom checkbox design

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  border: "1px solid #b2b3b3",
  width: 12,
  height: 12,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "transparent",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  "&:before": {
    display: "block",
    width: 12,
    height: 12,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Index.Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

export default function RoleManagement() {
  const [searchValue, setSearchValue] = useState("");
  let initialValues = {
    role: "",
  };
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const formRef = useRef();
  // add user modal
  const modules = [
    { tag: "dashboard", title: "Dashboard" },
    { tag: "user", title: "App User" },
    { tag: "user_posted_videos", title: "User Posted Videos" },
    { tag: "videos", title: "Add Videos" },
    { tag: "category", title: "Category" },
    { tag: "home_category", title: "Home Category" },
    { tag: "state", title: "State" },
    { tag: "zone", title: "Zone" },
    { tag: "district", title: "District" },
    { tag: "tag", title: "Tag" },
    { tag: "clients", title: "All Clients" },
    { tag: "event", title: "Event" },
    { tag: "client_zone", title: "Client Zone" },
    { tag: "sales_order", title: "Sales Deal" },
    { tag: "client_category", title: "Client Category" },
    { tag: "client_sub_category", title: "Client SubCategory" },
    { tag: "media_platform", title: "Media Platform" },

    { tag: "slot_schedule", title: "Advertisement Fct NonFct" },
    { tag: "website_ad", title: "Website Ad" },
    { tag: "nc_slot_schedule", title: "Nc Shots Ad" },
    { tag: "ads", title: "OTT Ad" },

    { tag: "element", title: "Inventory Fct NonFct Element" },
    { tag: "slot", title: "Inventory Fct NonFct Template" },
    // { tag: "inventory_ott", title: "Inventory OTT" },

    { tag: "creative", title: "Add Creative" },

    { tag: "movies", title: "Movies" },
    { tag: "series", title: "Series" },
    { tag: "season", title: "Season" },
    { tag: "episode", title: "Episode" },
    { tag: "actor", title: "Cast & Crew Member" },
    { tag: "movie_category", title: "Movie Category" },
    { tag: "movie_tag", title: "Movie Tag" },

    { tag: "role", title: "Role Permission" },
    { tag: "admin_user", title: "Admin User" },

    { tag: "advertising", title: "Advertising List" },
    { tag: "support", title: "Support List" },

    { tag: "cms", title: "CMS" },
    { tag: "faq", title: "FAQ" },

    { tag: "sponsor", title: "Sponsor" },

    { tag: "report_analytics", title: "Advertisement " },
    { tag: "voters_list", title: "Voters List" },

    { tag: "program", title: "Program" },
    { tag: "phone_directory", title: "Phone Directory" },

    // { tag: "ad_zone", title: "Advertisement Zone" },
    // { tag: "season", title: "Season" },
    // { tag: "episode", title: "Episode" },
    // { tag: "website_slot_schedule", title: "Website" },
    // { tag: "nc_slot_schedule", title: "Nc Shot" },
    // { tag: "platform", title: "Platform" },
    // { tag: "program_type", title: "Program Type" },

    { tag: "site_setting", title: "Site Setting" },
    { tag: "upload_video", title: "Upload Video" },
    { tag: "archive_video", title: "Archive Video" },
  ];
  const [open, setOpen] = useState(false);
  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [permissionsError, setPermissionsError] = useState("");
  const [id, setId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const [activityLog, setActivityLog] = useState([]);
  const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);
  const [filterLog, setFilterLog] = useState([]);
  const [activityLoading, setActivityLoading] = useState(true);
  const [openStatusModal, setOpenStatusModal] = useState(false);

  const [openLogModal, setOpenLogModal] = useState(false);
  const handleCloseLogModal = () => setOpenLogModal(false);

  const handleOpenLogModal = (id) => {
    setOpenLogModal(true);
    setActivityLoading(true);
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_ROLE_ACTIVITY_LOG + "/" + id
    ).then((res) => {
      setActivityLoading(false);
      if (res.status === 200) {
        let data = res?.data?.activityLog?.filter(
          (data) =>
            data?.previousRole !== data?.updatedRole ||
            data?.previousStatus !== data?.currentStatus
        );
        setFilterLog(data);
        setActivityLog(res.data?.activityLog);
      }
    });
  };
  useEffect(() => {
    setLoading(true);
    handleGetRoles();
  }, []);
  const handleOpen = (op) => {
    setOpen(true);
    setAddOrEdit(op);
  };
  const handleClose = () => {
    setId("");
    formRef.current.resetForm();
    setOpen(false);
  };
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setId(id);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };
  const onChangeCheckBox = (value) => {
    if (permissions?.includes(value)) {
      const newData = permissions?.filter(function (item) {
        return item !== value;
      });
      if (!newData.length) {
        setPermissionsError("Please select at least one permission");
      }
      setPermissions([...new Set([...newData])]);
    } else {
      const newData = [...permissions];
      newData.push(value);
      setPermissionsError("");
      setPermissions([...new Set([...newData])]);
    }
  };
  const checkUncheckAllType = (action, type) => {
    if (action === "add") {
      setPermissionsError("");
      let updatedData = modules?.map((ele) => {
        return ele?.tag + type;
      });
      setPermissions((prev) => [...new Set([...prev, ...updatedData])]);
    } else {
      const data = modules?.map((ele) => {
        return ele?.tag + type;
      });
      const removedArray = permissions.filter((el) => !data?.includes(el));
      if (!removedArray.length) {
        setPermissionsError("Please select at least one permission");
      }
      setPermissions(removedArray);
    }
  };
  const handleGetRoles = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ROLES).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setData(res.data);
        setFilteredData(res.data);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const handleAddEditRole = (values) => {
    setButtonSpinner(true);
    const urlEncoded = new URLSearchParams();
    if (id) {
      urlEncoded.append("id", id);
    }
    urlEncoded.append("role", values?.role);
    // urlEncoded.append("permissions", JSON.stringify([]));
    urlEncoded.append("permissions", JSON.stringify([...new Set(permissions)]));
    if (permissions.length) {
      PagesIndex.apiPostHandler(
        PagesIndex.Api.ADD_EDIT_ROLEPERMISSION,
        urlEncoded
      )
        .then((res) => {
          setButtonSpinner(false);
          if (res.status === 201 || res.status === 200) {
            PagesIndex.toasterSuccess(res?.message);
            handleClose();
            handleGetRoles();
            setSearchValue("");
          } else {
            PagesIndex.toasterError(res?.message);
          }
        })
        .catch((err) => {
          PagesIndex.toasterError(err.response.data.message);
        });
    }
  };
  const handleDeleteRole = () => {
    setDeleteButtonSpinner(true);
    PagesIndex.apiGetHandler(`${PagesIndex.Api.DELETE_ROLE}/${id}`).then(
      (res) => {
        setDeleteButtonSpinner(false);
        if (res.status === 200) {
          handleCloseDelete();
          handleGetRoles();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };

  const requestSearch = (e) => {
    setSearchValue(e.target.value);
    let result = data.filter((data) => {
      let role = data?.role
        ?.toLowerCase()
        ?.includes(e?.target?.value?.trim()?.toLowerCase());
      const date = new Date(data?.createdAt);
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      let filterDate = formattedDate.includes(e?.target?.value?.trim());
      return role || filterDate;
    });
    setCurrentPage(1);
    setFilteredData(result);
  };
  const handleOpenStatusModal = (id) => {
    setId(id);
    setOpenStatusModal(true);
  };
  const handleCloseStatusModal = () => {
    setId("");
    setOpenStatusModal(false);
  };
  const handleStatus = () => {
    setStatusButtonSpinner(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(PagesIndex.Api.ISACTIVE_ROLE, urlEncoded).then(
      (res) => {
        setStatusButtonSpinner(false);
        if (res.status === 200) {
          handleGetRoles();
          handleCloseStatusModal();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };

  const renderTableRows = (setFieldValue) => {
    return filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data) => {
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Box className="admin-table-data-flex">
                <Index.Typography className="admin-table-data-text">
                  {data?.role}
                </Index.Typography>
              </Index.Box>
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              className="table-td"
              align="right"
            >
              {PagesIndex.moment(data?.createdAt).format("DD/MM/YYYY")}
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
              align="right"
            >
              <Index.Typography className="admin-table-data-text">
                {data?.createdBy?.name || "-"}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
              align="right"
            >
              <Index.Typography className="admin-table-data-text">
                {data?.updatedBy?.name || "-"}
              </Index.Typography>
            </Index.TableCell>

            {(adminDetails?.roleId?.permissions?.includes("role_edit") ||
              adminDetails?.roleId?.permissions?.includes("role_delete")) && (
              <Index.TableCell
                component="td"
                variant="td"
                className="table-td"
                align="right"
              >
                <Index.Tooltip
                  title={data?.isActive ? "Active" : "Deactive"}
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Box className="admin-switch-main">
                    <Index.FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      className="admin-switch-lable"
                      checked={data?.isActive}
                      onChange={() => {
                        handleOpenStatusModal(data?._id);
                      }}
                    />
                  </Index.Box>
                </Index.Tooltip>
              </Index.TableCell>
            )}

            {(adminDetails?.roleId?.permissions?.includes("role_edit") ||
              adminDetails?.roleId?.permissions?.includes("role_log") ||
              adminDetails?.roleId?.permissions?.includes("role_delete")) && (
              <Index.TableCell
                component="td"
                variant="td"
                className="table-td"
                align="right"
              >
                <Index.Box className="admin-table-data-btn-flex ">
                  {adminDetails?.roleId?.permissions?.includes("role_edit") && (
                    <Index.Tooltip
                      title="Edit"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => {
                          setId(data?._id);
                          handleOpen("Edit");
                          setFieldValue("role", data?.role);
                          setPermissions(data?.permissions);
                        }}
                      >
                        <img
                          src={PagesIndex.Svg.blueedit}
                          className="admin-icon"
                          alt="Edit"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}

                  {adminDetails?.roleId?.permissions?.includes("role_log") && (
                    <Index.Tooltip
                      title="Log"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => handleOpenLogModal(data?._id)}
                      >
                        <img
                          src={PagesIndex.Svg.logicon}
                          className="admin-icon"
                          alt="Log"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}

                  {adminDetails?.roleId?.permissions?.includes(
                    "role_delete"
                  ) && (
                    <Index.Tooltip
                      title="Delete"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => handleOpenDelete(data?._id)}
                      >
                        <img
                          src={PagesIndex.Svg.trash}
                          className="admin-icon"
                          alt="Delete"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}
                </Index.Box>
              </Index.TableCell>
            )}
          </Index.TableRow>
        );
      });
  };

  const renderContent = (setFieldValue) => {
    if (filteredData?.length) {
      return renderTableRows(setFieldValue);
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };

  const renderActivityTableRows = () => {
    return activityLog
      .slice()
      .reverse()
      .map((data) => {
        let updatedInfo = "";

        if (
          data?.previousRole !== data?.updatedRole ||
          data?.previousStatus !== data?.currentStatus
        ) {
          if (data?.updatedBy?.name) {
            updatedInfo = `${data?.updatedBy?.name}-${
              data?.dateTime
                ? PagesIndex.moment(data?.dateTime).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )
                : "-"
            }`;
          } else {
            updatedInfo = "-";
          }
        }
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            {updatedInfo !== "" && (
              <>
                <Index.TableCell
                  component="td"
                  variant="td"
                  scope="row"
                  className="table-td"
                >
                  <Index.Tooltip
                    title={`${
                      data?.previousRole !== data?.updatedRole
                        ? `${data?.updatedBy?.name} has updated Tag Name from ${
                            data?.previousRole
                          } to ${data?.updatedRole + " "}`
                        : ""
                    }
                    ${
                      data?.previousStatus !== data?.currentStatus
                        ? `${
                            data?.updatedBy?.name
                          } Status  has been updated from ${
                            !data?.currentStatus ? "Active" : "Deactive"
                          } to ${data?.currentStatus ? "Active" : "Deactive"} `
                        : ""
                    }`}
                    arrow
                    placement="top"
                    className="admin-tooltip"
                  >
                    <Index.Typography className="admin-table-data-text">
                      {data?.previousRole !== data?.updatedRole && (
                        <span>
                          <span className="span-bold">
                            {data?.updatedBy?.name}
                          </span>{" "}
                          has updated Role Name from{" "}
                          <span className="span-bold">
                            {data?.previousRole}
                          </span>{" "}
                          to{" "}
                          <span className="span-bold">
                            {data?.updatedRole + " "}
                          </span>
                        </span>
                      )}

                      {data?.previousStatus !== data?.currentStatus && (
                        <span>
                          <span className="span-bold">
                            {data?.updatedBy?.name}
                          </span>{" "}
                          Status has been updated from{" "}
                          <span className="span-bold">
                            {!data?.currentStatus ? "Active" : "Deactive"}
                          </span>{" "}
                          to&nbsp;
                          <span className="span-bold">
                            {data?.currentStatus ? "Active" : "Deactive"}
                          </span>
                        </span>
                      )}
                    </Index.Typography>
                  </Index.Tooltip>
                </Index.TableCell>

                <Index.TableCell
                  component="td"
                  variant="td"
                  className="table-td"
                >
                  {updatedInfo}
                </Index.TableCell>
              </>
            )}
          </Index.TableRow>
        );
      });
  };

  const renderActivityLogContent = () => {
    return (
      <>
        {!activityLoading &&
          activityLog?.length > 0 &&
          renderActivityTableRows()}
        {!activityLoading &&
          (!activityLog ||
            activityLog.length === 0 ||
            filterLog.length === 0) && <PagesIndex.DataNotFound />}
      </>
    );
  };
  const ScrollIntoView = () => {
    // Scroll to the first error when errors change
    const { errors, isSubmitting } = PagesIndex.useFormikContext(); // Extracting errors from the Formik context
    useEffect(() => {
      if (errors && Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        let scrollDiv = document.getElementById("scrollDiv");
        const firstErrorElement = document.querySelector(
          `[id="${firstErrorField}"]`
        );
        if (firstErrorElement) {
          scrollDiv.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          firstErrorElement.focus();
        }
      }
    }, [isSubmitting]);
  };
  const renderRolePermistionModal = () => {
    return modules?.map((data) => {
      return (
        <Index.Box className="role-permission-flex" key={data?.tag}>
          <Index.Box className="role-permission-left-main">
            <Index.Typography className="role-permission-name">
              {data?.title}
            </Index.Typography>
          </Index.Box>
          <Index.Box className="role-permission-right-main">
            <Index.Box className="role-permission-check-flex">
              <Index.Box className="role-permission-check-main">
                <Index.Box className="admin-checkbox-main">
                  <BpCheckbox
                    checked={permissions?.includes(`${data?.tag}_view`)}
                    onChange={() => onChangeCheckBox(`${data?.tag}_view`)}
                  />
                  <Index.Typography className="admin-checkbox-lable">
                    View
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="role-permission-check-main">
                <Index.Box className="admin-checkbox-main">
                  <BpCheckbox
                    checked={permissions?.includes(`${data?.tag}_add`)}
                    onChange={() => onChangeCheckBox(`${data?.tag}_add`)}
                  />
                  <Index.Typography className="admin-checkbox-lable">
                    Add
                  </Index.Typography>
                </Index.Box>
              </Index.Box>

              <Index.Box className="role-permission-check-main">
                <Index.Box className="admin-checkbox-main">
                  <BpCheckbox
                    checked={permissions?.includes(`${data?.tag}_log`)}
                    onChange={() => onChangeCheckBox(`${data?.tag}_log`)}
                  />
                  <Index.Typography className="admin-checkbox-lable">
                    Log
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="role-permission-check-main">
                <Index.Box className="admin-checkbox-main">
                  <BpCheckbox
                    checked={permissions?.includes(`${data?.tag}_edit`)}
                    onChange={() => onChangeCheckBox(`${data?.tag}_edit`)}
                  />
                  <Index.Typography className="admin-checkbox-lable">
                    Edit
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="role-permission-check-main">
                <Index.Box className="admin-checkbox-main">
                  <BpCheckbox
                    checked={permissions?.includes(`${data?.tag}_delete`)}
                    onChange={() => onChangeCheckBox(`${data?.tag}_delete`)}
                  />
                  <Index.Typography className="admin-checkbox-lable">
                    Delete
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              {data?.tag === "upload_video" && (
                <Index.Box className="role-permission-check-main">
                  <Index.Box className="admin-checkbox-main">
                    <BpCheckbox
                      checked={permissions?.includes(`${data?.tag}_view_all`)}
                      onChange={() => onChangeCheckBox(`${data?.tag}_view_all`)}
                    />
                    <Index.Typography className="admin-checkbox-lable">
                      View All
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              )}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      );
    });
  };

  const renderAddEditModal = (
    values,
    errors,
    touched,
    handleChange,
    handleSubmit
  ) => {
    return (
      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <form
          noValidate="novalidate"
          onSubmit={(e) => {
            e.preventDefault();
            if (permissions.length) {
              setPermissionsError("");
            } else {
              setPermissionsError("Please select at least one permission");
            }
            handleSubmit(e);
          }}
        >
          <Index.Box
            sx={style}
            className="admin-add-role-modal-inner admin-modal-inner "
          >
            <Index.Box className="admin-modal-header">
              <Index.Typography
                id="modal-modal-title"
                className="admin-modal-title"
                variant="h6"
                component="h2"
              >
                {addOrEdit} Role
              </Index.Typography>
              {/* <Index.Button className="modal-close-btn" onClick={handleClose}>
                <img
                  src={PagesIndex.Svg.closeblack}
                  className="admin-modal-close-icon"
                  alt="Close"
                />
              </Index.Button> */}
            </Index.Box>
            <Index.Box
              className="admin-modal-hgt-scroll cus-scrollbar"
              id="scrollDiv"
            >
              {/* <ScrollIntoView /> */}
              <Index.Box className="add-role-main">
                <Index.Box className="add-role-content-main">
                  <Index.Box className="admin-input-box admin-add-user-input">
                    <Index.FormHelperText className="admin-form-lable">
                      Role<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        name="role"
                        fullWidth
                        id="role"
                        className="admin-form-control"
                        placeholder="Enter Role"
                        onChange={handleChange}
                        value={values?.role}
                        error={errors?.role && touched?.role}
                        inputProps={{ maxLength: 30 }}
                      />
                      <Index.FormHelperText className="admin-error-text">
                        {errors?.role && touched?.role ? errors?.role : null}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="add-permission-section admin-input-box">
                  <Index.Typography className="add-permission-title">
                    Permissions<span className="astrick-sing">*</span>
                  </Index.Typography>
                  <Index.Box className="role-permission-main">
                    <Index.Box className="role-permission-flex">
                      <Index.Box className="role-permission-left-main">
                        <Index.Typography className="role-permission-name">
                          All
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="role-permission-right-main">
                        <Index.Box className="role-permission-check-flex">
                          <Index.Box className="role-permission-check-main">
                            <Index.Box className="admin-checkbox-main">
                              <BpCheckbox
                                checked={
                                  permissions.filter((data) =>
                                    data?.includes("_view")
                                  ).length === modules.length
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    checkUncheckAllType("add", "_view");
                                  } else {
                                    checkUncheckAllType("remove", "_view");
                                  }
                                }}
                              />
                              <Index.Typography className="admin-checkbox-lable">
                                View
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="role-permission-check-main">
                            <Index.Box className="admin-checkbox-main">
                              <BpCheckbox
                                checked={
                                  permissions.filter((data) =>
                                    data?.includes("_add")
                                  ).length === modules.length
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    checkUncheckAllType("add", "_add");
                                  } else {
                                    checkUncheckAllType("remove", "_add");
                                  }
                                }}
                              />
                              <Index.Typography className="admin-checkbox-lable">
                                Add
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box className="role-permission-check-main">
                            <Index.Box className="admin-checkbox-main">
                              <BpCheckbox
                                checked={
                                  permissions.filter((data) =>
                                    data?.includes("_log")
                                  ).length === modules.length
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    checkUncheckAllType("add", "_log");
                                  } else {
                                    checkUncheckAllType("remove", "_log");
                                  }
                                }}
                              />
                              <Index.Typography className="admin-checkbox-lable">
                                Log
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box className="role-permission-check-main">
                            <Index.Box className="admin-checkbox-main">
                              <BpCheckbox
                                checked={
                                  permissions.filter((data) =>
                                    data?.includes("_edit")
                                  ).length === modules.length
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    checkUncheckAllType("add", "_edit");
                                  } else {
                                    checkUncheckAllType("remove", "_edit");
                                  }
                                }}
                              />
                              <Index.Typography className="admin-checkbox-lable">
                                Edit
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="role-permission-check-main">
                            <Index.Box className="admin-checkbox-main">
                              <BpCheckbox
                                checked={
                                  permissions.filter((data) =>
                                    data?.includes("_delete")
                                  ).length === modules.length
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    checkUncheckAllType("add", "_delete");
                                  } else {
                                    checkUncheckAllType("remove", "_delete");
                                  }
                                }}
                              />
                              <Index.Typography className="admin-checkbox-lable">
                                Delete
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    {renderRolePermistionModal()}
                    <Index.FormHelperText className="admin-error-text">
                      {permissionsError}
                    </Index.FormHelperText>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="modal-footer">
              <Index.Box className="admin-modal-user-btn-flex">
                <Index.Box className="admin-discard-btn-main border-btn-main">
                  <Index.Button
                    className="admin-discard-user-btn border-btn"
                    onClick={handleClose}
                    disabled={buttonSpinner}
                  >
                    Discard
                  </Index.Button>
                </Index.Box>
                <Index.Box className="admin-save-btn-main btn-main-primary">
                  <Index.Button
                    className={`${
                      id ? "update-btn" : "save-btn"
                    } admin-save-user-btn btn-primary`}
                    type="submit"
                    disabled={buttonSpinner}
                  >
                    {buttonSpinner ? (
                      <PagesIndex.Spinner />
                    ) : (
                      <>
                        <img
                          src={PagesIndex.Svg.save}
                          className="admin-user-save-icon"
                          alt="Save"
                        />
                        <span>{id ? "Update" : "Add"}</span>
                      </>
                    )}
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </form>
      </Index.Modal>
    );
  };

  const renderDeleteModal = () => {
    return (
      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box>
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              Do you really want to delete this role? This process cannot be
              undone.
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseDelete}
                disabled={deleteButtonSpinner}
              >
                Cancel
              </Index.Button>
              <Index.Button
                className="delete-user admin-modal-delete-btn btn"
                onClick={handleDeleteRole}
                disabled={deleteButtonSpinner}
              >
                {deleteButtonSpinner ? (
                  <PagesIndex.Spinner />
                ) : (
                  <span>Delete</span>
                )}
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    );
  };

  const renderActivityLogModal = () => {
    return (
      <Index.Modal
        open={openLogModal}
        onClose={handleCloseLogModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-log-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="admin-modal-title"
              variant="h6"
              component="h2"
            >
              {" "}
              Activity Log
            </Index.Typography>
            <Index.Button
              className="modal-close-btn"
              onClick={handleCloseLogModal}
            >
              <img
                src={PagesIndex.Svg.closeblack}
                className="admin-modal-close-icon"
                alt="Close"
              />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="common-card">
              <Index.Box className="page-table-main role-activity-table-main">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  {activityLoading ? (
                    <PagesIndex.Loader />
                  ) : (
                    <Index.Table aria-label="simple table" className="table">
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="70%"
                          >
                            Activity Log
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="30%"
                          >
                            Updated Date & Time
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {renderActivityLogContent()}
                      </Index.TableBody>
                    </Index.Table>
                  )}
                </Index.TableContainer>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    );
  };

  return (
    <PagesIndex.Formik
      enableReinitialize
      onSubmit={handleAddEditRole}
      initialValues={initialValues}
      validationSchema={
        addOrEdit === "Add"
          ? PagesIndex.rolePermissionSchema
          : PagesIndex.rolePermissionEditSchema
      }
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <>
          <Index.Box className="admin-dashboard-content admin-user-list-content">
            <Index.Box className="admin-user-list-flex admin-page-title-main">
              <Index.Typography
                className="admin-page-title admin-user-list-page-title"
                component="h2"
                variant="h2"
              >
                Role List
              </Index.Typography>
              <Index.Box className="admin-userlist-btn-flex">
                <Index.Box className="admin-search-main">
                  <Index.Box className="admin-search-box">
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="admin-form-control"
                        placeholder="Search"
                        value={searchValue}
                        onChange={requestSearch}
                      />
                      <img
                        src={PagesIndex.Svg.search}
                        className="admin-search-grey-img admin-icon"
                        alt="search"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                {adminDetails?.roleId?.permissions?.includes("role_add") && (
                  <Index.Box className="admin-userlist-inner-btn-flex">
                    <Index.Box className="admin-adduser-btn-main btn-main-primary">
                      <Index.Button
                        className="admin-adduser-btn btn-primary"
                        onClick={() => {
                          handleOpen("Add");
                          setFieldValue("role", "");
                          setPermissions([]);
                        }}
                      >
                        <img
                          src={PagesIndex.Svg.plus}
                          className="admin-plus-icon"
                          alt="plus"
                        />
                        <span>Add Role</span>
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                )}
              </Index.Box>
            </Index.Box>

            <Index.Box className="common-card">
              <Index.Box
                className={`admin-role-table-main ${
                  loading ? "" : "page-table-main"
                }`}
              >
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  {loading ? (
                    <PagesIndex.Loader />
                  ) : (
                    <Index.Table aria-label="simple table" className="table">
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="20%"
                          >
                            Role Name
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="12%"
                            align="right"
                          >
                            Created Date
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="12%"
                            align="right"
                          >
                            Created By
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="12%"
                            align="right"
                          >
                            Updated By
                          </Index.TableCell>

                          {(adminDetails?.roleId?.permissions?.includes(
                            "role_edit"
                          ) ||
                            adminDetails?.roleId?.permissions?.includes(
                              "role_delete"
                            )) && (
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="6%"
                              align="right"
                            >
                              Status
                            </Index.TableCell>
                          )}

                          {(adminDetails?.roleId?.permissions?.includes(
                            "role_edit"
                          ) ||
                            adminDetails?.roleId?.permissions?.includes(
                              "role_log"
                            ) ||
                            adminDetails?.roleId?.permissions?.includes(
                              "role_delete"
                            )) && (
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="right"
                              width="10%"
                            >
                              Action
                            </Index.TableCell>
                          )}
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {renderContent(setFieldValue)}
                      </Index.TableBody>
                    </Index.Table>
                  )}
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="admin-pagination-main">
                {filteredData?.length ? (
                  <Index.Pagination
                    page={currentPage}
                    count={Math.ceil(filteredData?.length / 10)}
                    onChange={(event, newPage) => setCurrentPage(newPage)}
                    variant="outlined"
                    shape="rounded"
                    className="admin-pagination"
                  />
                ) : (
                  ""
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {renderAddEditModal(
            values,
            errors,
            touched,
            handleChange,
            handleSubmit
          )}

          {renderDeleteModal()}

          {renderActivityLogModal()}
          <PagesIndex.ChangeStatusModal
            data={"role"}
            statusButtonSpinner={statusButtonSpinner}
            openStatusModal={openStatusModal}
            handleCloseStatusModal={handleCloseStatusModal}
            handleChangeStatus={handleStatus}
          />
        </>
      )}
    </PagesIndex.Formik>
  );
}
