import React, { useCallback, useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./slots.css";
import dayjs from "dayjs";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

// for custom switch design

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const TableData = ({
  filteredData,
  handleOpenDelete,
  currentPage,
  handleChangeStatusAd,
}) => {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const params = PagesIndex.useParams();
  const { page } = params;
  const navigate = PagesIndex.useNavigate();
  return filteredData?.length ? (
    filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data, index) => {
        return (
          <Index.TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            key={data?._id}
          >
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={data?.slotName}
                arrow
                placement="top"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  <span className="news-id-text"> {data?.slotNo}</span>{" "}
                  {data?.slotName}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {data?.type === "NonFCT" ? "Non FCT" : "FCT"}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {data?.createdBy?.name || "-"}
                {"-"}
                {PagesIndex.moment(data?.createdAt).format(
                  "DD/MM/YYYY hh:mm A"
                )}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {data?.updatedBy?.name || "-"}
                {"-"}
                {PagesIndex.moment(data?.updatedAt).format(
                  "DD/MM/YYYY hh:mm A"
                )}
              </Index.Typography>
            </Index.TableCell>
            {/* <Index.TableCell
              component="td"
              variant="td"
              className="table-td"
              align="right"
            >
              <Index.Tooltip
                title={data?.isActive ? "Active" : "Deactive"}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Box className="admin-switch-main">
                  <Index.FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    className="admin-switch-lable"
                    checked={data?.isActive}
                    onChange={() => handleChangeStatusAd(data?._id)}
                  />
                </Index.Box>
              </Index.Tooltip>
            </Index.TableCell> */}
            {adminDetails?.roleId?.permissions?.includes("slot_edit") && (
              <Index.TableCell component="td" variant="td" className="table-td">
                <Index.Box className="admin-table-data-btn-flex ">
                  <Index.Tooltip
                    title="Edit"
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                  >
                    <Index.Button
                      className="admin-table-data-btn"
                      onClick={() => {
                        navigate(`/admin/edit-slot/${page}/${data?._id}`, {
                          state: {
                            data: data,
                          },
                        });
                      }}
                    >
                      <img
                        src={PagesIndex.Svg.blueedit}
                        className="admin-icon"
                        alt="Edit"
                      ></img>
                    </Index.Button>
                  </Index.Tooltip>
                </Index.Box>
              </Index.TableCell>
            )}
          </Index.TableRow>
        );
      })
  ) : (
    <PagesIndex.DataNotFound />
  );
};

export default function Slot() {
  const formRef = useRef();
  const params = PagesIndex.useParams();
  const { page } = params;
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const navigate = PagesIndex.useNavigate();
  const initialValues = {
    startDate: null,
    endDate: null,
    status: "",
    category: null,
    subCategory: null,
  };
  const [data, setData] = useState([]);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [id, setId] = useState("");
  // delete modal
  const [openDelete, setOpenDelete] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const handleOpenStatusModal = (id) => {
    setId(id);
    setOpenStatusModal(true);
  };
  const handleCloseStatusModal = () => {
    setId("");
    setOpenStatusModal(false);
  };
  const handleStatus = () => {
    setStatusButtonSpinner(true)
    PagesIndex.apiGetHandler(
      PagesIndex.Api.ISACTIVE_ADVERTISER + "/" + id
    ).then((res) => {
      setStatusButtonSpinner(false)
      if (res.status === 200) {
        handleGetSlots();
        handleCloseStatusModal();
        PagesIndex.toasterSuccess(res?.message);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  // delete modal
  const handleOpenDelete = (id) => {
    setId(id);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };

  const handleDeleteAd = () => {
    setDeleteButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(
      PagesIndex.Api.DELETE_ADVERTISER,
      urlEncoded
    ).then((res) => {
      setDeleteButtonSpinner(false)
      if (res.status === 200) {
        handleCloseDelete();
        handleGetSlots();
        PagesIndex.toasterSuccess(res?.message);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const generateCSV = async () => {
    const headers = [
      "Sr. No.",
      "Client No.",
      "Account Director",
      "Group Head",
      "Manager",
      "Company Name",
      "Address 1",
      "Address 2",
      "State",
      "Zone",
      "City",
      "Brand Name",
      "Brand Slogan/Tagline",
      "Brand Ambassador",
      "Category",
      "Sub Category",
      "Normal Advertising Period",
      "Media Platform Use",
      "First Name",
      "Last Name",
      "Designation",
      "Agency Name",
      "Contact Number",
      "Email Id",
      "FCT",
      "NFCT",
      "Favourite Celebrities",
      "Passions",
      "Social Causes",
      "Impact Or Show Partnership",
      "Family Details",
    ];
    let modifiedData = filteredData.map((data, index) => {
      let objData = [
        index + 1,
        data?.advNo ? data?.advNo?.replaceAll(",", "") : "-",
        data?.accDirector?.name
          ? data?.accDirector?.name?.replaceAll(",", "|")
          : "-",
        data?.grpHead?.name ? data?.grpHead?.name?.replaceAll(",", "|") : "-",
        data?.manager?.name ? data?.manager?.name?.replaceAll(",", "|") : "-",
        data?.compName ? data?.compName?.replaceAll(",", "|") : "-",
        data?.add1 ? data?.add1?.replaceAll(",", "|") : "-",
        data?.add2 ? data?.add2?.replaceAll(",", "|") : "-",
        data?.state?.stateName
          ? data?.state?.stateName?.replaceAll(",", "|")
          : "-",
        data?.zone?.name ? data?.zone?.name?.replaceAll(",", "|") : "-",
        data?.city ? data?.city?.replaceAll(",", "|") : "-",
        data?.brand ? data?.brand?.replaceAll(",", "|") : "-",
        data?.brandSlogan ? data?.brandSlogan?.replaceAll(",", "|") : "-",
        data?.brandAmbassador
          ? data?.brandAmbassador?.replaceAll(",", "|")
          : "-",
        data?.category?.name ? data?.category?.name?.replaceAll(",", "|") : "-",
        data?.subCategory?.name
          ? data?.subCategory?.name?.replaceAll(",", "|")
          : "-",
        data?.advPeriod?.length
          ? data?.advPeriod?.toString().replaceAll(",", "|")
          : "-",
        data?.mediaPlatform ? data?.mediaPlatform?.replaceAll(",", "|") : "-",
        data?.firstName ? data?.firstName?.replaceAll(",", "|") : "-",
        data?.lastName ? data?.lastName?.replaceAll(",", "|") : "-",
        data?.designation ? data?.designation?.replaceAll(",", "|") : "-",
        data?.agency ? data?.agency?.replaceAll(",", "|") : "-",
        data?.contact ? data?.contact?.replaceAll(",", "|") : "-",
        data?.email ? data?.email?.replaceAll(",", "|") : "-",
        data?.fct ? JSON.stringify(data?.fct) : "-",
        data?.nfct ? JSON.stringify(data?.nfct) : "-",
        data?.celebrities
          ? data?.celebrities?.toString()?.replaceAll(",", "|")
          : "-",
        data?.passionate
          ? data?.passionate?.toString()?.replaceAll(",", "|")
          : "-",
        data?.socialCause
          ? data?.socialCause?.toString()?.replaceAll(",", "|")
          : "-",
        data?.partenership ? data?.partenership?.replaceAll(",", "|") : "-",
        data?.familyDetails ? data?.familyDetails?.replaceAll(",", "|") : "-",
      ];
      return objData.join(",");
    });

    exportData(
      [[headers, ...modifiedData].join("\n")],
      `${PagesIndex.moment().format("DD-MM-YYYY hh:mm:ss A")}_slot_list.csv`,
      "text/csv"
    );
  };
  const handleGetSlots = useCallback(() => {
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_SLOTS_BY_TYPE + "/" + page
    ).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setData(res?.data);
        setFilteredData(res?.data);
      }
    });
  }, [page]);
  useEffect(() => {
    setLoading(true);
    handleGetSlots();
  }, [handleGetSlots]);
  const requestSearch = (e) => {
    setSearchValue(e.target.value);
    let filteredData = data.filter(
      (data) =>
        data?.slotNo
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.slotName
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.type
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.updatedBy?.name
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.createdBy?.name
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase())
    );
    setCurrentPage(1);
    setFilteredData(filteredData);
  };
  return (
    <>
      <Index.Box className="admin-dashboard-content admin-user-management-content">
        <Index.Box className="admin-user-list-flex admin-page-title-main">
          <Index.Typography
            className="admin-page-title admin-user-list-page-title"
            component="h2"
            variant="h2"
          >
            {page === "FCT" ? "FCT" : "Non FCT"} Template List
          </Index.Typography>
          <Index.Box className="admin-userlist-btn-flex">
            <Index.Box className="admin-search-main">
              <Index.Box className="admin-search-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="admin-form-control"
                    placeholder="Search"
                    value={searchValue}
                    onChange={requestSearch}
                  />
                  <img
                    src={PagesIndex.Svg.search}
                    className="admin-search-grey-img admin-icon"
                    alt="search"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="admin-userlist-inner-btn-flex">
              {adminDetails?.roleId?.permissions?.includes("slot_add") && (
                <Index.Box className="admin-adduser-btn-main btn-main-primary">
                  <Index.Button
                    className="admin-adduser-btn btn-primary"
                    onClick={() => {
                      navigate(`/admin/add-slot/${page}`);
                    }}
                  >
                    <img
                      src={PagesIndex.Svg.plus}
                      className="admin-plus-icon"
                      alt="plus"
                    />
                    <span>Add Template</span>
                  </Index.Button>
                </Index.Box>
              )}
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="common-card">
          <Index.Box className={`admin-slot-table-main page-table-main`}>
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              {loading ? (
                <PagesIndex.Loader />
              ) : (
                <Index.Table aria-label="simple table" className="table">
                  <Index.TableHead className="table-head">
                    <Index.TableRow className="table-row">
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="12%"
                      >
                        Template
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="12%"
                      >
                        Type
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="12%"
                      >
                        Created By
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="12%"
                      >
                        Updated By
                      </Index.TableCell>
                      {/* <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="3%"
                        align="right"
                      >
                        Status
                      </Index.TableCell> */}
                      {adminDetails?.roleId?.permissions?.includes(
                        "slot_edit"
                      ) && (
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="5%"
                            align="right"
                          >
                            Action
                          </Index.TableCell>
                        )}
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="table-body">
                    {loading ? (
                      <PagesIndex.Loader />
                    ) : (
                      <TableData
                        adDataList={data}
                        filteredData={filteredData}
                        currentPage={currentPage}
                        handleOpenDelete={handleOpenDelete}
                        handleChangeStatusAd={handleOpenStatusModal}
                      />
                    )}
                  </Index.TableBody>
                </Index.Table>
              )}
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="admin-pagination-main">
            {data.length ? (
              <Index.Pagination
                page={currentPage}
                count={Math.ceil(data.length / 10)}
                onChange={(event, newPage) => setCurrentPage(newPage)}
                variant="outlined"
                shape="rounded"
                className="admin-pagination"
              />
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box>
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              Do you really want to delete this client? This process cannot be
              undone.
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseDelete}
                disabled={deleteButtonSpinner}
              >
                Cancel
              </Index.Button>
              <Index.Button
                className="delete-user admin-modal-delete-btn btn"
                onClick={handleDeleteAd}
                disabled={deleteButtonSpinner}
              >
                {deleteButtonSpinner ? <PagesIndex.Spinner /> : <span>Delete</span>
                }
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      <PagesIndex.ChangeStatusModal
        data={"client"}
        statusButtonSpinner={statusButtonSpinner}
        openStatusModal={openStatusModal}
        handleCloseStatusModal={handleCloseStatusModal}
        handleChangeStatus={handleStatus}
      />
    </>
  );
}
