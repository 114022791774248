import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

// for modal design
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function UploadDocuments(props) {
  const params = PagesIndex.useParams();
  const { id } = params;
  const formRefDocument = useRef();
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const { data, handleGetDocumentTypes } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [docName, setDocName] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
  const [openUploadDocument, setOpenUploadDocument] = useState(false);
  const initialValuesDocumentUpload = {
    documentType: "",
    image: "",
  };
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setDocName(id);
  };
  const handleCloseDelete = () => {
    setDocName("");
    setOpenDelete(false);
  };
  const handleAddDocument = (values) => {
    setButtonSpinner(true)
    const formData = new FormData();
    formData.append("clientId", id);
    formData.append("documentsName", values?.documentType);
    formData.append("profile", values?.image);
    PagesIndex.apiPostHandler(PagesIndex.Api.UPLOAD_CLIENT_DOCUMENT, formData)
      .then((res) => {
        setButtonSpinner(false)
        if (res.status === 201 || res.status === 200) {
          handleGetDocumentTypes();
          handleCloseUploadDocument();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };
  const handleDocumentDelete = () => {
    setDeleteButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", docName);
    PagesIndex.apiPostHandler(PagesIndex.Api.DELETE_DOCUMENT, urlEncoded).then(
      (res) => {
        setDeleteButtonSpinner(false)
        if (res.status === 200) {
          handleCloseDelete();
          handleGetDocumentTypes();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const handleDownloadVideo = async (data) => {
    const a = document.createElement("a");
    a.setAttribute("download", data?.documentsName); // Replace with the desired filename for the downloaded file
    a.href = process.env.REACT_APP_IMAGE_ENDPOINT + data?.documentsFile;
    a.click();
    window.URL.revokeObjectURL(
      process.env.REACT_APP_IMAGE_ENDPOINT + data?.documentsFile
    );
  };
  const handleOpenUploadDocument = () => {
    setOpenUploadDocument(true);
  };
  const handleCloseUploadDocument = () => {
    formRefDocument.current.resetForm();
    setOpenUploadDocument(false);
  };
  const renderImage = (values) => {
    const isUploadImg = values.image;
    const isImageBlob = values.image instanceof Blob;
    let src;
    let image = values.image.type?.includes("image");
    let video = values.image.type?.includes("video");
    let xmlimage = values.image.type?.includes("xml");
    if (image == false && video == false) {
      src = PagesIndex.Svg.pdfIcon;
    } else if (isImageBlob) {
      src = URL.createObjectURL(values.image);
    } else {
      src = PagesIndex.Svg.fileupload;
    }
    return (
      <img
        className={
          image && !xmlimage && isUploadImg
            ? "upload-img"
            : "upload-profile-img"
        }
        src={src}
        alt="document"
      />
    );
  };
  const ScrollIntoView = () => {
    // Scroll to the first error when errors change
    const { errors, isSubmitting } = PagesIndex.useFormikContext(); // Extracting errors from the Formik context
    useEffect(() => {
      if (errors && Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        let scrollDiv = document.getElementById("scrollDiv");
        const firstErrorElement = document.querySelector(
          `[id="${firstErrorField}"]`
        );
        if (firstErrorElement) {
          scrollDiv.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          firstErrorElement.focus();
        }
      }
    }, [isSubmitting]);
  };
  return (
    <Index.Box>
      <Index.Box className="dash-slider-row">
        <Index.Box className="admin-user-list-flex admin-page-title-main">
          <Index.Box className="admin-title-left"></Index.Box>
          {adminDetails?.roleId?.permissions?.includes("clients_add") && (
            <Index.Box className="admin-userlist-inner-btn-flex">
              <Index.Box className="admin-adduser-btn-main btn-main-primary">
                <Index.Button
                  className="admin-adduser-btn btn-primary"
                  onClick={() => {
                    handleOpenUploadDocument();
                  }}
                >
                  <img
                    src={PagesIndex.Svg.plus}
                    className="admin-plus-icon"
                    alt="plus"
                  />
                  <span>Upload Documents</span>
                </Index.Button>
              </Index.Box>
            </Index.Box>
          )}
        </Index.Box>
        <Index.Box className="page-table-main common-card">
          <Index.TableContainer
            component={Index.Paper}
            className="table-container"
          >
            <Index.Table aria-label="simple table" className="table">
              <Index.TableHead className="table-head">
                <Index.TableRow className="table-row">
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="47%"
                  >
                    Document Name
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="47%"
                  >
                    Upload Date & Time
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="3%"
                  >
                    Action
                  </Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              <Index.TableBody className="table-body">
                {data?.length ? (
                  data
                    ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
                    ?.map((data) => {
                      return (
                        <Index.TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={data?.documentsName}
                        >
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td border-btn-main"
                          >
                            <span className="news-id-text">
                              {data?.documentsName || "-"}
                            </span>
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td "
                          >
                            {data?.updatedAt
                              ? `${data?.createdBy?.name
                                ? `${data?.createdBy?.name}-`
                                : ""
                              }${PagesIndex.moment(data?.updatedAt)?.format(
                                "DD/MM/YYYY hh:mm A"
                              )}`
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td "
                          >
                            <Index.Box className="admin-table-data-btn-flex">
                              <Index.Tooltip
                                title="Download"
                                arrow
                                placement="bottom"
                                className="admin-tooltip"
                              >
                                <Index.Button
                                  className="admin-table-data-btn"
                                  onClick={() => handleDownloadVideo(data)}
                                >
                                  <img
                                    src={PagesIndex.Svg.download}
                                    className="admin-icon"
                                    alt="Download"
                                  ></img>
                                </Index.Button>
                              </Index.Tooltip>
                              <Index.Tooltip
                                title="Delete"
                                arrow
                                placement="bottom"
                                className="admin-tooltip"
                              >
                                <Index.Button
                                  className="admin-table-data-btn"
                                  onClick={() => handleOpenDelete(data?._id)}
                                >
                                  <img
                                    src={PagesIndex.Svg.trash}
                                    className="admin-icon"
                                    alt="Delete"
                                  ></img>
                                </Index.Button>
                              </Index.Tooltip>
                            </Index.Box>
                          </Index.TableCell>
                        </Index.TableRow>
                      );
                    })
                ) : (
                  <PagesIndex.DataNotFound />
                )}
              </Index.TableBody>
            </Index.Table>
          </Index.TableContainer>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={openUploadDocument}
        onClose={handleCloseUploadDocument}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <PagesIndex.Formik
          enableReinitialize
          onSubmit={handleAddDocument}
          initialValues={initialValuesDocumentUpload}
          validationSchema={PagesIndex.uploadDocumentSchema}
          innerRef={formRefDocument}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} noValidate="novalidate">
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Upload Document
                  </Index.Typography>
                </Index.Box>
                <Index.Box
                  className="admin-modal-hgt-scroll cus-scrollbar"
                  id="scrollDiv"
                >
                  {/* <ScrollIntoView /> */}
                  <Index.Box className="admin-input-box admin-add-user-input">
                    <Index.FormHelperText className="admin-form-lable">
                      Document Name
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.Box className="admin-dropdown-box">
                        <Index.FormControl className="admin-form-control">
                          <Index.TextField
                            name="documentType"
                            fullWidth
                            id="fullWidth"
                            className="admin-form-control"
                            placeholder="Enter Document Name"
                            autoComplete="off"
                            onChange={handleChange}
                            value={values.documentType}
                            inputProps={{ maxLength: 50 }}
                          />
                        </Index.FormControl>
                      </Index.Box>
                    </Index.Box>
                    {touched.documentType && errors.documentType && (
                      <PagesIndex.FormHelperText className="admin-error-text">
                        {errors.documentType}
                      </PagesIndex.FormHelperText>
                    )}
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Document<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    {values?.image ? (
                      <Index.Box className="file-upload-main thumbnail-box admin-file-browse-btn-main ">
                        <Index.Box className="upload-news-img-box">
                          {renderImage(values)}
                        </Index.Box>
                        <Index.Button
                          variant="contained"
                          component="label"
                          className="admin-file-browse-btn"
                        >
                          <img
                            src={PagesIndex.Svg.whiteedit}
                            className="admin-browse-icon-img"
                            alt="upload img"
                          ></img>
                          <input
                            hidden
                            accept="image/*, .pdf"
                            id="image"
                            name="image"
                            type="file"
                            onChange={(e) => {
                              if (e.target.files?.length !== 0) {
                                setFieldValue("image", e.target.files[0]);
                              }
                            }}
                          />
                        </Index.Button>
                      </Index.Box>
                    ) : (
                      <Index.Box className="file-upload-main thumbnail-box">
                        <Index.Button
                          variant="contained"
                          component="label"
                          className="file-upload-button"
                        >
                          {renderImage(values)}
                          <input
                            hidden
                            accept="image/*, .pdf"
                            id="image"
                            name="image"
                            type="file"
                            onChange={(e) => {
                              if (e.target.files?.length !== 0) {
                                setFieldValue("image", e.target.files[0]);
                              }
                            }}
                          />
                        </Index.Button>
                      </Index.Box>
                    )}
                    {touched.image && errors.image && (
                      <PagesIndex.FormHelperText className="admin-error-text">
                        {errors.image}
                      </PagesIndex.FormHelperText>
                    )}
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="admin-discard-btn-main border-btn-main">
                      <Index.Button
                        className="admin-discard-user-btn border-btn"
                        onClick={handleCloseUploadDocument}
                        disabled={buttonSpinner}
                      >
                        Discard
                      </Index.Button>
                    </Index.Box>
                    <Index.Box className="admin-save-btn-main btn-main-primary">
                      <Index.Button
                        className="admin-save-user-btn btn-primary upload-btn"
                        type="submit"
                        disabled={buttonSpinner}
                      >
                        {buttonSpinner ? <PagesIndex.Spinner /> :(
                          <>
                            <img
                              src={PagesIndex.Svg.save}
                              className="admin-user-save-icon"
                              alt="Save"
                            />
                            <span>Upload</span>
                          </>
                        )}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>
      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box>
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              Do you really want to delete this uploaded document? This process
              cannot be undone.
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseDelete}
                disabled={deleteButtonSpinner}
              >
                Cancel
              </Index.Button>
              <Index.Button
                className="delete-user admin-modal-delete-btn btn"
                onClick={handleDocumentDelete}
                disabled={deleteButtonSpinner}
              >
                {deleteButtonSpinner ? <PagesIndex.Spinner /> : <span>Delete</span>
                }
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </Index.Box>
  );
}
