import React from "react";
import Index from "../../../Index";
import { useLocation } from "react-router";
import PagesIndex from "../../../../component/PagesIndex";

export default function MovieActivityLog() {
  const location = useLocation();
  const oldData = location?.state?.log;
  const oldIndex = location?.state?.index;

  let log = oldData?.slice()?.reverse()?.find((e, index) => index == oldIndex)

  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-view-page-title"
          component="h2"
          variant="h2"
        >
          Movie Activity Details
        </Index.Typography>
      </Index.Box>
      <Index.Box className="view-details-card">
        <Index.Box className="movie-activity-log-main common-card">
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Movie Title:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.title ? log?.title : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Release Date:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.releaseDate
                    ? PagesIndex.moment(log?.releaseDate).format("DD/MM/YYYY")
                    : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Duration:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.duration ? log?.duration : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Censor Rating:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.censorRating ? log?.censorRating : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Language:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.language ? log?.language : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Type:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.type ? log?.type : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Categories:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                {log?.categoryId?.map((category) => {
                  if (category?.name) {
                    return (
                      <Index.Typography
                        className="view-content-data"
                        key={category?._id}
                      >
                        <Index.Box className="view-category-flex-box">
                          <Index.Box className="view-category-box">
                            <Index.Typography className="view-content-data">
                              {category?.name}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Typography>
                    );
                  } else {
                    return null;
                  }
                })}
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Cast:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                {log?.cast?.map((cast) => {
                  if (cast?.castId?.name) {
                    return (
                      <Index.Typography
                        className="view-content-data"
                        key={cast?.castId?._id}
                      >
                        <Index.Box className="view-category-flex-box">
                          <Index.Box className="view-category-box">
                            <Index.Typography className="view-content-data">
                              {cast?.castId?.name}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Typography>
                    );
                  } else {
                    return null;
                  }
                })}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Description:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.description ? log?.description : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Movie Link:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.link ? log?.link : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Movie Trailer Link:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.trailerLink ? log?.trailerLink : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Status:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                {log?.previousIsActive ? "Active" : log?.isActive ? "Active" : "Deactive"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Thumbnail:
              </Index.Typography>
                {log?.image ? (
                  <img
                    src={process.env.REACT_APP_IMAGE_ENDPOINT + log?.image}
                    className="admin-activity-thumbnail-img"
                    alt="Thumbnail"
                  />
                ) : (
                  "-"
                )}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
