import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

export default function ViewSeasons() {
  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-view-page-title"
          component="h2"
          variant="h2"
        >
          Season Details
        </Index.Typography>
      </Index.Box>
      <Index.Box className="news-content-main">
        <Index.Typography className="news-name-text">
          Stranger Things Season 1
        </Index.Typography>
        <Index.Typography className="news-discription-text">
          The host invites famous personalities from various industries to
          interrogate them about controversies surrounding their personal and
          professional lives.
        </Index.Typography>
      </Index.Box>

      <Index.Box className="view-details-card">
        <Index.Box className="admin-file-view-card common-card">
          <Index.Box className="admin-file-view-main">
            <Index.Box className="highlight-box">
              <Index.Box className="highlight-flex">
                <img
                  src={PagesIndex.Svg.calendaricon}
                  className="calendar-icon"
                  alt=""
                />
                <Index.Typography className="highlight-content-lable">
                  Release Date:
                </Index.Typography>
                <Index.Typography className="highlight-content-data">
                  13-03-1992
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <img
              src={PagesIndex.Svg.noimgfound}
              className="admin-file-view-img"
              alt="upload img"
            />
          </Index.Box>
          <Index.Box className="admin-view-btn-flex">
            <Index.Box className="btn-main-primary">
              <Index.Button className="btn-primary">
                <img
                  src={PagesIndex.Svg.playicon}
                  className="admin-icon play-icon"
                  alt="View"
                />
                <span>Youtube</span>
              </Index.Button>
            </Index.Box>
            <Index.Box className="btn-main-secondary">
              <Index.Button className="btn-secondary">
                <img
                  src={PagesIndex.Svg.playicon}
                  className="admin-icon play-icon"
                  alt="View"
                />
                <span>Wowza</span>
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="common-card brif-content-card">
          <Index.Box className="view-movie-content-main brif-content-main">
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Durations:
                </Index.Typography>
                <Index.Typography className="view-content-data">
                  1 hours
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Episode No:
                </Index.Typography>
                <Index.Typography className="view-content-data">
                  33
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Cast/Crew:
                </Index.Typography>
                <Index.Typography className="view-content-data">
                  Prashant Kishor , Rajat Sharma
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Tags:
                </Index.Typography>
                <Index.Typography className="view-content-data">
                  Plitics
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Episode Link:
                </Index.Typography>
                <Index.Typography className="view-content-data">
                  htps:abcd.com
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-category-content-main">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Category/Genres:
              </Index.Typography>
              <Index.Box className="view-category-flex-box">
                <Index.Box className="view-category-box">
                  <Index.Typography className="view-content-data">
                    Drama
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="view-category-box">
                  <Index.Typography className="view-content-data">
                    Action
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="view-category-box">
                  <Index.Typography className="view-content-data">
                    Comedy
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="view-category-box">
                  <Index.Typography className="view-content-data">
                    Thriller
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
