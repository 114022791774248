import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function FeedBack() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);

  // delete modal
  const [openDelete, setOpenDelete] = useState(false);
  // const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  // add message modal
  const [openMessage, setOpenMessage] = useState(false);
  // const handleOpenMessage = () => setOpenMessage(true);
  const handleCloseMessage = () => setOpenMessage(false);

  const handleGetFeedback = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_FEEDBACK).then((res) => {
      if (res.status === 200) {
        setData(res.data);
        setFilteredData(res.data);
      } else {
        // PagesIndex.toasterError(res?.message);
      }
    });
  };

  useEffect(() => {
    handleGetFeedback();
  }, []);

  const requestSearch = (e) => {
    let result = data.filter((data) => {
      let fullName = (data?.firstName + " " + data?.lastName)
        ?.toLowerCase()
        .replace(/\s/g, "");
      let searchQuery = e?.target?.value
        ?.trim()
        ?.toLowerCase()
        .replace(/\s/g, "");

      let email = data?.email?.toLowerCase().includes(searchQuery);
      let number = data?.phoneNumber?.toString().includes(searchQuery);
      let subject = data?.subject?.toString().includes(searchQuery);

      const date = new Date(data?.createdAt);
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      let filterDate = formattedDate.includes(e?.target?.value);

      return (
        fullName.includes(searchQuery) ||
        email ||
        number ||
        filterDate ||
        subject
      );
    });
    setCurrentPage(1);
    setFilteredData(result);
  };

  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-page-title admin-user-list-page-title"
          component="h2"
          variant="h2"
        >
          FeedBack
        </Index.Typography>
        <Index.Box className="admin-userlist-btn-flex">
          <Index.Box className="admin-search-main">
            <Index.Box className="admin-search-box">
              <Index.Box className="admin-form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="admin-form-control"
                  placeholder="Search"
                  onChange={requestSearch}
                />
                <img
                  src={PagesIndex.Svg.search}
                  className="admin-search-grey-img admin-icon"
                  alt="search"
                ></img>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* <Index.Box className="admin-userlist-inner-btn-flex">
            <Index.Box className="admin-adduser-btn-main btn-main-primary">
              <Index.Button
                className="admin-adduser-btn btn-primary" onClick={handleOpenMessage}
              >
                <img
                  src={PagesIndex.Svg.plus}
                  className="admin-plus-icon"
                  alt="plus"
                />
                Add Message
              </Index.Button>
            </Index.Box>
            <Index.Box className="admin-adduser-btn-main btn-main-primary">
              <Index.Button
                className="admin-adduser-btn btn-primary"
              >
                <img
                  src={PagesIndex.Svg.plus}
                  className="admin-plus-icon"
                  alt="plus"
                />
                Export Data
              </Index.Button>
            </Index.Box>
          </Index.Box> */}
        </Index.Box>
      </Index.Box>

      <Index.Box className="common-card">
        <Index.Box className="admin-zone-table-main page-table-main">
          <Index.TableContainer
            component={Index.Paper}
            className="table-container"
          >
            <Index.Table aria-label="simple table" className="table">
              <Index.TableHead className="table-head">
                <Index.TableRow className="table-row">
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="10%"
                  >
                    Sr. No.
                  </Index.TableCell>

                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="20%"
                  >
                    FeedBack
                  </Index.TableCell>

                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="30%"
                  ></Index.TableCell>

                  {/* <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="5%"
                    align='right'
                  >
                    Action
                  </Index.TableCell> */}
                </Index.TableRow>
              </Index.TableHead>
              <Index.TableBody className="table-body">
                {filteredData
                  ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
                  ?.map((data, index) => {
                    const startIndex = (currentPage - 1) * 10;
                    const adjustedIndex = startIndex + index + 1;
                    return (
                      <Index.TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        key={data?._id}
                      >
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="table-td"
                        >
                          <Index.Typography className="admin-table-data-text">
                            {" "}
                            {adjustedIndex}
                          </Index.Typography>
                        </Index.TableCell>

                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="table-td"
                        >
                          <Index.Typography className="admin-table-data-text">
                            {" "}
                            {data?.feedback ? data?.feedback : "-"}
                          </Index.Typography>
                        </Index.TableCell>

                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="table-td"
                        >
                          <Index.Typography className="admin-table-data-text">
                            {" "}
                            {data?.description ? data?.description : "-"}
                          </Index.Typography>
                        </Index.TableCell>

                        {/* <Index.TableCell
                          component="td"
                          variant="td"
                          className="table-td"
                        >
                          <Index.Box className="admin-table-data-btn-flex">
                            <Index.Tooltip
                              title="Delete"
                              arrow
                              placement="bottom"
                              className="admin-tooltip"
                            >
                              <Index.Button className="admin-table-data-btn" onClick={handleOpenDelete}>
                                <img
                                  src={PagesIndex.Svg.trash}
                                  className="admin-icon"
                                  alt="Delete"
                                ></img>
                              </Index.Button>
                            </Index.Tooltip>
                          </Index.Box>
                        </Index.TableCell> */}
                      </Index.TableRow>
                    );
                  })}
              </Index.TableBody>
            </Index.Table>
          </Index.TableContainer>
        </Index.Box>
        <Index.Box className="admin-pagination-main">
          {filteredData?.length ? (
            <Index.Pagination
              page={currentPage}
              count={Math.ceil(filteredData?.length / 10)}
              onChange={(event, newPage) => setCurrentPage(newPage)}
              variant="outlined"
              shape="rounded"
              className="admin-pagination"
            />
          ) : (
            ""
          )}
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={openMessage}
        onClose={handleCloseMessage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-add-user-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="admin-modal-title"
              variant="h6"
              component="h2"
            >
              FeedBack
            </Index.Typography>
            <Index.Button
              className="modal-close-btn"
              onClick={handleCloseMessage}
            >
              <img
                src={PagesIndex.Svg.closeblack}
                className="admin-modal-close-icon"
                alt="Close"
              />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-input-box admin-modal-input-box">
              <Index.FormHelperText className="admin-form-lable">
                Response
              </Index.FormHelperText>
              <Index.Box className="admin-form-group">
                <Index.TextareaAutosize
                  aria-label="minimum height"
                  style={{ resize: "vertical", overflow: "auto" }}
                  // minRows={3}
                  placeholder="Enter Response"
                  className="admin-form-control-textarea"
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="modal-footer">
            <Index.Box className="admin-modal-user-btn-flex">
              <Index.Box className="admin-save-btn-main btn-main-primary">
                <Index.Button className="admin-save-user-btn btn-primary">
                  <img
                    src={PagesIndex.Svg.save}
                    className="admin-user-save-icon"
                    alt="Save"
                  ></img>
                  <span>Save</span>
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box>
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              Do you really want to delete this record? This process cannot be
              undone.
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseDelete}
              >
                Cancel
              </Index.Button>
              <Index.Button className="admin-modal-delete-btn btn">
                Delete
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </Index.Box>
  );
}
