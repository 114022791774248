import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./news.css";
import "./news.responsive.css";
import { debounce } from "@mui/material/utils";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PropTypes from "prop-types";

const autocompleteService = { current: null };
const placeService = { current: null };

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const AutoCompleteLocation = ({
  initialValues,
  setFieldValue,
  errors,
  touched,
  values,
}) => {
  const [placeInputValue, setPlaceInputValue] = useState([]);
  const [placeOptions, setPlaceOptions] = useState([]);

  const loaded = React.useRef(false);

  // Google place functionality start
  // if (typeof window !== "undefined" && !loaded.current) {
  //   if (!document.querySelector("#google-maps")) {
  //     loadScript(
  //       `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
  //       document.querySelector("head"),
  //       "google-maps"
  //     );
  //   }

  //   loaded.current = true;
  // }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
      // Place detail instance to get lat/lng from place id, becuase Autocomplete does not provide place lat/lng
      placeService.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (placeInputValue === "") {
      setPlaceOptions(initialValues.location ? [initialValues.location] : []);
      return undefined;
    }

    fetch({ input: placeInputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (initialValues.location) {
          newOptions = [initialValues.location];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setPlaceOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [initialValues.location, placeInputValue, fetch]);

  return (
    <Index.Box className="admin-form-group">
      <Index.Autocomplete
        fullWidth
        size="small"
        id="google-map-demo"
        className="admin-form-control admin-auto-complete-control"
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        isOptionEqualToValue={(option, value) =>
          option.description === value.location
        }
        filterOptions={(x) => x}
        options={placeOptions}
        autoComplete
        closeText={""}
        openText={""}
        clearText={""}
        includeInputInList
        filterSelectedOptions
        disableClearable={initialValues?.location ? false : true}
        value={initialValues?.location}
        noOptionsText="No locations"
        onChange={(event, newValue) => {
          setPlaceOptions(
            newValue ? [newValue, ...placeOptions] : placeOptions
          );

          if (newValue === null) {
            setFieldValue("location", "");
            setFieldValue("latitude", "");
            setFieldValue("longitude", "");
          } else {
            setFieldValue("location", newValue?.description);
            // Get lat and lng from place details
            const request = {
              placeId: newValue?.place_id,
              fields: ["geometry", "address_components"],
            };

            placeService.current.getDetails(request, (place, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const locality = place?.address_components?.filter((ele) =>
                  ele?.types?.includes("locality")
                );
                setFieldValue("city", locality?.[0]?.long_name);

                let latitude = place.geometry.location.lat();
                let longitude = place.geometry.location.lng();
                setFieldValue("latitude", latitude);
                setFieldValue("longitude", longitude);
              }
            });
          }
        }}
        onInputChange={(event, newInputValue) => {
          setPlaceInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <Index.TextField
            size="small"
            {...params}
            // label="Address"
            id="location"
            name="location"
            fullWidth
            placeholder="Enter Location"
            className="admin-form-control"
            // inputProps={{
            //   className: 'input_props',
            // }}
            // InputLabelProps={{ className: 'add-formlabel' }}
            // FormHelperTextProps={{
            //   className: 'input_label_props',
            // }}
            // helperText={
            //     touched.location ? errors.location : undefined
            // }
            // error={Boolean(
            //     errors.location ? touched.location : undefined
            // )}
          />
        )}
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );

          return (
            <li {...props}>
              <Index.Grid container alignItems="center">
                <Index.Grid item sx={{ display: "flex", width: 44 }}>
                  <LocationOnIcon sx={{ color: "text.secondary" }} />
                </Index.Grid>
                <Index.Grid
                  item
                  sx={{
                    width: "calc(100% - 44px)",
                    wordWrap: "break-word",
                  }}
                >
                  {parts.map((part, index) => (
                    <Index.Box
                      key={Index.uuidv4()}
                      component="span"
                      sx={{
                        fontWeight: part.highlight ? "bold" : "regular",
                      }}
                    >
                      {part.text}
                    </Index.Box>
                  ))}

                  <Index.Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Index.Typography>
                </Index.Grid>
              </Index.Grid>
            </li>
          );
        }}
      />
      {touched.location && errors.location && (
        <PagesIndex.FormHelperText className="admin-error-text">
          {errors.location}
        </PagesIndex.FormHelperText>
      )}
    </Index.Box>
  );
};

export default AutoCompleteLocation;

AutoCompleteLocation.propTypes = {
  initialValues: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};
