// firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCHYpkNiP-3a5fn7b-Wf8zB4LQcyChwb4k",
  authDomain: "news-capital-ott.firebaseapp.com",
  databaseURL:
    "https://news-capital-ott-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "news-capital-ott",
  storageBucket: "news-capital-ott.appspot.com",
  messagingSenderId: "627718619185",
  appId: "1:627718619185:web:e9e34952320f3b0f655510",
  measurementId: "G-K5DK1K1SYN",
};
const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BGB0ot6z7048Z7mhYaTswKPkpKhNE2m1aU-PIyd7_ppU1OHLEjGjIVooQUQ1e0h1eiBd4QngFa7VkCZYXtEWk8U",
    });
    return token;
  }
  if (permission === "denied") {
    return "";
  }
};
