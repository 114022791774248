import Svg from "../assets/Svg";
import Png from "../assets/Png";
import Gif from "../assets/Gif";
import Sidebar from "./admin/defaulLayout/Sidebar";
import Header from "./admin/defaulLayout/Header";
import PaidLable from "./common/lables/PaidLable";
import FailedLable from "./common/lables/FailedLable";
import PendingLable from "./common/lables/PendingLable";
import PrimaryButton from "./common/Button/PrimaryButton";
import BorderButton from "./common/Button/BorderButton";
import AuthFooter from "./admin/defaulLayout/AuthFooter";
import AuthBackground from "./admin/defaulLayout/AuthBackground";
import { useLocation, useNavigate } from "react-router-dom";
import EditProfile from "./admin/pages/account/editProfile/EditProfile";
import ChangePassword from "./admin/pages/account/changePassword/ChangePassword";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Spinner from "./common/spinner/Spinner";
import {
  loginValidationSchema,
  forgotPasswordValidationSchema,
  otpSchema,
  editProfileValidationSchema,
  resetPasswordValidationSchema,
  editProfileWithProfileValidationSchema,
  changePasswordValidationSchema,
} from "../utils/validation/FormikValidation";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  AdminLogInAction,
  AdminLogOutAction,
  GetAdminTokenAction,
} from "../redux/action";
import { Api } from "../config/Api";
import {
  apiGetHandler,
  apiPostHandler,
  apiPostWithFormHandler,
} from "../config/ApiHandler";
import {
  toasterSuccess,
  toasterError,
  toasterWarning,
  toasterInfo,
} from "../utils/toaster/Toaster";
import { FormHelperText } from "@mui/material";
import DataService from "../config/DataService";
import PageLoader from "./common/loader/PageLoader";
import moment from "moment";

const PagesIndex = {
  moment,
  Spinner,
  Svg,
  Png,
  Form,
  PageLoader,
  FieldArray,
  LocalizationProvider,
  Field,
  ErrorMessage,
  AdapterDayjs,
  DatePicker,
  Sidebar,
  Header,
  PaidLable,
  FailedLable,
  PendingLable,
  PrimaryButton,
  AuthFooter,
  AuthBackground,
  useLocation,
  useNavigate,
  BorderButton,
  EditProfile,
  ChangePassword,
  Gif,
  loginValidationSchema,
  forgotPasswordValidationSchema,
  otpSchema,
  Formik,
  FormHelperText,
  useDispatch,
  AdminLogInAction,
  Api,
  apiGetHandler,
  apiPostHandler,
  apiPostWithFormHandler,
  toasterSuccess,
  toasterError,
  toasterWarning,
  DataService,
  AdminLogOutAction,
  editProfileValidationSchema,
  useSelector,
  resetPasswordValidationSchema,
  editProfileWithProfileValidationSchema,
  GetAdminTokenAction,
  changePasswordValidationSchema,
  toasterInfo,
};

export default PagesIndex;
