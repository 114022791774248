import React, { useRef, useState } from "react";
import Index from "../../../../Index";
import PagesIndex from "../../../../PagesIndex";
import "./changePassword.css";
import "./changePassword.responsive.css";

export default function ChangePassword() {
  const formRef = useRef();
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleMouseDownOldPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };
  const changePassword = (values, { resetForm }) => {
    setButtonSpinner(true)
    const urlencoded = new URLSearchParams();
    urlencoded.append("oldPassword", values?.oldPassword?.trim());
    urlencoded.append("newPassword", values?.newPassword?.trim());
    PagesIndex.apiPostHandler(PagesIndex.Api.CHANGE_PASSWORD, urlencoded).then(
      (res) => {
        setButtonSpinner(false)
        if (res.status === 200) {
          resetForm();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  return (
    <PagesIndex.Formik
      enableReinitialize
      onSubmit={changePassword}
      initialValues={initialValues}
      validationSchema={PagesIndex.changePasswordValidationSchema}
      innerRef={formRef}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <Index.Box
          component={"form"}
          noValidate="novalidate"
          onSubmit={handleSubmit}
          className="admin-change-pass-main common-card"
        >
          <Index.Typography
            className="admin-common-para admin-edit-highlight-text"
            component="p"
            variant="p"
          >
            Password
          </Index.Typography>
          <Index.Typography
            className="admin-common-para admin-edit-para-text"
            component="p"
            variant="p"
          >
            Enter your current & new password to reset your password
          </Index.Typography>
          <Index.Box className="admin-input-box admin-change-pass-input-box">
            <Index.FormHelperText className="admin-form-lable">
              Old Password
            </Index.FormHelperText>
            <Index.Box className="admin-form-group">
              <Index.OutlinedInput
                name="oldPassword"
                fullWidth
                id="fullWidth"
                className="admin-form-control admin-form-control-eye"
                placeholder="Enter Old Password"
                onChange={handleChange}
                value={values.oldPassword}
                type={showOldPassword ? "text" : "password"}
                autoComplete="off"
                inputProps={{
                  autocomplete: "new-password",
                  form: {
                    autocomplete: "off",
                  },
                }}
                endAdornment={
                  <Index.InputAdornment position="end">
                    <Index.IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowOldPassword}
                      onMouseDown={handleMouseDownOldPassword}
                      edge="end"
                    >
                      {showOldPassword ? (
                        <Index.Visibility />
                      ) : (
                        <Index.VisibilityOff />
                      )}
                    </Index.IconButton>
                  </Index.InputAdornment>
                }
                error={
                  errors?.oldPassword && touched?.oldPassword
                    ? errors?.oldPassword
                    : null
                }
              />
              <Index.FormHelperText className="admin-error-text">
                {errors?.oldPassword && touched?.oldPassword
                  ? errors?.oldPassword
                  : null}
              </Index.FormHelperText>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-input-box admin-change-pass-input-box">
            <Index.FormHelperText className="admin-form-lable">
              New Password
            </Index.FormHelperText>
            <Index.Box className="admin-form-group">
              <Index.OutlinedInput
                name="newPassword"
                className="admin-form-control-eye admin-form-control"
                autoComplete="off"
                placeholder="Enter New Password"
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                inputProps={{
                  autocomplete: "new-password",
                  form: {
                    autocomplete: "off",
                  },
                }}
                endAdornment={
                  <Index.InputAdornment position="end">
                    <Index.IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <Index.Visibility />
                      ) : (
                        <Index.VisibilityOff />
                      )}
                    </Index.IconButton>
                  </Index.InputAdornment>
                }
                onChange={handleChange}
                value={values.newPassword}
              />
              <Index.FormHelperText className="admin-error-text">
                {errors?.newPassword && touched?.newPassword
                  ? errors?.newPassword
                  : null}
              </Index.FormHelperText>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-input-box admin-change-pass-input-box">
            <Index.FormHelperText className="admin-form-lable">
              Confirm Password
            </Index.FormHelperText>
            <Index.Box className="admin-form-group">
              <Index.OutlinedInput
                name="confirmPassword"
                className="admin-form-control-eye admin-form-control"
                autoComplete="off"
                placeholder="Enter Confirm Password"
                id="outlined-adornment-password"
                type={showConfirmPassword ? "text" : "password"}
                endAdornment={
                  <Index.InputAdornment position="end">
                    <Index.IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <Index.Visibility />
                      ) : (
                        <Index.VisibilityOff />
                      )}
                    </Index.IconButton>
                  </Index.InputAdornment>
                }
                onChange={handleChange}
                value={values.confirmPassword}
              />
              <Index.FormHelperText className="admin-error-text">
                {errors?.confirmPassword && touched?.confirmPassword
                  ? errors?.confirmPassword
                  : null}
              </Index.FormHelperText>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-user-btn-flex admin-change-pass-flex">
            {/* <Index.Box className="admin-discard-btn-main" >
              <PagesIndex.BorderButton
                btnLabel="Discard"
                className="admin-discard-user-btn border-btn"
              />
            </Index.Box> */}
            <Index.Box className="admin-save-btn-main btn-main-primary">
              <Index.Button
                className="admin-save-user-btn btn-primary"
                type="submit"
              >
                {buttonSpinner ? <PagesIndex.Spinner /> : (
                  <>
                    <img
                      src={PagesIndex.Svg.save}
                      className="admin-user-save-icon"
                      alt="Save" />
                    <span>Save</span>
                  </>
                )}
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </PagesIndex.Formik>
  );
}
