import React from 'react'
import Index from '../../../../Index'

export default function ActivityLogTable() {
      return (
            <Index.Box className="common-card">
                  <Index.Box className="page-table-main">
                        <Index.TableContainer
                              component={Index.Paper}
                              className="table-container"
                        >
                              <Index.Table aria-label="simple table" className="table">
                                    <Index.TableHead className="table-head">
                                          <Index.TableRow className="table-row">
                                                <Index.TableCell
                                                      component="th"
                                                      variant="th"
                                                      className="table-th"
                                                      width="33%"
                                                >
                                                      User Name
                                                </Index.TableCell>
                                                <Index.TableCell
                                                      component="th"
                                                      variant="th"
                                                      className="table-th"
                                                      width="33%"
                                                >
                                                      Date & Time
                                                </Index.TableCell>
                                                <Index.TableCell
                                                      component="th"
                                                      variant="th"
                                                      className="table-th"
                                                      width="33%"
                                                >
                                                      Location
                                                </Index.TableCell>
                                          </Index.TableRow>
                                    </Index.TableHead>
                                    <Index.TableBody className="table-body">
                                          <Index.TableRow
                                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                          >
                                                <Index.TableCell
                                                      component="td"
                                                      variant="td"
                                                      scope="row"
                                                      className="table-td"
                                                >
                                                      <Index.Typography className="admin-table-data-text">
                                                            {" "}
                                                            Rinku Prajapati
                                                      </Index.Typography>
                                                </Index.TableCell>
                                                <Index.TableCell
                                                      component="td"
                                                      variant="td"
                                                      className="table-td"
                                                >
                                                      <Index.Typography className="admin-table-data-text">
                                                            {" "}
                                                            4.5
                                                      </Index.Typography>
                                                </Index.TableCell>
                                                <Index.TableCell
                                                      component="td"
                                                      variant="td"
                                                      className="table-td"
                                                >
                                                      {" "}
                                                      Ahmedabad
                                                </Index.TableCell>
                                          </Index.TableRow>
                                    </Index.TableBody>
                              </Index.Table>
                        </Index.TableContainer>
                  </Index.Box>
                  <Index.Box className="admin-pagination-main">
                        <Index.Pagination
                              count={3}
                              variant="outlined"
                              shape="rounded"
                              className="admin-pagination"
                        />
                  </Index.Box>
            </Index.Box>
      )
}
