import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./subAdmin.Rsponsive.css";
import { useNavigate } from "react-router-dom";

// for modal design

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function SubAdminManagement() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const navigate = useNavigate();
  const formRef = useRef();
  const [id, setId] = useState("");
  const [data, setData] = useState([]);
  const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const initialValues = {
    name: "",
    email: "",
    roleId: "",
    password: "",
  };
  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [filteredData, setFilteredData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [loading, setLoading] = useState(true);
  // add user modal
  const [open, setOpen] = useState(false);
  // delete modal
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showPassword, setShowPassword] = useState(false);

  const [activityLog, setActivityLog] = useState([]);
  const [activityLoading, setActivityLoading] = useState(true);
  const [filterLog, setFilterLog] = useState([]);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openLogModal, setOpenLogModal] = useState(false);
  const [userName, setUserName] = useState({})

  const handleCloseLogModal = () => setOpenLogModal(false);
  const handleOpenLogModal = (id, data) => {
    setOpenLogModal(true);
    setActivityLoading(true);
    setUserName(data)
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_ISACTIVE_ACTIVITY_LOG + "/" + id
    ).then((res) => {
      setActivityLoading(false);
      if (res.status === 200) {
        let data = res?.data?.activityLog?.filter(
          (data) =>
            data?.previousStatus !== data?.currentStatus ||
            data?.previousRoleId?.role !== data?.updatedRoleId?.role ||
            data?.previousEmail !== data?.updatedEmail ||
            data?.previousName !== data?.updatedName
        );
        setFilterLog(data);
        setActivityLog(res.data?.activityLog);
      }
    });
  };


  useEffect(() => {
    setLoading(true);
    handleGetSubAdmins();
    handleGetRoles();
  }, []);
  const handleOpen = (op) => {
    setOpen(true);
    setAddOrEdit(op);
  };
  const handleClose = () => {
    formRef.current.resetForm();
    setId("");
    setOpen(false);
  };
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setId(id);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleGetSubAdmins = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_SUBADMIN).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setData(res.data);
        setFilteredData(res.data);
      }
    });
  };
  const handleGetRoles = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ROLES).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        const activeRoles = res?.data?.filter((role) => role?.isActive);
        setRoleData(activeRoles);
      }
    });
  };
  const handleAddEditSubAdmin = (values) => {
    setButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    if (id) {
      urlEncoded.append("id", id);
    }
    urlEncoded.append("name", values?.name);
    urlEncoded.append("email", values?.email);
    urlEncoded.append("password", values?.password);
    urlEncoded.append("roleId", values?.roleId);
    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_EDIT_SUBADMIN, urlEncoded)
      .then((res) => {
        setButtonSpinner(false)
        if (res.status === 201 || res.status === 200) {
          handleClose();
          handleGetSubAdmins();
          setSearchValue("");
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };

  const requestSearch = (e) => {
    setSearchValue(e.target.value);
    let result = data.filter((data) => {
      let name = data?.name
        ?.toLowerCase()
        .includes(e?.target?.value?.trim()?.toLowerCase());
      let role = data?.roleId?.role
        ?.toLowerCase()
        .includes(e?.target?.value?.trim()?.toLowerCase());
      let email = data?.email
        ?.toLowerCase()
        .includes(e?.target?.value?.trim()?.toLowerCase());

      return role || name || email;
    });
    setCurrentPage(1);
    setFilteredData(result);
  };

  const handleDeleteSubAdmin = () => {
    setDeleteButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(PagesIndex.Api.DELETE_SUBADMIN, urlEncoded).then(
      (res) => {
        setDeleteButtonSpinner(false)
        if (res.status === 200) {
          handleCloseDelete();
          handleGetSubAdmins();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const handleOpenStatusModal = (id) => {
    setId(id);
    setOpenStatusModal(true);
  };
  const handleCloseStatusModal = () => {
    setId("");
    setOpenStatusModal(false);
  };
  const handleStatus = () => {
    setStatusButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(
      PagesIndex.Api.ISACTIVE_SUBADMIN,
      urlEncoded
    ).then((res) => {
      setStatusButtonSpinner(false)
      if (res.status === 200) {
        handleGetSubAdmins();
        handleCloseStatusModal();
        PagesIndex.toasterSuccess(res?.message);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  const renderTableRows = (setFieldValue) => {
    return filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data) => {
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.name}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              {" "}
              {data?.email}
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              {" "}
              {data?.roleId?.role}
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              className="table-td"
              align="right"
            >
              {" "}
              {data?.createdBy?.name || "-"}
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              className="table-td"
              align="right"
            >
              {" "}
              {data?.updatedBy?.name || "-"}
            </Index.TableCell>

            {(adminDetails?.roleId?.permissions?.includes("admin_user_edit") ||
              adminDetails?.roleId?.permissions?.includes(
                "admin_user_delete"
              )) && (
                <Index.TableCell
                  component="td"
                  variant="td"
                  className="table-td"
                  align="right"
                >
                  <Index.Tooltip
                    title={data?.isActive ? "Active" : "Deactive"}
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                  >
                    <Index.Box className="admin-switch-main">
                      <Index.FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} />}
                        className="admin-switch-lable"
                        checked={data?.isActive}
                        onChange={() => {
                          handleOpenStatusModal(data?._id);
                        }}
                      />
                    </Index.Box>
                  </Index.Tooltip>
                </Index.TableCell>
              )}

            {/* {(adminDetails?.roleId?.permissions?.includes("admin_user_edit") ||
              adminDetails?.roleId?.permissions?.includes("admin_user_log") ||
              adminDetails?.roleId?.permissions?.includes(
                "admin_user_delete"
              )) && ( */}
            <Index.TableCell
              component="td"
              variant="td"
              align="right"
              className="table-td"
            >
              <Index.Box className="admin-table-data-btn-flex ">
                <Index.Tooltip
                  title="Login Log"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-table-data-btn"
                    onClick={() => navigate("/admin/user-activity-log")}
                  >
                    <img
                      src={PagesIndex.Svg.yelloweye}
                      className="admin-icon"
                      alt="Login Log"
                    ></img>
                  </Index.Button>
                </Index.Tooltip>

                {adminDetails?.roleId?.permissions?.includes(
                  "admin_user_edit"
                ) && (
                    <Index.Tooltip
                      title="Edit"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => {
                          setId(data?._id);
                          setFieldValue("name", data.name);
                          setFieldValue("email", data.email);
                          setFieldValue("roleId", data?.roleId?._id);
                          handleOpen("Edit");
                        }}
                      >
                        <img
                          src={PagesIndex.Svg.blueedit}
                          className="admin-icon"
                          alt="Edit"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}

                {adminDetails?.roleId?.permissions?.includes(
                  "admin_user_log"
                ) && (
                    <Index.Tooltip
                      title="Log"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => handleOpenLogModal(data?._id, data)}
                      >
                        <img
                          src={PagesIndex.Svg.logicon}
                          className="admin-icon"
                          alt="Log"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}

                {adminDetails?.roleId?.permissions?.includes(
                  "admin_user_delete"
                ) && (
                    <Index.Tooltip
                      title="Delete"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => handleOpenDelete(data?._id)}
                      >
                        <img
                          src={PagesIndex.Svg.trash}
                          className="admin-icon"
                          alt="Trash"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}

                {adminDetails?.roleId?.permissions?.includes(
                  "admin_user_log"
                ) && (
                    <Index.Tooltip
                      title="Admin Log"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => navigate(`/admin/subAdmin-management/${data?._id}`, {
                          state: {
                            data: data?.name
                          }
                        })}
                      >
                        <img
                          src={PagesIndex.Svg.logicon}
                          className="admin-icon"
                          alt="Admin Log"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}
              </Index.Box>
            </Index.TableCell>
            {/* )} */}
          </Index.TableRow >
        );
      });
  };

  const renderContent = (setFieldValue) => {
    if (filteredData?.length) {
      return renderTableRows(setFieldValue);
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };

  const renderActivityTableRows = () => {
    return activityLog
      .slice()
      .reverse()
      .map((data) => {
        let updatedInfo = "";

        if (
          data?.previousStatus !== data?.currentStatus ||
          data?.previousRoleId?.role !== data?.updatedRoleId?.role ||
          data?.previousEmail !== data?.updatedEmail ||
          data?.previousName !== data?.updatedName
        ) {
          if (data?.updatedBy?.name) {
            updatedInfo = `${data?.updatedBy?.name}-${data?.dateTime
              ? PagesIndex.moment(data?.dateTime).format(
                "DD/MM/YYYY HH:mm:ss"
              )
              : "-"
              }`;
          } else {
            updatedInfo = "-";
          }
        }
        return (
          <Index.TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            key={data?._id}
          >
            {updatedInfo !== "" && (
              <>
                <Index.TableCell
                  component="td"
                  variant="td"
                  scope="row"
                  className="table-td"
                >
                  <Index.Typography className="admin-table-data-text">
                    <Index.Tooltip
                      title={`${data?.previousName !== data?.updatedName
                        ? `${data?.updatedBy?.name} has updated name from ${data?.previousName
                        } to ${data?.updatedName + " "}`
                        : ""
                        }`}
                      arrow
                      placement="top"
                      className="admin-tooltip"
                    >
                      {data?.previousName !== data?.updatedName && (
                        <span>
                          <span className="span-bold">
                            {data?.updatedBy?.name}
                          </span>{" "} has updated Name from{" "}
                          <span className="span-bold">
                            {data?.previousName}
                          </span>{" "}
                          to{" "}
                          <span className="span-bold">
                            {data?.updatedName + " "}
                          </span>
                        </span>
                      )}
                    </Index.Tooltip>
                  </Index.Typography>

                  <Index.Typography className="admin-table-data-text">
                    <Index.Tooltip
                      title={`${data?.previousEmail !== data?.updatedEmail
                        ? `${data?.updatedBy?.name} has updated email id from ${data?.previousEmail
                        } to ${data?.updatedEmail + " "}`
                        : ""
                        }`}
                      arrow
                      placement="top"
                      className="admin-tooltip"
                    >
                      {data?.previousEmail !== data?.updatedEmail && (
                        <span>
                          <span className="span-bold">
                            {data?.updatedBy?.name}
                          </span>{" "} has updated email from{" "}
                          <span className="span-bold">
                            {data?.previousEmail}
                          </span>{" "}
                          to{" "}
                          <span className="span-bold">
                            {data?.updatedEmail + " "}
                          </span>
                        </span>
                      )}
                    </Index.Tooltip>
                  </Index.Typography>

                  <Index.Typography className="admin-table-data-text">
                    <Index.Tooltip
                      title={`${data?.previousRoleId?.role !== data?.updatedRoleId?.role
                        ? `${data?.updatedBy?.name} has updated role from ${data?.previousRoleId?.role
                        } to ${data?.updatedRoleId?.role + " "}`
                        : ""
                        }`}
                      arrow
                      placement="top"
                      className="admin-tooltip"
                    >
                      {data?.previousRoleId?.role !==
                        data?.updatedRoleId?.role && (
                          <span>
                            <span className="span-bold">
                              {data?.updatedBy?.name}
                            </span>{" "} has updated Role from{" "}
                            <span className="span-bold">
                              {data?.previousRoleId?.role}
                            </span>{" "}
                            to{" "}
                            <span className="span-bold">
                              {data?.updatedRoleId?.role + " "}
                            </span>
                          </span>
                        )}
                    </Index.Tooltip>
                  </Index.Typography>

                  <Index.Typography className="admin-table-data-text">
                    <Index.Tooltip
                      title={`${data?.previousStatus !== data?.updatedStatus
                        ? `${data?.updatedBy?.name} Status  has been updated from ${!data?.updatedStatus ? "Active" : "Deactive"
                        } to ${data?.updatedStatus ? "Active" : "Deactive"} `
                        : ""
                        }`}
                      arrow
                      placement="top"
                      className="admin-tooltip"
                    >
                      {data?.previousStatus !== data?.updatedStatus && (
                        <span>
                          <span className="span-bold">
                            {data?.updatedBy?.name}
                          </span>{" "} Status  has been updated from{" "}
                          <span className="span-bold">
                            {!data?.updatedStatus ? "Active" : "Deactive"}
                          </span>{" "}
                          to&nbsp;
                          <span className="span-bold">
                            {data?.updatedStatus ? "Active" : "Deactive"}
                          </span>
                        </span>
                      )}
                    </Index.Tooltip>
                  </Index.Typography>
                </Index.TableCell>

                <Index.TableCell
                  component="td"
                  variant="td"
                  className="table-td"
                >
                  {updatedInfo}
                </Index.TableCell>
              </>
            )}
          </Index.TableRow>
        );
      });
  };

  const renderActivityLogContent = () => {
    return (
      <>
        {!activityLoading &&
          activityLog?.length > 0 &&
          renderActivityTableRows()}
        {!activityLoading &&
          (!activityLog ||
            activityLog.length === 0 ||
            filterLog.length === 0) && <PagesIndex.DataNotFound />}
      </>
    );
  };

  return (
    <PagesIndex.Formik
      enableReinitialize
      onSubmit={handleAddEditSubAdmin}
      initialValues={initialValues}
      validationSchema={
        addOrEdit === "Add"
          ? PagesIndex.subAdminSchema
          : PagesIndex.subAdminEditSchema
      }
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <>
          <Index.Box className="admin-dashboard-content admin-user-list-content">
            <Index.Box className="admin-user-list-flex admin-page-title-main">
              <Index.Typography
                className="admin-page-title admin-user-list-page-title"
                component="h2"
                variant="h2"
              >
                Admin User List
              </Index.Typography>
              <Index.Box className="admin-userlist-btn-flex">
                <Index.Box className="admin-search-main">
                  <Index.Box className="admin-search-box">
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="admin-form-control"
                        placeholder="Search"
                        value={searchValue}
                        onChange={requestSearch}
                      />
                      <img
                        src={PagesIndex.Svg.search}
                        className="admin-search-grey-img admin-icon"
                        alt="search"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                {adminDetails?.roleId?.permissions?.includes(
                  "admin_user_add"
                ) && (
                    <Index.Box className="admin-userlist-inner-btn-flex">
                      <Index.Box className="admin-adduser-btn-main btn-main-primary">
                        <Index.Button
                          className="admin-adduser-btn btn-primary"
                          onClick={() => {
                            handleOpen("Add");
                          }}
                        >
                          <img
                            src={PagesIndex.Svg.plus}
                            className="admin-plus-icon"
                            alt="plus"
                          />
                          <span>Add Admin User</span>
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  )}
              </Index.Box>
            </Index.Box>

            <Index.Box className="common-card">
              <Index.Box
                className={`admin-sub-admin-table-main ${loading ? "" : "page-table-main"
                  }`}
              >
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  {loading ? (
                    <PagesIndex.Loader />
                  ) : (
                    <Index.Table aria-label="simple table" className="table">
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="10%"
                          >
                            Name
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="20%"
                          >
                            Email Id
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="10%"
                          >
                            Role
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="10%"
                            align="right"
                          >
                            Created By
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="10%"
                            align="right"
                          >
                            Updated By
                          </Index.TableCell>

                          {(adminDetails?.roleId?.permissions?.includes(
                            "admin_user_edit"
                          ) ||
                            adminDetails?.roleId?.permissions?.includes(
                              "admin_user_delete"
                            )) && (
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="5%"
                                align="right"
                              >
                                Status
                              </Index.TableCell>
                            )}

                          {/* {(adminDetails?.roleId?.permissions?.includes("admin_user_edit") ||
                            adminDetails?.roleId?.permissions?.includes("admin_user_log") ||
                            adminDetails?.roleId?.permissions?.includes(
                              "admin_user_delete"
                            )) && ( */}
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="5%"
                            align="right"
                          >
                            Action
                          </Index.TableCell>
                          {/* )} */}
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {renderContent(setFieldValue)}
                      </Index.TableBody>
                    </Index.Table>
                  )}
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="admin-pagination-main">
                {filteredData?.length ? (
                  <Index.Pagination
                    page={currentPage}
                    count={Math.ceil(filteredData?.length / 10)}
                    onChange={(event, newPage) => setCurrentPage(newPage)}
                    variant="outlined"
                    shape="rounded"
                    className="admin-pagination"
                  />
                ) : (
                  ""
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <form onSubmit={handleSubmit} noValidate="novalidate">
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {addOrEdit} Admin User
                  </Index.Typography>
                  {/* <Index.Box className="modal-close-btn" onClick={handleClose}>
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Box> */}
                </Index.Box>
                <Index.Box
                  className="admin-modal-hgt-scroll cus-scrollbar"
                  id="scrollDiv"
                >
                  {/* <ScrollIntoView /> */}
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Name<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        name="name"
                        fullWidth
                        id="name"
                        className="admin-form-control"
                        placeholder="Enter Name"
                        onChange={handleChange}
                        value={values?.name}
                        error={errors.name && touched.name}
                        inputProps={{ maxLength: 30 }}
                      />
                      <PagesIndex.FormHelperText className="admin-error-text">
                        {errors.name && touched.name ? errors.name : null}
                      </PagesIndex.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Email Id<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        name="email"
                        fullWidth
                        id="email"
                        className="admin-form-control"
                        placeholder="Enter Email Id"
                        onChange={handleChange}
                        value={values?.email}
                        error={errors.email && touched.email}
                        inputProps={{ maxLength: 50 }}
                      />
                      <PagesIndex.FormHelperText className="admin-error-text">
                        {errors.email && touched.email ? errors.email : null}
                      </PagesIndex.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  {addOrEdit === "Add" && (
                    <Index.Box className="admin-input-box admin-modal-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Password<span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.OutlinedInput
                          name="password"
                          className="admin-form-control-eye admin-form-control"
                          autoComplete="off"
                          placeholder="Enter Password"
                          id="password"
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <Index.InputAdornment position="end">
                              <Index.IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Index.Visibility />
                                ) : (
                                  <Index.VisibilityOff />
                                )}
                              </Index.IconButton>
                            </Index.InputAdornment>
                          }
                          onChange={handleChange}
                          value={values.password}
                        />
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.password && touched.password
                            ? errors.password
                            : null}
                        </PagesIndex.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  )}
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Role<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-dropdown-box">
                      <Index.FormControl className="admin-form-control admin-drop-form-control">
                        <Index.Select
                          id="roleId"
                          name="roleId"
                          className="admin-dropdown-select admin-drop-select"
                          value={values?.roleId}
                          onChange={handleChange}
                          defaultValue={values?.roleId}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          renderValue={
                            values?.roleId ? undefined : () => "Select Role"
                          }
                        >
                          {roleData?.map((data) => {
                            return (
                              <Index.MenuItem
                                key={data?._id}
                                value={data?._id}
                                className="admin-drop-menuitem"
                              >
                                {data?.role}
                              </Index.MenuItem>
                            );
                          })}
                        </Index.Select>
                      </Index.FormControl>
                      <PagesIndex.FormHelperText className="admin-error-text">
                        {errors.roleId && touched.roleId ? errors.roleId : null}
                      </PagesIndex.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="admin-discard-btn-main border-btn-main">
                      <Index.Button
                        className="admin-discard-user-btn border-btn"
                        onClick={handleClose}
                        disabled={buttonSpinner}
                      >
                        Discard
                      </Index.Button>
                    </Index.Box>
                    <Index.Box className="admin-save-btn-main btn-main-primary">
                      <Index.Button
                        className={`${id ? "update-btn" : "save-btn"} admin-save-user-btn btn-primary`}
                        type="submit"
                        disabled={buttonSpinner}
                      >
                        {buttonSpinner ? <PagesIndex.Spinner /> :
                          (<>
                            <img
                              src={PagesIndex.Svg.save}
                              className="admin-user-save-icon"
                              alt="Save"
                            />
                            <span>{id ? "Update" : "Add"}</span>
                          </>)
                        }
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          </Index.Modal>

          <Index.Modal
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <Index.Box
              sx={style}
              className="admin-delete-modal-inner-main admin-modal-inner"
            >
              <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                <Index.Box className="admin-modal-circle-main">
                  <img
                    src={PagesIndex.Svg.closecircle}
                    className="admin-user-circle-img"
                    alt="Close"
                  />
                </Index.Box>
                <Index.Typography
                  className="admin-delete-modal-title"
                  component="h2"
                  variant="h2"
                >
                  Are you sure?
                </Index.Typography>
                <Index.Typography
                  className="admin-delete-modal-para admin-common-para"
                  component="p"
                  variant="p"
                >
                  Do you really want to delete this admin user? This process
                  cannot be undone.
                </Index.Typography>
                <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
                  <Index.Button
                    className="admin-modal-cancel-btn border-btn"
                    onClick={handleCloseDelete}
                    disabled={deleteButtonSpinner}
                  >
                    Cancel
                  </Index.Button>
                  <Index.Button
                    className="delete-user admin-modal-delete-btn btn"
                    onClick={handleDeleteSubAdmin}
                    disabled={deleteButtonSpinner}
                  >
                    {deleteButtonSpinner ? <PagesIndex.Spinner /> : <span>Delete</span>
                    }
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>

          <Index.Modal
            open={openLogModal}
            onClose={handleCloseLogModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <Index.Box
              sx={style}
              className="admin-log-modal-inner-main admin-modal-inner"
            >
              <Index.Box className="admin-modal-header">
                <Index.Typography
                  id="modal-modal-title"
                  className="admin-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {" "}
                  Activity Log of  <span className="title-text">{userName?.name}</span>
                </Index.Typography>
                <Index.Button
                  className="modal-close-btn"
                  onClick={handleCloseLogModal}
                >
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="admin-modal-close-icon"
                    alt="Close"
                  />
                </Index.Button>
              </Index.Box>
              <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                <Index.Box className="common-card">
                  <Index.Box className="page-table-main sub-admin-activity-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      {activityLoading ? (
                        <PagesIndex.Loader />
                      ) : (
                        <Index.Table aria-label="simple table" className="table">
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="70%"
                              >
                                Activity Log
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="30%"
                              >
                                Updated Date & Time
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {renderActivityLogContent()}
                          </Index.TableBody>
                        </Index.Table>
                      )}
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>

          <PagesIndex.ChangeStatusModal
            data={"admin user"}
            statusButtonSpinner={statusButtonSpinner}
            openStatusModal={openStatusModal}
            handleCloseStatusModal={handleCloseStatusModal}
            handleChangeStatus={handleStatus}
          />
        </>
      )}
    </PagesIndex.Formik>
  );
}
