import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import DataService from "../config/DataService";
import PropTypes from 'prop-types';

const PrivateRoutes = ({ children }) => {
  const { isAdminLogin, token } = useSelector((state) => state.AdminReducer);
  DataService.defaults.headers.common.auth = token;
  return isAdminLogin ? children : <Navigate to="/" replace />;
};

export default PrivateRoutes;

PrivateRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};