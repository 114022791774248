import React, { Fragment } from "react";
import { GoogleMap, Marker, Circle } from "@react-google-maps/api";

const MapComp = ({ coordinates }) => {
  const mapContainerStyle = {
    width: "100%",
    height: "500px",
  };
  return (
    // <LoadScript googleMapsApiKey="AIzaSyDLGdcCaMUGj0JselBgVcMfaooSGW9w6RE">
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={coordinates?.length ? 8 : 5}
      center={{
        lat: parseFloat(
          coordinates?.length
            ? coordinates?.[coordinates?.length - 1]?.latitude
            : 20.5937
        ),
        lng: parseFloat(
          coordinates?.length
            ? coordinates?.[coordinates?.length - 1]?.longitude
            : 78.9629
        ),
      }}
    >
      {coordinates?.map((item) => {
        return (
          <Fragment key={item?.name}>
            <Marker
              position={{
                lat: parseFloat(item?.latitude),
                lng: parseFloat(item?.longitude),
              }}
            />
            <Circle
              center={{
                lat: parseFloat(item?.latitude),
                lng: parseFloat(item?.longitude),
              }}
              radius={40000}
              // options={{
              //   fillColor: "#5da9ff",
              //   strokeColor: "#5da9ff",
              //   strokeWeight: 1
              // }}
            />
          </Fragment>
        );
      })}
    </GoogleMap>
    // </LoadScript>
  );
};

export default MapComp;
