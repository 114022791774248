import React, { useState, useEffect, useRef } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function CMSManagement() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const initialValues = {
    cmsText: "",
  };
  const params = PagesIndex.useParams();
  const formik = useRef();
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const { path } = params;
  const [cmsText, setCmsText] = useState("");
  useEffect(() => {
    getCMSData();
  }, [path]);
  const getCMSData = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_CMS)
      .then((res) => {
        if (res.status === 200) {
          if (path === "privacy-policy") {
            // setCmsText(res?.data?.privacyPolicy || "<p></p>");
            formik.current.setFieldValue(
              "cmsText",
              res?.data?.privacyPolicy || "<p></p>"
            );
          }
          if (path === "help-support") {
            // setCmsText(res?.data?.helpAndSupport || "<p></p>");
            formik.current.setFieldValue(
              "cmsText",
              res?.data?.helpAndSupport || "<p></p>"
            );
          }
          if (path === "terms-and-condition") {
            // setCmsText(res?.data?.termsCondition || "<p></p>");
            formik.current.setFieldValue(
              "cmsText",
              res?.data?.termsCondition || "<p></p>"
            );
          }
        } else {
          PagesIndex.toasterError("Something went wrong!");
        }
      })
      .catch((err) => { });
  };
  const pageTitle = () => {
    if (path === "privacy-policy") {
      return "Privacy Policy";
    }
    if (path === "help-support") {
      return "Help & Support";
    }
    if (path === "terms-and-condition") {
      return "Terms & Conditions";
    }
  };
  const handleCmsSubmit = (values) => {
    setButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    if (path === "privacy-policy") {
      urlEncoded.append("cmsType", 0);
    }
    if (path === "terms-and-condition") {
      urlEncoded.append("cmsType", 1);
    }
    if (path === "help-support") {
      urlEncoded.append("cmsType", 2);
    }
    urlEncoded.append("description", values?.cmsText);
    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_EDIT_CMS, urlEncoded)
      .then((res) => {
        setButtonSpinner(false)
        if (res.status === 201 || res.status === 200) {
          PagesIndex.toasterSuccess(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };
  return (
    <Index.Box className="admin-dashboard-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-page-title"
          component="h2"
          variant="h2"
        >
          {pageTitle()}
        </Index.Typography>
      </Index.Box>
      <PagesIndex.Formik
        enableReinitialize
        onSubmit={handleCmsSubmit}
        initialValues={initialValues}
        validationSchema={PagesIndex.cmsSchema}
        innerRef={formik}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Index.Box
            component={"form"}
            noValidate="novalidate"
            onSubmit={handleSubmit}
            className="admin-input-box cms-page-content-main privacy-policy-page common-card"
          >
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "blockQuote",
                  "link",
                  "|",
                  "numberedList",
                  "bulletedList",
                  // "imageUpload",
                  // "mediaEmbed",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
              data={values?.cmsText}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFieldValue("cmsText", data);
              }}
              onBlur={(event, editor) => { }}
              onFocus={(event, editor) => { }}
            />
            {touched.cmsText && errors.cmsText && (
              <PagesIndex.FormHelperText className="admin-error-text">
                {errors.cmsText}{" "}
                {path === "privacy-policy"
                  ? "privacy policy"
                  : path === "help-support"
                    ? "help & support"
                    : path === "terms-and-condition"
                      ? "terms & conditions"
                      : ""}
              </PagesIndex.FormHelperText>
            )}
            <Index.Box className="admin-user-btn-flex">
              {adminDetails?.roleId?.permissions?.includes("cms_edit") && (
                <Index.Box className="btn-main-primary">
                  <Index.Button
                    className="admin-save-user-btn btn-primary save-btn"
                    type="submit"
                    disabled={buttonSpinner}
                  >
                    {buttonSpinner ? <PagesIndex.Spinner /> : (
                      <>
                        <img
                          src={PagesIndex.Svg.save}
                          className="admin-user-save-icon"
                          alt="Save"
                        />
                        <span>Save</span>
                      </>
                    )}
                  </Index.Button>
                </Index.Box>
              )}
            </Index.Box>
          </Index.Box>
        )}
      </PagesIndex.Formik>
    </Index.Box>
  );
}
