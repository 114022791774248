import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { styled } from "@mui/material/styles";

// for modal design
const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 3,
    border: "1px solid #b2b3b3",
    width: 12,
    height: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "transparent",
}));

function BpCheckbox(props) {
    return (
        <Index.Checkbox
            sx={{
                "&:hover": { bgcolor: "transparent" },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ "aria-label": "Checkbox demo" }}
            {...props}
        />
    );
}

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "transparent",
    "&:before": {
        display: "block",
        width: 12,
        height: 12,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
        content: '""',
    },
    "input:hover ~ &": {
        backgroundColor: "transparent",
    },
});


const IOSSwitch = Index.styled((props) => (
    <Index.Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 34,
    height: 20,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 3,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 14,
        height: 14,
        boxShadow: "none",
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
};

export default function AdvertiseSubCategory() {
    const { adminDetails } = PagesIndex.useSelector(
        (state) => state.AdminReducer
    );
    const [data, setData] = useState([]);
    const [id, setId] = useState("");
    const [selectuserData, setSelectuserData] = useState("");
    const [loading, setLoading] = useState(true);

    const [activityLog, setActivityLog] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [activityLoading, setActivityLoading] = useState(true);
    const [filterLog, setFilterLog] = useState([]);
    const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);
    const [buttonSpinner, setButtonSpinner] = useState(false);
    const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);

    const [openLogModal, setOpenLogModal] = useState(false);
    const handleCloseLogModal = () => setOpenLogModal(false);
    const [searchValue, setSearchValue] = useState("");
    const [openStatusModal, setOpenStatusModal] = useState(false);

    const handleOpenLogModal = (id) => {
        setOpenLogModal(true);
        setActivityLoading(true);
        PagesIndex.apiGetHandler(
            PagesIndex.Api.GET_CLIENT_SUB_CATEGORY_ACTIVITY_LOG + "/" + id
        ).then((res) => {
            setActivityLoading(false);
            if (res.status === 200) {
                let data = res?.data?.activityLog?.filter(
                    (data) =>
                        data?.previousCategoryId?.categoryName !== data?.currentCategoryId?.categoryName ||
                        data?.previousSubCategoryName !== data?.subCategoryName ||
                        data?.previousStatus !== data?.currentStatus
                );
                setFilterLog(data);
                setActivityLog(res.data?.activityLog);
            }
        });
    };

    const formRef = useRef();
    const initialValues = {
        categoryId: "",
        subCategoryName: "",
    };


    const [addOrEdit, setAddOrEdit] = useState("Add");
    const [filteredData, setFilteredData] = useState([]);
    // add user modal
    const [open, setOpen] = useState(false);
    // delete modal
    const [openDelete, setOpenDelete] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const handleOpen = (op, data) => {
        setAddOrEdit(op);
        setSelectuserData(data);
        setOpen(true);
    };
    const handleClose = () => {
        formRef.current.resetForm();
        setOpen(false);
    };
    const handleOpenDelete = (id) => {
        setOpenDelete(true);
        setId(id);
    };
    const handleCloseDelete = () => {
        setId("");
        setOpenDelete(false);
    };

    const handleGetSubCategory = () => {
        PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_CLIENT_SUB_CATEGORY).then((res) => {
            setLoading(false);
            if (res.status === 200) {
                setData(res.data);
                setFilteredData(res.data);
            }
        });
    };

    const handleGetCategory = () => {
        PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_CLIENT_CATEGORY).then((res) => {
            setLoading(false);
            if (res.status === 200) {
                const activeState = res?.data?.filter((state) => state?.isActive);
                setCategoryData(activeState);
            }
        });
    };

    useEffect(() => {
        setLoading(true);
        handleGetSubCategory();
        handleGetCategory();
    }, []);

    const handleAddEditCategory = (values) => {
        setButtonSpinner(true)
        const urlEncoded = new URLSearchParams();
        if (id) {
            urlEncoded.append("id", id);
        }
        urlEncoded.append("categoryId", values?.categoryId);
        urlEncoded.append("subCategoryName", values?.subCategoryName);

        PagesIndex.apiPostHandler(PagesIndex.Api.ADD_EDIT_CLIENT_SUB_CATEGORY, urlEncoded)
            .then((res) => {
                setButtonSpinner(false)
                if (res.status === 201 || res.status === 200) {
                    handleClose();
                    handleGetSubCategory();
                    setSearchValue("");
                    PagesIndex.toasterSuccess(res?.message);
                } else {
                    PagesIndex.toasterError(res?.message);
                }
            })
            .catch((err) => {
                PagesIndex.toasterError(err.response.message);
            });
    };

    const handleDeleteCategory = () => {
        setDeleteButtonSpinner(true)
        const urlEncoded = new URLSearchParams();
        urlEncoded.append("id", id);
        PagesIndex.apiPostHandler(PagesIndex.Api.DELETE_CLIENT_SUB_CATEGORY, urlEncoded).then(
            (res) => {
                setDeleteButtonSpinner(false)
                if (res.status === 200) {
                    handleCloseDelete();
                    handleGetSubCategory();
                    PagesIndex.toasterSuccess(res?.message);
                } else {
                    PagesIndex.toasterError(res?.message);
                }
            }
        );
    };
    const requestSearch = (e) => {
        setSearchValue(e.target.value);
        let filteredData = data.filter((data) =>
            data?.subCategoryName
                ?.toLowerCase()
                .includes(e?.target?.value?.trim()?.toLowerCase())
        );
        setCurrentPage(1);
        setFilteredData(filteredData);
    };
    const handleOpenStatusModal = (id) => {
        setId(id);
        setOpenStatusModal(true);
    };
    const handleCloseStatusModal = () => {
        setId("");
        setOpenStatusModal(false);
    };

    const handleStatus = () => {
        setStatusButtonSpinner(true)
        const urlEncoded = new URLSearchParams();
        urlEncoded.append("id", id);
        PagesIndex.apiPostHandler(
            PagesIndex.Api.ISACTIVE_CLIENT_SUB_CATEGORY,
            urlEncoded
        ).then((res) => {
            setStatusButtonSpinner(false)
            if (res.status === 200) {
                handleCloseStatusModal();
                PagesIndex.toasterSuccess(res?.message);
                handleGetSubCategory();
            } else {
                PagesIndex.toasterError(res?.message);
            }
        });
    };

    const renderTableRows = (setFieldValue) => {
        return filteredData
            ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
            ?.map((data) => {
                return (
                    <Index.TableRow
                        sx={{
                            "&:last-child td, &:last-child th": {
                                border: 0,
                            },
                        }}
                        key={data?._id}
                    >
                        <Index.TableCell component="td" variant="td" className="table-td">
                            <Index.Typography className="admin-table-data-text">
                                {" "}
                                {data?.subCategoryName ? data?.subCategoryName : "-"}
                            </Index.Typography>
                        </Index.TableCell>

                        <Index.TableCell component="td" variant="td" className="table-td">
                            <Index.Typography className="admin-table-data-text">
                                {" "}
                                {data?.categoryId?.categoryName ? data?.categoryId?.categoryName : "-"}
                            </Index.Typography>
                        </Index.TableCell>

                        <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="right"
                        >
                            <Index.Typography className="admin-table-data-text">
                                {" "}
                                {data?.createdBy?.name || "-"}
                            </Index.Typography>
                        </Index.TableCell>

                        <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="right"
                        >
                            <Index.Typography className="admin-table-data-text">
                                {" "}
                                {data?.updatedBy?.name || "-"}
                            </Index.Typography>
                        </Index.TableCell>

                        {(adminDetails?.roleId?.permissions?.includes("client_sub_category_edit") ||
                            adminDetails?.roleId?.permissions?.includes(
                                "client_sub_category_delete"
                            )) && (
                                <Index.TableCell
                                    component="td"
                                    variant="td"
                                    align="right"
                                    className="table-td"
                                >
                                    <Index.Box className="admin-switch-main">
                                        <Index.FormControlLabel
                                            control={<IOSSwitch sx={{ m: 1 }} />}
                                            className="admin-switch-lable"
                                            checked={data?.isActive}
                                            onChange={() => {
                                                handleOpenStatusModal(data?._id);
                                            }}
                                        />
                                    </Index.Box>
                                </Index.TableCell>
                            )}

                        {(adminDetails?.roleId?.permissions?.includes("client_sub_category_edit") ||
                            adminDetails?.roleId?.permissions?.includes("client_sub_category_log") ||
                            adminDetails?.roleId?.permissions?.includes(
                                "client_sub_category_delete"
                            )) && (
                                <Index.TableCell
                                    component="td"
                                    variant="td"
                                    align="right"
                                    className="table-td"
                                >
                                    <Index.Box className="admin-table-data-btn-flex ">
                                        {adminDetails?.roleId?.permissions?.includes(
                                            "client_sub_category_edit"
                                        ) && (
                                                <Index.Tooltip
                                                    title="Edit"
                                                    arrow
                                                    placement="bottom"
                                                    className="admin-tooltip"
                                                >
                                                    <Index.Button
                                                        className="admin-table-data-btn"
                                                        onClick={() => {
                                                            setId(data?._id);
                                                            handleOpen("Edit", data);
                                                            setFieldValue("categoryId", data?.categoryId?._id)
                                                            setFieldValue("subCategoryName", data?.subCategoryName)
                                                        }}
                                                    >
                                                        <img
                                                            src={PagesIndex.Svg.blueedit}
                                                            className="admin-icon"
                                                            alt="Edit"
                                                        ></img>
                                                    </Index.Button>
                                                </Index.Tooltip>
                                            )}

                                        {adminDetails?.roleId?.permissions?.includes(
                                            "client_sub_category_log"
                                        ) && (
                                                <Index.Tooltip
                                                    title="Log"
                                                    arrow
                                                    placement="bottom"
                                                    className="admin-tooltip"
                                                >
                                                    <Index.Button
                                                        className="admin-table-data-btn"
                                                        onClick={() => handleOpenLogModal(data?._id)}
                                                    >
                                                        <img
                                                            src={PagesIndex.Svg.logicon}
                                                            className="admin-icon"
                                                            alt="Log"
                                                        ></img>
                                                    </Index.Button>
                                                </Index.Tooltip>
                                            )}

                                        {adminDetails?.roleId?.permissions?.includes(
                                            "client_sub_category_delete"
                                        ) && (
                                                <Index.Tooltip
                                                    title="Delete"
                                                    arrow
                                                    placement="bottom"
                                                    className="admin-tooltip"
                                                >
                                                    <Index.Button
                                                        className="admin-table-data-btn"
                                                        onClick={() => handleOpenDelete(data?._id)}
                                                    >
                                                        <img
                                                            src={PagesIndex.Svg.trash}
                                                            className="admin-icon"
                                                            alt="Delete"
                                                        ></img>
                                                    </Index.Button>
                                                </Index.Tooltip>
                                            )}
                                    </Index.Box>
                                </Index.TableCell>
                            )}
                    </Index.TableRow>
                );
            });
    };

    const renderContent = (setFieldValue) => {
        if (filteredData?.length) {
            return renderTableRows(setFieldValue);
        } else {
            return <PagesIndex.DataNotFound />;
        }
    };

    const renderActivityTableRows = () => {
        return activityLog
            .slice()
            .reverse()
            .map((data) => {
                let updatedInfo = "";

                if (
                    data?.previousSubCategoryName !== data?.subCategoryName ||
                    data?.currentCategoryId?.categoryName !== data?.previousCategoryId?.categoryName ||
                    data?.previousStatus !== data?.currentStatus
                ) {
                    if (data?.updatedBy?.name) {
                        updatedInfo = `${data?.updatedBy?.name}-${data?.dateTime
                            ? PagesIndex.moment(data?.dateTime).format(
                                "DD/MM/YYYY HH:mm:ss"
                            )
                            : "-"
                            }`;
                    } else {
                        updatedInfo = "-";
                    }
                }
                return (
                    <Index.TableRow
                        sx={{
                            "&:last-child td, &:last-child th": {
                                border: 0,
                            },
                        }}
                        key={data?._id}
                    >
                        {updatedInfo !== "" && (
                            <>
                                <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                >
                                    <Index.Typography className="admin-table-data-text">
                                        <Index.Tooltip
                                            title={`${data?.previousSubCategoryName !== data?.subCategoryName
                                                ? `${data?.updatedBy?.name} has updated Sub Category from ${data?.previousSubCategoryName
                                                } to ${data?.subCategoryName + " "}`
                                                : ""
                                                }`}
                                            arrow
                                            placement="top"
                                            className="admin-tooltip"
                                        >
                                            {data?.previousSubCategoryName !== data?.subCategoryName && (
                                                <span>
                                                    <span className="span-bold">
                                                        {data?.updatedBy?.name}
                                                    </span>{" "} has updated Sub Category from{" "}
                                                    <span className="span-bold">
                                                        {data?.previousSubCategoryName}
                                                    </span>{" "}
                                                    to{" "}
                                                    <span className="span-bold">
                                                        {data?.subCategoryName + " "}
                                                    </span>
                                                </span>
                                            )}
                                        </Index.Tooltip>
                                    </Index.Typography>

                                    <Index.Typography className="admin-table-data-text">
                                        <Index.Tooltip
                                            title={`${data?.previousCategoryId?.categoryName !== data?.currentCategoryId?.categoryName
                                                ? `${data?.updatedBy?.name} has updated category name from ${data?.previousCategoryId?.categoryName
                                                } to ${data?.currentCategoryId?.categoryName + " "}`
                                                : ""
                                                }`}
                                            arrow
                                            placement="top"
                                            className="admin-tooltip"
                                        >
                                            {data?.previousCategoryId?.categoryName !== data?.currentCategoryId?.categoryName && (
                                                <span>
                                                    <span className="span-bold">
                                                        {data?.updatedBy?.name}
                                                    </span>{" "} has updated category name from{" "}
                                                    <span className="span-bold">
                                                        {data?.previousCategoryId?.categoryName}
                                                    </span>{" "}
                                                    to{" "}
                                                    <span className="span-bold">
                                                        {data?.currentCategoryId?.categoryName + " "}
                                                    </span>
                                                </span>
                                            )}
                                        </Index.Tooltip>
                                    </Index.Typography>

                                    <Index.Typography className="admin-table-data-text">
                                        <Index.Tooltip
                                            title={`${data?.previousStatus !== data?.currentStatus
                                                ? `${data?.updatedBy?.name} Status has been updated from ${!data?.currentStatus ? "Active" : "Deactive"
                                                } to ${data?.currentStatus ? "Active" : "Deactive"} `
                                                : ""
                                                }`}
                                            arrow
                                            placement="top"
                                            className="admin-tooltip"
                                        >
                                            {data?.previousStatus !== data?.currentStatus && (
                                                <span>
                                                    <span className="span-bold">
                                                        {data?.updatedBy?.name}
                                                    </span>{" "} Status  has been updated from{" "}
                                                    <span className="span-bold">
                                                        {!data?.currentStatus ? "Active" : "Deactive"}
                                                    </span>{" "}
                                                    to&nbsp;
                                                    <span className="span-bold">
                                                        {data?.currentStatus ? "Active" : "Deactive"}
                                                    </span>
                                                </span>
                                            )}
                                        </Index.Tooltip>
                                    </Index.Typography>

                                </Index.TableCell>

                                <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                >
                                    {updatedInfo}
                                </Index.TableCell>
                            </>
                        )
                        }
                    </Index.TableRow >
                );
            });
    };

    const renderActivityLogContent = () => {
        return (
            <>
                {!activityLoading &&
                    activityLog?.length > 0 &&
                    renderActivityTableRows()}
                {!activityLoading &&
                    (!activityLog ||
                        activityLog.length === 0 ||
                        filterLog.length === 0) && <PagesIndex.DataNotFound />}
            </>
        );
    };

    return (
        <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleAddEditCategory}
            initialValues={initialValues}
            validationSchema={PagesIndex.clientSubCategorySchema}
            innerRef={formRef}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
            }) => (
                <>
                    <Index.Box className="admin-dashboard-content admin-user-list-content">
                        <Index.Box className="admin-user-list-flex admin-page-title-main">
                            <Index.Typography
                                className="admin-page-title admin-user-list-page-title"
                                component="h2"
                                variant="h2"
                            >
                                Sub Category List
                            </Index.Typography>
                            <Index.Box className="admin-userlist-btn-flex">
                                <Index.Box className="admin-search-main">
                                    <Index.Box className="admin-search-box">
                                        <Index.Box className="admin-form-group">
                                            <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                className="admin-form-control"
                                                placeholder="Search"
                                                value={searchValue}
                                                onChange={requestSearch}
                                            />
                                            <img
                                                src={PagesIndex.Svg.search}
                                                className="admin-search-grey-img admin-icon"
                                                alt="search"
                                            ></img>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                                {adminDetails?.roleId?.permissions?.includes(
                                    "client_sub_category_add"
                                ) && (
                                        <Index.Box className="admin-userlist-inner-btn-flex">
                                            <Index.Box className="admin-adduser-btn-main btn-main-primary">
                                                <Index.Button
                                                    className="admin-adduser-btn btn-primary"
                                                    onClick={() => {
                                                        setId("");
                                                        handleOpen("Add");
                                                    }}
                                                >
                                                    <img
                                                        src={PagesIndex.Svg.plus}
                                                        className="admin-plus-icon"
                                                        alt="plus"
                                                    />
                                                    <span>Add Sub Category</span>
                                                </Index.Button>
                                            </Index.Box>
                                        </Index.Box>
                                    )}
                            </Index.Box>
                        </Index.Box>

                        <Index.Box className="common-card">
                            <Index.Box
                                className={`admin-category-table-main ${loading ? "" : "page-table-main"
                                    }`}
                            >
                                <Index.TableContainer
                                    component={Index.Paper}
                                    className="table-container"
                                >
                                    {loading ? (
                                        <PagesIndex.Loader />
                                    ) : (
                                        <Index.Table aria-label="simple table" className="table">
                                            <Index.TableHead className="table-head">
                                                <Index.TableRow className="table-row">

                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                        width="30%"
                                                    >
                                                        Sub Category Name
                                                    </Index.TableCell>

                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                        width="30%"
                                                    >
                                                        Category Name
                                                    </Index.TableCell>

                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                        width="12%"
                                                        align="right"
                                                    >
                                                        Created By
                                                    </Index.TableCell>

                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                        width="12%"
                                                        align="right"
                                                    >
                                                        Updated By
                                                    </Index.TableCell>

                                                    {(adminDetails?.roleId?.permissions?.includes(
                                                        "client_sub_category_edit"
                                                    ) ||
                                                        adminDetails?.roleId?.permissions?.includes(
                                                            "client_sub_category_delete"
                                                        )) && (
                                                            <Index.TableCell
                                                                component="th"
                                                                variant="th"
                                                                className="table-th"
                                                                width="6%"
                                                                align="right"
                                                            >
                                                                Status
                                                            </Index.TableCell>
                                                        )}

                                                    {(adminDetails?.roleId?.permissions?.includes(
                                                        "client_sub_category_edit"
                                                    ) ||
                                                        adminDetails?.roleId?.permissions?.includes(
                                                            "client_sub_category_log"
                                                        ) ||
                                                        adminDetails?.roleId?.permissions?.includes(
                                                            "client_sub_category_delete"
                                                        )) && (
                                                            <Index.TableCell
                                                                component="th"
                                                                variant="th"
                                                                className="table-th"
                                                                width="4%"
                                                                align="right"
                                                            >
                                                                Action
                                                            </Index.TableCell>
                                                        )}
                                                </Index.TableRow>
                                            </Index.TableHead>
                                            <Index.TableBody className="table-body">
                                                {renderContent(setFieldValue)}
                                            </Index.TableBody>
                                        </Index.Table>
                                    )}
                                </Index.TableContainer>
                            </Index.Box>
                            <Index.Box className="admin-pagination-main">
                                {filteredData?.length ? (
                                    <Index.Pagination
                                        page={currentPage}
                                        count={Math.ceil(filteredData?.length / 10)}
                                        onChange={(event, newPage) => setCurrentPage(newPage)}
                                        variant="outlined"
                                        shape="rounded"
                                        className="admin-pagination"
                                    />
                                ) : (
                                    ""
                                )}
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>

                    <Index.Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className="admin-modal"
                    >
                        <form onSubmit={handleSubmit} noValidate="novalidate">
                            <Index.Box
                                sx={style}
                                className="admin-add-user-modal-inner-main admin-modal-inner"
                            >
                                <Index.Box className="admin-modal-header">
                                    <Index.Typography
                                        id="modal-modal-title"
                                        className="admin-modal-title"
                                        variant="h6"
                                        component="h2"
                                    >
                                        {addOrEdit} Sub Category
                                    </Index.Typography>
                                    {/* <Index.Box className="modal-close-btn" onClick={handleClose}>
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Box> */}
                                </Index.Box>
                                <Index.Box
                                    className="admin-modal-hgt-scroll cus-scrollbar"
                                    id="scrollDiv"
                                >
                                    {/* <ScrollIntoView /> */}
                                    <Index.Box className="admin-input-box admin-modal-input-box">
                                        <Index.FormHelperText className="admin-form-lable">
                                            Category Name<span className="astrick-sing">*</span>
                                        </Index.FormHelperText>
                                        <Index.Box className="admin-form-group">
                                            <Index.Autocomplete
                                                className="admin-auto-complete-control"
                                                options={categoryData || []}
                                                id="categoryId"
                                                closeText={""}
                                                openText={""}
                                                clearText={""}
                                                value={
                                                    values?.categoryId
                                                        ? categoryData?.find(
                                                            (o) => o._id === values.categoryId
                                                        ) ?? null
                                                        : null
                                                }
                                                onChange={(e, value) => {
                                                    setFieldValue("categoryId", value?._id);
                                                }}
                                                getOptionLabel={(option) => option?.categoryName}
                                                renderOption={(props, item) => (
                                                    <li {...props} key={item?._id}>
                                                        {item?.categoryName}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <Index.TextField
                                                        fullWidth
                                                        className="admin-form-control"
                                                        name="state"
                                                        {...params}
                                                        size="small"
                                                        variant="outlined"
                                                        placeholder="Select Category Name"
                                                    />
                                                )}
                                            />
                                            {errors.categoryId && touched?.categoryId && (
                                                <PagesIndex.FormHelperText className="admin-error-text">
                                                    {errors.categoryId}
                                                </PagesIndex.FormHelperText>
                                            )}
                                        </Index.Box>
                                    </Index.Box>

                                    <Index.Box className="admin-input-box admin-modal-input-box">
                                        <Index.FormHelperText className="admin-form-lable">
                                            Sub Category Name<span className="astrick-sing">*</span>
                                        </Index.FormHelperText>
                                        <Index.Box className="admin-form-group">
                                            <Index.TextField
                                                name="subCategoryName"
                                                fullWidth
                                                id="subCategoryName"
                                                className="admin-form-control"
                                                placeholder="Enter Sub Category Name"
                                                onChange={handleChange}
                                                value={values?.subCategoryName}
                                                error={errors.subCategoryName && touched.subCategoryName}
                                                inputProps={{ maxLength: 40 }}
                                            />
                                            {touched.subCategoryName && errors.subCategoryName && (
                                                <PagesIndex.FormHelperText className="admin-error-text">
                                                    {errors.subCategoryName}
                                                </PagesIndex.FormHelperText>
                                            )}
                                        </Index.Box>
                                    </Index.Box>

                                </Index.Box>
                                <Index.Box className="modal-footer">
                                    <Index.Box className="admin-modal-user-btn-flex">
                                        <Index.Box className="admin-discard-btn-main border-btn-main">
                                            <Index.Button
                                                className="admin-discard-user-btn border-btn"
                                                onClick={handleClose}
                                                disabled={buttonSpinner}
                                            >
                                                Discard
                                            </Index.Button>
                                        </Index.Box>
                                        <Index.Box className="admin-save-btn-main btn-main-primary">
                                            <Index.Button
                                                className={`${id ? "update-btn" : "save-btn"} admin-save-user-btn btn-primary`}
                                                type="submit"
                                                disabled={buttonSpinner}
                                            >
                                                {buttonSpinner ? <PagesIndex.Spinner /> :
                                                    (<>
                                                        <img
                                                            src={PagesIndex.Svg.save}
                                                            className="admin-user-save-icon"
                                                            alt="Save"
                                                        />
                                                        <span>{id ? "Update" : "Add"}</span>
                                                    </>)
                                                }
                                            </Index.Button>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </form>
                    </Index.Modal>

                    <Index.Modal
                        open={openDelete}
                        onClose={handleCloseDelete}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className="admin-modal"
                    >
                        <Index.Box
                            sx={style}
                            className="admin-delete-modal-inner-main admin-modal-inner"
                        >
                            <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                                <Index.Box className="admin-modal-circle-main">
                                    <img
                                        src={PagesIndex.Svg.closecircle}
                                        className="admin-user-circle-img"
                                        alt="Close"
                                    />
                                </Index.Box>
                                <Index.Typography
                                    className="admin-delete-modal-title"
                                    component="h2"
                                    variant="h2"
                                >
                                    Are you sure?
                                </Index.Typography>
                                <Index.Typography
                                    className="admin-delete-modal-para admin-common-para"
                                    component="p"
                                    variant="p"
                                >
                                    Do you really want to delete this sub category? This process
                                    cannot be undone.
                                </Index.Typography>
                                <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
                                    <Index.Button
                                        className="admin-modal-cancel-btn border-btn"
                                        onClick={handleCloseDelete}
                                        disabled={deleteButtonSpinner}
                                    >
                                        Cancel
                                    </Index.Button>

                                    <Index.Button
                                        className="delete-user admin-modal-delete-btn btn"
                                        onClick={handleDeleteCategory}
                                        disabled={deleteButtonSpinner}
                                    >
                                        {deleteButtonSpinner ? <PagesIndex.Spinner /> : <span>Delete</span>
                                        }
                                    </Index.Button>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Modal>

                    <Index.Modal
                        open={openLogModal}
                        onClose={handleCloseLogModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className="admin-modal"
                    >
                        <Index.Box
                            sx={style}
                            className="admin-log-modal-inner-main admin-modal-inner"
                        >
                            <Index.Box className="admin-modal-header">
                                <Index.Typography
                                    id="modal-modal-title"
                                    className="admin-modal-title"
                                    variant="h6"
                                    component="h2"
                                >
                                    {" "}
                                    Activity Log
                                </Index.Typography>
                                <Index.Button
                                    className="modal-close-btn"
                                    onClick={handleCloseLogModal}
                                >
                                    <img
                                        src={PagesIndex.Svg.closeblack}
                                        className="admin-modal-close-icon"
                                        alt="Close"
                                    />
                                </Index.Button>
                            </Index.Box>
                            <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                                <Index.Box className="common-card">
                                    <Index.Box className="page-table-main category-activity-table-main">
                                        <Index.TableContainer
                                            component={Index.Paper}
                                            className="table-container"
                                        >
                                            {activityLoading ? (
                                                <PagesIndex.Loader />
                                            ) : (
                                                <Index.Table aria-label="simple table" className="table">
                                                    <Index.TableHead className="table-head">
                                                        <Index.TableRow className="table-row">
                                                            <Index.TableCell
                                                                component="th"
                                                                variant="th"
                                                                className="table-th"
                                                                width="70%"
                                                            >
                                                                Activity Log
                                                            </Index.TableCell>

                                                            <Index.TableCell
                                                                component="th"
                                                                variant="th"
                                                                className="table-th"
                                                                width="30%"
                                                            >
                                                                Updated Date & Time
                                                            </Index.TableCell>
                                                        </Index.TableRow>
                                                    </Index.TableHead>
                                                    <Index.TableBody className="table-body">
                                                        {renderActivityLogContent()}
                                                    </Index.TableBody>
                                                </Index.Table>
                                            )}
                                        </Index.TableContainer>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Modal>
                    <PagesIndex.ChangeStatusModal
                      statusButtonSpinner={statusButtonSpinner}
                        data={"sub category"}
                        openStatusModal={openStatusModal}
                        handleCloseStatusModal={handleCloseStatusModal}
                        handleChangeStatus={handleStatus}
                    />
                </>
            )}
        </PagesIndex.Formik>
    );
}
