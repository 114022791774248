import usericon from "./images/png/user.png";
import adminlogo from "./images/png/admin-logo.png";
import edit from "./images/png/edit.png";
import userlist1 from "./images/png/userlist1.png";
import bergurmenu from "./images/png/bergur-menu.png";
import close from "./images/png/close.png";
import user1 from "./images/png/user-1.png";
import reaction5 from "./images/png/reaction5.png";

const Png = {
  usericon,
  adminlogo,
  edit,
  userlist1,
  bergurmenu,
  close,
  user1,
  reaction5,
};

export default Png;
