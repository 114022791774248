import React, { useState } from "react";
import "./profileDetails.css";
import "./profileDetails.responsive.css";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function ProfileDetails() {
  const [seriesPie, setSeriesPie] = useState([44, 55, 13, 43]);
  const [optionsPie, setOptionsPie] = useState({
    chart: {
      width: 500,
      height: 400,
      type: "pie",
    },
    stroke: {
      width: 0
    },
    dataLabels: {
      style: {
        fontSize: "10px",
        fontWeight: 100,
      },
      formatter: function (val) {
        return val == 100 ? val : val.toFixed(2) + '%';
      },
    },
    labels: ["COMEDY", "ACTION", "THRILLER", "DRAMA"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  const [seriesBar, setSeriesBar] = useState([
    {
      name: "Inflation",
      data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
    },
  ]);
  const [optionsBar, setOptionsBar] = useState({
    chart: {
      height: 300,
      type: "bar",
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "10px",
        fontWeight: 100,
      },
      formatter: function (val) {
        return val == 100 ? val : val.toFixed(2) + '%';
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },

    xaxis: {
      categories: ["COMEDY", "ACTION", "THRILLER", "DRAMA"],
      position: "top",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val + "%";
        },
      },
    },
  });

  // add user modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Index.Box className="admin-dashboard-content admin-profile-details-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-view-page-title"
          component="h2"
          variant="h2"
        >
          Profile Details
        </Index.Typography>
      </Index.Box>

      <Index.Box className="profile-card-main common-card">
        <Index.Box className="profile-details-card">
          <Index.Box className="profile-details-content-flex">
            <Index.Box className="profile-details-img-flex">
              <Index.Box className="admin-file-upload-btn-main">
                <img
                  src={PagesIndex.Png.usericon}
                  className="admin-upload-profile-img"
                  alt="upload img"
                ></img>
                <Index.Button
                  variant="contained"
                  component="label"
                  className="admin-file-upload-btn"
                >
                  <img
                    src={PagesIndex.Svg.edit}
                    className="admin-upload-icon-img"
                    alt="upload img"
                  ></img>
                  <input hidden accept="image/*" multiple type="file" />
                </Index.Button>
              </Index.Box>
              <Index.Box className="profile-content-main">
                <Index.Typography className="profile-user-name">
                  John Doe
                </Index.Typography>
                <Index.Typography className="profile-user-email">
                  johndoe@gmail.com
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-userlist-inner-btn-flex">
              <Index.Box className="admin-icons-btn-main btn-main-primary">
                <Index.Tooltip
                  title="Edit"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-icons-btn btn-primary"
                    type="submit"
                  >
                    <img
                      src={PagesIndex.Svg.editwhite}
                      className="admin-icons"
                      alt="edit"
                    />
                  </Index.Button>
                </Index.Tooltip>
              </Index.Box>
              <Index.Box className="admin-icons-btn-main btn-main-primary">
                <Index.Tooltip
                  title="Delete"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-icons-btn btn-primary"
                    type="submit"
                  >
                    <img
                      src={PagesIndex.Svg.deleteicon}
                      className="admin-icons"
                      alt="Delete"
                    />
                  </Index.Button>
                </Index.Tooltip>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="profile-view-content-main">
          <Index.Box className="profile-content-box">
            <Index.Box className="profile-content-flex">
              <Index.Typography className="profile-content-lable">
                DOB:
              </Index.Typography>
              <Index.Typography className="profile-content-data">
                December 8th 1999
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="profile-content-box">
            <Index.Box className="profile-content-flex">
              <Index.Typography className="profile-content-lable">
                Phone No.:
              </Index.Typography>
              <Index.Typography className="profile-content-data">
                +1(555) 123-4567
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="profile-content-box">
            <Index.Box className="profile-content-flex">
              <Index.Typography className="profile-content-lable">
                City:
              </Index.Typography>
              <Index.Typography className="profile-content-data">
                India
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="profile-content-box">
            <Index.Box className="profile-content-flex">
              <Index.Typography className="profile-content-lable">
                Age:
              </Index.Typography>
              <Index.Typography className="profile-content-data">
                24 Years
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="profile-content-box">
            <Index.Box className="profile-content-flex">
              <Index.Typography className="profile-content-lable">
                Account Creation Date:
              </Index.Typography>
              <Index.Typography className="profile-content-data">
                2024-01-17
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="profile-content-box">
            <Index.Box className="profile-content-flex">
              <Index.Typography className="profile-content-lable">
                Gender:
              </Index.Typography>
              <Index.Typography className="profile-content-data">
                Male
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="analytic-title-flex">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            className="admin-sub-title"
            component="h2"
            variant="h2"
          >
            Analytics
          </Index.Typography>
        </Index.Box>
        <Index.Box className="admin-userlist-inner-btn-flex">
          <Index.Box className="admin-adduser-btn-main border-btn-main">
            <Index.Button
              className="admin-adduser-btn border-btn"
              onClick={handleOpen}
            >
              Watch History
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="chart-row-main">
        <Index.Box className="chart-col common-card">
          <Index.Box className="chart-title-main">
            <Index.Typography
              className="chart-title"
              component="h2"
              variant="h2"
            >
              Content Duration Distribution
            </Index.Typography>
          </Index.Box>
          <Index.Box className="pie-chart-main">
            <Index.Box className="chart-box">
              <PagesIndex.ReactApexChart
                options={optionsPie}
                series={seriesPie}
                type="pie"
                width={optionsPie.chart.width}
                height={optionsPie.chart.height}
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="chart-col common-card">
          <Index.Box className="chart-title-main">
            <Index.Typography
              className="chart-title"
              component="h2"
              variant="h2"
            >
              Content Duration Distribution
            </Index.Typography>
          </Index.Box>
          <Index.Box className="chart-box">
            <PagesIndex.ReactApexChart
              options={optionsBar}
              series={seriesBar}
              type="bar"
            />
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="watch-rasio-row">
        <Index.Box className="watch-rasio-col">
          <Index.Box className="rasio-title-flex">
            <Index.Box className="admin-sub-title-main">
              <Index.Typography
                className="admin-sub-title"
                component="h2"
                variant="h2"
              >
                Category-wise watching rasio
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="common-card">
            <Index.Box className="admin-profile-details-table-main page-table-main">
              <Index.TableContainer
                component={Index.Paper}
                className="table-container"
              >
                <Index.Table aria-label="simple table" className="table">
                  <Index.TableHead className="table-head">
                    <Index.TableRow className="table-row">
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="3%"
                      >
                        Categories
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="5%"
                      >
                        Ads Clicked
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="5%"
                      >
                        % Watched
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="table-body">
                    <Index.TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          Comedy
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          07
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          53%
                        </Index.Typography>
                      </Index.TableCell>
                    </Index.TableRow>
                    <Index.TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          Action
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          11
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          46%
                        </Index.Typography>
                      </Index.TableCell>
                    </Index.TableRow>
                    <Index.TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          Thriller
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          03
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          79%
                        </Index.Typography>
                      </Index.TableCell>
                    </Index.TableRow>
                    <Index.TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          Horror
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          00
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          17%
                        </Index.Typography>
                      </Index.TableCell>
                    </Index.TableRow>
                    <Index.TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          Drama
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          21
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          62%
                        </Index.Typography>
                      </Index.TableCell>
                    </Index.TableRow>
                    <Index.TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          Romance
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          47
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          94%
                        </Index.Typography>
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                </Index.Table>
              </Index.TableContainer>
            </Index.Box>
            <Index.Box className="admin-pagination-main">
              <Index.Pagination
                count={3}
                variant="outlined"
                shape="rounded"
                className="admin-pagination"
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="watch-rasio-col">
          <Index.Box className="rasio-title-flex">
            <Index.Box className="admin-sub-title-main">
              <Index.Typography
                className="admin-sub-title"
                component="h2"
                variant="h2"
              >
                Time Duration-wise watching rasio
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="common-card">
            <Index.Box className="admin-profile-details-main page-table-main">
              <Index.TableContainer
                component={Index.Paper}
                className="table-container"
              >
                <Index.Table aria-label="simple table" className="table">
                  <Index.TableHead className="table-head">
                    <Index.TableRow className="table-row">
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="3%"
                      >
                        Most viewed Categories
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="2%"
                      >
                        Ads Clicked
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="5%"
                      >
                        Time Duration
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="table-body">
                    <Index.TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          Comedy
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          07
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          00 to 04
                        </Index.Typography>
                      </Index.TableCell>
                    </Index.TableRow>
                    <Index.TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          Action
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          11
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          04 to 08
                        </Index.Typography>
                      </Index.TableCell>
                    </Index.TableRow>
                    <Index.TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          Thriller
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          03
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          08 to 12
                        </Index.Typography>
                      </Index.TableCell>
                    </Index.TableRow>
                    <Index.TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          Horror
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          00
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          12 to 16
                        </Index.Typography>
                      </Index.TableCell>
                    </Index.TableRow>
                    <Index.TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          Drama
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          21
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          16 to 20
                        </Index.Typography>
                      </Index.TableCell>
                    </Index.TableRow>
                    <Index.TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          Romance
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          47
                        </Index.Typography>
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-data-text">
                          {" "}
                          20 to 24
                        </Index.Typography>
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                </Index.Table>
              </Index.TableContainer>
            </Index.Box>
            <Index.Box className="admin-pagination-main">
              <Index.Pagination
                count={3}
                variant="outlined"
                shape="rounded"
                className="admin-pagination"
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-watch-history-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="admin-modal-title"
              variant="h6"
              component="h2"
            >
              User Watch History
            </Index.Typography>
            <Index.Button className="modal-close-btn" onClick={handleClose}>
              <img
                src={PagesIndex.Svg.closeblack}
                className="admin-modal-close-icon"
                alt="Close"
              />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="common-card">
              <Index.Box className="admin-profile-details-main page-table-main">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  <Index.Table aria-label="simple table" className="table">
                    <Index.TableHead className="table-head">
                      <Index.TableRow className="table-row">
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="4%"
                        >
                          Sr. No.
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="5%"
                        >
                          Category
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="5%"
                        >
                          Video Link
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="6%"
                        >
                          Date & Time
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="5%"
                        >
                          Duration
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="table-body">
                      <Index.TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="table-td"
                        >
                          <Index.Typography className="admin-table-data-text">
                            {" "}
                            1
                          </Index.Typography>
                        </Index.TableCell>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          className="table-td"
                        >
                          <Index.Typography className="admin-table-data-text">
                            {" "}
                            Drama
                          </Index.Typography>
                        </Index.TableCell>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          className="table-td"
                        >
                          <Index.Typography className="admin-table-data-text">
                            {" "}
                            johndoe@example...
                          </Index.Typography>
                        </Index.TableCell>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          className="table-td"
                        >
                          {" "}
                          30 Jan 2024 05:49 PM
                        </Index.TableCell>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          className="table-td"
                        >
                          50 secondas
                        </Index.TableCell>
                      </Index.TableRow>
                      <Index.TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="table-td"
                        >
                          <Index.Typography className="admin-table-data-text">
                            {" "}
                            1
                          </Index.Typography>
                        </Index.TableCell>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          className="table-td"
                        >
                          <Index.Typography className="admin-table-data-text">
                            {" "}
                            Drama
                          </Index.Typography>
                        </Index.TableCell>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          className="table-td"
                        >
                          <Index.Typography className="admin-table-data-text">
                            {" "}
                            johndoe@example...
                          </Index.Typography>
                        </Index.TableCell>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          className="table-td"
                        >
                          {" "}
                          30 Jan 2024 05:49 PM
                        </Index.TableCell>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          className="table-td"
                        >
                          50 secondas
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="admin-pagination-main">
                <Index.Pagination
                  count={3}
                  variant="outlined"
                  shape="rounded"
                  className="admin-pagination"
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </Index.Box>
  );
}
