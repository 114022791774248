import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

export default function BillingInformation(props) {
  const { data, handleOpen } = props;
  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="view-advertiser-details-card">
        <Index.Box className="view-add-common-card common-card">
          <Index.Box className="admin-sub-title-main billing-info-btn-flex">
            <Index.Typography
              className="admin-sub-title"
              component="h2"
              variant="h2"
            >
              Billing Details
            </Index.Typography>
            <Index.Box className="admin-adduser-btn-main btn-main-primary">
              <Index.Button
                className="admin-adduser-btn btn-primary"
                onClick={handleOpen}
              >
                <img
                  src={PagesIndex.Svg.editwhite}
                  className="admin-plus-icon"
                  alt="plus"
                />
                <span> Edit Billing Information</span>
              </Index.Button>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-advertiser-content-main">
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Company Name
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.billingCompName || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Address:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.billingAddress || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Mobile Number:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.billingContact || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Email Id:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.billingEmail || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  GST No.:
                </Index.Typography>

                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.gstNo || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  PAN No.:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.panNo || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
