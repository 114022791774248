import dashboard from "./images/svg/dashboard.svg";
import notification from "./images/svg/notification.svg";
import settings from "./images/svg/settings.svg";
import userlist from "./images/svg/user-list.svg";
import editpage from "./images/svg/edit-page.svg";
import changepassword from "./images/svg/change-password.svg";
import profile from "./images/svg/profile.svg";
import profilegrey from "./images/svg/profile-grey.svg";
import dashicon1 from "./images/svg/dash1.svg";
import dashicon2 from "./images/svg/dash2.svg";
import dashicon3 from "./images/svg/dash3.svg";
import dashicon4 from "./images/svg/dash4.svg";
import save from "./images/svg/save.svg";
import edit from "./images/svg/edit.svg";
import blueedit from "./images/svg/blue-edit.svg";
import trash from "./images/svg/trash.svg";
import yelloweye from "./images/svg/yellow-eye.svg";
import plus from "./images/svg/plus.svg";
import down from "./images/svg/down.svg";
import search from "./images/svg/search.svg";
import closecircle from "./images/svg/close-circle.svg";
import bergurmenu from "./images/svg/bergur-menu.svg";
import paginationleft from "./images/svg/pagination-left.svg";
import paginationright from "./images/svg/pagination-right.svg";
import filter from "./images/svg/filter.svg";
import close from "./images/svg/close.svg";
import instagram from "./images/svg/instagram.svg";
import youtube from "./images/svg/youtube.svg";
import skype from "./images/svg/skype.svg";
import linkdin from "./images/svg/linkedin.svg";
import twitter from "./images/svg/twitter.svg";
import closeblack from "./images/svg/close-black.svg";
import deleteicon from "./images/svg/delete.svg";
import facebook from "./images/svg/facebook.svg";
import discord from "./images/svg/discord.svg";
import usercard from "./images/svg/user-card.svg";
import logout from "./images/svg/log-out.svg";
import termsandconditionicon from "./images/svg/terms-and-conditions.svg";
import abouticon from "./images/svg/about.svg";
import policyicon from "./images/svg/policy.svg";
import profileEditIcon from "./images/svg/profile-edit.svg";
import roleManagment from "./images/svg/role-managment.svg";
import fileupload from "./images/svg/upload.svg";
import email from "./images/svg/email.svg";
import infoicon from "./images/svg/info.svg";
import adminicon from "./images/svg/admin-icon.svg";
import cmsicon from "./images/svg/cms-icon.svg";
import deletewhite from "./images/svg/delete-icon.svg";
import editwhite from "./images/svg/edit-white.svg";
import darkplus from "./images/svg/plus-dark.svg";
import casticon from "./images/svg/cast-icon.svg";
import episodeicon from "./images/svg/episode.svg";
import movieicon from "./images/svg/movie.svg";
import newsicon from "./images/svg/news.svg";
import seasonicon from "./images/svg/season-icon.svg";
import tagicon from "./images/svg/tag-icon.svg";
import webseriesicon from "./images/svg/web-series-icon.svg";
import zoneicon from "./images/svg/zone-icon.svg";
import eventicon from "./images/svg/events-icon.svg";
import sitesettingicon from "./images/svg/site-setting-icon.svg";
import supporticon from "./images/svg/support-icon.svg";
import advertising from "./images/svg/advertising.svg";
import feedbackicon from "./images/svg/feedback-icon.svg";
import categoryIcon from "./images/svg/category-icon.svg";
import faqIcon from "./images/svg/faq-icon.svg";
import stateIcon from "./images/svg/state-icon.svg";
import whiteedit from "./images/svg/white-edit.svg";
import districticon from "./images/svg/district.svg";
import manageadminicon from "./images/svg/mange-admin.svg";
import playicon from "./images/svg/play-button.svg";
import noimgfound from "./images/svg/no-img-found.svg";
import calendaricon from "./images/svg/calendar.svg";
import closeroundicon from "./images/svg/close-round.svg";
import reverticon from "./images/svg/revert-icon.svg";
import blockicon from "./images/svg/block.svg";
import logicon from "./images/svg/logicon.svg";
import copyicon from "./images/svg/copy-icon.svg";
import unlockicon from "./images/svg/unlock.svg";
import unlockroundicon from "./images/svg/unlock-round.svg";
import managenews from "./images/svg/manage-news.svg";
import managemovie from "./images/svg/manage-movie.svg";
import manageadvertiser from "./images/svg/manage-advertiser.svg";
import manageadminuser from "./images/svg/manage-admin-user.svg";
import managelist from "./images/svg/manage-list.svg";
import reaction1 from "./images/svg/reaction5.svg";
import reaction2 from "./images/svg/reaction4.svg";
import reaction3 from "./images/svg/reaction3.svg";
import reaction4 from "./images/svg/reaction2.svg";
import reaction5 from "./images/svg/reaction1.svg";
import changeIcon from "./images/svg/change.svg";
import checkIcon from "./images/svg/check-mark.svg";
import advertisementIcon from "./images/svg/advertisement.svg";
import searchwhite from "./images/svg/search-white.svg";
import sendicon from "./images/svg/send-icon.svg";
import reset from "./images/svg/reset.svg";
import backarrow from "./images/svg/backarrow.svg";
import trashwhite from "./images/svg/trashwhite.svg";
import exporticon from "./images/svg/export.svg";
import usersicon from "./images/svg/users-icon.svg";
import Advertisericons from "./images/svg/Advertiser-icons.svg";
import videoicons from "./images/svg/video-icons.svg";
import sponsericons from "./images/svg/sponser.svg";
import primaryeye from "./images/svg/primary-eye.svg";
import likeicon from "./images/svg/like.svg";
import rightarrow from "./images/svg/right-arrow.svg";
import previousarrow from "./images/svg/previous.svg";
import nextarrow from "./images/svg/next.svg";
import sponsershipicon from "./images/svg/sponsor-icons.svg";
import channel from "./images/svg/channel.svg";
import eductionicon from "./images/svg/eduction.svg";
import totalViews from "./images/svg/total-views.svg";
import liveVideos from "./images/svg/live-videos.svg";
import totalRating from "./images/svg/total-rating.svg";
import closeBold from "./images/svg/close-bold.svg";
import download from "./images/svg/download.svg";
import report from "./images/svg/report.svg";
import phoneDirectory from "./images/svg/phone-directory.svg";
import anlytics from "./images/svg/analytics.svg";
import postVideo from "./images/svg/posted-videeo.svg";
import avatarIcon from "./images/svg/avatar.svg";
import plusWhiteIcon from "./images/svg/plus-white.svg";
import manIcon from "./images/svg/man.svg";
import clientIcon from "./images/svg/client-icon.svg";
import scheduleIcons from "./images/svg/schedule-icons.svg";
import inventoryIcons from "./images/svg/inventory.svg";
import creativeIcon from "./images/svg/creative-icon.svg";
import addBookingIcon from "./images/svg/add-booking.svg";
import slotManagmentIcon from "./images/svg/slot-managment.svg";
import scheduleeManagmentIcon from "./images/svg/schedulee-managment.svg";
import programTypeIcon from "./images/svg/program-type.svg";
import elementIcon from "./images/svg/element-icon.svg";
import TvIcon from "./images/svg/tv.svg";
import CalenderColorIcon from "./images/svg/calendar-color.svg";
import salesOrderIcon from "./images/svg/sales-order.svg";
import phonebookIcon from "./images/svg/phone-book.svg";
import DotsIcon from "./images/svg/dots.svg";
import pdfIcon from "./images/svg/pdf.svg";
import viewbookingIcon from "./images/svg/view-booking.svg";
import salesCampainIcon from "./images/svg/sales-campain.svg";
import subCategoryIcon from "./images/svg/sub-category.svg";
import socialIcon from "./images/svg/social.svg";
import youtubeIcon from "./images/png/youtubeicon.png";
import aws from "./images/png/aws.png";
import server from "./images/png/serverLink.png";
import notificationActive from "./images/svg/notification-white.svg";
import youtubeicons from "./images/svg/youtube-icons.svg";
import awsicons from "./images/png/aws-icon.png";
import internalservericons from "./images/svg/internal-server.svg";
import restore from "./images/svg/restore.svg";
import advertise from "./images/svg/advertise.svg";
import blockad from "./images/svg/block-ad.svg";
import serverLinkIcon from "./images/svg/server-link-icon.svg";
import serverVideoIcon from "./images/svg/server-video-link.svg";

const Svg = {
  dashboard,
  server,
  aws,
  notificationActive,
  youtubeIcon,
  pdfIcon,
  anlytics,
  liveVideos,
  phoneDirectory,
  postVideo,
  totalRating,
  totalViews,
  notification,
  settings,
  report,
  changepassword,
  editpage,
  channel,
  userlist,
  profile,
  dashicon1,
  dashicon2,
  dashicon3,
  dashicon4,
  save,
  edit,
  blueedit,
  trash,
  yelloweye,
  plus,
  down,
  search,
  closecircle,
  profilegrey,
  bergurmenu,
  paginationleft,
  paginationright,
  filter,
  close,
  instagram,
  youtube,
  skype,
  twitter,
  linkdin,
  closeblack,
  deleteicon,
  facebook,
  discord,
  usercard,
  logout,
  termsandconditionicon,
  abouticon,
  policyicon,
  profileEditIcon,
  roleManagment,
  fileupload,
  categoryIcon,
  faqIcon,
  stateIcon,
  email,
  infoicon,
  adminicon,
  cmsicon,
  deletewhite,
  editwhite,
  darkplus,
  casticon,
  episodeicon,
  movieicon,
  newsicon,
  seasonicon,
  tagicon,
  webseriesicon,
  zoneicon,
  eventicon,
  sitesettingicon,
  supporticon,
  advertising,
  feedbackicon,
  whiteedit,
  districticon,
  manageadminicon,
  playicon,
  noimgfound,
  calendaricon,
  closeroundicon,
  reverticon,
  blockicon,
  logicon,
  copyicon,
  unlockicon,
  unlockroundicon,
  managenews,
  managemovie,
  manageadvertiser,
  manageadminuser,
  managelist,
  reaction1,
  reaction2,
  reaction3,
  reaction4,
  reaction5,
  changeIcon,
  checkIcon,
  advertisementIcon,
  searchwhite,
  sendicon,
  reset,
  backarrow,
  trashwhite,
  exporticon,
  usersicon,
  Advertisericons,
  videoicons,
  sponsericons,
  primaryeye,
  likeicon,
  rightarrow,
  previousarrow,
  nextarrow,
  sponsershipicon,
  eductionicon,
  closeBold,
  download,
  avatarIcon,
  plusWhiteIcon,
  manIcon,
  clientIcon,
  scheduleIcons,
  inventoryIcons,
  creativeIcon,
  addBookingIcon,
  slotManagmentIcon,
  scheduleeManagmentIcon,
  programTypeIcon,
  elementIcon,
  TvIcon,
  CalenderColorIcon,
  salesOrderIcon,
  phonebookIcon,
  DotsIcon,
  viewbookingIcon,
  salesCampainIcon,
  subCategoryIcon,
  socialIcon,
  internalservericons,
  awsicons,
  youtubeicons,
  restore,
  advertise,
  blockad,
  serverLinkIcon,
  serverVideoIcon,
};

export default Svg;
