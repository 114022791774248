import React, { useState } from "react";
import Index from "../../../../Index";
import PagesIndex from "../../../../PagesIndex";

export default function WebSeriesWatchlistTable(props) {
  const { data } = props;
  const [currentPage, setCurrentPage] = useState(1);
  return (
    <Index.Box className="common-card">
      <Index.Box className="page-table-main">
        <Index.TableContainer
          component={Index.Paper}
          className="table-container"
        >
          <Index.Table aria-label="simple table" className="table">
            <Index.TableHead className="table-head">
              <Index.TableRow className="table-row">
                <Index.TableCell
                  component="th"
                  variant="th"
                  className="table-th"
                  width="33%"
                >
                  Sr. No.
                </Index.TableCell>
                <Index.TableCell
                  component="th"
                  variant="th"
                  className="table-th"
                  width="33%"
                >
                  User Name
                </Index.TableCell>
              </Index.TableRow>
            </Index.TableHead>
            <Index.TableBody className="table-body">
              {data?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
                .length ? (
                data
                  ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
                  ?.map((data, index) => {
                    return (
                      <Index.TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        key={data?._id}
                      >
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="table-td"
                        >
                          <Index.Typography className="admin-table-data-text">
                            {" "}
                            {(currentPage - 1) * 10 + (index + 1)}
                          </Index.Typography>
                        </Index.TableCell>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="table-td"
                        >
                          <Index.Typography className="admin-table-data-text">
                            {" "}
                            {data?.userId?.firstName} {data?.userId?.lastName}
                          </Index.Typography>
                        </Index.TableCell>
                      </Index.TableRow>
                    );
                  })
              ) : (
                <PagesIndex.DataNotFound />
              )}
            </Index.TableBody>
          </Index.Table>
        </Index.TableContainer>
      </Index.Box>
      <Index.Box className="admin-pagination-main">
        {data?.length ? (
          <Index.Pagination
            page={currentPage}
            count={Math.ceil(data?.length / 10)}
            onChange={(event, newPage) => setCurrentPage(newPage)}
            variant="outlined"
            shape="rounded"
            className="admin-pagination"
          />
        ) : (
          ""
        )}
      </Index.Box>
    </Index.Box>
  );
}
