import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import "./siteSettings.css";
import "./siteSettings.responsive.css";
import PagesIndex from "../../../../component/PagesIndex";
import { styled } from "@mui/material/styles";
import {
  settingValidationSchema,
  settingEditValidationSchema,
} from "../../../../utils/validation/FormikValidation";

// for custom switch design

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  border: "1px solid #b2b3b3",
  width: 12,
  height: 12,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "transparent",
}));
function BpCheckbox(props) {
  return (
    <Index.Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  "&:before": {
    display: "block",
    width: 12,
    height: 12,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
export default function SiteSettings() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const [settingListData, setSettingListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDelete, setopenDelete] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);

  const [message, setMessage] = useState("Copy");

  function randomString(len, an) {
    an = an && an.toLowerCase();
    var str = "",
      i = 0,
      min = an === "a" ? 10 : 0,
      max = an === "n" ? 10 : 62;
    for (; i++ < len; ) {
      var r = (Math.random() * (max - min) + min) << 0;
      str += String.fromCharCode((r += r > 9 ? (r < 36 ? 55 : 61) : 48));
    }
    return str;
  }

  let fontSize = [
    {
      size: 12,
    },
    {
      size: 13,
    },
    {
      size: 14,
    },
    {
      size: 15,
    },
    {
      size: 16,
    },
    {
      size: 17,
    },
    {
      size: 18,
    },
    {
      size: 19,
    },
    {
      size: 20,
    },
    {
      size: 21,
    },
    {
      size: 22,
    },
    {
      size: 23,
    },
    {
      size: 24,
    },
  ];

  let initialValue = {
    id: settingListData ? settingListData?._id : "",
    adsPlayTime: settingListData ? settingListData?.adsPlayTime : "",
    apiKey: settingListData ? settingListData?.apiKey : "",
    liveNews: settingListData?.liveNews
      ? settingListData?.liveNews
      : [
          { videoUrl: "", isActive: false },
          { videoUrl: "", isActive: false },
          { videoUrl: "", isActive: false },
        ],
    showInterests: settingListData ? settingListData.showInterests : false,
    // eventNotifyBeforeTime: settingListData?.eventNotifyBeforeTime ? settingListData?.eventNotifyBeforeTime : 1,
    emailList: settingListData?.eventEmail ? settingListData?.eventEmail : [],
    salesOrderEmailApproval: settingListData?.salesOrderEmailApproval
      ? settingListData?.salesOrderEmailApproval
      : [],
    isBanner: settingListData?.isBanner ? settingListData?.isBanner : false,
    isMovie: settingListData?.isMovie ? settingListData?.isMovie : false,
    isWebSeries: settingListData?.isWebSeries
      ? settingListData?.isWebSeries
      : false,
    // image: settingListData?.logo ? settingListData?.logo : null,
    image: "",
    bgImage: "",
    bannerImage: "",
    imageUrl: settingListData?.logo ? settingListData?.logo : null,
    bgImageUrl: settingListData?.backgroundImage
      ? settingListData?.backgroundImage
      : null,
    bannerImageUrl: settingListData?.banner ? settingListData?.banner : null,
    fontTitleColor: settingListData?.fontTitleColor
      ? settingListData?.fontTitleColor
      : "",
    fontMainColor: settingListData?.fontMainColor
      ? settingListData?.fontMainColor
      : "",
    drawerMenuColor: settingListData?.drawerMenuColor
      ? settingListData?.drawerMenuColor
      : "",
    fontSecondaryColor: settingListData?.fontSecondaryColor
      ? settingListData?.fontSecondaryColor
      : "",
    fontMostViewedColor: settingListData?.fontMostViewedColor
      ? settingListData?.fontMostViewedColor
      : "",
    placeholderFontColor: settingListData?.placeholderFontColor
      ? settingListData?.placeholderFontColor
      : "",
    voteColor: settingListData?.voteColor ? settingListData?.voteColor : "",
    ratingWatchListIconColor: settingListData?.ratingWatchListIconColor
      ? settingListData?.ratingWatchListIconColor
      : "",
    statusbar: settingListData?.statusBar ? settingListData?.statusBar : "",
    fontSize: settingListData?.fontSize ? settingListData?.fontSize : "",
    mainCategoryFontSize: settingListData?.mainCategoryfontSize
      ? settingListData?.mainCategoryfontSize
      : "",
    categoryfontSize: settingListData?.categoryfontSize
      ? settingListData?.categoryfontSize
      : "",
    isViewCount: settingListData?.isViewCount
      ? settingListData?.isViewCount
      : false,
    internalUrl: settingListData?.internalUrl
      ? settingListData?.internalUrl
      : false,
  };

  const getSettingData = () => {
    setLoading(true);
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_SETTING_DATA).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setSettingListData(res?.data?.[0]);
      }
    });
  };

  useEffect(() => {
    getSettingData();
  }, []);

  const handleLink1Change = (index, setFieldValue, values) => () => {
    setFieldValue(
      "liveNews",
      values.liveNews.map((item, i) => ({
        ...item,
        isActive: i === index,
      }))
    );
  };
  const handleCloseDelete = () => {
    setopenDelete(false);
  };

  const handleSubmitDeleteImage = (data) => {
    const formdata = new FormData();
    formdata.append("id", settingListData?._id);
    formdata.append("deleteImage", data);
    setDeleteButtonSpinner(true);
    PagesIndex.apiPostHandler(PagesIndex.Api.UPDATE_SETTING, formdata).then(
      (res) => {
        setDeleteButtonSpinner(false);
        if (res.status === 200) {
          handleCloseDelete();
          getSettingData();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const handleSubmit = (values) => {
    setButtonSpinner(true);
    const formdata = new FormData();
    formdata.append("id", values?.id);
    formdata.append("adsPlayTime", values?.adsPlayTime);
    formdata.append("liveNews", JSON.stringify(values?.liveNews));
    formdata.append("showInterests", values?.showInterests);
    formdata.append("isBanner", values?.isBanner);
    formdata.append("isMovie", values?.isMovie);
    formdata.append("apiKey", values?.apiKey);
    formdata.append("isWebSeries", values?.isWebSeries);
    formdata.append("eventEmail", JSON.stringify(values?.emailList));
    formdata.append(
      "salesOrderEmailApproval",
      JSON.stringify(values?.salesOrderEmailApproval)
    );

    formdata.append("fontTitleColor", values?.fontTitleColor);
    formdata.append("placeholderFontColor", values?.placeholderFontColor);
    formdata.append("fontMainColor", values?.fontMainColor);
    formdata.append("fontSecondaryColor", values?.fontSecondaryColor);
    formdata.append("drawerMenuColor", values?.drawerMenuColor);
    formdata.append("fontMostViewedColor", values?.fontMostViewedColor);
    formdata.append(
      "ratingWatchListIconColor",
      values?.ratingWatchListIconColor
    );
    formdata.append("voteColor", values?.voteColor);
    formdata.append("statusBar", values?.statusbar);
    formdata.append("fontSize", values?.fontSize);
    formdata.append("mainCategoryfontSize", values?.mainCategoryFontSize);
    formdata.append("categoryfontSize", values?.categoryfontSize);
    formdata.append("isViewCount", values?.isViewCount);
    formdata.append("internalUrl", values?.internalUrl);
    // formdata.append("eventNotifyBeforeTime", values?.eventNotifyBeforeTime);
    if (values?.image) {
      formdata.append("profile", values?.image);
    }
    if (values?.bgImage) {
      formdata.append("background", values?.bgImage);
    }
    if (values?.bannerImage) {
      formdata.append("banner", values?.bannerImage);
    }

    PagesIndex.apiPostHandler(PagesIndex.Api.UPDATE_SETTING, formdata).then(
      (res) => {
        setButtonSpinner(false);
        if (res.status === 200) {
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };

  // for img
  const renderImg = (values) => {
    if (values.image instanceof Blob) {
      return URL.createObjectURL(values.image);
    } else if (values?.imageUrl) {
      return `${process.env.REACT_APP_IMAGE_ENDPOINT}${values?.imageUrl}`;
    } else {
      return PagesIndex.Svg.noimgfound;
    }
  };
  // for img
  const renderBgImg = (values) => {
    if (values.bgImage instanceof Blob) {
      return URL.createObjectURL(values.bgImage);
    } else if (values?.bgImageUrl) {
      return `${process.env.REACT_APP_IMAGE_ENDPOINT}${values?.bgImageUrl}`;
    } else {
      return PagesIndex.Gif.logoGif;
    }
  };
  // for img
  const renderBannerImage = (values) => {
    if (values.bannerImage instanceof Blob) {
      return URL.createObjectURL(values.bannerImage);
    } else if (values?.bannerImageUrl) {
      return `${process.env.REACT_APP_IMAGE_ENDPOINT}${values?.bannerImageUrl}`;
    } else {
      return PagesIndex.Gif.logoGif;
    }
  };

  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      {loading ? (
        <PagesIndex.PageLoader />
      ) : (
        <PagesIndex.Formik
          enableReinitialize
          initialValues={initialValue}
          onSubmit={handleSubmit}
          validationSchema={
            settingListData?._id
              ? settingEditValidationSchema
              : settingValidationSchema
          }
          validateOnChange={false}
          validateOnBlur={true}
        >
          {({
            values,
            handleChange,
            setFieldError,
            setFieldValue,
            errors,
            touched,
          }) => (
            <PagesIndex.Form>
              <Index.Box className="admin-user-list-flex admin-page-title-main">
                <Index.Typography
                  className="admin-page-title admin-user-list-page-title"
                  component="h2"
                  variant="h2"
                >
                  Site Settings
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-card site-settings-card">
                <Index.Box className="site-settings-card-inner-main">
                  <Index.Box className="site-settings-flex-all">
                    <Index.Box className="site-settings-contnet-box">
                      <Index.Typography className="site-settings-title">
                        Live News
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="site-setting-row">
                    {values?.liveNews?.map((ele, index) => {
                      return (
                        <Index.Box className="site-settings-col" key={ele?._id}>
                          <Index.Box className="admin-input-box site-setting-input-box">
                            <Index.Box className="site-setting-title-flex">
                              <Index.FormHelperText className="admin-form-lable">
                                Video {index + 1}
                              </Index.FormHelperText>
                              <Index.Box className="site-settings-contnet-box">
                                <Index.Box className="admin-switch-main">
                                  <Index.FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                    className="admin-switch-lable"
                                    name={`videoLink.${index}.isActive`}
                                    checked={ele.isActive}
                                    onChange={handleLink1Change(
                                      index,
                                      setFieldValue,
                                      values
                                    )}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                fullWidth
                                className="admin-form-control"
                                name={`videoLink.${index + 1}.videoUrl`}
                                type="text"
                                value={ele.videoUrl}
                                onChange={(e) =>
                                  setFieldValue(
                                    `liveNews[${index}].videoUrl`,
                                    e.target.value
                                  )
                                }
                              />
                              <Index.FormHelperText
                                error={!!errors?.liveNews?.[index]?.videoUrl}
                                className="admin-error-text"
                              >
                                {errors?.liveNews &&
                                typeof errors?.liveNews[index]?.videoUrl ===
                                  "string"
                                  ? errors?.liveNews[index]?.videoUrl
                                  : null}
                              </Index.FormHelperText>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      );
                    })}
                  </Index.Box>

                  <Index.Box className="grid-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        columnGap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="site-settings-col">
                            <Index.Box className="admin-input-box site-setting-input-box">
                              <Index.FormHelperText className="admin-form-lable">
                                Advertisement Play Time (In Seconds)
                              </Index.FormHelperText>
                              <Index.Box className="admin-form-group site-info-flex">
                                <Index.TextField
                                  name="adsPlayTime"
                                  fullWidth
                                  onKeyDown={(e) => {
                                    if (
                                      e.key === "e" ||
                                      e.key === "E" ||
                                      e.key === "-" ||
                                      e.key === "." ||
                                      e.key === "+"
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onWheelCapture={(e) => {
                                    e.target.blur();
                                  }}
                                  className="admin-form-control"
                                  placeholder="Enter Advertisement Play Time"
                                  onChange={(e) => {
                                    const regex = /^[0-9]*$/;
                                    if (regex.test(e.target.value)) {
                                      handleChange(e);
                                    }
                                  }}
                                  value={values?.adsPlayTime}
                                  inputProps={{ maxLength: 4 }}
                                />
                                <Index.Tooltip
                                  title="The time defined here is the interval in the video in which the ad will be played, for example if it is mentioned as 30 seconds then each ad will be played after every 30 seconds in the particular video"
                                  arrow
                                  placement="bottom"
                                  className="admin-tooltip"
                                >
                                  <img
                                    src={PagesIndex.Svg.infoicon}
                                    className="info-icon"
                                    alt="information"
                                  />
                                </Index.Tooltip>
                              </Index.Box>
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.adsPlayTime && touched.adsPlayTime
                                  ? errors.adsPlayTime
                                  : null}
                              </PagesIndex.FormHelperText>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 8",
                            lg: "span 5",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="site-settings-col">
                            <Index.Box className="admin-input-box site-setting-input-box">
                              <Index.FormHelperText className="admin-form-lable">
                                Api Key
                              </Index.FormHelperText>
                              <Index.Box className="admin-form-group site-info-flex">
                                <Index.Box className="site-key-input-flex">
                                  <Index.TextField
                                    name="adsPlayTime"
                                    fullWidth
                                    onWheelCapture={(e) => {
                                      e.target.blur();
                                    }}
                                    className="admin-form-control"
                                    placeholder="Api Key"
                                    disabled
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    value={values?.apiKey}
                                    inputProps={{ maxLength: 10 }}
                                  />
                                  <Index.Tooltip
                                    title={message}
                                    arrow
                                    placement="bottom"
                                    className="admin-tooltip"
                                    onMouseLeave={() => {
                                      setTimeout(() => {
                                        setMessage("Copy");
                                      }, 1000);
                                    }}
                                  >
                                    <Index.Box className="copy-btn-main">
                                      <Index.Button
                                        className="copy-btn"
                                        onClick={() => {
                                          setMessage("Copied ✓");
                                          navigator.clipboard.writeText(
                                            values?.apiKey
                                          );
                                        }}
                                      >
                                        <img
                                          src={PagesIndex.Svg.copyicon}
                                          className="copy-icon"
                                          alt="copy"
                                        />
                                      </Index.Button>
                                    </Index.Box>
                                  </Index.Tooltip>
                                </Index.Box>
                                <Index.Box className="btn-main-primary genertate-api-key-btn-main">
                                  <Index.Button
                                    className="btn-primary genertate-api-key-btn"
                                    onClick={() => {
                                      setFieldValue(
                                        "apiKey",
                                        window.crypto.randomUUID()
                                      );
                                    }}
                                  >
                                    Generate API Key
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box>
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.apiKey && touched.apiKey
                                  ? errors.apiKey
                                  : null}
                              </PagesIndex.FormHelperText>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="grid-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        columnGap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 8",
                            md: "span 8",
                            lg: "span 8",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="site-settings-col">
                            <Index.Box className="admin-input-box site-setting-input-box">
                              <Index.FormHelperText className="admin-form-lable">
                                Internal Server Link
                              </Index.FormHelperText>
                              <Index.Box className="admin-form-group site-info-flex">
                                <Index.TextField
                                  name="internalUrl"
                                  fullWidth
                                  className="admin-form-control"
                                  placeholder="Enter Internal Server Link"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  value={values?.internalUrl}
                                  inputProps={{ maxLength: 50 }}
                                />
                              </Index.Box>
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.internalUrl && touched.internalUrl
                                  ? errors.internalUrl
                                  : null}
                              </PagesIndex.FormHelperText>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="common-card site-settings-card">
                <Index.Box className="site-settings-card-inner-main">
                  <Index.Box className="show-intrest-flex-all">
                    <Index.Box className="site-settings-contnet-box">
                      <Index.Typography className="site-settings-title">
                        Show Interests
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="site-settings-contnet-box">
                      <Index.Box className="admin-switch-main">
                        <Index.FormControlLabel
                          control={<IOSSwitch sx={{ m: 1 }} />}
                          className="admin-switch-lable"
                          name="showInterests"
                          checked={values?.showInterests}
                          onChange={handleChange}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="common-card site-settings-card">
                <Index.Box className="site-settings-card-inner-main">
                  <Index.Box className="show-intrest-flex-all">
                    <Index.Box className="site-settings-contnet-box">
                      <Index.Typography className="site-settings-title">
                        Show Movie
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="site-settings-contnet-box">
                      <Index.Box className="admin-switch-main">
                        <Index.FormControlLabel
                          control={<IOSSwitch sx={{ m: 1 }} />}
                          className="admin-switch-lable"
                          name="isMovie"
                          checked={values?.isMovie}
                          onChange={handleChange}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="common-card site-settings-card">
                <Index.Box className="site-settings-card-inner-main">
                  <Index.Box className="show-intrest-flex-all">
                    <Index.Box className="site-settings-contnet-box">
                      <Index.Typography className="site-settings-title">
                        Show WebSeries
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="site-settings-contnet-box">
                      <Index.Box className="admin-switch-main">
                        <Index.FormControlLabel
                          control={<IOSSwitch sx={{ m: 1 }} />}
                          className="admin-switch-lable"
                          name="isWebSeries"
                          checked={values?.isWebSeries}
                          onChange={handleChange}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="common-card site-settings-card">
                <Index.Box className="site-settings-card-inner-main">
                  <Index.Box className="show-intrest-flex-all">
                    <Index.Box className="site-settings-contnet-box">
                      <Index.Typography className="site-settings-title">
                        Video Viewers
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="site-settings-contnet-box">
                      <Index.Box className="admin-switch-main">
                        <Index.FormControlLabel
                          control={<IOSSwitch sx={{ m: 1 }} />}
                          className="admin-switch-lable"
                          name="isViewCount"
                          checked={values?.isViewCount}
                          onChange={handleChange}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="common-card site-settings-card">
                <Index.Box className="site-settings-card-inner-main">
                  <Index.Box className="site-settings-flex-all">
                    <Index.Box className="site-settings-flex">
                      <Index.Box className="site-settings-col">
                        <Index.Box className="site-settings-contnet-box">
                          <Index.Typography className="site-settings-title">
                            Events
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="site-settings-flex">
                    <Index.Box className="site-settings-contnet-box">
                      <Index.Typography className="site-settings-lables">
                        Email Id
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="site-settings-contnet-box">
                      <Index.Box className="admin-input-box site-setting-event-input-box">
                        <Index.Box className="admin-form-group">
                          <PagesIndex.FieldArray name="emailList">
                            <>
                              <Index.TextField
                                variant="outlined"
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                    const newEmail = e.target.value.trim();
                                    const emailPattern =
                                      /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                    if (
                                      newEmail &&
                                      emailPattern.test(e.target.value)
                                    ) {
                                      setFieldValue("emailList", [
                                        ...values.emailList,
                                        newEmail,
                                      ]);
                                      e.target.value = "";
                                    } else {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }
                                  }
                                }}
                                inputProps={{ maxLength: 50 }}
                                InputProps={{
                                  startAdornment: values.emailList?.map(
                                    (email, index) => {
                                      return (
                                        <Index.Chip
                                          className="chip-control"
                                          key={Index.uuidv4()}
                                          label={email}
                                          onDelete={() => {
                                            const updatedEmails = [
                                              ...values.emailList,
                                            ];
                                            updatedEmails.splice(index, 1);
                                            setFieldValue(
                                              "emailList",
                                              updatedEmails
                                            );
                                          }}
                                          variant="outlined"
                                        />
                                      );
                                    }
                                  ),
                                }}
                              />
                              <Index.FormHelperText
                                error
                                className="admin-error-text"
                              >
                                {errors.emailList ? errors.emailList : null}
                              </Index.FormHelperText>
                            </>
                          </PagesIndex.FieldArray>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    {/* <Index.Box className="site-settings-contnet-box">
                        <Index.Box className="site-info-flex">
                          <Index.Tooltip
                            title="information"
                            arrow
                            placement="bottom"
                            className="admin-tooltip"
                          >
                            <img src={PagesIndex.Svg.infoicon} className='info-icon' alt='information' />
                          </Index.Tooltip>
                          <Index.Box className="admin-input-box site-setting-time-input-box">
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                fullWidth
                                id="videoLink1.range"
                                className="admin-form-control"
                                name="eventNotifyBeforeTime"
                                type="number"
                                min={0.1}
                                max={24}
                                value={values.eventNotifyBeforeTime || settingListData?.showInterests}
                                onChange={(e) => {
                                  let { value } = e.target;
                                  value = value.replace(/[^\d.]/g, '');
                                  if (parseFloat(value) > 24) {
                                    value = '24';
                                  }
                                  setFieldValue('eventNotifyBeforeTime', value);
                                }}
                              />
                              <Index.FormHelperText error>
                                {errors?.eventNotifyBeforeTime ? errors?.eventNotifyBeforeTime : null}
                              </Index.FormHelperText>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box> */}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="common-card site-settings-card">
                <Index.Box className="site-settings-card-inner-main">
                  <Index.Box className="site-settings-flex-all">
                    <Index.Box className="site-settings-flex">
                      <Index.Box className="site-settings-col">
                        <Index.Box className="site-settings-contnet-box">
                          <Index.Typography className="site-settings-title">
                            Sales Deal Email Notification Person
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="site-settings-flex">
                    <Index.Box className="site-settings-contnet-box">
                      <Index.Typography className="site-settings-lables">
                        Email Id
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="site-settings-contnet-box">
                      <Index.Box className="admin-input-box site-setting-event-input-box">
                        <Index.Box className="admin-form-group">
                          <PagesIndex.FieldArray name="emailList">
                            <>
                              <Index.TextField
                                variant="outlined"
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                    const newEmail = e.target.value.trim();
                                    const emailPattern =
                                      /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                    if (
                                      newEmail &&
                                      emailPattern.test(e.target.value)
                                    ) {
                                      setFieldValue("salesOrderEmailApproval", [
                                        ...values.salesOrderEmailApproval,
                                        newEmail,
                                      ]);
                                      e.target.value = "";
                                    } else {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }
                                  }
                                }}
                                inputProps={{ maxLength: 50 }}
                                InputProps={{
                                  startAdornment:
                                    values.salesOrderEmailApproval?.map(
                                      (email, index) => {
                                        return (
                                          <Index.Chip
                                            className="chip-control"
                                            key={Index.uuidv4()}
                                            label={email}
                                            onDelete={() => {
                                              const updatedEmails = [
                                                ...values.salesOrderEmailApproval,
                                              ];
                                              updatedEmails.splice(index, 1);
                                              setFieldValue(
                                                "salesOrderEmailApproval",
                                                updatedEmails
                                              );
                                            }}
                                            variant="outlined"
                                          />
                                        );
                                      }
                                    ),
                                }}
                              />
                              <Index.FormHelperText
                                error
                                className="admin-error-text"
                              >
                                {errors.salesOrderEmailApproval
                                  ? errors.salesOrderEmailApproval
                                  : null}
                              </Index.FormHelperText>
                            </>
                          </PagesIndex.FieldArray>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="common-card site-settings-card">
                <Index.Box className="site-settings-card-inner-main">
                  <Index.Box className="site-settings-contnet-box">
                    <Index.Typography className="site-settings-title">
                      Sponsor Logo
                    </Index.Typography>
                  </Index.Box>

                  <Index.Box className="site-settings-contnet-box admin-input-box">
                    <Index.Box className="site-logo-browse-main">
                      <Index.Box className="admin-file-browse-btn-main">
                        <img
                          src={renderImg(values)}
                          className="admin-browse-profile-img"
                          alt="upload img"
                        />
                        <Index.Button
                          variant="contained"
                          component="label"
                          className="admin-file-browse-btn"
                        >
                          <img
                            src={PagesIndex.Svg.whiteedit}
                            className="admin-browse-icon-img"
                            alt="upload img"
                          ></img>
                          <input
                            hidden
                            accept="image/*"
                            name="image"
                            type="file"
                            onChange={(e) => {
                              if (e.target.files?.length !== 0) {
                                setFieldValue("image", e.target.files[0]);
                              }
                            }}
                          />
                        </Index.Button>
                        {(values?.imageUrl || values?.image) && (
                          <Index.Button
                            variant="contained"
                            component="label"
                            className="admin-file-browse-btn admin-file-browse-btn-bottom"
                            onClick={() => {
                              setopenDelete(true);
                            }}
                          >
                            <img
                              src={PagesIndex.Svg.trashwhite}
                              className="admin-browse-icon-img"
                              alt="upload img"
                            ></img>
                          </Index.Button>
                        )}
                      </Index.Box>
                      {touched.image && errors.image && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.image}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                    <span className="option-condition-text">
                      Image must be 115px(Width) * 40px(Height)
                    </span>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="common-card site-settings-card">
                <Index.Box className="site-settings-card-inner-main">
                  <Index.Box className="site-settings-contnet-box">
                    <Index.Typography className="site-settings-title">
                      Background Image
                    </Index.Typography>
                  </Index.Box>

                  <Index.Box className="site-settings-contnet-box">
                    <Index.Box className="site-logo-browse-main">
                      <Index.Box className="admin-file-browse-btn-main">
                        <img
                          src={renderBgImg(values)}
                          className="admin-browse-profile-img"
                          alt="upload img"
                        />
                        <Index.Button
                          variant="contained"
                          component="label"
                          className="admin-file-browse-btn"
                        >
                          <img
                            src={PagesIndex.Svg.whiteedit}
                            className="admin-browse-icon-img"
                            alt="upload img"
                          ></img>
                          <input
                            hidden
                            accept="image/*"
                            name="bgImage"
                            type="file"
                            onChange={(e) => {
                              if (e.target.files?.length !== 0) {
                                setFieldValue("bgImage", e.target.files[0]);
                              }
                            }}
                          />
                        </Index.Button>
                      </Index.Box>
                      {touched.bgImage && errors.bgImage && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.bgImage}
                        </PagesIndex.FormHelperText>
                      )}
                      <span className="option-condition-text">
                        Image must be 375px(Width) * 812px(Height)
                      </span>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="common-card site-settings-card">
                <Index.Box className="site-settings-card-inner-main">
                  <Index.Box className="site-settings-contnet-box show-intrest-flex-all">
                    <Index.Typography className="site-settings-title">
                      Banner Image
                    </Index.Typography>
                    <Index.Box className="admin-switch-main">
                      {settingListData?.backgroundImage && (
                        <Index.FormControlLabel
                          control={<IOSSwitch sx={{ m: 1 }} />}
                          className="admin-switch-lable"
                          name="isBanner"
                          checked={values?.isBanner}
                          onChange={handleChange}
                        />
                      )}
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="site-settings-contnet-box">
                    <Index.Box className="site-logo-browse-main">
                      <Index.Box className="admin-file-browse-btn-main">
                        <img
                          src={renderBannerImage(values)}
                          className="admin-browse-profile-img"
                          alt="upload img"
                        />
                        <Index.Button
                          variant="contained"
                          component="label"
                          className="admin-file-browse-btn"
                        >
                          <img
                            src={PagesIndex.Svg.whiteedit}
                            className="admin-browse-icon-img"
                            alt="upload img"
                          ></img>
                          <input
                            hidden
                            accept="image/*"
                            name="bannerImage"
                            type="file"
                            onChange={(e) => {
                              if (e.target.files?.length !== 0) {
                                setFieldValue("bannerImage", e.target.files[0]);
                              }
                            }}
                          />
                        </Index.Button>
                      </Index.Box>
                      {touched.bannerImage && errors.bannerImage && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.bannerImage}
                        </PagesIndex.FormHelperText>
                      )}
                      <span className="option-condition-text">
                        Image must be 1400px(Width) * 250px(Height)
                      </span>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="common-card site-settings-card">
                <Index.Box className="site-settings-card-inner-main">
                  <Index.Box className="site-settings-contnet-box site-title-space">
                    <Index.Typography className="site-settings-title">
                      Font Color
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="grid-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-modal-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Font First Title Color
                              <span className="astrick-sing">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                id="fontMainColor"
                                name="fontMainColor"
                                fullWidth
                                type="color"
                                className="admin-form-control"
                                placeholder="Enter Font First Title Color"
                                onChange={handleChange}
                                value={values?.fontMainColor}
                                error={
                                  errors.fontMainColor && touched.fontMainColor
                                }
                                inputProps={{ maxLength: 40 }}
                              />
                              {touched.fontMainColor &&
                                errors.fontMainColor && (
                                  <PagesIndex.FormHelperText className="admin-error-text">
                                    {errors.fontMainColor}
                                  </PagesIndex.FormHelperText>
                                )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-modal-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Font Second Title Color
                              <span className="astrick-sing">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                id="fontTitleColor"
                                name="fontTitleColor"
                                fullWidth
                                type="color"
                                className="admin-form-control"
                                placeholder="Enter Font Second Title Color"
                                onChange={handleChange}
                                value={values?.fontTitleColor}
                                error={
                                  errors.fontTitleColor &&
                                  touched.fontTitleColor
                                }
                                inputProps={{ maxLength: 40 }}
                              />
                              {touched.fontTitleColor &&
                                errors.fontTitleColor && (
                                  <PagesIndex.FormHelperText className="admin-error-text">
                                    {errors.fontTitleColor}
                                  </PagesIndex.FormHelperText>
                                )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-modal-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Font Secondry Color
                              <span className="astrick-sing">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                id="fontSecondaryColor"
                                name="fontSecondaryColor"
                                fullWidth
                                type="color"
                                className="admin-form-control"
                                placeholder="Enter FontSecondColor"
                                onChange={handleChange}
                                value={values?.fontSecondaryColor}
                                error={
                                  errors.fontSecondaryColor &&
                                  touched.fontSecondaryColor
                                }
                                inputProps={{ maxLength: 40 }}
                              />
                              {touched.fontSecondaryColor &&
                                errors.fontSecondaryColor && (
                                  <PagesIndex.FormHelperText className="admin-error-text">
                                    {errors.fontSecondaryColor}
                                  </PagesIndex.FormHelperText>
                                )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-modal-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Font Most Viewed Color
                              <span className="astrick-sing">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                id="fontMostViewedColor"
                                name="fontMostViewedColor"
                                fullWidth
                                type="color"
                                className="admin-form-control"
                                placeholder="Enter FontMostViewedColor"
                                onChange={handleChange}
                                value={values?.fontMostViewedColor}
                                error={
                                  errors.fontSecondaryColor &&
                                  touched.fontMostViewedColor
                                }
                                inputProps={{ maxLength: 40 }}
                              />
                              {touched.fontMostViewedColor &&
                                errors.fontMostViewedColor && (
                                  <PagesIndex.FormHelperText className="admin-error-text">
                                    {errors.fontMostViewedColor}
                                  </PagesIndex.FormHelperText>
                                )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-modal-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Drawer Menu Color{" "}
                              <span className="astrick-sing">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                id="drawerMenuColor"
                                name="drawerMenuColor"
                                fullWidth
                                type="color"
                                className="admin-form-control"
                                placeholder="Enter Drawer Menu Color"
                                onChange={handleChange}
                                value={values?.drawerMenuColor}
                                error={
                                  errors.drawerMenuColor &&
                                  touched.drawerMenuColor
                                }
                                inputProps={{ maxLength: 40 }}
                              />
                              {touched.drawerMenuColor &&
                                errors.drawerMenuColor && (
                                  <PagesIndex.FormHelperText className="admin-error-text">
                                    {errors.drawerMenuColor}
                                  </PagesIndex.FormHelperText>
                                )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-modal-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Placeholder Font Color{" "}
                              <span className="astrick-sing">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                id="placeholderFontColor"
                                name="placeholderFontColor"
                                fullWidth
                                type="color"
                                className="admin-form-control"
                                placeholder="Enter Placeholder Font Color"
                                onChange={handleChange}
                                value={values?.placeholderFontColor}
                                error={
                                  errors.placeholderFontColor &&
                                  touched.placeholderFontColor
                                }
                                inputProps={{ maxLength: 40 }}
                              />
                              {touched.placeholderFontColor &&
                                errors.placeholderFontColor && (
                                  <PagesIndex.FormHelperText className="admin-error-text">
                                    {errors.placeholderFontColor}
                                  </PagesIndex.FormHelperText>
                                )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-modal-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Vote Color <span className="astrick-sing">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                id="voteColor"
                                name="voteColor"
                                fullWidth
                                type="color"
                                className="admin-form-control"
                                placeholder="Enter Vote Color"
                                onChange={handleChange}
                                value={values?.voteColor}
                                error={errors.voteColor && touched.voteColor}
                                inputProps={{ maxLength: 40 }}
                              />
                              {touched.voteColor && errors.voteColor && (
                                <PagesIndex.FormHelperText className="admin-error-text">
                                  {errors.voteColor}
                                </PagesIndex.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-modal-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Rating/WatchList Icon Color{" "}
                              <span className="astrick-sing">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                id="ratingWatchListIconColor"
                                name="ratingWatchListIconColor"
                                fullWidth
                                type="color"
                                className="admin-form-control"
                                placeholder="Enter Rating WatchListIcon Color"
                                onChange={handleChange}
                                value={values?.ratingWatchListIconColor}
                                error={
                                  errors.ratingWatchListIconColor &&
                                  touched.ratingWatchListIconColor
                                }
                                inputProps={{ maxLength: 40 }}
                              />
                              {touched.ratingWatchListIconColor &&
                                errors.ratingWatchListIconColor && (
                                  <PagesIndex.FormHelperText className="admin-error-text">
                                    {errors.ratingWatchListIconColor}
                                  </PagesIndex.FormHelperText>
                                )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-modal-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Statusbar<span className="astrick-sing">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-dropdown-box">
                              <Index.FormControl className="admin-form-control admin-drop-form-control">
                                <Index.Select
                                  className="admin-dropdown-select admin-drop-select"
                                  id="statusbar"
                                  name="statusbar"
                                  value={values?.statusbar}
                                  onChange={handleChange}
                                  defaultValue={values?.statusbar}
                                  displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                  renderValue={
                                    values?.statusbar
                                      ? undefined
                                      : () => "Select Status"
                                  }
                                >
                                  <Index.MenuItem
                                    value="light"
                                    className="admin-drop-menuitem"
                                  >
                                    Light
                                  </Index.MenuItem>
                                  <Index.MenuItem
                                    value="dark"
                                    className="admin-drop-menuitem"
                                  >
                                    Dark
                                  </Index.MenuItem>
                                </Index.Select>
                              </Index.FormControl>
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.statusbar && touched.statusbar
                                  ? errors.statusbar
                                  : null}
                              </PagesIndex.FormHelperText>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-modal-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              District Title Font Size
                              <span className="astrick-sing">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-dropdown-box">
                              <Index.FormControl className="admin-form-control admin-drop-form-control">
                                <Index.Select
                                  className="admin-dropdown-select admin-drop-select"
                                  id="fontSize"
                                  name="fontSize"
                                  value={values?.fontSize}
                                  onChange={handleChange}
                                  defaultValue={values?.fontSize}
                                  displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                  renderValue={
                                    values?.fontSize
                                      ? undefined
                                      : () => "Select fontSize"
                                  }
                                >
                                  {fontSize?.map((ele) => {
                                    return (
                                      <Index.MenuItem
                                        value={ele?.size}
                                        className="admin-drop-menuitem"
                                        key={ele?.size}
                                      >
                                        {ele?.size}
                                      </Index.MenuItem>
                                    );
                                  })}
                                </Index.Select>
                              </Index.FormControl>
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.fontSize && touched.fontSize
                                  ? errors.fontSize
                                  : null}
                              </PagesIndex.FormHelperText>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-modal-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Main Category Font Size
                              <span className="astrick-sing">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-dropdown-box">
                              <Index.FormControl className="admin-form-control admin-drop-form-control">
                                <Index.Select
                                  className="admin-dropdown-select admin-drop-select"
                                  id="mainCategoryFontSize"
                                  name="mainCategoryFontSize"
                                  value={values?.mainCategoryFontSize}
                                  onChange={handleChange}
                                  defaultValue={values?.mainCategoryFontSize}
                                  displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                  renderValue={
                                    values?.mainCategoryFontSize
                                      ? undefined
                                      : () => "Select Main Category Font Size"
                                  }
                                >
                                  {fontSize?.map((ele) => {
                                    return (
                                      <Index.MenuItem
                                        value={ele?.size}
                                        className="admin-drop-menuitem"
                                        key={ele?.size}
                                      >
                                        {ele?.size}
                                      </Index.MenuItem>
                                    );
                                  })}
                                </Index.Select>
                              </Index.FormControl>
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.mainCategoryFontSize &&
                                touched.mainCategoryFontSize
                                  ? errors.mainCategoryFontSize
                                  : null}
                              </PagesIndex.FormHelperText>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-modal-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Category Font Size
                              <span className="astrick-sing">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-dropdown-box">
                              <Index.FormControl className="admin-form-control admin-drop-form-control">
                                <Index.Select
                                  className="admin-dropdown-select admin-drop-select"
                                  id="categoryfontSize"
                                  name="categoryfontSize"
                                  value={values?.categoryfontSize}
                                  onChange={handleChange}
                                  defaultValue={values?.categoryfontSize}
                                  displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                  renderValue={
                                    values?.categoryfontSize
                                      ? undefined
                                      : () => "Select Category Font Size"
                                  }
                                >
                                  {fontSize?.map((ele) => {
                                    return (
                                      <Index.MenuItem
                                        value={ele?.size}
                                        className="admin-drop-menuitem"
                                        key={ele?.size}
                                      >
                                        {ele?.size}
                                      </Index.MenuItem>
                                    );
                                  })}
                                </Index.Select>
                              </Index.FormControl>
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.categoryfontSize &&
                                touched.categoryfontSize
                                  ? errors.categoryfontSize
                                  : null}
                              </PagesIndex.FormHelperText>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              {(adminDetails?.roleId?.permissions?.includes(
                "site_setting_edit"
              ) ||
                adminDetails?.roleId?.permissions?.includes(
                  "site_setting_add"
                )) && (
                <Index.Box className="admin-user-btn-flex">
                  <Index.Box className="admin-save-btn-main btn-main-primary">
                    <Index.Button
                      className={`update-btn admin-save-user-btn btn-primary`}
                      type="submit"
                      disabled={buttonSpinner}
                    >
                      {buttonSpinner ? (
                        <PagesIndex.Spinner />
                      ) : (
                        <>
                          <img
                            src={PagesIndex.Svg.save}
                            className="admin-user-save-icon"
                            alt="Save"
                          />
                          <span>Update</span>
                        </>
                      )}
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              )}
              <Index.Modal
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="admin-modal"
              >
                <Index.Box
                  sx={style}
                  className="admin-delete-modal-inner-main admin-modal-inner"
                >
                  <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                    <Index.Box className="admin-modal-circle-main">
                      <img
                        src={PagesIndex.Svg.closecircle}
                        className="admin-user-circle-img"
                        alt="Close"
                      />
                    </Index.Box>
                    <Index.Typography
                      className="admin-delete-modal-title"
                      component="h2"
                      variant="h2"
                    >
                      Are you sure?
                    </Index.Typography>
                    <Index.Typography
                      className="admin-delete-modal-para admin-common-para"
                      component="p"
                      variant="p"
                    >
                      Do you really want to delete sponsor logo? This process
                      cannot be undone.
                    </Index.Typography>
                    <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
                      <Index.Button
                        className="admin-modal-cancel-btn border-btn"
                        onClick={handleCloseDelete}
                        disabled={deleteButtonSpinner}
                      >
                        Cancel
                      </Index.Button>
                      <Index.Button
                        className="admin-modal-delete-btn btn"
                        onClick={() => handleSubmitDeleteImage(true)}
                        disabled={deleteButtonSpinner}
                      >
                        {deleteButtonSpinner ? (
                          <PagesIndex.Spinner />
                        ) : (
                          "Delete"
                        )}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Modal>
            </PagesIndex.Form>
          )}
        </PagesIndex.Formik>
      )}
    </Index.Box>
  );
}
