import React from "react";
import Index from "../../../Index";
import { useLocation } from "react-router";
import PagesIndex from "../../../PagesIndex";

export default function NewsActivityLog() {
  const location = useLocation();
  const oldData = location?.state?.log;
  const oldIndex = location?.state?.index;

  let log = oldData
    ?.slice()
    ?.reverse()
    ?.find((e, index) => index == oldIndex);
  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-view-page-title"
          component="h2"
          variant="h2"
        >
          Video Activity Details
        </Index.Typography>
      </Index.Box>
      <Index.Box className="video-activity-logview-card common-card">
        <Index.Box className="view-row-content-box">
          <Index.Box className="view-content-flex">
            <Index.Typography className="view-content-lable">
              Video Title:
            </Index.Typography>
            <Index.Typography className="view-content-data">
              {log?.newsTitle ? log?.newsTitle : "-"}
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box className="view-row-content-box">
          <Index.Box className="view-content-flex">
            <Index.Typography className="view-content-lable">
              Description:
            </Index.Typography>
            <Index.Typography className="view-content-data">
              {log?.description ? log?.description : "-"}
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box className="video-activity-logview-main">
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Tags:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                {log?.tags?.map((tag) => {
                  return (
                    <Index.Typography
                      className="view-content-data"
                      key={tag?._id}
                    >
                      <Index.Typography className="view-content-data">
                        {tag?.tag}
                      </Index.Typography>
                    </Index.Typography>
                  );
                })}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Youtube Link:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.youtube_link ? log?.youtube_link : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                AWS Link:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.s3_link ? log?.s3_link : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Categories:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                {log?.category?.map((category) => {
                  return (
                    <Index.Box
                      className="view-category-flex-box"
                      key={category?._id}
                    >
                      <Index.Box className="view-category-box">
                        <Index.Typography className="view-content-data">
                          {category?.categoryName}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  );
                })}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Location:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.location ? log?.location : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                City:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.city ? log?.city : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                State:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.state?.stateName ? log?.state?.stateName : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Zone Name:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.zone?.name ? log?.zone?.name : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                District Name:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.district ? (
                    <Index.Typography className="view-content-data">
                      <Index.Box className="view-category-flex-box">
                        <Index.Box className="view-category-box">
                          <Index.Typography className="view-content-data">
                            {log?.district?.name}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Typography>
                  ) : (
                    "-"
                  )}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Section To Show:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                {log?.sectionToShow?.length > 0
                  ? log?.sectionToShow?.map((sectionToShow) => {
                      return (
                        <Index.Typography
                          className="view-content-data"
                          key={sectionToShow?._id}
                        >
                          <Index.Box className="view-category-flex-box">
                            <Index.Box className="view-category-box">
                              <Index.Typography className="view-content-data">
                                {sectionToShow?.name}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Typography>
                      );
                    })
                  : "-"}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Schedule Date:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.scheduledDate
                    ? PagesIndex.moment(log?.scheduledDate).format("DD/MM/YYYY")
                    : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Status:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.previousIsActive
                    ? "Active"
                    : log?.isActive
                    ? "Active"
                    : "Deactive"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Image:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                {log?.image ? (
                  <img
                    src={process.env.REACT_APP_IMAGE_ENDPOINT + log?.image}
                    className="admin-activity-thumbnail-img"
                    alt="profile"
                  />
                ) : (
                  "-"
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
