import React from 'react'
import Index from '../../Index'
import { useParams } from 'react-router-dom'

export default function ComingSoon() {
    const params = useParams();
    return (
        <Index.Table aria-label="simple table" className="table"><Index.TableHead className="table-head">
            <Index.TableRow className="table-row"></Index.TableRow></Index.TableHead><Index.TableBody className="table-body">
                <Index.TableRow>
                    <Index.TableCell className='table-not-found-td' colSpan={12}>
                        <Index.Box className="loader-position">
                            <Index.Box className="loader-main">
                                <b>{`${params?.comingSoon} Is Coming soon...`}</b> 
                            </Index.Box>
                        </Index.Box>
                    </Index.TableCell>
                </Index.TableRow>
            </Index.TableBody>
        </Index.Table>
    )
}
