import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./sponserManagement.css";
import "./sponserManagementResponsive.css";

export default function SponsorActivityLog() {
  const location = PagesIndex.useLocation();
  const oldData = location?.state?.log;
  const oldIndex = location?.state?.index;

  let log = oldData
    ?.slice()
    ?.reverse()
    ?.find((e, index) => index === oldIndex);

  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-view-page-title"
          component="h2"
          variant="h2"
        >
          Sponsor Activity Details
        </Index.Typography>
      </Index.Box>
      <Index.Box className="view-details-card">
        <Index.Box className="sponser-activity-view-main common-card">
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Sponsor Name:
              </Index.Typography>
              <Index.Typography className="view-content-data">
                {log?.name?.compName ? log?.name?.compName : "-"}
              </Index.Typography>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Email Id:
              </Index.Typography>
              <Index.Typography className="view-content-data">
                {log?.email ? log?.email : "-"}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Categories:
              </Index.Typography>
              {log?.category?.length ? (
                <Index.Box className="view-content-data-flex">
                  {log?.category?.map((category) => {
                    return (
                      <Index.Box
                        className="view-category-flex-box"
                        key={category?._id}
                      >
                        <Index.Box className="view-category-box">
                          <Index.Typography className="view-content-data">
                            {category?.categoryName}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    );
                  })}
                </Index.Box>
              ) : (
                "-"
              )}
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Districts:
              </Index.Typography>
              {log?.district?.length ? (
                <Index.Box className="view-content-data-flex">
                  {log?.district?.map((data) => {
                    return (
                      <Index.Box
                        className="view-category-flex-box"
                        key={data?._id}
                      >
                        <Index.Box className="view-category-box">
                          <Index.Typography className="view-content-data">
                            {data?.name}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    );
                  })}
                </Index.Box>
              ) : (
                "-"
              )}
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Video:
              </Index.Typography>
              {log?.news?.length ? (
                <Index.Box className="view-content-data-flex">
                  {log?.news?.map((data) => {
                    return (
                      <Index.Box
                        className="view-category-flex-box"
                        key={data?._id}
                      >
                        <Index.Box className="view-category-box">
                          <Index.Typography className="view-content-data">
                            {data?.newsNo}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    );
                  })}
                </Index.Box>
              ) : (
                "-"
              )}
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Start Date:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.startDate
                    ? PagesIndex.moment(log?.startDate).format("DD/MM/YYYY")
                    : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                End Date:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.endDate
                    ? PagesIndex.moment(log?.endDate).format("DD/MM/YYYY")
                    : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Status:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <Index.Typography className="view-content-data">
                  {log?.previousStatus
                    ? "Active"
                    : log?.isActive
                    ? "Active"
                    : "Deactive"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Sponsor Logo:
              </Index.Typography>
              <Index.Box className="view-content-data-flex">
                <img
                  src={
                    log?.logo
                      ? process.env.REACT_APP_IMAGE_ENDPOINT + log?.logo
                      : PagesIndex.Svg.noimgfound
                  }
                  className="admin-activity-thumbnail-img"
                  alt="profile"
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
