import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./homeCategoryManagement.responsive.css";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import update from "immutability-helper";
import { styled } from "@mui/material/styles";
import { HTML5Backend } from "react-dnd-html5-backend";

export const DraggableTypes = {
  REORDERABLE_ROW: "reorderable_row",
};

// for modal design

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  border: "1px solid #b2b3b3",
  width: 12,
  height: 12,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "transparent",
}));
function BpCheckbox(props) {
  return (
    <Index.Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  "&:before": {
    display: "block",
    width: 12,
    height: 12,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
});


export default function HomeCategoryManagement() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [selectuserData, setSelectuserData] = useState();
  const [loading, setLoading] = useState(true);

  const [activityLog, setActivityLog] = useState([]);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
  const [activityLoading, setActivityLoading] = useState(true);
  const [filterLog, setFilterLog] = useState([]);

  const [openLogModal, setOpenLogModal] = useState(false);
  const handleCloseLogModal = () => setOpenLogModal(false);
  const [searchValue, setSearchValue] = useState("");
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);

  const handleOpenLogModal = (id) => {
    setOpenLogModal(true);
    setActivityLoading(true);
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_HOME_CATEGORY_ACTIVITY_LOG + "/" + id
    ).then((res) => {
      setActivityLoading(false);
      if (res.status === 200) {
        let data = res?.data?.activityLog?.filter(
          (data) =>
            data?.previousSectionName !== data?.name ||
            data?.previousStatus !== data?.currentStatus
        );
        setFilterLog(data);
        setActivityLog(res.data?.activityLog);
      }
    });
  };

  const formRef = useRef();
  const initialValues = {
    name: id ? selectuserData?.name : "",
    color: id ? selectuserData?.colorCode : "#000",
    vertical: id ? selectuserData?.vertical : false,
  };

  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [filteredData, setFilteredData] = useState([]);
  // add user modal
  const [open, setOpen] = useState(false);
  // delete modal
  const [openDelete, setOpenDelete] = useState(false);

  const handleOpen = (op, id, data) => {
    setOpen(true);
    setAddOrEdit(op);
    setId(id);
    setSelectuserData(data);
  };
  const handleClose = () => {
    setId("");
    formRef.current.resetForm();
    setOpen(false);
  };
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setId(id);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };

  const handleGetCategory = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_HOME_CATEGORY).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setData(res.data);
        setFilteredData(res.data);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    handleGetCategory();
  }, []);
  const handleAddEditCategory = (values) => {
    setButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    if (id) {
      urlEncoded.append("id", id);
    }
    urlEncoded.append("name", values?.name);
    urlEncoded.append("vertical", values?.vertical);
    urlEncoded.append("colorCode", values?.color);
    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_EDIT_HOME_CATEGORY, urlEncoded)
      .then((res) => {
        setButtonSpinner(false)
        if (res.status === 201 || res.status === 200) {
          handleClose();
          handleGetCategory();
          setSearchValue("");
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };
  const handleDeleteCategory = () => {
    setDeleteButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(
      PagesIndex.Api.DELETE_HOME_CATEGORY,
      urlEncoded
    ).then((res) => {
      setDeleteButtonSpinner(false)
      if (res.status === 200) {
        handleCloseDelete();
        handleGetCategory();
        PagesIndex.toasterSuccess(res?.message);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const requestSearch = (e) => {
    setSearchValue(e.target.value);
    let filteredData = data.filter(
      (data) =>
        data?.name?.toLowerCase()?.includes(e?.target?.value?.toLowerCase()) ||
        data?.sequence == e?.target?.value
    );
    setFilteredData(filteredData);
  };
  const handleOpenStatusModal = (id) => {
    setId(id);
    setOpenStatusModal(true);
  };
  const handleCloseStatusModal = () => {
    setId("");
    setOpenStatusModal(false);
  };
  const handleStatus = () => {
    setStatusButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(
      PagesIndex.Api.ISACTIVE_HOME_CATEGORY,
      urlEncoded
    ).then((res) => {
      setStatusButtonSpinner(false)
      if (res.status === 200) {
        handleCloseStatusModal();
        PagesIndex.toasterSuccess(res?.message);
        handleGetCategory();
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = filteredData[dragIndex];
      setFilteredData(
        update(filteredData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
      handleChangeSequence(
        update(filteredData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [filteredData]
  );
  const handleChangeSequence = (list) => {
    const urlEncoded = new URLSearchParams();
    const updatedRows = list?.map((data, index) => ({
      ...data,
      sequence: index + 1,
    }));
    urlEncoded.append("updatedRows", JSON.stringify(updatedRows));
    PagesIndex.apiPostHandler(
      PagesIndex.Api.UPDATE_ROW_HOME_CATEGORY,
      urlEncoded
    ).then((res) => {
      if (res.status === 200) {
        PagesIndex.toasterSuccess(res?.message);
      } else {
        handleGetCategory();
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const renderTableRows = (setFieldValue) => {
    return filteredData?.map((data, index) => {
      return (
        <ReorderableRow
          key={data?._id}
          index={index}
          id={data?._id}
          moveRow={moveRow}
        >
          <Fragment key={data?._id}>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {index + 1}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.name}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell
              component="td"
              variant="td"
              className="table-td"
              align="right"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.createdBy?.name || "-"}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell
              component="td"
              variant="td"
              className="table-td"
              align="right"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.updatedBy?.name || "-"}
              </Index.Typography>
            </Index.TableCell>
            {adminDetails?.roleId?.permissions?.includes(
              "home_category_edit"
            ) && (
                <Index.TableCell
                  component="td"
                  variant="td"
                  align="right"
                  className="table-td"
                >
                  <Index.Box className="admin-switch-main">
                    <Index.FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      className="admin-switch-lable"
                      checked={data?.isActive}
                      onChange={() => {
                        handleOpenStatusModal(data?._id);
                      }}
                    />
                  </Index.Box>
                </Index.TableCell>
              )}
            {(adminDetails?.roleId?.permissions?.includes(
              "home_category_edit"
            ) ||
              adminDetails?.roleId?.permissions?.includes(
                "home_category_delete"
              ) ||
              adminDetails?.roleId?.permissions?.includes("home_category_log")) && (
                <Index.TableCell
                  component="td"
                  variant="td"
                  align="right"
                  className="table-td"
                >
                  <Index.Box className="admin-table-data-btn-flex ">
                    {adminDetails?.roleId?.permissions?.includes(
                      "home_category_edit"
                    ) && (
                        <Index.Tooltip
                          title="Edit"
                          arrow
                          placement="bottom"
                          className="admin-tooltip"
                        >
                          <Index.Button
                            className="admin-table-data-btn"
                            onClick={() => {
                              setId(data?._id);
                              setFieldValue("name", data.name);
                              setFieldValue("sequence", data.sequence);
                              handleOpen("Edit", data?._id, data);
                            }}
                          >
                            <img
                              src={PagesIndex.Svg.blueedit}
                              className="admin-icon"
                              alt="Edit"
                            ></img>
                          </Index.Button>
                        </Index.Tooltip>
                      )}
                    {adminDetails?.roleId?.permissions?.includes(
                      "home_category_log"
                    ) && (
                        <Index.Tooltip
                          title="Log"
                          arrow
                          placement="bottom"
                          className="admin-tooltip"
                        >
                          <Index.Button
                            className="admin-table-data-btn"
                            onClick={() => handleOpenLogModal(data?._id)}
                          >
                            <img
                              src={PagesIndex.Svg.logicon}
                              className="admin-icon"
                              alt="Log"
                            ></img>
                          </Index.Button>
                        </Index.Tooltip>
                      )}

                    {adminDetails?.roleId?.permissions?.includes(
                      "home_category_delete"
                    ) &&
                      !(
                        data?._id === "660c00e40b2c9ea28bd1df3b" ||
                        data?._id === "660cfd2b2005bb0bea620452" ||
                        data?._id === "660cfd1c2005bb0bea62043e"
                      ) && (
                        <Index.Tooltip
                          title="Delete"
                          arrow
                          placement="bottom"
                          className="admin-tooltip"
                        >
                          <Index.Button
                            className="admin-table-data-btn"
                            onClick={() => handleOpenDelete(data?._id)}
                          >
                            <img
                              src={PagesIndex.Svg.trash}
                              className="admin-icon"
                              alt="Delete"
                            ></img>
                          </Index.Button>
                        </Index.Tooltip>
                      )}
                  </Index.Box>
                </Index.TableCell>
              )}
          </Fragment>
        </ReorderableRow>
      );
    });
  };

  const renderContent = (setFieldValue) => {
    if (filteredData?.length) {
      return renderTableRows(setFieldValue);
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };
  const renderActivityTableRows = () => {
    return activityLog
      .slice()
      .reverse()
      .map((data) => {
        let updatedInfo = "";

        if (
          data?.previousSectionName !== data?.updatedSectionName ||
          data?.updatedColorCode !== data?.previousColorCode ||
          data?.previousStatus !== data?.currentStatus
        ) {
          if (data?.updatedBy?.name) {
            updatedInfo = `${data?.updatedBy?.name}-${data?.dateTime
              ? PagesIndex.moment(data?.dateTime).format(
                "DD/MM/YYYY HH:mm:ss"
              )
              : "-"
              }`;
          } else {
            updatedInfo = "-";
          }
        }
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            {updatedInfo !== "" && (
              <>
                <Index.TableCell
                  component="td"
                  variant="td"
                  scope="row"
                  className="table-td"
                >
                  {data?.previousSectionName !== data?.updatedSectionName && (
                    <Index.Tooltip
                      title={
                        data?.previousSectionName !== data?.updatedSectionName
                          ? `${data?.updatedBy?.name
                          } has updated home category name from ${data?.previousSectionName || "-"
                          } to ${data?.updatedSectionName + " "}`
                          : ""
                      }
                      arrow
                      placement="top"
                      className="admin-tooltip"
                    >
                      <Index.Typography className="admin-table-data-text">
                        <span>
                          <span className="span-bold">
                            {data?.updatedBy?.name}
                          </span>{" "}
                          has updated home category name from{" "}
                          <span className="span-bold">
                            {data?.previousSectionName || "-"}
                          </span>{" "}
                          to{" "}
                          <span className="span-bold">
                            {data?.updatedSectionName + " "}
                          </span>
                        </span>
                      </Index.Typography>
                    </Index.Tooltip>
                  )}
                  {data?.previousStatus !== data?.currentStatus && (
                    <Index.Tooltip
                      title={
                        data?.previousStatus !== data?.currentStatus
                          ? `${data?.updatedBy?.name} has updated status from ${!data?.currentStatus ? "Active" : "Deactive"
                          } to ${data?.currentStatus ? "Active" : "Deactive"
                          } `
                          : ""
                      }
                      arrow
                      placement="top"
                      className="admin-tooltip"
                    >
                      <Index.Typography className="admin-table-data-text">
                        <span>
                          <span className="span-bold">
                            {data?.updatedBy?.name}
                          </span>{" "}
                          has updated status from {" "}
                          <span className="span-bold">
                            {!data?.currentStatus ? "Active" : "Deactive"}
                          </span>{" "}
                          to&nbsp;
                          <span className="span-bold">
                            {data?.currentStatus ? "Active" : "Deactive"}
                          </span>
                        </span>
                      </Index.Typography>
                    </Index.Tooltip>
                  )}
                </Index.TableCell>

                <Index.TableCell
                  component="td"
                  variant="td"
                  className="table-td"
                >
                  {updatedInfo}
                </Index.TableCell>
              </>
            )}
          </Index.TableRow>
        );
      });
  };
  const renderActivityLogContent = () => {
    return (
      <>
        {!activityLoading &&
          activityLog?.length > 0 &&
          renderActivityTableRows()}
        {!activityLoading &&
          (!activityLog ||
            activityLog.length === 0 ||
            filterLog.length === 0) && <PagesIndex.DataNotFound />}
      </>
    );
  };
  const ScrollIntoView = () => {
    // Scroll to the first error when errors change
    const { errors, isSubmitting } = PagesIndex.useFormikContext(); // Extracting errors from the Formik context
    useEffect(() => {
      if (errors && Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        let scrollDiv = document.getElementById("scrollDiv");
        const firstErrorElement = document.querySelector(
          `[id="${firstErrorField}"]`
        );
        if (firstErrorElement) {
          scrollDiv.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          firstErrorElement.focus();
        }
      }
    }, [isSubmitting]);
  };
  return (
    <PagesIndex.Formik
      enableReinitialize
      onSubmit={handleAddEditCategory}
      initialValues={initialValues}
      validationSchema={PagesIndex.homeCategorySchema}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <>
          <Index.Box className="admin-dashboard-content admin-user-list-content">
            <Index.Box className="admin-user-list-flex admin-page-title-main">
              <Index.Typography
                className="admin-page-title admin-user-list-page-title"
                component="h2"
                variant="h2"
              >
                Home Category List
              </Index.Typography>
              <Index.Box className="admin-userlist-btn-flex">
                {/* <Index.Box className="admin-search-main">
                  <Index.Box className="admin-search-box">
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="admin-form-control"
                        placeholder="Search"
                        value={searchValue}
                        onChange={requestSearch}
                      />
                      <img
                        src={PagesIndex.Svg.search}
                        className="admin-search-grey-img admin-icon"
                        alt="search"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box> */}
                {adminDetails?.roleId?.permissions?.includes(
                  "home_category_add"
                ) && (
                    <Index.Box className="admin-userlist-inner-btn-flex">
                      <Index.Box className="admin-adduser-btn-main btn-main-primary">
                        <Index.Button
                          className="admin-adduser-btn btn-primary"
                          onClick={() => {
                            setId("");
                            handleOpen("Add");
                          }}
                        >
                          <img
                            src={PagesIndex.Svg.plus}
                            className="admin-plus-icon"
                            alt="plus"
                          />
                          <span>Add Home Category</span>
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  )}
              </Index.Box>
            </Index.Box>

            <Index.Box className="common-card">
              <Index.Box
                className={`admin-category-table-main ${loading ? "" : "page-table-main"
                  }`}
              >
                <DndProvider backend={HTML5Backend}>
                  {loading ? (
                    <PagesIndex.Loader />
                  ) : (
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table aria-label="simple table" className="table">
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="5%"
                            >
                              Sequence No.
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="20%"
                            >
                              Home Category Name
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="12%"
                              align="right"
                            >
                              Created By
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="12%"
                              align="right"
                            >
                              Updated By
                            </Index.TableCell>

                            {(adminDetails?.roleId?.permissions?.includes(
                              "home_category_edit"
                            ) ||
                              adminDetails?.roleId?.permissions?.includes(
                                "home_category_delete"
                              )) && (
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="6%"
                                  align="right"
                                >
                                  Status
                                </Index.TableCell>
                              )}

                            {(adminDetails?.roleId?.permissions?.includes(
                              "home_category_edit"
                            ) ||
                              adminDetails?.roleId?.permissions?.includes(
                                "home_category_delete"
                              ) ||
                              adminDetails?.roleId?.permissions?.includes(
                                "home_category_log"
                              )) && (
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="4%"
                                  align="right"
                                >
                                  Action
                                </Index.TableCell>
                              )}
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {renderContent(setFieldValue)}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  )}
                </DndProvider>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <form onSubmit={handleSubmit} noValidate="novalidate">
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {addOrEdit} Home Category
                  </Index.Typography>
                  {/* <Index.Box className="modal-close-btn" onClick={handleClose}>
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Box> */}
                </Index.Box>
                <Index.Box
                  className="admin-modal-hgt-scroll cus-scrollbar"
                  id="scrollDiv"
                >
                  {/* <ScrollIntoView /> */}
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Color<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        id="color"
                        name="color"
                        fullWidth
                        type="color"
                        className="admin-form-control"
                        placeholder="Enter Color"
                        onChange={handleChange}
                        value={values?.color}
                        error={errors.color && touched.color}
                        inputProps={{ maxLength: 40 }}
                      />
                      {touched.color && errors.color && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.color}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Home Category Name<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        name="name"
                        fullWidth
                        id="name"
                        className="admin-form-control"
                        placeholder="Enter Home Category Name"
                        onChange={handleChange}
                        value={values?.name}
                        error={errors.name && touched.name}
                        inputProps={{ maxLength: 40 }}
                      />
                      {touched.name && errors.name && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.name}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="admin-input-box admin-add-news-input-box">
                    <Index.Box className="admin-news-check-main admin-form-lable">
                      <Index.Box className="admin-checkbox-main">
                        <BpCheckbox
                          checked={values?.vertical}
                          onChange={() => {
                            const newValue = !values.vertical;
                            setFieldValue("vertical", newValue);
                          }}
                          name="vote"
                        />
                        <Index.Typography className="admin-checkbox-lable">
                          Vertical
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="admin-discard-btn-main border-btn-main">
                      <Index.Button
                        className="admin-discard-user-btn border-btn"
                        onClick={handleClose}
                        disabled={buttonSpinner}
                      >
                        Discard
                      </Index.Button>
                    </Index.Box>
                    <Index.Box className="admin-save-btn-main btn-main-primary">
                      <Index.Button
                        className={`${id ? "update-btn" : "save-btn"} admin-save-user-btn btn-primary`}
                        type="submit"
                        disabled={buttonSpinner}
                      >
                        {buttonSpinner ? <PagesIndex.Spinner /> :
                          (<>
                            <img
                              src={PagesIndex.Svg.save}
                              className="admin-user-save-icon"
                              alt="Save"
                            />
                            <span>{id ? "Update" : "Add"}</span>
                          </>)
                        }
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          </Index.Modal>

          <Index.Modal
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <Index.Box
              sx={style}
              className="admin-delete-modal-inner-main admin-modal-inner"
            >
              <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                <Index.Box className="admin-modal-circle-main">
                  <img
                    src={PagesIndex.Svg.closecircle}
                    className="admin-user-circle-img"
                    alt="Close"
                  />
                </Index.Box>
                <Index.Typography
                  className="admin-delete-modal-title"
                  component="h2"
                  variant="h2"
                >
                  Are you sure?
                </Index.Typography>
                <Index.Typography
                  className="admin-delete-modal-para admin-common-para"
                  component="p"
                  variant="p"
                >
                  Do you really want to delete this home category? This process
                  cannot be undone.
                </Index.Typography>
                <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
                  <Index.Button
                    className="admin-modal-cancel-btn border-btn"
                    onClick={handleCloseDelete}
                    disabled={deleteButtonSpinner}
                  >
                    Cancel
                  </Index.Button>
                  <Index.Button
                    className="delete-user admin-modal-delete-btn btn"
                    onClick={handleDeleteCategory}
                    disabled={deleteButtonSpinner}
                  >
                    {deleteButtonSpinner ? <PagesIndex.Spinner /> : <span>Delete</span>
                    }
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>

          <Index.Modal
            open={openLogModal}
            onClose={handleCloseLogModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <Index.Box
              sx={style}
              className="admin-log-modal-inner-main admin-modal-inner"
            >
              <Index.Box className="admin-modal-header">
                <Index.Typography
                  id="modal-modal-title"
                  className="admin-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {" "}
                  Activity Log
                </Index.Typography>
                <Index.Button
                  className="modal-close-btn"
                  onClick={handleCloseLogModal}
                >
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="admin-modal-close-icon"
                    alt="Close"
                  />
                </Index.Button>
              </Index.Box>
              <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                <Index.Box className="common-card">
                  <Index.Box className="page-table-main home-activity-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      {activityLoading ? (
                        <PagesIndex.Loader />
                      ) : (
                        <Index.Table
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="70%"
                              >
                                Activity Log
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="30%"
                              >
                                Updated Date & Time
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {renderActivityLogContent()}
                          </Index.TableBody>
                        </Index.Table>
                      )}
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>
          <PagesIndex.ChangeStatusModal
            data={"home category"}
            statusButtonSpinner={statusButtonSpinner}
            openStatusModal={openStatusModal}
            handleCloseStatusModal={handleCloseStatusModal}
            handleChangeStatus={handleStatus}
          />
        </>
      )}
    </PagesIndex.Formik>
  );
}

const ReorderableRow = ({ id, children, index, moveRow }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: DraggableTypes.REORDERABLE_ROW,
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DraggableTypes.REORDERABLE_ROW,
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Index.TableRow
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
      style={{ opacity }}
      ref={ref}
    >
      {children}
    </Index.TableRow>
  );
};
