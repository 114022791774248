import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import PropTypes from "prop-types";
import axios, { Axios } from "axios";
import './userPostVideo.css'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

// for custom switch design

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const TableData = ({
  filteredData,
  currentPage,
  handleOpenView,
  handleDownloadVideo,
  handleOpenLogModal,
}) => {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );

  return filteredData?.length ? (
    filteredData
      ?.map((data, index) => {
        return (
          <Index.TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            key={data?._id}
          >
            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Box className="admin-table-data-flex">
                <video
                  controls={false}
                  className="admin-table-data-img"
                >
                  <source
                    src={process.env.REACT_APP_IMAGE_ENDPOINT + data?.video + "#t=0.1"}
                    type="video/mp4"
                  />
                </video>
              </Index.Box>
            </Index.TableCell>
            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Tooltip
                title={data?.postNo || "-"}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text news-id-text">
                  {data?.postNo || "-"}
                </Index.Typography>
              </Index.Tooltip>

              <Index.Tooltip
                title={data?.title || "-"}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {data?.title || "-"}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Tooltip
                title={data?.userId?.email}
                arrow
                placement="top"
                className="admin-tooltip"
              >
                <Index.Box className="admin-main-data-flex">
                  <Index.Box className="admin-table-avatar-icon-box">
                    <img
                      src={data?.userId?.image ? process.env.REACT_APP_IMAGE_ENDPOINT + data?.userId?.image : PagesIndex.Svg.manIcon}
                      className="avtar-icons"
                      alt="user"
                    />
                  </Index.Box>
                  <Index.Box className="admin-inner-data-flex">

                    <Index.Typography className="admin-table-data-text">
                      {" "}
                      {data?.userId?.email}
                    </Index.Typography>
                    <Index.Typography className="admin-table-data-text">
                      {" "}
                      {data?.userId?.mobile}
                    </Index.Typography>

                  </Index.Box>
                </Index.Box>
              </Index.Tooltip>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={
                  data?.createdAt
                    ? PagesIndex.moment(data?.createdAt).format(
                      "DD/MM/YYYY hh:mm A"
                    )
                    : "-"
                }
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {" "}
                  {data?.createdAt
                    ? PagesIndex.moment(data?.createdAt).format(
                      "DD/MM/YYYY hh:mm A"
                    )
                    : "-"}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Box
                className={
                  data?.isAccept == true
                    ? "status-lable" : data?.isAccept == false ?
                      "status-lable-red" : "status-lable-orang"
                }
              >
                {" "}
                {data?.isAccept == true ? "Accepted" :
                  data?.isAccept == false ? "Rejected" : "Pending"}
              </Index.Box>
            </Index.TableCell>
            <Index.TableCell
              component="td"
              variant="td"
              className="table-td border-btn-main"
            >
              {data?.newsId?.newsNo ? (
                <Index.Link
                  to={`/admin/view-video/${data?.newsId?._id}`}
                  className="border-btn border-link"
                  target="_blank"
                >
                  {data?.newsId?.newsNo}
                </Index.Link>
              ) : (
                "-"
              )}
            </Index.TableCell>
            <Index.TableCell
              component="td"
              variant="td"
              className="table-td">
              <Index.Box className="admin-table-data-btn-flex">
                <Index.Tooltip
                  title="Download"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-table-data-btn"
                    onClick={() => handleDownloadVideo(data)}
                  >
                    <img
                      src={PagesIndex.Svg.download}
                      className="admin-icon"
                      alt="Download"
                    ></img>
                  </Index.Button>
                </Index.Tooltip>
                {adminDetails?.roleId?.permissions?.includes(
                  "user_posted_videos_log"
                ) && (
                    <Index.Tooltip
                      title="Log"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => handleOpenLogModal(data?._id)}
                      >
                        <img
                          src={PagesIndex.Svg.logicon}
                          className="admin-icon"
                          alt="Log"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}
                <Index.Tooltip
                  title="View"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-table-data-btn"
                    onClick={() => {
                      handleOpenView(data);
                    }}
                  >
                    <img
                      src={PagesIndex.Svg.yelloweye}
                      className="admin-icon"
                      alt="View"
                    ></img>
                  </Index.Button>
                </Index.Tooltip>
              </Index.Box>
            </Index.TableCell>
          </Index.TableRow >
        );
      })
  ) : (
    <PagesIndex.DataNotFound />
  );
};

export default function UserPostVideo() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataLength, setFilteredDataLength] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [openView, setOpenView] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const [openLogModal, setOpenLogModal] = useState(false);
  const [statusValue, setStatusValue] = useState("Pending");
  const handleCloseLogModal = () => setOpenLogModal(false);
  const [activityLog, setActivityLog] = useState([]);
  const [activityLoading, setActivityLoading] = useState(true);
  const [rejectSpinner, setRejectSpinner] = useState(false);
  const [accepteSpinner, setAccepteSpinner] = useState(false);

  const handleOpenLogModal = (id) => {
    setOpenLogModal(true);
    setActivityLoading(true);
    PagesIndex.apiGetHandler(
      PagesIndex.Api.USER_VIDEO_ACTIVITY_LOG + "/" + id
    ).then((res) => {
      setActivityLoading(false);
      if (res.status === 200) {
        setActivityLog(res?.data?.activityLog);
      }
    });
  };

  const renderActivityLogContent = () => {
    return (
      <>
        {!activityLoading &&
          activityLog?.length > 0 &&
          renderActivityTableRows()}
        {!activityLoading && (!activityLog || activityLog.length === 0) && (
          <PagesIndex.DataNotFound />
        )}
      </>
    );
  };

  const renderActivityTableRows = () => {
    return activityLog
      .slice()
      .reverse()
      .map((data) => {
        let updatedInfo = "-";

        if (data?.updatedBy?.name) {
          updatedInfo = `${data?.updatedBy?.name}-${data?.dateTime
            ? PagesIndex.moment(data?.dateTime).format("DD/MM/YYYY HH:mm:ss")
            : "-"
            }`;
        }
        const renderActionContent = () => {
          switch (data?.actionType) {
            case "Rejected":
              return (
                <span>
                  <span className="span-bold">{data?.updatedBy?.name}</span> has{" "}
                  <span className="span-bold">rejected</span> posted Video{" "}
                </span>
              );
            case "Accepted":
              return (
                <span>
                  <span className="span-bold">{data?.updatedBy?.name}</span> has{" "}
                  <span className="span-bold">accepted</span> posted Video{" "}
                </span>
              );
            case "Linked":
              return (
                <span>
                  <span className="span-bold">{data?.updatedBy?.name}</span> has{" "}
                  <span className="span-bold">linked</span> video to news{" "}
                </span>
              );
            case "Download post video":
              return (
                <span>
                  <span className="span-bold">{data?.updatedBy?.name}</span> has{" "}
                  <span className="span-bold">downloaded posted video</span>{" "}
                </span>
              );
            default:
              return null;
          }
        };

        return (
          <Index.TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            key={data?._id}
          >
            {updatedInfo !== "-" && (
              <>
                <Index.TableCell
                  component="td"
                  variant="td"
                  scope="row"
                  className="table-td"
                >
                  <Index.Tooltip
                    title={renderActionContent()}
                    arrow
                    placement="top"
                    className="admin-tooltip"
                  >
                    <Index.Typography className="admin-table-data-text">
                      {renderActionContent()}

                      {data?.previousStatus !== data?.currentStatus && (
                        <span>
                          <span className="span-bold">
                            {data?.updatedBy?.name}
                          </span>{" "} Status  has been updated{" "}
                          <span className="span-bold">
                            {!data?.currentStatus ? "Active" : "Deactive"}
                          </span>{" "}
                          to&nbsp;
                          <span className="span-bold">
                            {data?.currentStatus ? "Active" : "Deactive"}
                          </span>
                        </span>
                      )}
                    </Index.Typography>
                  </Index.Tooltip>
                </Index.TableCell>

                <Index.TableCell
                  component="td"
                  variant="td"
                  className="table-td"
                >
                  {updatedInfo}
                </Index.TableCell>
              </>
            )}
          </Index.TableRow>
        );
      });
  };

  useEffect(() => {
    handleGetUserPosts();
  }, [currentPage, searchValue, statusValue]);
  const requestSearch = (e) => {
    setSearchValue(e.target.value)
    let result = data.filter((data) => {

      let date = PagesIndex.moment(data?.createdAt)
        .format("DD/MM/YYYY hh:mm A")
        ?.toLowerCase()
        .includes(e?.target?.value?.trim()?.toLowerCase());

      let Post = data?.postNo
        ?.toLowerCase()
        .includes(e?.target.value?.trim()?.toLowerCase());

      let title = data?.title
        ?.toLowerCase()
        .includes(e?.target.value?.trim()?.toLowerCase());

      let id = data?.userId?.userName
        ?.toLowerCase()
        .includes(e?.target.value?.trim()?.toLowerCase());

      let fullName = (data?.userId?.firstName + " " + data?.userId?.lastName)
        ?.toLowerCase()
        .replace(/\s/g, "");

      let searchQuery = e?.target?.value
        ?.trim()
        ?.toLowerCase()
        .replace(/\s/g, "");

      return title || fullName.includes(searchQuery) || Post || date || id;
    });

    setCurrentPage(1);
    setFilteredData(result);

  };

  const handleSelect = (e) => {
    setStatusValue(e.target.value);
    setCurrentPage(1)
  }
  const handleGetUserPosts = () => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("pageNumber", currentPage);
    urlEncoded.append("pageSize", 10);
    if (searchValue) {
      urlEncoded.append("search", searchValue);
    }
    if (statusValue) {
      urlEncoded.append("status", statusValue);
    }

    PagesIndex.apiPostHandler(PagesIndex.Api.GET_POST_VIDEO_LIST, urlEncoded).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setData(res?.data?.paginatedResults);
        setFilteredData(res?.data?.paginatedResults);
        setFilteredDataLength(res?.data?.totalCount?.[0]?.count)
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const handleAcceptRejectUserPosts = (value, type) => {
    if (type === "accept") {
      setAccepteSpinner(true)
    } else if (type === "reject") {
      setRejectSpinner(true)
    }
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", selectedData?._id);
    urlEncoded.append("isAccept", value);
    PagesIndex.apiPostHandler(
      PagesIndex.Api.USER_ACCEPT_REJECT_VIDEO,
      urlEncoded
    ).then((res) => {
      setLoading(false);
      setAccepteSpinner(false)
      setRejectSpinner(false)
      if (res?.status === 200) {
        handleCloseView();
        handleGetUserPosts();
        PagesIndex.toasterSuccess(res?.message);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const handleOpenView = (data) => {
    setOpenView(true);
    setSelectedData(data);
  };
  const handleCloseView = () => {
    setOpenView(false);
    setSelectedData({});
  };
  const handleDownloadVideo = async (data) => {
    PagesIndex.apiGetHandler(
      PagesIndex.Api.USER_DOWNLOAD_VIDEO + "/" + data?._id
    ).then((res) => {
      if (res?.status === 200) {
        const a = document.createElement("a");
        a.setAttribute("download", data?.postNo); // Replace with the desired filename for the downloaded file
        a.href = process.env.REACT_APP_IMAGE_ENDPOINT + data?.video;
        a.click();
        window.URL.revokeObjectURL(
          process.env.REACT_APP_IMAGE_ENDPOINT + data?.video
        );
        PagesIndex.toasterSuccess(res?.message);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-page-title admin-user-list-page-title"
          component="h2"
          variant="h2"
        >
          User Posted Videos List
        </Index.Typography>
        <Index.Box className="admin-userlist-btn-flex">

          <Index.Box className="admin-input-box admin-add-event-input-box">
            {/* <Index.FormHelperText className="admin-form-lable">
              Status
            </Index.FormHelperText> */}
            <Index.Box className="admin-dropdown-box">
              <Index.FormControl className="admin-form-control admin-drop-form-control">
                <Index.Select
                  className="admin-dropdown-select admin-drop-select"
                  id="status"
                  name="status"
                  value={statusValue}
                  onChange={handleSelect}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  renderValue={
                    statusValue ? undefined : () => "Select Status"
                  }
                >
                  <Index.MenuItem
                    value={"Approve"}
                    className="admin-drop-menuitem"
                  >
                    Approve
                  </Index.MenuItem>
                  <Index.MenuItem
                    value={"Reject"}
                    className="admin-drop-menuitem"
                  >
                    Reject
                  </Index.MenuItem>
                  <Index.MenuItem
                    value={"Pending"}
                    className="admin-drop-menuitem"
                  >
                    Pending
                  </Index.MenuItem>
                </Index.Select>
              </Index.FormControl>
            </Index.Box>
          </Index.Box>

          <Index.Box className="admin-search-main">
            <Index.Box className="admin-search-box">
              <Index.Box className="admin-form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="admin-form-control"
                  placeholder="Search"
                  name="searchValue"
                  value={searchValue}
                  onChange={requestSearch}
                />
                <img
                  src={PagesIndex.Svg.search}
                  className="admin-search-grey-img admin-icon"
                  alt="search"
                ></img>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="common-card">
        <Index.Box className="admin-user-post-table-main page-table-main">
          <Index.TableContainer
            component={Index.Paper}
            className="table-container"
          >
            {loading ? (
              <PagesIndex.Loader />
            ) : (
              <Index.Table aria-label="simple table" className="table">
                <Index.TableHead className="table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="2%"
                    >
                      Video
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="7%"
                    >
                      Post No. & Title
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="5%"
                    >
                      App User
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="10%"
                    >
                      Uploaded Date & Time
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="5%"
                    >
                      Status
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="5%"
                    >
                      Linked News
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="3%"
                      align="right"
                    >
                      Action
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  <TableData
                    filteredData={filteredData}
                    currentPage={currentPage}
                    handleOpenView={handleOpenView}
                    handleDownloadVideo={handleDownloadVideo}
                    handleOpenLogModal={handleOpenLogModal}
                  />
                </Index.TableBody>
              </Index.Table>
            )}
          </Index.TableContainer>
        </Index.Box>
        <Index.Box className="admin-pagination-main">
          {filteredDataLength ? (
            <Index.Pagination
              page={currentPage}
              count={Math.ceil(filteredDataLength / 10)}
              onChange={(event, newPage) => setCurrentPage(newPage)}
              variant="outlined"
              shape="rounded"
              className="admin-pagination"
            />
          ) : (
            ""
          )}
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={openView}
        onClose={handleCloseView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-add-user-modal-inner-main admin-modal-inner admin-post-video-modal"
        >
          <Index.Box className="admin-modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="admin-modal-title"
              variant="h6"
              component="h2"
            >
              Uploaded Video
            </Index.Typography>
            <Index.Button className="modal-close-btn" onClick={handleCloseView}>
              <img
                src={PagesIndex.Svg.closeblack}
                className="admin-modal-close-icon"
                alt="Close"
              />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-input-box admin-modal-input-box">
              <Index.Box className="uoload-post-video-main">
                <video
                  controls
                  disablePictureInPicture
                  controlsList="nodownload nofullscreen"
                  autoPlay="autoplay"
                  muted
                  className="admin-profile-img uoload-post-video"
                >
                  <source
                    src={
                      process.env.REACT_APP_IMAGE_ENDPOINT + selectedData?.video
                    }
                    type="video/mp4"
                  />
                </video>
              </Index.Box>
            </Index.Box>
            <Index.Box className="post-video-content-main">
              <Index.Box className="view-content-box">
                <Index.Box className="view-content-flex post-video-content-flex">
                  <Index.Typography className="view-content-lable">Video Title:</Index.Typography>
                  <Index.Box className="view-content-data-flex">
                    <Index.Typography className="view-content-data">
                      {selectedData?.title && selectedData?.title || "-"}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="view-content-flex post-video-content-flex">
                  <Index.Typography className="view-content-lable">User Name:</Index.Typography>
                  <Index.Box className="view-content-data-flex">
                    <Index.Typography className="view-content-data">
                      {selectedData?.userId?.firstName &&
                        selectedData?.userId?.lastName
                        ? `${selectedData?.userId?.firstName} ${selectedData?.userId?.lastName}`
                        : selectedData?.userId?.userName || "-"}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="view-content-flex post-video-content-flex">
                  <Index.Typography className="view-content-lable">Video Description:</Index.Typography>
                  <Index.Box className="view-content-data-flex">
                    <Index.Typography className="view-content-data">
                      {selectedData?.description || "-"}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="modal-footer">
            <Index.Box className="admin-modal-user-btn-flex">
              {!selectedData.isAccept && selectedData.isAccept !== false ? (
                <>
                  <Index.Box className="admin-discard-btn-main border-btn-main download-btn-main">
                    <Index.Tooltip
                      title="Download"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => handleDownloadVideo(selectedData)}
                      >
                        <img
                          src={PagesIndex.Svg.download}
                          className="admin-icon"
                          alt="Download"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                    <Index.Button
                      disabled={accepteSpinner || rejectSpinner}
                      className="admin-discard-user-btn border-btn"
                      onClick={() => handleAcceptRejectUserPosts(false, "reject")}
                    >
                      {rejectSpinner ? <PagesIndex.Spinner /> : "Reject"}
                    </Index.Button>
                  </Index.Box>
                  <Index.Box className="admin-save-btn-main btn-main-primary">
                    <Index.Button
                      disabled={rejectSpinner || accepteSpinner}
                      className="admin-save-user-btn btn-primary aceept-btn"
                      onClick={() => handleAcceptRejectUserPosts(true, "accept")}
                    >
                      {accepteSpinner ? <PagesIndex.Spinner /> : <span>Accept</span>}
                    </Index.Button>
                  </Index.Box>
                </>
              ) : (
                ""
              )}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>

      <Index.Modal
        open={openLogModal}
        onClose={handleCloseLogModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-log-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="admin-modal-title"
              variant="h6"
              component="h2"
            >
              {" "}
              Activity Log
            </Index.Typography>
            <Index.Button
              className="modal-close-btn"
              onClick={handleCloseLogModal}
            >
              <img
                src={PagesIndex.Svg.closeblack}
                className="admin-modal-close-icon"
                alt="Close"
              />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="common-card">
              <Index.Box className="page-table-main state-activity-table-main">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  {activityLoading ? (
                    <PagesIndex.Loader />
                  ) : (
                    <Index.Table aria-label="simple table" className="table">
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="60%"
                          >
                            Activity Log
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="40%"
                          >
                            Updated Date & Time
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {renderActivityLogContent()}
                      </Index.TableBody>
                    </Index.Table>
                  )}
                </Index.TableContainer>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </Index.Box>
  );
}
TableData.propTypes = {
  filteredData: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
};
