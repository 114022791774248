import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { SwipeableDrawer } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import update from "immutability-helper";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
export const DraggableTypes = {
  REORDERABLE_ROW: "reorderable_row",
};
// for custom progressbar design

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

export default function AddScheduleTable() {
  const navigate = PagesIndex.useNavigate();
  const formRef = useRef();
  const params = PagesIndex.useParams();
  const { id, page } = params;
  const location = PagesIndex.useLocation();
  const { state } = location;
  const [days, setDays] = useState([]);
  const [advertiserList, setAdvertiserList] = useState([]);
  const [creativeList, setCreativeList] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedDataId, setSelectedDataId] = useState([]);
  const [selectDeleted, setSelectDeleted] = useState([]);
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTimeSlotStartTime, setSelectedTimeSlotStartTime] =
    useState("");
  const [selectedTimeSlotEndTime, setSelectedTimeSlotEndTime] = useState("");
  const [durationError, setDurationError] = useState("");
  const [openCalenderStart, setOpenCalenderStart] = useState(false);
  const [openCalenderEnd, setOpenCalenderEnd] = useState(false);
  const [startDate, setStartDate] = useState(
    PagesIndex.dayjs(state?.startDate)
  );
  const [endDate, setEndDate] = useState(PagesIndex.dayjs(state?.startDate).add(7,"days"));
  const initialValues = {
    type: page,
    advertiser: "",
    creative: {},
    noOfTimesPlay: "",
  };
  useEffect(() => {
    handleGetAdvertiser();
    handleGetCreatives();
    handleGetScheduleData();
  }, []);
  useEffect(() => {
    if (startDate && endDate) {
      getWeekDays();
    }
  }, [startDate, endDate]);
  const handleGetAdvertiser = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_ADVERTISER).then((res) => {
      if (res?.status === 200) {
        setAdvertiserList(
          res?.data?.filter((item) => item?.isActive && !item?.isDraft)
        );
      }
    });
  };
  const handleGetCreatives = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_CREATIVES).then((res) => {
      if (res?.status === 200) {
        setCreativeList(res?.data?.filter((item) => item?.isActive));
      }
    });
  };
  const handleGetScheduleData = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_SLOT_SCHEDULE + "/" + id).then(
      (res) => {
        if (res?.status === 200) {
          setScheduleData(res?.data);
        }
      }
    );
  };
  const getWeekDays = () => {
    let days = [];
    const start = PagesIndex.moment(new Date(startDate)).format("YYYY-MM-DD");
    const end = PagesIndex.moment(new Date(endDate)).format("YYYY-MM-DD");
    const startDateVal = PagesIndex.moment(new Date(start));
    const endDateVal = PagesIndex.moment(new Date(end));
    let daysRequired = endDateVal.diff(startDateVal, "days");
    for (let i = 0; i <= daysRequired; i++) {
      let objData = {
        date: PagesIndex.moment(startDateVal)
          .add(i, "day")
          .format("DD-MM-YYYY"),
      };
      days.push(objData);
    }
    setDays(days);
  };

  // filter
  const [stateFilter, setStateFilter] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => {
    if (!open) {
      setDurationError("");
      formRef.current.resetForm();
    }
    setStateFilter({ ...state, [anchor]: open });
  };

  const normalise = (value, diff) => {
    return ((value - 0) * 100) / (diff - 0);
  };
  const handleClickCell = (dataDays, data) => {
    setSelectedDate(dataDays?.date);
    const scheduleDataArr = scheduleData
      ?.filter(
        (dataSchedule) =>
          dataSchedule?.date === dataDays?.date &&
          PagesIndex?.moment(dataSchedule?.startTime).format("hh:mm:ss A") ===
          PagesIndex?.moment(data?.fromTime).format("hh:mm:ss A") &&
          PagesIndex?.moment(dataSchedule?.endTime).format("hh:mm:ss A") ===
          PagesIndex?.moment(data?.toTime).format("hh:mm:ss A")
      )
      ?.sort((a, b) => a?.sequence - b?.sequence)
      .map((scheduleData) => {
        return {
          date: dataDays?.date,
          startTime: data?.fromTime,
          endTime: data?.toTime,
          creative: scheduleData?.creative?._id,
          _id: scheduleData?._id,
        };
      });
    setSelectedDataId(scheduleDataArr);
    setSelectedData(
      scheduleData
        ?.filter(
          (dataSchedule) =>
            dataSchedule?.date === dataDays?.date &&
            PagesIndex?.moment(dataSchedule?.startTime).format("hh:mm:ss A") ===
            PagesIndex?.moment(data?.fromTime).format("hh:mm:ss A") &&
            PagesIndex?.moment(dataSchedule?.endTime).format("hh:mm:ss A") ===
            PagesIndex?.moment(data?.toTime).format("hh:mm:ss A")
        )
        ?.sort((a, b) => a?.sequence - b?.sequence)
        .map((scheduleData) => {
          return {
            creative: scheduleData?.creative,
            noOfTimesPlay: scheduleData?.noOfTimesPlay,
            _id: scheduleData?._id,
          };
        })
    );
    setSelectedTimeSlotStartTime(data?.fromTime);
    setSelectedTimeSlotEndTime(data?.toTime);
    toggleDrawer("right", true);
  };
  const handleAddEditSchedule = () => {
    setButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    const selectDataArray = selectedDataId?.map((data, index) => ({
      ...data,
      sequence: index + 1,
    }));
    urlEncoded.append("id", id);
    urlEncoded.append("ads", JSON.stringify(selectDataArray));
    urlEncoded.append("deleteIds", JSON.stringify(selectDeleted));
    PagesIndex.apiPostHandler(
      PagesIndex.Api.ADD_EDIT_SLOT_SCHEDULE,
      urlEncoded
    ).then((res) => {
      setButtonSpinner(false)
      if (res?.status === 200) {
        handleGetScheduleData();
        setSelectedDataId([]);
        setSelectDeleted([]);
        toggleDrawer("right", false);
      }
    });
  };
  const addData = () => {
    return selectedData?.reduce(
      (current, data) => data?.creative?.duration + current,
      0
    );
  };
  const timeDiff = () => {
    return PagesIndex.moment(selectedTimeSlotEndTime).diff(
      PagesIndex.moment(selectedTimeSlotStartTime),
      "seconds"
    );
  };
  const handleRemoveSelectedData = (setFieldValue, indexOuter, data) => {
    setSelectedData((prev) =>
      prev.filter((data, indexInner) => indexInner !== indexOuter)
    );
    if (data?._id) {
      setSelectDeleted((prev) => [...prev, data?._id]);
    }
    setSelectedDataId((prev) =>
      prev.filter((data, indexInner) => indexInner !== indexOuter)
    );
  };
  const timeDiffVal = timeDiff();
  const addDataVal = addData();
  const handleAddData = (values) => {
    if (
      page === "FCT" &&
      values?.creative?.duration + addDataVal <= timeDiffVal
    ) {
      setSelectedData((prev) => [
        ...prev,
        {
          creative: values?.creative,
        },
      ]);
      setSelectedDataId((prev) => [
        ...prev,
        {
          date: selectedDate,
          startTime: selectedTimeSlotStartTime,
          endTime: selectedTimeSlotEndTime,
          creative: values?.creative?._id,
        },
      ]);
      setDurationError("");
      formRef.current.resetForm();
    }
    if (page === "NonFCT") {
      setSelectedData((prev) => [
        ...prev,
        {
          creative: values?.creative,
          noOfTimesPlay: values?.noOfTimesPlay,
        },
      ]);
      setSelectedDataId((prev) => [
        ...prev,
        {
          date: selectedDate,
          startTime: selectedTimeSlotStartTime,
          endTime: selectedTimeSlotEndTime,
          creative: values?.creative?._id,
          noOfTimesPlay: values?.noOfTimesPlay,
        },
      ]);
      setDurationError("");
      formRef.current.resetForm();
    }
    if (
      page === "FCT" &&
      values?.creative?.duration + addDataVal > timeDiffVal
    ) {
      setDurationError(
        "Selcted creative exceeds break duration, Select different creative"
      );
    }
  };
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = selectedData[dragIndex];
      const dragRowId = selectedDataId[dragIndex];
      setSelectedData(
        update(selectedData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
      setSelectedDataId(
        update(selectedDataId, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRowId],
          ],
        })
      );
    },
    [selectedData]
  );
  return (
    <Index.Box className="admin-dashboard-content admin-user-list-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-page-title admin-user-list-page-title"
          component="h2"
          variant="h2"
        >
          {page === "FCT" ? "FCT" : "Non FCT"} Schedule
        </Index.Typography>
        <Index.Box className="admin-userlist-inner-btn-flex">
          <Index.Box className="admin-modal-input-box admin-date-input-main">
            <Index.Box className="admin-form-group" id="startDate">
              <PagesIndex.LocalizationProvider dateAdapter={Index.AdapterDayjs}>
                <PagesIndex.DatePicker
                  open={openCalenderStart}
                  onClose={() => setOpenCalenderStart(false)}
                  className="admin-form-control admin-date-picker-control"
                  value={startDate}
                  minDate={PagesIndex.dayjs(state?.startDate).add(0, "day")}
                  maxDate={PagesIndex.dayjs(state?.endDate).add(0, "day")}
                  onChange={(date) => {
                    setStartDate(PagesIndex.moment(new Date(date)));
                    setEndDate(null);
                  }}
                  slotProps={{
                    popper: {
                      className: "date-picker-body",
                    },
                    textField: {
                      readOnly: true,
                      onClick: () => setOpenCalenderStart(true),
                    },
                  }}
                  format="DD/MM/YYYY"
                />
              </PagesIndex.LocalizationProvider>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-modal-input-box admin-date-input-main">
            <Index.Box className="admin-form-group" id="startDate">
              <PagesIndex.LocalizationProvider dateAdapter={Index.AdapterDayjs}>
                <PagesIndex.DatePicker
                  open={openCalenderEnd}
                  onClose={() => setOpenCalenderEnd(false)}
                  className="admin-form-control admin-date-picker-control"
                  value={endDate}
                  minDate={
                    startDate
                      ? PagesIndex.dayjs(startDate).add(0, "day")
                      : PagesIndex.dayjs(state?.startDate).add(0, "day")
                  }
                  maxDate={PagesIndex.dayjs(state?.endDate).add(0, "day")}
                  onChange={(date) => {
                    setEndDate(PagesIndex.moment(new Date(date)));
                  }}
                  slotProps={{
                    popper: {
                      className: "date-picker-body",
                    },
                    textField: {
                      readOnly: true,
                      onClick: () => setOpenCalenderEnd(true),
                    },
                  }}
                  format="DD/MM/YYYY"
                />
              </PagesIndex.LocalizationProvider>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-adduser-btn-main btn-main-primary">
            <Index.Button
              className="admin-adduser-btn btn-primary"
              onClick={() => {
                navigate(`/admin/view-slot-schedule/${page}/${id}`);
              }}
            >
              <span>View Schedule</span>
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="common-card">
        <Index.Box className="page-table-main add-schedule-table-main">
          <Index.TableContainer
            component={Index.Paper}
            className="table-container"
          >
            <Index.Table aria-label="simple table" className="table">
              <Index.TableHead className="table-head">
                <Index.TableRow className="table-row">
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                  // width="10%"
                  >
                    Slot
                  </Index.TableCell>
                  {days?.map((data) => {
                    return (
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        key={data?.date}
                      >
                        {data?.date}
                      </Index.TableCell>
                    );
                  })}
                </Index.TableRow>
              </Index.TableHead>
              <Index.TableBody className="table-body">
                {state?.slotId?.slot?.map((data) => {
                  return (
                    <Index.TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      key={Math.random() + 0}
                    >
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                      >
                        <Index.Typography className="admin-table-slot-box">
                          {PagesIndex.moment(data?.fromTime).format(
                            "hh:mm:ss A"
                          )}{" "}
                          -{" "}
                          {PagesIndex.moment(data?.toTime).format("hh:mm:ss A")}
                        </Index.Typography>
                      </Index.TableCell>
                      {days?.map((dataDays) => {
                        return (
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            key={dataDays?.date}
                          >
                            <Index.Box className="slot-content-main">
                              <Index.Box className="slot-flex">
                                {scheduleData
                                  ?.filter(
                                    (dataSchedule) =>
                                      dataSchedule?.date === dataDays?.date &&
                                      PagesIndex?.moment(
                                        dataSchedule?.startTime
                                      ).format("hh:mm:ss A") ===
                                      PagesIndex?.moment(
                                        data?.fromTime
                                      ).format("hh:mm:ss A") &&
                                      PagesIndex?.moment(
                                        dataSchedule?.endTime
                                      ).format("hh:mm:ss A") ===
                                      PagesIndex?.moment(data?.toTime).format(
                                        "hh:mm:ss A"
                                      )
                                  )
                                  ?.sort((a, b) => a?.sequence - b?.sequence)
                                  .map((data) => {
                                    return (
                                      <Index.Button
                                        className="slot-creative-select-text"
                                        key={data?._id}
                                      // onClick={(e) =>
                                      //   handleDeleteSlot(data?._id)
                                      // }
                                      >
                                        {data?.creative?.code}
                                      </Index.Button>
                                    );
                                  })}
                              </Index.Box>
                              <Index.Box className="slot-creative-icon-flex">
                                {page === "FCT" && (
                                  <Index.Button className="slot-creative-progress-btn">
                                    <span className="slot-value-lable">
                                      {PagesIndex.moment(data?.toTime).diff(
                                        PagesIndex.moment(data?.fromTime),
                                        "seconds"
                                      ) -
                                        scheduleData
                                          ?.filter(
                                            (dataSchedule) =>
                                              dataSchedule?.date ===
                                              dataDays?.date &&
                                              PagesIndex?.moment(
                                                dataSchedule?.startTime
                                              ).format("hh:mm:ss A") ===
                                              PagesIndex?.moment(
                                                data?.fromTime
                                              ).format("hh:mm:ss A") &&
                                              PagesIndex?.moment(
                                                dataSchedule?.endTime
                                              ).format("hh:mm:ss A") ===
                                              PagesIndex?.moment(
                                                data?.toTime
                                              ).format("hh:mm:ss A")
                                          )
                                          ?.reduce(
                                            (current, data) =>
                                              data?.creative?.duration +
                                              current,
                                            0
                                          )}
                                    </span>
                                    <span className="slot-value-total">
                                      /{" "}
                                      {PagesIndex.moment(data?.toTime).diff(
                                        PagesIndex.moment(data?.fromTime),
                                        "seconds"
                                      )}
                                    </span>
                                  </Index.Button>
                                )}
                                <Index.Button
                                  className="slot-creative-icon-btn"
                                  onClick={(e) =>
                                    handleClickCell(dataDays, data)
                                  }
                                >
                                  <img
                                    src={PagesIndex.Svg.plusWhiteIcon}
                                    alt="plus"
                                    className="slot-plus-icon"
                                  />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                        );
                      })}
                    </Index.TableRow>
                  );
                })}
              </Index.TableBody>
            </Index.Table>
          </Index.TableContainer>
        </Index.Box>
      </Index.Box>
      <Index.Box className="admin-filter-main">
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            <SwipeableDrawer
              className="drawer-filter-main"
              anchor={anchor}
              open={stateFilter[anchor]}
              onClose={(e) => toggleDrawer(anchor, false)}
            >
              <Index.Box className="admin-filter-header">
                <Index.Typography className="admin-filter-title">
                  Select Creative
                </Index.Typography>
                <Index.Button onClick={(e) => toggleDrawer(anchor, false)}>
                  <img
                    src={PagesIndex.Png.close}
                    className="admin-filter-close-icon"
                    alt="Close"
                  />
                </Index.Button>
              </Index.Box>
              <PagesIndex.Formik
                enableReinitialize
                onSubmit={handleAddData}
                initialValues={initialValues}
                validationSchema={PagesIndex.addSlotSchema}
                innerRef={formRef}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  setFieldError,
                }) => {
                  return (
                    <form onSubmit={handleSubmit} noValidate="novalidate">
                      <Index.Box className="admin-filter-inner-main">
                        <Index.Box className="admin-input-box add-user-input">
                          <Index.FormHelperText className="admin-form-lable">
                            Select Client
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.Box className="admin-dropdown-box">
                              <Index.FormControl className="admin-form-control">
                                <Index.Autocomplete
                                  className="admin-auto-complete-control"
                                  options={advertiserList || []}
                                  id="advertiser"
                                  value={
                                    values?.advertiser
                                      ? advertiserList?.find(
                                        (o) => o._id === values.advertiser
                                      ) ?? null
                                      : null
                                  }
                                  disableClearable
                                  closeText={""}
                                  openText={""}
                                  clearText={""}
                                  onChange={(e, value) => {
                                    setFieldValue("advertiser", value?._id);
                                    setFieldTouched("advertiser", false);
                                    setFieldValue("creative", {});
                                    setDurationError("");
                                  }}
                                  getOptionLabel={(option) => option?.compName}
                                  renderOption={(props, item) => (
                                    <li {...props} key={item?._id}>
                                      {item?.compName}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      className="admin-form-control"
                                      {...params}
                                      size="small"
                                      name="advertiser"
                                      placeholder="Select Client"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Index.FormControl>
                            </Index.Box>
                          </Index.Box>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.advertiser && touched.advertiser
                              ? errors.advertiser
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>

                        <Index.Box className="admin-input-box add-user-input">
                          <Index.FormHelperText className="admin-form-lable">
                            Select Creative
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.Box className="admin-dropdown-box">
                              <Index.FormControl className="admin-form-control">
                                <Index.Autocomplete
                                  className="admin-auto-complete-control"
                                  options={creativeList
                                    .filter(
                                      (data) =>
                                        values?.advertiser ===
                                        data?.clientId?._id
                                    )
                                    .filter((data) => {
                                      if (page === "FCT") {
                                        return (
                                          data?.platformId?._id ===
                                          "664360b5b539341a1a081253"
                                        );
                                      } else if (page === "NonFCT") {
                                        return (
                                          data?.platformId?._id ===
                                          "665465fac233edbd7824176a"
                                        );
                                      }
                                    })
                                    .filter((item) => {
                                      return page === "NonFCT"
                                        ? !selectedDataId
                                          ?.map((data) => data?.creative)
                                          .includes(item?._id)
                                        : item;
                                    })}
                                  id="creative"
                                  value={
                                    values?.creative
                                      ? creativeList?.find(
                                        (o) => o._id === values.creative?._id
                                      ) ?? null
                                      : null
                                  }
                                  disableClearable
                                  closeText={""}
                                  openText={""}
                                  clearText={""}
                                  onChange={(e, value) => {
                                    setFieldValue("creative", value);
                                  }}
                                  getOptionLabel={(option) => {
                                    if (page === "FCT") {
                                      return `${option?.code}-${option?.name} (${option?.duration} sec)`;
                                    }
                                    if (page === "NonFCT") {
                                      return `${option?.code}-${option?.name}`;
                                    }
                                  }}
                                  renderOption={(props, item) => (
                                    <li {...props} key={item?._id}>
                                      {item?.code}-{item?.name}{" "}
                                      {page === "FCT"
                                        ? `${item?.duration} sec`
                                        : ""}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      className="admin-form-control"
                                      {...params}
                                      size="small"
                                      name="creative"
                                      placeholder="Select Creative"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Index.FormControl>
                            </Index.Box>
                          </Index.Box>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.creative?._id ?? durationError ?? null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>

                        {page === "NonFCT" && (
                          <Index.Box className="admin-input-box add-user-input">
                            <Index.FormHelperText className="admin-form-lable">
                              No. Of Times Play
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                fullWidth
                                name="noOfTimesPlay"
                                id="fullWidth"
                                className="admin-form-control"
                                placeholder="Enter No. Of Times Play"
                                value={values?.noOfTimesPlay}
                                onChange={(e) => {
                                  const regex = /^[0-9]*$/;
                                  if (regex.test(e.target.value)) {
                                    handleChange(e);
                                  }
                                }}
                              />
                            </Index.Box>
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.noOfTimesPlay && touched.noOfTimesPlay
                                ? errors.noOfTimesPlay
                                : null}
                            </PagesIndex.FormHelperText>
                          </Index.Box>
                        )}
                        <Index.Box className="admin-filter-btn-main border-btn-main btn-main-primary">
                          <Index.Button
                            className="btn-primary admin-filter-btn"
                            type="submit"
                          >
                            Add
                          </Index.Button>
                        </Index.Box>
                        {page === "FCT" && (
                          <Index.Box className="admin-progress-bar-content">
                            <Index.Box className="admin-progress-bar-main">
                              <BorderLinearProgress
                                variant="determinate"
                                value={normalise(addDataVal, timeDiffVal)}
                                className="admin-progress-bar"
                              />
                              <span className="admin-progress-bar-text">
                                {addDataVal} of {timeDiffVal} Seconds
                              </span>
                            </Index.Box>
                          </Index.Box>
                        )}
                        {selectedData?.length ? (
                          <>
                            <Index.Box className="analytic-title-flex list-ads-title">
                              <Index.Box className="admin-sub-title-main">
                                <Index.Typography
                                  className="admin-sub-title"
                                  component="h2"
                                  variant="h2"
                                >
                                  List of Ads
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="common-card">
                              <Index.Box className="page-table-main add-schedule-table">
                                <DndProvider backend={HTML5Backend}>
                                  <Index.TableContainer
                                    component={Index.Paper}
                                    className="table-container"
                                  >
                                    <Index.Table
                                      aria-label="simple table"
                                      className="table"
                                    >
                                      <Index.TableHead className="table-head">
                                        <Index.TableRow className="table-row">
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                          >
                                            Priority
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                          >
                                            Client Name
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                          >
                                            Creative
                                          </Index.TableCell>
                                          {page == "FCT" && (
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                            >
                                              Duration
                                            </Index.TableCell>
                                          )}
                                          {page === "NonFCT" && (
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                            >
                                              No. of Times Play
                                            </Index.TableCell>
                                          )}
                                          <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            align="right"
                                          >
                                            Action
                                          </Index.TableCell>
                                        </Index.TableRow>
                                      </Index.TableHead>
                                      <Index.TableBody className="table-body">
                                        {selectedData
                                          ?.sort(
                                            (a, b) => a?.sequence - b?.sequence
                                          )
                                          ?.map((data, indexOuter) => {
                                            return (
                                              <ReorderableRow
                                                key={data?._id}
                                                index={indexOuter}
                                                id={data?._id}
                                                moveRow={moveRow}
                                              >
                                                <Fragment key={data?._id}>
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    scope="row"
                                                    className="table-td"
                                                  >
                                                    {indexOuter + 1}
                                                  </Index.TableCell>
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    scope="row"
                                                    className="table-td"
                                                  >
                                                    <Index.Typography className="add-schedule-tale-text">
                                                      {
                                                        data?.creative?.clientId
                                                          ?.compName
                                                      }
                                                    </Index.Typography>
                                                  </Index.TableCell>
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    className="table-td"
                                                  >
                                                    <Index.Typography className="add-schedule-tale-text">
                                                      {data?.creative?.name}
                                                    </Index.Typography>
                                                  </Index.TableCell>
                                                  {page === "FCT" && (
                                                    <Index.TableCell
                                                      component="td"
                                                      variant="td"
                                                      className="table-td"
                                                    >
                                                      <Index.Typography className="add-schedule-tale-text">
                                                        {
                                                          data?.creative
                                                            ?.duration
                                                        }
                                                      </Index.Typography>
                                                    </Index.TableCell>
                                                  )}
                                                  {page === "NonFCT" && (
                                                    <Index.TableCell
                                                      component="td"
                                                      variant="td"
                                                      className="table-td"
                                                    >
                                                      <Index.Typography className="add-schedule-tale-text">
                                                        {data?.noOfTimesPlay}
                                                      </Index.Typography>
                                                    </Index.TableCell>
                                                  )}

                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    className="table-td"
                                                  >
                                                    <Index.Box className="admin-table-data-btn-flex">
                                                      <Index.Tooltip
                                                        title="Delete"
                                                        arrow
                                                        placement="bottom"
                                                        className="admin-tooltip"
                                                      >
                                                        <Index.Button
                                                          className="admin-table-data-btn"
                                                          onClick={() =>
                                                            handleRemoveSelectedData(
                                                              setFieldValue,
                                                              indexOuter,
                                                              data
                                                            )
                                                          }
                                                        >
                                                          <img
                                                            src={
                                                              PagesIndex.Svg
                                                                .trash
                                                            }
                                                            className="admin-icon"
                                                            alt="Delete"
                                                          ></img>
                                                        </Index.Button>
                                                      </Index.Tooltip>
                                                    </Index.Box>
                                                  </Index.TableCell>
                                                </Fragment>
                                              </ReorderableRow>
                                            );
                                          })}
                                      </Index.TableBody>
                                    </Index.Table>
                                  </Index.TableContainer>
                                </DndProvider>
                              </Index.Box>
                            </Index.Box>
                          </>
                        ) : (
                          ""
                        )}
                      </Index.Box>
                      <Index.Box className="admin-filter-footer">
                        <Index.Box className="admin-filter-btn-main border-btn-main btn-main-primary">
                          <Index.Button
                            className="border-btn admin-filter-cancel-btn"
                            onClick={(e) => toggleDrawer("right", false)}
                            disabled={buttonSpinner}
                          >
                            Cancel
                          </Index.Button>
                          <Index.Button
                            className="btn-primary admin-filter-btn"
                            onClick={handleAddEditSchedule}
                            disabled={buttonSpinner}
                          >
                            {buttonSpinner ? <PagesIndex.Spinner /> : "Apply"}
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </form>
                  );
                }}
              </PagesIndex.Formik>
            </SwipeableDrawer>
          </React.Fragment>
        ))}
      </Index.Box>
    </Index.Box>
  );
}

const ReorderableRow = ({ id, children, index, moveRow }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: DraggableTypes.REORDERABLE_ROW,
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DraggableTypes.REORDERABLE_ROW,
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Index.TableRow
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
      // style={{ opacity }}
      ref={ref}
    >
      {children}
    </Index.TableRow>
  );
};
