import React, { useCallback, useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

// for modal design
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function Creative(props) {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const params = PagesIndex.useParams();
  const { id } = params;
  const formRef = useRef();
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const videoRef = useRef();
  const { creativeListData, handleGetCreatives, creativeCount } = props;
  const [open, setOpen] = useState(false);
  const [elementsData, setElementsData] = useState([]);
  const [programTypeData, setProgramTypeData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const initialValues = {
    clientId: id,
    platform: "",
    elements: "",
    code: `NC-${PagesIndex.moment().format("MMMYY").toUpperCase()}-${
      creativeCount < 9 ? "0" + (creativeCount + 1) : creativeCount + 1
    }`,
    name: "",
    duration: "",
    imageVideo: "",
    imageUrl: "",
  };
  const renderImageVideo = (values) => {
    const isUploadImg = values?.imageUrl || values.imageVideo;
    const isImageBlob = values.imageVideo instanceof Blob;
    const imageUrl = values?.imageUrl
      ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${values?.imageUrl}`
      : null;
    let src;

    if (isImageBlob) {
      src = URL.createObjectURL(values.imageVideo);
    } else if (isUploadImg) {
      src = imageUrl;
    } else {
      src = PagesIndex.Svg.fileupload;
    }
    if (values.imageVideo.type === "video/mp4" || imageUrl?.includes(".mp4")) {
      return (
        <video
          ref={videoRef}
          className="upload-img"
          loop={true}
          autoPlay="autoplay"
          muted
        >
          <source src={src} type="video/mp4" />
        </video>
      );
    } else {
      return (
        <img
          className={isUploadImg ? "upload-img" : "upload-profile-img"}
          src={src}
          alt="category "
        />
      );
    }
  };
  const handleOpen = (op, id, data) => {
    setOpen(true);
  };
  const handleClose = () => {
    formRef.current.resetForm();
    setOpen(false);
  };
  useEffect(() => {
    handleGetClients();
    handleGetElements();
    handleGetProgramType();
  }, []);
  const handleGetClients = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_ADVERTISER).then((res) => {
      if (res.status === 200) {
        const activeZone = res?.data?.filter(
          (item) => item?.isActive && !item?.isDraft
        );
        setClientData(activeZone);
      }
    });
  };
  const handleGetProgramType = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_PROGRAM_TYPE).then((res) => {
      if (res.status === 200) {
        const activeZone = res?.data?.filter((channel) => channel?.isActive);

        setProgramTypeData(activeZone);
      }
    });
  };
  const handleGetElements = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ELEMENTS).then((res) => {
      if (res?.status === 200) {
        setElementsData(res?.data?.filter((item) => item?.isActive));
      }
    });
  };
  const handleAddEditCreative = (values) => {
    setButtonSpinner(true);
    const formData = new FormData();
    formData.append("clientId", values?.clientId);
    formData.append("platformId", values?.platform);
    formData.append("elementId", values?.elements);
    formData.append("code", values?.code);
    formData.append("name", values?.name);
    if (values?.duration !== null) {
      formData.append("duration", values?.duration);
    }
    formData.append("image", values?.imageVideo);

    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_EDIT_CREATIVE, formData)
      .then((res) => {
        setButtonSpinner(false);
        if (res.status === 201 || res.status === 200) {
          handleClose();
          handleGetCreatives();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };
  return (
    <Index.Box>
      <Index.Box className="dash-slider-row">
        <Index.Box className="admin-user-list-flex admin-page-title-main">
          <Index.Typography
            className="admin-page-title admin-user-list-page-title"
            component="h2"
            variant="h2"
          ></Index.Typography>
          <Index.Box className="admin-userlist-btn-flex">
            {adminDetails?.roleId?.permissions?.includes("creative_add") && (
              <Index.Box className="admin-userlist-inner-btn-flex">
                <Index.Box className="admin-adduser-btn-main btn-main-primary">
                  <Index.Button
                    className="admin-adduser-btn btn-primary"
                    onClick={() => {
                      handleOpen();
                    }}
                  >
                    <img
                      src={PagesIndex.Svg.plus}
                      className="admin-plus-icon"
                      alt="plus"
                    />
                    <span>Add Creative</span>
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>
        <Index.Box className="page-table-main common-card">
          <Index.TableContainer
            component={Index.Paper}
            className="table-container"
          >
            <Index.Table aria-label="simple table" className="table">
              <Index.TableHead className="table-head">
                <Index.TableRow className="table-row">
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="31%"
                  >
                    Creative Name
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="15%"
                  >
                    Platform
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="15%"
                  >
                    Element
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="15%"
                  >
                    Duration
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="15%"
                  >
                    Created By
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="15%"
                  >
                    Updated By
                  </Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              <Index.TableBody className="table-body">
                {creativeListData?.length ? (
                  creativeListData?.map((data) => {
                    return (
                      <Index.TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        key={data?.documentName}
                      >
                        <Index.TableCell
                          component="td"
                          variant="td"
                          className="table-td"
                        >
                          <Index.Tooltip
                            title={data?.name}
                            arrow
                            placement="top"
                            className="admin-tooltip"
                          >
                            <Index.Typography className="admin-table-data-text">
                              <span className="news-id-text">{data?.code}</span>
                              {data?.name}
                            </Index.Typography>
                          </Index.Tooltip>
                        </Index.TableCell>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          className="table-td "
                        >
                          {data?.platformId?.typeName || "-"}
                        </Index.TableCell>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          className="table-td "
                        >
                          {data?.elementId?.name || "-"}
                        </Index.TableCell>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          className="table-td "
                        >
                          {data?.duration || "-"}
                        </Index.TableCell>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          className="table-td"
                        >
                          <Index.Typography className="admin-table-data-text">
                            {data?.createdBy?.name || "-"}
                            {"-"}
                            {PagesIndex.moment(data?.createdAt).format(
                              "DD/MM/YYYY hh:mm A"
                            )}
                          </Index.Typography>
                        </Index.TableCell>

                        <Index.TableCell
                          component="td"
                          variant="td"
                          className="table-td"
                        >
                          <Index.Typography className="admin-table-data-text">
                            {data?.updatedBy?.name || "-"}
                            {"-"}
                            {PagesIndex.moment(data?.updatedAt).format(
                              "DD/MM/YYYY hh:mm A"
                            )}
                          </Index.Typography>
                        </Index.TableCell>
                      </Index.TableRow>
                    );
                  })
                ) : (
                  <PagesIndex.DataNotFound />
                )}
              </Index.TableBody>
            </Index.Table>
          </Index.TableContainer>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <PagesIndex.Formik
          enableReinitialize
          onSubmit={handleAddEditCreative}
          initialValues={initialValues}
          validationSchema={
            id ? PagesIndex.creativeEditSchema : PagesIndex.creativeAddSchema
          }
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} noValidate="novalidate">
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Add Creative
                  </Index.Typography>
                </Index.Box>

                <Index.Box
                  component={"form"}
                  noValidate="novalidate"
                  onSubmit={handleSubmit}
                  className="admin-modal-hgt-scroll cus-scrollbar"
                  id="scrollDiv"
                >
                  {/* <ScrollIntoView /> */}
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Code<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        name="code"
                        fullWidth
                        id="code"
                        className="admin-form-control"
                        placeholder="Enter Code"
                        onChange={handleChange}
                        value={values?.code}
                        disabled
                        inputProps={{ maxLength: 10 }}
                        // error={errors.code && touched.code}
                      />
                      {touched.code && errors.code && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.code}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Client<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.Autocomplete
                        className="admin-auto-complete-control"
                        options={clientData || []}
                        id="clientId"
                        closeText={""}
                        openText={""}
                        disabled
                        clearText={""}
                        value={
                          values?.clientId
                            ? clientData?.find(
                                (o) => o._id === values?.clientId
                              )
                            : values?.clientId || null
                        }
                        onChange={(e, value) => {
                          setFieldValue("clientId", value?._id);
                        }}
                        getOptionLabel={(option) => option?.compName}
                        renderOption={(props, item) => (
                          <li {...props} key={item?._id}>
                            {item?.compName}
                          </li>
                        )}
                        renderInput={(params) => (
                          <Index.TextField
                            fullWidth
                            className="admin-form-control"
                            name="clientId"
                            {...params}
                            size="small"
                            variant="outlined"
                            placeholder="Select Client"
                          />
                        )}
                      />
                      {touched.clientId && errors.clientId && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.clientId}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Platform<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.Autocomplete
                        className="admin-auto-complete-control"
                        options={
                          programTypeData?.filter(
                            (data) =>
                              !data.typeName?.toLowerCase().includes("ott")
                          ) || []
                        }
                        name="platform"
                        id="platform"
                        closeText={""}
                        openText={""}
                        clearText={""}
                        value={
                          values?.platform
                            ? programTypeData?.find(
                                (o) => o._id === values?.platform
                              )
                            : values?.platform || null
                        }
                        onChange={(e, value) => {
                          setFieldValue("platform", value?._id);
                          setFieldValue("elements", "");
                        }}
                        getOptionLabel={(option) => option?.typeName}
                        renderOption={(props, item) => (
                          <li {...props} key={item?._id}>
                            {item?.typeName}
                          </li>
                        )}
                        renderInput={(params) => (
                          <Index.TextField
                            fullWidth
                            className="admin-form-control"
                            name="platform"
                            {...params}
                            size="small"
                            variant="outlined"
                            placeholder="Select Platform"
                          />
                        )}
                      />
                      {touched.platform && errors.platform && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.platform}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Element<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.Autocomplete
                        className="admin-auto-complete-control"
                        options={
                          elementsData?.filter(
                            (data) =>
                              data?.programTypeId?._id === values?.platform
                          ) || []
                        }
                        name="elements"
                        id="elements"
                        closeText={""}
                        openText={""}
                        clearText={""}
                        value={
                          values?.elements
                            ? elementsData?.find(
                                (o) => o._id === values?.elements
                              )
                            : values?.elements || null
                        }
                        onChange={(e, value) => {
                          setFieldValue("elements", value?._id);
                        }}
                        getOptionLabel={(option) => option?.name}
                        renderOption={(props, item) => (
                          <li {...props} key={item?._id}>
                            {item?.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <Index.TextField
                            fullWidth
                            className="admin-form-control"
                            name="programType"
                            {...params}
                            size="small"
                            variant="outlined"
                            placeholder="Select Element"
                          />
                        )}
                      />
                      {touched.elements && errors.elements && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.elements}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Creative Name<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        name="name"
                        fullWidth
                        id="name"
                        className="admin-form-control"
                        placeholder="Enter Creative Name"
                        onChange={handleChange}
                        value={values?.name}
                        inputProps={{ maxLength: 40 }}
                        // error={errors.name && touched.name}
                      />
                      {touched.name && errors.name && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.name}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  {values?.platform === "664360b5b539341a1a081253" ? (
                    <Index.Box className="admin-input-box admin-modal-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Duration<span className="astrick-sing">*</span>
                        <span className="option-condition-text">
                          (in seconds)
                        </span>
                        <span className="option-condition-text">
                          (Max Value: 600 Seconds)
                        </span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          name="duration"
                          fullWidth
                          id="duration"
                          className="admin-form-control"
                          placeholder="Enter Duration"
                          onChange={(e) => {
                            const regex = /^[0-9]*$/;
                            if (
                              regex.test(e.target.value) &&
                              e.target.value <= 600
                            ) {
                              handleChange(e);
                            }
                          }}
                          value={values?.duration}
                          // error={errors.duration && touched.duration}
                        />
                        {touched.duration && errors.duration && (
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.duration}
                          </PagesIndex.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Box>
                  ) : (
                    <></>
                  )}
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Content<span className="astrick-sing">*</span>
                      <span className="option-condition-text">
                        (Image/Video)
                      </span>
                    </Index.FormHelperText>
                    <Index.Box className="file-upload-main thumbnail-box">
                      {values?.imageVideo || values?.imageUrl ? (
                        <Index.Box className="file-upload-main thumbnail-box admin-file-browse-btn-main ">
                          <Index.Box className="upload-news-img-box">
                            {renderImageVideo(values)}
                          </Index.Box>
                          <Index.Button
                            variant="contained"
                            component="label"
                            className="admin-file-browse-btn"
                          >
                            <img
                              src={PagesIndex.Svg.whiteedit}
                              className="admin-browse-icon-img"
                              alt="upload img"
                            ></img>
                            <input
                              hidden
                              accept="image/*, video/mp4"
                              id="imageVideo"
                              name="imageVideo"
                              type="file"
                              onChange={(e) => {
                                if (e.target.files?.length !== 0) {
                                  setFieldValue(
                                    "imageVideo",
                                    e.target.files[0]
                                  );
                                }
                              }}
                            />
                          </Index.Button>
                        </Index.Box>
                      ) : (
                        <Index.Box className="file-upload-main thumbnail-box">
                          <Index.Button
                            variant="contained"
                            component="label"
                            className="file-upload-button"
                          >
                            {renderImageVideo(values)}
                            <input
                              hidden
                              accept="image/*, video/mp4"
                              id="imageVideo"
                              name="imageVideo"
                              type="file"
                              onChange={(e) => {
                                if (e.target.files?.length !== 0) {
                                  setFieldValue(
                                    "imageVideo",
                                    e.target.files[0]
                                  );
                                }
                              }}
                            />
                          </Index.Button>
                        </Index.Box>
                      )}
                    </Index.Box>
                    {touched.imageVideo && errors.imageVideo && (
                      <PagesIndex.FormHelperText className="admin-error-text">
                        {errors.imageVideo}
                      </PagesIndex.FormHelperText>
                    )}
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="border-btn-main">
                      <Index.Button
                        className="border-btn"
                        onClick={() => handleClose()}
                        disabled={buttonSpinner}
                      >
                        Discard
                      </Index.Button>
                    </Index.Box>
                    <Index.Box className="btn-main-primary">
                      <Index.Button
                        className="btn-primary"
                        type="submit"
                        disabled={buttonSpinner}
                      >
                        {buttonSpinner ? (
                          <PagesIndex.Spinner />
                        ) : (
                          <>
                            <img
                              src={PagesIndex.Svg.save}
                              className="admin-user-save-icon"
                              alt="Save"
                            />
                            <span>Add</span>
                          </>
                        )}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>
    </Index.Box>
  );
}
